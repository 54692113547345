import { useState } from 'react'
import { Navbar, Group, Code, ScrollArea, createStyles, Button, UnstyledButton, Avatar } from '@mantine/core';
import {
    Home,
    Pencil,
    DatabaseImport,
    ReportMoney,
    Notes,
    Settings,
    Help,
    MailForward,
    ChevronDown,
    Logout,
    Heart,
    Star,
    ListNumbers,
    ChartBar
  } from 'tabler-icons-react';
  import { Menu, Text, useMantineTheme } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';
import { TextInput, TextInputProps, ActionIcon } from '@mantine/core';
import { IconSearch, IconArrowRight, IconArrowLeft } from '@tabler/icons';
// import { UserButton } from '../UserButton/UserButton';
import LinksGroup from '../dashboard/LinksGroup';

import logo from "../../assets/logo.png"
import "./Dashboard.css"

import { getAuth, signOut } from 'firebase/auth';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useStore } from '../../hooks/useStore';
import { useHotkeys } from '@mantine/hooks';


const mockdata = [
    { label: 'Főoldal',
      icon: Home,
      link: "/home"
    },
    {
        label: "Statisztikák",
        icon: ChartBar,
        link: "/statistics"
    },
    {
      label: 'Napi bejegyzések',
      icon: Pencil,
      initiallyOpened: false,
      link: "/daily-posts"
    },
    {
      label: 'Adatbázis',
      icon: DatabaseImport,
      links: [
        { label: 'Adattáblák', link: '/database/tables' },
        { label: 'Feltöltés', link: '/database/upload' },
        { label: 'AI', link: '/database/ai' },
      ],
    },
    { label: 'Tervezett Költségek',
      icon: ReportMoney,
      link: "/planned-costs" },
    { label: 'Szerződések',
      icon: Notes,
      links: [
        { label: 'Saját szerződések', link: '/own-contracts' },
        { label: 'Alvállalkozói szerződések', link: '/subcontractor-contracts' },
        { label: 'Saját szerződéses haladások', link: '/own-contract-progress' },
        { label: 'Alvállalkozói szerződéses haladások', link: '/subcontractor-contract-progress' },
        ],
    },
    {
        label: 'Munkaszámok',
        icon: ListNumbers,
        link: "/project-numbers"
      },
  ];
const useStyles = createStyles((theme) => ({
navbar: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
},

header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderBottom: `1px solid ${
    theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
},

links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
},

linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
},

footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
    theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
},
}));

function Dashboard() {
    const { classes } = useStyles();
    // const isLoading = useStore((state:any) => state.isLoading)
    const links = mockdata.map((item: any) => item.links ? <LinksGroup {...item} key={item.label} size={33}/> : <Link to={item.link} key={item.link}><LinksGroup {...item} size={33}/></Link>);

    return (
        <div className='page'>
            <div className='leftsidebar'>
                <Navbar height={800} width={{ sm: 300 }} p="md" className={classes.navbar}>
                    <Navbar.Section className={classes.header}>
                    <Group position="apart">
                        <img width={120} src={logo}/>
                        <Code sx={{ fontWeight: 700 }}>v1.0.0 április</Code>
                    </Group>
                    </Navbar.Section>
            
                    <Navbar.Section grow className={classes.links} component={ScrollArea}>
                        <ButtonMenu />
                    <div className={classes.linksInner}>{links}</div>
                    </Navbar.Section>
            
                    <Navbar.Section className={classes.footer}>
                        <Link to={'/settings'}>
                            <LinksGroup icon={Settings} label={'Beállítások'} size={30}/>
                        </Link>
                        <Link to={'/help'}>
                            <LinksGroup icon={Help} label={'Segítség'} size={30}/>
                        </Link>
                    {/* <UserButton
                        image="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=255&q=80"
                        name="Ann Nullpointer"
                        email="anullpointer@yahoo.com"
                    /> */}
                    </Navbar.Section>
                </Navbar>

            </div>
            <div className='rightbar'>
                <div className='topbar'>
                    <div></div>
                    <InputWithButton></InputWithButton>
                    <div className='profile'>
                        <ProfileButton></ProfileButton>
                    </div>
                </div>
                <div className='page-content'>
                    {/* {children} */}
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Dashboard












function ButtonMenu() {
    const theme = useMantineTheme();
    const navigate = useNavigate()

    function openNewDay(){
        navigate("/daily-posts/new-day")
    }

    function openNewPlan(){
        navigate("/planned-costs/new-plan")
    }

    function openNewProjectNumber(){
        navigate("/project-numbers")
    }


    useHotkeys([
        ['alt+n', () => openNewDay()],
        ['alt+t', () => openNewPlan()],
        ['alt+m', () => openNewProjectNumber()],
      ]);


    return (
        <div className='buttonmenu'>
            <Menu transition="pop-top-right" position="top-end" width={220}>
                <Menu.Target>
                <Button rightIcon={<IconChevronDown size={16} stroke={1.5} />} pr={10} color={"green"} variant="light">
                    Új Hozzáadása
                </Button>
                </Menu.Target>
                <Menu.Dropdown className='dropdown'>
                <Menu.Item
                    onClick={openNewDay}
                    icon={<Pencil size={16} color={theme.colors.blue[6]} />}
                    rightSection={
                        <Text size="xs" transform="uppercase" weight={700} color="dimmed">
                            Alt + N
                        </Text>
                    }
                >
                    Napi Bejegyzés
                </Menu.Item>
                <Menu.Item
                    onClick={openNewPlan}
                    icon={<ReportMoney size={16} color={theme.colors.lime[6]} />}
                    rightSection={
                        <Text size="xs" transform="uppercase" weight={700} color="dimmed">
                            Alt + T
                        </Text>
                    }
                >
                    Tervezett
                </Menu.Item>
                <Menu.Item
                    onClick={openNewProjectNumber}
                    icon={<ListNumbers size={16} color={theme.colors.red[6]} />}
                    rightSection={
                        <Text size="xs" transform="uppercase" weight={700} color="dimmed">
                            Alt + M
                        </Text>
                    }
                >
                    Munkaszám
                </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </div>
    );
  }

function InputWithButton(props: TextInputProps) {
    const theme = useMantineTheme();

    return (
        <TextInput
        className='searchbox'
        icon={<IconSearch size={18} stroke={1.5} />}
        radius="xl"
        size="md"
        rightSection={
            <ActionIcon size={32} radius="xl" color={"green"} variant="filled">
            {theme.dir === 'ltr' ? (
                <IconArrowRight size={18} stroke={1.5} />
            ) : (
                <IconArrowLeft size={18} stroke={1.5} />
            )}
            </ActionIcon>
        }
        placeholder="Keres bárhol..."
        rightSectionWidth={42}
        {...props}
        />
    );
}

function ProfileButton(){

    const { classes, theme, cx } = useStylesProfile();
    const [userMenuOpened, setUserMenuOpened] = useState(false);
    const auth = getAuth();
    let navigate = useNavigate();

    function signout_from_firebase(){
        signOut(auth).then(() => {
            localStorage.removeItem("user")
            localStorage.removeItem("photoURL")
            localStorage.removeItem("displayName")

            // clear database cache
            sessionStorage.clear()
            console.log("Signout successfull")
            navigate("/login")
        }).catch((error: Error) => {
            console.log(error)
        // An error happened.
        });
    }

    return (
        <Menu
        width={260}
        position="bottom-end"
        transition="pop-top-right"
        onClose={() => setUserMenuOpened(false)}
        onOpen={() => setUserMenuOpened(true)}
        >
           <Menu.Target>
              <UnstyledButton
                className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
              >
                <Group spacing={7}>
                  <Avatar src={JSON.parse(localStorage.getItem("photoURL") || "")} alt={""} radius="xl" size={20} />
                  <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                    {JSON.parse(localStorage.getItem("displayName") || "")}
                  </Text>
                  <IconChevronDown size={12} stroke={1.5} />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              {/* <Menu.Label>Segítség</Menu.Label> */}
              <Menu.Item icon={<MailForward size={14} color={theme.colors.green[6]} />}>
                  Elérhetőségek
              </Menu.Item>
              {/* <Menu.Label>Bejegyzések</Menu.Label> */}
              <Menu.Item icon={<Heart size={14} color={theme.colors.red[6]} />}>
                Mentett Bejegyzések
              </Menu.Item>
              <Menu.Item icon={<Star size={14} color={theme.colors.yellow[6]} />}>
                Mentett Tervezett
              </Menu.Item>
              {/* <Menu.Item icon={<Message size={14} color={theme.colors.blue[6]} />}>
                Your comments
              </Menu.Item> */}
  
              <Menu.Label>Fiók</Menu.Label>
              <Menu.Item icon={<ReportMoney size={14} />}>Előfizetésem</Menu.Item>
              <Menu.Item icon={<Settings size={14} />}>Fiók Beállítások</Menu.Item>
              <Menu.Item onClick={signout_from_firebase} color="red" icon={<Logout size={14} />}>Kijelentkezés</Menu.Item>
  
              {/* <Divider />
  
              <Menu.Label>Danger zone</Menu.Label>
              <Menu.Item icon={<PlayerPause size={14} />}>Pause subscription</Menu.Item>
              <Menu.Item color="red" icon={<Trash size={14} />}>
                Delete account
              </Menu.Item> */}
            </Menu.Dropdown>
        </Menu>
    )
}
const useStylesProfile = createStyles((theme) => ({
header: {
    paddingTop: theme.spacing.sm,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    borderBottom: `1px solid ${
    theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[2]
    }`,
    marginBottom: 120,
},

mainSection: {
    paddingBottom: theme.spacing.sm,
},

user: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    },

    [theme.fn.smallerThan('xs')]: {
    display: 'none',
    },
},

burger: {
    [theme.fn.largerThan('xs')]: {
    display: 'none',
    },
},

userActive: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
},

tabs: {
    [theme.fn.smallerThan('sm')]: {
    display: 'none',
    },
},

tabsList: {
    borderBottom: '0 !important',
},

tab: {
    fontWeight: 500,
    height: 38,
    backgroundColor: 'transparent',

    '&:hover': {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
    },

    '&[data-active]': {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
    },
},
}));