import { ActionIcon, Button, Modal, ScrollArea, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { doc, updateDoc } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AlertCircle, Check, DeviceFloppy, Edit, Plus, Upload } from 'tabler-icons-react'
import { useFirestore } from '../../../hooks/useFirebase'
import { useStore } from '../../../hooks/useStore'
import { contractContentType } from '../../../interfaces/contractContentType'
import SheetNamesRow from '../SheetNamesRow'




interface AddEditSheetNamesModalProps {
    IsEditMode: boolean,
    showActionIcon: boolean
}

export default function AddEditSheetNamesModal({ IsEditMode, showActionIcon }: AddEditSheetNamesModalProps){

    const [uploadManuallyModalOpened, setUploadManuallyModalOpened] = useState(false)

    const { docId } = useParams()

    const viewport = useRef<HTMLDivElement>(null);
    const scrollToBottom = () => viewport.current?.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });

    const setContractContent = useStore((state:any) => state.setContractContent)
    const contractContent: contractContentType = useStore((state:any) => state.contractContent)
    
    // async function handleSave(){
    //     try {
    //         const db = useFirestore()
    //         const ref = doc(db, "contract", docId || "");
    
    //         // Set the "capital" field of the city 'DC'
    //         await updateDoc(ref, {
    //             content: contractContent
    //         });

    //         showNotification({
    //             id: 'noti',
    //             color: "green",
    //             title: `Szerződéses tartalom sikeresen módosítva`,
    //             icon: <Check size={16} />,
    //             message: 'A szerződést sikeresen módosítottuk',
    //             autoClose: 5000,
    //         });
    //     } catch (error) {
    //         console.log(error)
    //         showNotification({
    //             id: 'noti',
    //             color: "red",
    //             title: `Hiba történt`,
    //             icon: <AlertCircle size={16} />,
    //             message: 'Nem sikerült módosítani a szerződéses tartalmat, kérlek később próbáld újra!',
    //             autoClose: false,
    //         });
    //     }

    //     setUploadManuallyModalOpened(false)
    // }

    function removeSheetName(indexToRemove: number){
        const contractContentCopy = contractContent
        contractContent.splice(indexToRemove, 1);
        setContractContent([...contractContentCopy])
    }

    useEffect(() => {
      scrollToBottom()
    }, [contractContent])
    

    return (
        <>

        {showActionIcon ? (
            <ActionIcon
                variant="outline"
                size={"lg"}
                style={{marginLeft: "15px", transform: "translateY(-3px)"}}
                onClick={() => setUploadManuallyModalOpened(true)}
            >
                <Edit size={16} />
            </ActionIcon>
        ) : (
            <Button leftIcon={<Upload/>} onClick={() => setUploadManuallyModalOpened(true)}>Feltöltés manuálisan</Button>
        )}

        <Modal
            withCloseButton
            opened={uploadManuallyModalOpened}
            onClose={() => setUploadManuallyModalOpened(false)}
            size={"xl"}
            title={<h4 style={{margin: 0}}>Munkalapok {IsEditMode ? "szerkesztése" : "létrehozása"}</h4>}
        >
        <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "10px"}}>

        <div style={{width: "100%", display: "flex", alignContent: "center", justifyContent: "center"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>

                {contractContent.length == 0 ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}><Text>Adj hozzá munkalapot, a lenti munkalap hozzáadása gombbal!</Text></div>
                ) : (
                    <ScrollArea style={{ height: "400px" }} type="always" offsetScrollbars viewportRef={viewport}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            {contractContent.map((item, index) => <SheetNamesRow
                                                                    key={item.id}
                                                                    itemIndex={index}
                                                                    contractContent={contractContent}
                                                                    removeButtonClick={removeSheetName}
                                                                    isEditMode={IsEditMode}
                                                                  />)}

                        </div>
                    </ScrollArea>
                )}
                    
                <div style={{display: "flex", gap: "20px", marginTop: "30px", width: "80%"}}>
                    <Button
                        fullWidth
                        variant={"outline"}
                        leftIcon={<Plus/>}
                        color={"gray"}
                        onClick={() => setContractContent([...contractContent, { id: Date.now().toString(), sheetName: `Új munkalap ${contractContent.map((item: { sheetName: string }) =>  item.sheetName).length + 1}`, sheetData: [] }])}
                    >
                        Munkalap Hozzáadása
                    </Button>
                    <Button
                        fullWidth
                        variant={"outline"}
                        leftIcon={<DeviceFloppy/>}
                        onClick={() => setUploadManuallyModalOpened(false)}
                    >
                        Mentés és bezárás
                    </Button>
                </div>
            </div>
        </div>
        </div>
        </Modal>
        </>
    )
}
