import { Accordion, createStyles } from '@mantine/core'
import React from 'react'
import { TableSort } from './TableSort';

const useStyles = createStyles((theme) => ({
    wrapper: {
      paddingTop: theme.spacing.xl * 2,
      paddingBottom: theme.spacing.xl * 2,
      minHeight: 650,
    },
  
    title: {
      marginBottom: theme.spacing.xl * 1.5,
    },
  
    item: {
      borderRadius: theme.radius.md,
      marginBottom: theme.spacing.lg,
  
      border: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
  }));

interface TableBlockType {
  rowArray: {data: any;company: string;table_name: string;_firestore_id: string;}[],
  table_name: string
}

export default function TableBlock({ rowArray, table_name }: TableBlockType) {

  const { classes } = useStyles();
  
  

  return (
    //the default value means that it is initially open
    <Accordion variant="separated" defaultValue={"block"}>
        <Accordion.Item className={classes.item} value="block">
          <Accordion.Control><h2 style={{margin: "5px"}}>{table_name}</h2></Accordion.Control>
          <Accordion.Panel>
          <TableSort data={rowArray} />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
  )
}
