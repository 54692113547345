import React, { HtmlHTMLAttributes, useRef } from "react";
// import { FaSquare, FaCheckSquare, FaMinusSquare } from "react-icons/fa";
// import { IoMdArrowDropright } from "react-icons/io";
import TreeView, { flattenTree } from "react-accessible-treeview";
import cx from "classnames";
import "./styles.css";
import { ArrowBadgeRight, ChevronRight, Square, SquareCheck, SquareOff } from "tabler-icons-react";
import { Checkbox, ScrollArea } from "@mantine/core";

const folder = {
  name: "",
  children: [
    {
      name: "Költségek",
      children: [
        { name: "Cég szerint", children: [
          { name: "Napi" },
          { name: "Heti" },
          { name: "Havi" },
          { name: "Évi" },
        ] },
        { name: "Adattáblák szerint", children: [
          { name: "Napi" },
          { name: "Heti" },
          { name: "Havi" },
          { name: "Évi" },
        ] },
        { name: "Munkaszámok szerint", children: [
          { name: "Napi" },
          { name: "Heti" },
          { name: "Havi" },
          { name: "Évi" },
        ] },
      ],
    },
    {
        name: "Bevételek",
        children: [
          { name: "Megrendelő szerint", children: [
            { name: "Heti" },
            { name: "Havi" },
            { name: "Évi" },
          ] },
          { name: "Munkaszámok szerint", children: [
            { name: "Heti" },
            { name: "Havi" },
            { name: "Évi" },
          ] },
        ],
      },
    {
        name: "Eredmények",
        children: [
          { name: "Havi" },
          { name: "Évi" },
          { name: "Megrendelő szerint", children: [
            { name: "Heti" },
            { name: "Havi" },
            { name: "Évi" },
          ] },
          { name: "Munkaszámok szerint", children: [
            { name: "Heti" },
            { name: "Havi" },
            { name: "Évi" },
          ] },
        ],
      },
    // {
    //     name: "Tervezett és valós költségek összehasonlítása",
    //     children: [
    //       { name: "Cég szerint", children: [
    //         { name: "Napi" },
    //         { name: "Heti" },
    //         { name: "Havi" },
    //         { name: "Évi" },
    //       ] },
    //       { name: "Adattáblák szerint", children: [
    //         { name: "Napi" },
    //         { name: "Heti" },
    //         { name: "Havi" },
    //         { name: "Évi" },
    //       ] },
    //       { name: "Munkaszámok szerint", children: [
    //         { name: "Napi" },
    //         { name: "Heti" },
    //         { name: "Havi" },
    //         { name: "Évi" },
    //       ] },
    //     ],
    //   },
    // {
    //     name: "Mennyiségek",
    //     children: [
    //       { name: "Cég szerint", children: [
    //         { name: "Napi" },
    //         { name: "Heti" },
    //         { name: "Havi" },
    //         { name: "Évi" },
    //       ] },
    //       { name: "Adattáblák szerint", children: [
    //         { name: "Napi" },
    //         { name: "Heti" },
    //         { name: "Havi" },
    //         { name: "Évi" },
    //       ] },
    //       { name: "Munkaszámok szerint", children: [
    //         { name: "Napi" },
    //         { name: "Heti" },
    //         { name: "Havi" },
    //         { name: "Évi" },
    //       ] },
    //     ],
    //   },
  ],
};

const data = flattenTree(folder);

interface BuiltyTreeViewProps {
  selectedIds: number[];
  setSelectedIds: Function;
}
function BuiltyTreeView({ selectedIds, setSelectedIds }: BuiltyTreeViewProps) {

  const ref = useRef<HTMLUListElement>(null);
  return (
    <ScrollArea h={"415px"}>
      <div className="checkbox" style={{width: "470px"}}>
        <TreeView
          data={data}
          ref={ref}
          aria-label="Checkbox tree"
          multiSelect
          propagateSelect
          propagateSelectUpwards
          togglableSelect
          defaultSelectedIds={selectedIds}
          onSelect={({treeState}) => {
            console.log("treeState", Array.from(treeState.selectedIds));
            setSelectedIds(Array.from(treeState.selectedIds));
          }}
          onExpand={async (props) => {
            if(props.isExpanded === false) {
              ref.current?.classList.add("expanded");
              // hint: react-transition-group library
              // continue
            }
          }}
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            isSelected,
            isHalfSelected,
            getNodeProps,
            level,
            handleSelect,
            handleExpand,
          }) => {
            return (
              <div
                {...getNodeProps({ onClick: handleExpand })}
                style={{ marginLeft: 40 * (level - 1) }}
              >
                {isBranch && <ArrowIcon isOpen={isExpanded} />}
                {/* {isBranch && <ChevronRight />} */}
                <CheckBoxIcon
                  className="checkbox-icon"
                  onClick={(e: React.MouseEvent<unknown, unknown> | React.KeyboardEvent<unknown>) => {
                    handleSelect(e);
                    e.stopPropagation();
                  }}
                  variant={
                    isHalfSelected ? "some" : isSelected ? "all" : "none"
                  }
                />
                <span
                    className="name"
                    onClick={(e: React.MouseEvent<unknown, unknown> | React.KeyboardEvent<unknown>) => {
                        handleSelect(e);
                        e.stopPropagation();
                    }}
                >{element.name}</span>
              </div>
            );
          }}
        />
      </div>
    </ScrollArea>
  );
}

const ArrowIcon = ({ isOpen, className }: { isOpen: boolean; className?: string }) => {
  const baseClass = "arrow";
  const classes = cx(
    baseClass,
    { [`${baseClass}--closed`]: !isOpen },
    { [`${baseClass}--open`]: isOpen },
    className
  );
  return <ChevronRight className={classes} />;
};

const CheckBoxIcon = ({ variant, ...rest }: { variant: "all" | "none" | "some"; className: string; onClick: (e: React.MouseEvent<unknown, unknown>) => void }) => {
  switch (variant) {
    case "all":
      return <Checkbox checked readOnly {...rest} />;
    case "none":
      return <Checkbox checked={false} readOnly {...rest} />;
    case "some":
      return <Checkbox checked={false} readOnly indeterminate {...rest} />;
    default:
      return null;
  }
};

export default BuiltyTreeView;