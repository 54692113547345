import { ActionIcon, Button, Modal, Select, Switch, useMantineTheme } from "@mantine/core";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertCircle, ChevronDown, Edit, Eye, Plus } from "tabler-icons-react";
import { useAuth, useFirestore } from "../../../../hooks/useFirebase";

import { yellow_button } from "../../../../App";
import { useStore } from "../../../../hooks/useStore";
import { FormSchemeType, FormStateType } from "../../../../interfaces/formTypes";
import { DailyPostType } from "../../../../interfaces/dailyPostType";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import FullDailyCostsModal from "./FullDailyCostsModal/FullDailyCostsModal";

interface HeaderProps {
  projectNumbers: string[],
  selectedProjectNumber: string | null,
  setProjectNumbers: Function,
  setSelectedProjectNumber: Function,
  mode: string,
  setModeIsDelete: Function,
  // withAddedContractProgress: boolean,
  // setWithAddedContractProgress: Function
  posts: DailyPostType[],
}

export default function Header({ projectNumbers, selectedProjectNumber, setProjectNumbers, setSelectedProjectNumber, mode, setModeIsDelete, posts }: HeaderProps) {

  // const formState: FormStateType = useStore((state:any) => state.formState)
  // const setFormState = useStore((state:any) => state.setFormState)
  // const formScheme: FormSchemeType = useStore((state:any) => state.formScheme)
  // const setFormScheme = useStore((state:any) => state.setFormScheme)

  const [fullDailyCostsModalIsOpened, setFullDailyCostsModalIsOpened] = useState(false)

  const navigate = useNavigate()
  const theme = useMantineTheme();

  console.log('%cHeader.tsx line:20 mode', 'color: #007acc;', mode);

  async function getProjectNumbers(){
    const db = useFirestore()
    const user = useAuth()
    const docRef = doc(db, "user", user.uid);
    const docSnap = await getDoc(docRef);
    
    let projectNumbers: string[] = []
    // projectNumbers.push("Összes munkaszám")
    docSnap.data()?.project_numbers.forEach((project:string) => projectNumbers.push(project))

    setProjectNumbers(projectNumbers)
  }

  // function decideUnsavedButton(){
  //   if(mode.toLowerCase().includes("dailyPost".toLowerCase())){
  //     if(localStorage.getItem("daily-form") != "[]" && localStorage.getItem("daily-form") != null){
  //       return true
  //     } else { return false }
  //   } else {
  //     if(localStorage.getItem("planned-form") != "[]" && localStorage.getItem("planned-form") != null){
  //       return true
  //     } else { return false }
  //   }
  // }

  useEffect(() => {
    getProjectNumbers()
  }, [])
  

  return <><div style={{
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "space-between",
    "margin": "-5px 0px 20px 0px"
  }}>
    <h2 style={{"margin": "0px"}}>{ mode == "dailyPost" ? "Napi bejegyzések" : "Költség tervezettek"}</h2>
    <div style={{"display": "flex", "alignItems": "center", "gap": "20px"}}>
      {/* <Switch
        label="Alv. szerz. mutatása"
        checked={withAddedContractProgress}
        onChange={() => setWithAddedContractProgress((withAddedContractProgress: boolean) => !withAddedContractProgress)}
      /> */}
      {mode == "dailyPost" ? (
        <Button leftIcon={<Eye size={20} />} variant="light" onClick={() => setFullDailyCostsModalIsOpened(true)}>Teljes napi költségek</Button>
      ): ""}
      <Select
        label=""
        style={{"width": "250px"}}
        placeholder="Munkaszám szűrő"
        searchable
        clearable
        nothingFound="Nincs találat"
        maxDropdownHeight={280}
        data={projectNumbers}
        value={selectedProjectNumber}
        onChange={(text) => setSelectedProjectNumber(text)}
        styles={(theme) => ({
          item: {
            // applies styles to selected item
            '&[data-selected]': {
              '&, &:hover': {
                backgroundColor:
                  mode === 'dailyPost' ? theme.colors.green[8] : yellow_button,
              },
            },
  
            // applies styles to hovered item (with mouse or keyboard)
            '&[data-hovered]': {},
          },
          input: {
            ":focus-within": {
              borderColor: mode === 'dailyPost' ? theme.colors.green[8] : yellow_button,
            }
          }
        })}
      />
      <ActionIcon size={36} variant="default" onClick={() => setModeIsDelete((modeIsDelete: boolean) => !modeIsDelete)}>
        <Edit size={16} />
      </ActionIcon>
      {
      // decideUnsavedButton() ? ( // if the form is empty, it still stores an emplty array in localstorage
      //   <Button
      //   styles={(theme) => ({
      //     root: {
      //       backgroundColor: theme.colors.red[9],
      //       '&:hover': {
      //         backgroundColor: theme.fn.darken(theme.colors.red[6], 0.1),
      //       },
      //     },
      //   })}
      //   leftIcon={<AlertCircle size={20} />}
      //   onClick={() => navigate(mode == "dailyPost" ? "/daily-posts/new-day" : "/planned-costs/new-plan")}>
      //     Befejezetlen {mode == "dailyPost" ? "összesítő" : "tervezett"} folytatása
      //   </Button>
      // ) : (
        <Button
        leftIcon={<Plus size={20} />}
        onClick={() => {navigate(mode == "dailyPost" ? "/daily-posts/new-day" : "/planned-costs/new-plan")}}
        style={{backgroundColor: mode === "dailyPost" ? theme.colors.green[8] : yellow_button}}
        >
          Új {mode == "dailyPost" ? "összesítő" : "tervezett"} létrehozása
        </Button>
      // )
      }
    </div>
      </div>


      <FullDailyCostsModal
        fullDailyCostsModalIsOpened={fullDailyCostsModalIsOpened}
        setFullDailyCostsModalIsOpened={setFullDailyCostsModalIsOpened}
        projectNumbers={projectNumbers}
        selectedProjectNumber={selectedProjectNumber}
        setSelectedProjectNumber={setSelectedProjectNumber}
        posts={posts}
      />
    </>
}
  