import { ActionIcon, Button, Divider, Loader, Paper, Popover, Progress, ScrollArea, Select, Switch, Table, Text, useMantineTheme } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { ChartBar, ChartInfographic, ChartLine, ChartPie, DotsVertical, Download, Plus, Printer } from 'tabler-icons-react'
import { yellow_button } from '../../../../App'
import StatisticsProgress from '../../../../components/StatisticsProgress/StatisticsProgress'
import { ContractType } from '../../../../interfaces/contractType'
import { DailyPostType } from '../../../../interfaces/dailyPostType'


interface CostBudgetProps {
    projectNumbersList: string[],
    selectedProjectNumber: string | null,
    setSelectedProjectNumber: Function,
    dailyPosts: DailyPostType[],
    plannedCosts: DailyPostType[],
    ownContracts: ContractType[],
    subcontractorContracts: ContractType[],
    isLoading: boolean,
    setIsLoading: Function
}

export default function CostBudget({ projectNumbersList, selectedProjectNumber, setSelectedProjectNumber, dailyPosts, plannedCosts, ownContracts, subcontractorContracts, isLoading, setIsLoading }: CostBudgetProps) {

  const theme = useMantineTheme()

  const [dailyCostWithoutContracts, setDailyCostWithoutContracts] = useState(0)
  const [plannedCostWithoutContracts, setPlannedCostWithoutContracts] = useState(0)
  const [subcontractorContractsReadiness, setSubcontractorContractsReadiness] = useState(0)
  const [subcontractorContractsPrice, setSubcontractorContractsPrice] = useState(0)

  const [realCost, setRealCost] = useState(0)
  const [plannedCost, setplannedCost] = useState(0)
  const [percentageBar, setPercentageBar] = useState(0)


  function calculate(){

    let dailyCostTotal = 0
    dailyPosts.forEach((post) => {
      dailyCostTotal += post.final_price
      // console.log(post)
    })

    let subContractorContractReadinessTotal = 0
    subcontractorContracts.forEach((contract) => {
      subContractorContractReadinessTotal += contract.forint_readiness
      // console.log(contract)
    })

    let plannedCostTotal = 0
    plannedCosts.forEach((post) => {
      plannedCostTotal += post.final_price
      // console.log(post)
    })

    let subcontractorContractPriceTotal = 0
    subcontractorContracts.forEach((contract) => {
      subcontractorContractPriceTotal += contract.contract_price
      subcontractorContractPriceTotal += contract.after_work
      // console.log(contract)
    })


    const realCost = dailyCostTotal + subContractorContractReadinessTotal
    const plannedCost = plannedCostTotal + subcontractorContractPriceTotal
    const percentageBar = Number(((realCost / plannedCost) * 100).toFixed(2))

    setDailyCostWithoutContracts(dailyCostTotal)
    setPlannedCostWithoutContracts(plannedCostTotal)
    setSubcontractorContractsReadiness(subContractorContractReadinessTotal)
    setSubcontractorContractsPrice(subcontractorContractPriceTotal)
    setRealCost(realCost)
    setplannedCost(plannedCost)
    setPercentageBar(percentageBar)

  }

  useEffect(() => {
    if(selectedProjectNumber){
      calculate()
      setTimeout(() => {
        setIsLoading(false)
      }, 1000);
    } else {
      setDailyCostWithoutContracts(0)
      setPlannedCostWithoutContracts(0)
      setSubcontractorContractsReadiness(0)
      setSubcontractorContractsPrice(0)
      setRealCost(0)
      setplannedCost(0)
      setPercentageBar(0)
    }
  }, [selectedProjectNumber, dailyPosts, plannedCosts, ownContracts, subcontractorContracts])


  return (
    <StatisticsProgress
      projectNumbersList={projectNumbersList}
      selectedProjectNumber={selectedProjectNumber}
      setSelectedProjectNumber={setSelectedProjectNumber}
      modeIsResult={false}
      leftTable={{
        rows: [
          {
            name: 'Napi költségek (alvállalkozói szerz. nélkül):',
            value: dailyCostWithoutContracts
          },
          {
            name: 'Alvállalkozói készültségek:',
            value: subcontractorContractsReadiness
          },
        ],
        footer: {
          name: 'Valós költségek:',
          value: realCost
        }
      }}
      rightTable={{
        rows: [
          {
            name: 'Tervezett költségek (alvállalkozói szerz. nélkül):',
            value: plannedCostWithoutContracts
          },
          {
            name: 'Alvállalkozói szerz. összegek:',
            value: subcontractorContractsPrice
          }
        ],
        footer: {
          name: 'Tervezett költségek:',
          value: plannedCost
        }
      }}
      barPercentage={percentageBar}
      history={[]}
      isLoading={isLoading}
      overheadPercentage={0}
      setoverheadPercentage={() => { } }
      overheadForint={0}
      setoverheadForint={() => { } }
      otherCosts={0}
      setotherCosts={() => { } }
      resultForint={0}
    />
  )
}
