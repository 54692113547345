import { Accordion, ActionIcon, Button, Checkbox, createStyles, Input, Modal, ScrollArea, SegmentedControl, Table, Text, TextInput, Tooltip } from '@mantine/core'
import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown, DeviceFloppy, DotsVertical, Edit, Plus, Trash } from 'tabler-icons-react'
import { useStore } from '../../../hooks/useStore'
import { contractContentType } from '../../../interfaces/contractContentType'
import TableBlock from './TableBlock'
import "./../../../index.css"
import AddEditSheetNamesModal from '../AddEditSheetNamesModal'
import GroupChangeModal from '../GroupChangeModal'



interface ExcelLikeWatcherProps {
    modeIsEdit: boolean,
    modeIsWatch: boolean,
    contractContentIsNull: boolean,
    previewData?: contractContentType,
    modeIsOwnContracts: boolean,
    setIsLoading: Function
}

export default function ExcelLikeWatcher({ modeIsEdit, modeIsWatch, contractContentIsNull, previewData, modeIsOwnContracts, setIsLoading }: ExcelLikeWatcherProps) {
    
    const contractContent: contractContentType = previewData ? previewData : useStore((state:any) => state.contractContent)
    const setContractContent = previewData ? () => {} : useStore((state:any) => state.setContractContent)

//   const [switcherData, setSwitcherData] = useState<string[]>(contractContent.map((item: { sheetName: string }) =>  item.sheetName))
  const [switcherValue, setSwitcherValue] = useState(contractContent.map((item: { sheetName: string }) =>  item.sheetName)[0])

  const [sheetData, setSheetData] = useState(contractContent.find(item => item.sheetName === switcherValue))
  const [sheetIndex, setSheetIndex] = useState(contractContent.findIndex((item) => item.sheetName == switcherValue))


//   const [changeTableNameModalOpened, setChangeTableNameModalOpened] = useState(false)
//   const [changeTableNameValue, setChangeTableNameValue] = useState("")
//   const [changeTableNameValueIsInvalid, setChangeTableNameValueIsInvalid] = useState(false)
//   const [changeTableNameIsAfterWork, setChangeTableNameIsAfterWork] = useState(false)


  const viewport = useRef<HTMLDivElement>(null);
  const tabChangerScrollView = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => viewport.current?.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });


  useEffect(() => {
    // console.log(contractContent.length)
    const sheetNames = contractContent.map((item: { sheetName: string }) =>  item.sheetName)
    // setSwitcherData([...innerArray])
    console.log(sheetNames)
    // console.log(switcherValue)
    if(switcherValue == undefined || switcherValue == null){
        setSwitcherValue(sheetNames[0])
    }

    if(!(contractContent.map((item: { sheetName: string }) =>  item.sheetName).includes(switcherValue))){
        setSwitcherValue(contractContent.map((item: { sheetName: string }) =>  item.sheetName)[0])
    }

    // updating the sheetNames to "Új munkalap" if == ""
    const contractContentCopy = contractContent
    if(sheetNames.includes("")){
        contractContent.forEach((sheet, index) => {
            if(sheet.sheetName == ""){
                let newSheetName = `Új munkalap ${contractContent.map((item: { sheetName: string }) =>  item.sheetName).length}`

                if(sheetNames.includes(newSheetName)){
                    contractContentCopy[index].sheetName = newSheetName + "-1"
                } else {
                    contractContentCopy[index].sheetName = newSheetName
                }
            }
        })
    
        setContractContent(contractContentCopy)
    }
}, [contractContent])

useEffect(() => {
    setSheetData(contractContent.find(item => item.sheetName === switcherValue))
    setSheetIndex(contractContent.findIndex((item) => item.sheetName == switcherValue))
    // console.log(contractContent.find(item => item.sheetName === switcherValue))
    // console.log(sheetData, contractContent)
  }, [switcherValue])


  return (
    <>
    <div style={{ height: previewData ? "515px" : "82vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>

        <div>
            {sheetData?.sheetData.length == 0 ? (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: previewData ? "475px" : "76.5vh", width: "100%"}}>
                    <h3>Ez a munkalap üres. {modeIsEdit ? "Adj hozzá táblát a jobb alsó sarokban lévő Tábla hozzáadása gombbal!" : ""}</h3>
                </div>
            ) : (
                <ScrollArea style={{ height: previewData ? "475px" : "76.5vh" }} offsetScrollbars viewportRef={viewport}>

                    {/* <Accordion chevronPosition="left" chevron={<ChevronDown size={30} />} variant="separated" defaultValue={""}> */}
                    {sheetData?.sheetData.map((tableBlock, tableIndex) => 
                            <TableBlock
                                key={tableBlock.id}
                                modeIsEdit={modeIsEdit}
                                modeIsWatch={modeIsWatch}
                                tableBlock={tableBlock}
                                tableIndex={tableIndex}
                                sheetIndex={sheetIndex}
                                modeIsOwnContracts={modeIsOwnContracts}
                                setIsLoading={setIsLoading}
                            />
                    )}
                    {/* </Accordion> */}

                </ScrollArea>
            )
            }
        </div>
        
        <div style={{ display: "flex", justifyContent: "space-between"}}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{maxWidth: "70vw", overflowX: "scroll"}} className="contractTabSwitcherScrollbar" ref={tabChangerScrollView}>
                    {contractContent.map((item: { sheetName: string }) =>  item.sheetName).length == 0 ? (<div></div>) : (
                        <SegmentedControl
                            data={contractContent.map((item: { sheetName: string }) =>  item.sheetName)}
                            value={switcherValue}
                            onChange={(value) => setSwitcherValue(value)}
                        />
                    )}
                </div >
                {/* <SelectMoreTab tabChangerScrollView={tabChangerScrollView} onButtonClick={() => {}}/> */}
                {modeIsEdit ? (
                    <div>
                        <AddEditSheetNamesModal IsEditMode={contractContentIsNull ? false : true} showActionIcon={true} />
                    </div>
                ) : (
                    !modeIsWatch ? (
                        <div style={{paddingBottom: "8px"}}>
                            <GroupChangeModal modeIsOwnContracts={modeIsOwnContracts} setIsLoading={setIsLoading} sheetIndexToChange={sheetIndex} showOnlyIcon></GroupChangeModal>
                        </div>
                    ) : null
                )}
            </div>
            {modeIsEdit ? (
                <AddNewTableModal scrollToBottom={scrollToBottom} sheetIndex={sheetIndex} contractContent={contractContent} setContractContent={setContractContent}/>
            ) : ""}
        </div>
        
    </div>
    </>
  )
}


function AddNewTableModal({ scrollToBottom, sheetIndex, contractContent, setContractContent }: { scrollToBottom: () => void, sheetIndex: number, contractContent: contractContentType, setContractContent: React.Dispatch<React.SetStateAction<contractContentType>> }) {

    const [changeTableNameModalOpened, setChangeTableNameModalOpened] = useState(false)
    const [changeTableNameValue, setChangeTableNameValue] = useState("")
    const [changeTableNameValueIsInvalid, setChangeTableNameValueIsInvalid] = useState(false)
    const [changeTableNameIsAfterWork, setChangeTableNameIsAfterWork] = useState(false)

    function addTable(){
        if(changeTableNameValue.length == 0){
            setChangeTableNameValueIsInvalid(true)
        } else {
            const contractContentCopy = contractContent
            contractContentCopy[sheetIndex].sheetData.push({
                id: Date.now().toString(),
                is_after_work: changeTableNameIsAfterWork,
                tableName: changeTableNameValue,
                tableData: [{
                    id: Date.now().toString(),
                    name: "",
                    quantity: 0,
                    unit: "",
                    total: 0,
                    measuredQuantity: 0,
                    forint_readiness: 0,
                    percentage_readiness: 0
                }]})
            setContractContent([...contractContentCopy])
            setChangeTableNameModalOpened(false)
            console.log(contractContentCopy)
            setTimeout(() => scrollToBottom(), 0)
        }
    }

    useEffect(() => {
        if(!changeTableNameModalOpened){
            setChangeTableNameValue("")
            setChangeTableNameIsAfterWork(false)
        }
      }, [changeTableNameModalOpened])
    
      useEffect(() => {
        if(changeTableNameValue.length != 0){
            setChangeTableNameValueIsInvalid(false)
        }
      }, [changeTableNameValue])

    return (
        <>
        <Tooltip
            multiline
            width={220}
            withArrow
            transition="fade"
            transitionDuration={200}
            label="Ezzel a gombbal tudsz hozzáadni táblát a kijelölt munkalaphoz. (bal alsó sarok)"
        >
            <Button
                leftIcon={<Plus/>}
                variant="gradient"
                gradient={{ from: 'teal', to: 'blue', deg: 60 }}
                onClick={() =>  setChangeTableNameModalOpened(true)}
            >Tábla hozzáadása</Button>
        </Tooltip>

        <Modal
        centered
        title={"Add meg a tábla nevét!"}
        opened={changeTableNameModalOpened}
        onClose={() => setChangeTableNameModalOpened(false)}
        >
            <div>
                <TextInput
                    value={changeTableNameValue}
                    onChange={(event) => setChangeTableNameValue(event.currentTarget.value)}
                    error={changeTableNameValueIsInvalid ? "A tábla neve nem lehet üres" : null}
                />
                <div style={{marginTop: "25px", width: "100%", display: "flex", justifyContent: "center"}}>
                <Checkbox
                    label="Pótmunka"
                    color="green"
                    checked={changeTableNameIsAfterWork}
                    onChange={(event) => setChangeTableNameIsAfterWork(event.currentTarget.checked)}
                />
                </div>
                <Button
                    leftIcon={<Plus/>}
                    style={{marginTop: "25px"}}
                    fullWidth
                    onClick={() => addTable() }
                >
                    Tábla létrehozás
                </Button>
            </div>
        </Modal>
        </>
    )
}




