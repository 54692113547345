import { create } from 'zustand'
import { contractContentType } from '../interfaces/contractContentType'
import { FormSchemeType, FormStateType } from '../interfaces/formTypes'

export const useStore = create((set) => ({
  // isLoading: false,
  // setIsLoading: () => set((state:boolean) => ({ isLoading: state })),
  // removeAllBears: () => set({ bears: 0 }),
  // increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  formScheme: [],//JSON.parse(localStorage.getItem("daily-form-scheme") || "[]"),
  setFormScheme: (newstate:FormSchemeType) => set({ formScheme: newstate }),
  formState: [],//JSON.parse(localStorage.getItem("daily-form") || "[]"),
  setFormState: (newstate:FormStateType) => set({ formState: newstate }),

  formFinalPrice: 0,
  setFormFinalPrice: (newstate: number) => set({ formFinalPrice: newstate }),
  formProjectNumber: "",
  setFormProjectNumber: (newstate: number) => set({ formProjectNumber: newstate }),
  formDate: new Date(),
  setFormDate: (newstate: number) => set({ formDate: newstate }),
  formComment: "",
  setFormComment: (newstate: number) => set({ formComment: newstate }),

  formTitle: "",
  setFormTitle: (newstate: number) => set({ formTitle: newstate }),

  formMode: "",
  setFormMode: (newstate: string) => set({ formMode: newstate }),

  isSaving: false,
  setIsSaving: (newstate: string) => set({ isSaving: newstate }),
  
  formDailyIncomeObject: {},
  setFormDailyIncomeObject: (newstate: string) => set({ formDailyIncome: newstate }),
  formDailySubcontractorCostObject: {},
  setFormDailySubcontractorCostObject: (newstate: string) => set({ formDailySubcontractorCostObject: newstate }),


  selection: [],
  setSelection: (newstate: []) => set({ selection: newstate }),

  allRowsForImporting: [],
  setAllRowsForImporting: (newstate: []) => set({ allRowsForImporting: newstate }),

  
  contractContent: [],
  setContractContent: (newstate: contractContentType) => set({ contractContent: newstate }),

  contractMode: false,
  setContractMode: (newstate: string) => set({ contractMode: newstate }),

}))