import { Button, Input, NumberInput, Modal, Select, Textarea } from '@mantine/core'
import React, { useState } from 'react'
import { DeviceFloppy, Plus } from 'tabler-icons-react'
import SearchCompany from '../../../../components/SearchCompany/SearchCompany'

interface ModalProps {
    modeIsOwnContrats: boolean
    modeIsCreate: boolean
    modalOpened: boolean,
    onButtonClick: Function,
    setModalOpened: Function,
    projectNumber: string | null,
    setProjectNumber: Function,
    project_numbers: {value:string, label:string}[],
    buyerName: string,
    setBuyerName: Function,
    contractorName: string,
    setContractorName: Function
    comment: string,
    setComment: Function
}

export default function CreateEditModal({ modeIsOwnContrats, modeIsCreate, modalOpened, onButtonClick, setModalOpened, projectNumber, setProjectNumber, project_numbers, buyerName, setBuyerName, contractorName, setContractorName, comment, setComment }: ModalProps) {
  
    const [buttonIsLoading, setButtonIsLoading] = useState(false)
  
    return (
    <Modal
    opened={modalOpened}
    onClose={() => setModalOpened(false)}
    title={modeIsCreate ? "Szerződés létrehozása" : `${projectNumber} szerkesztése`}
  >
    <div style={{ height: "380px", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
        <Input.Wrapper label="Munkaszám" required>
            <Select
                searchable
                nothingFound="Nincs találat"
                placeholder="Válassz munkaszámot!"
                value={projectNumber}
                onChange={(value) => setProjectNumber(value)}
                data={project_numbers}
                styles={(theme) => ({
                    item: {
                      // applies styles to selected item
                      '&[data-selected]': {
                        '&, &:hover': {
                          backgroundColor:
                            modeIsOwnContrats ? theme.colors.cyan[8] : theme.colors.green[8],
                        },
                      },
            
                      // applies styles to hovered item (with mouse or keyboard)
                      '&[data-hovered]': {},
                    },
                    input: {
                      ":focus-within": {
                        borderColor: modeIsOwnContrats ? theme.colors.cyan[8] : theme.colors.green[8],
                      }
                    }
                  })}
            />
        </Input.Wrapper>

        <Input.Wrapper label={modeIsOwnContrats ? "Megrendelő" : "Alvállalkozó"} required>
            <SearchCompany
                value={modeIsOwnContrats ? buyerName : contractorName}
                onChange={(value) => {modeIsOwnContrats ? setBuyerName(value || "") : setContractorName(value || "")}}
                placeholder={modeIsOwnContrats ? "A megrendelőd neve" : "Az alvállalkozód neve"}
                canSelectItself={false}
                styles={(theme: { colors: { cyan: any[]; green: any[] } }) => ({
                    item: {
                      // applies styles to selected item
                      '&[data-selected]': {
                        '&, &:hover': {
                          backgroundColor:
                            modeIsOwnContrats ? theme.colors.cyan[8] : theme.colors.green[8],
                        },
                      },
            
                      // applies styles to hovered item (with mouse or keyboard)
                      '&[data-hovered]': {},
                    },
                    input: {
                      ":focus-within": {
                        borderColor: modeIsOwnContrats ? theme.colors.cyan[8] : theme.colors.green[8],
                      }
                    }
                  })}
            />
        </Input.Wrapper>

        {/* <Input.Wrapper label="Szerződéses Ár" required>
            <NumberInput
                value={contractPrice}
                onChange={(value) => setContractPrice(value || 0)}
                placeholder="A szerződéses árad"
                parser={(value:any) => value.replace(/\Ft\s?|( *)/g, '')}
                formatter={(value:any) => !Number.isNaN(parseFloat(value)) ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                rightSection={<p style={{marginRight: "10px"}}>Ft</p>}
            />
        </Input.Wrapper>

        <Input.Wrapper label="Pótmunka">
            <NumberInput
                value={afterWorkPrice}
                onChange={(value) => setAfterWorkPrice(value || 0)}
                placeholder="Pótmunka ára (ha van)"
                parser={(value:any) => value.replace(/\Ft\s?|( *)/g, '')}
                formatter={(value:any) => !Number.isNaN(parseFloat(value)) ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                rightSection={<p style={{marginRight: "10px"}}>Ft</p>}
            />
        </Input.Wrapper> */}

        <Textarea
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            style={{marginTop: "10px"}}
            placeholder="Ide írhatsz megjegyzést a szerződéssel kapcsolatban"
            label="Megjegyzés"
            styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor:
                        modeIsOwnContrats ? theme.colors.cyan[8] : theme.colors.green[8],
                    },
                  },
        
                  // applies styles to hovered item (with mouse or keyboard)
                  '&[data-hovered]': {},
                },
                input: {
                  ":focus-within": {
                    borderColor: modeIsOwnContrats ? theme.colors.cyan[8] : theme.colors.green[8],
                  }
                }
              })}
        />

        <Button
            style={{marginTop: "30px"}}
            loading={buttonIsLoading}
            color={modeIsOwnContrats ? "cyan" : "green"}
            leftIcon={modeIsCreate ? <Plus/> : <DeviceFloppy/>}
            onClick={() => {
                setButtonIsLoading(true),
                setTimeout(() => {
                    onButtonClick()
                }, 1000);
                setTimeout(() => {
                    setButtonIsLoading(false)
                    if(modeIsCreate){
                        setProjectNumber("")
                        setBuyerName("")
                        setComment("")
                    }
                }, 2000);
            }}
        >
            {modeIsCreate ? "Létrehozás" : "Mentés"}
        </Button>
    </div>


</Modal>
  )
}
