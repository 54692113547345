import { Accordion, Button, Card, Checkbox, CloseButton, DefaultMantineColor, Group, Loader, LoadingOverlay, Modal, MultiSelect, ScrollArea, SegmentedControl, Select, Space, Stepper, Table, Text, useMantineTheme } from '@mantine/core'
import { Dropzone, FileWithPath, MS_EXCEL_MIME_TYPE } from '@mantine/dropzone'
import { showNotification } from '@mantine/notifications'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { AlertCircle, AlertTriangle, Check, CircleCheck, CloudUpload, FileUpload, Number0, Upload, X } from 'tabler-icons-react'
import { answerType, classificationResultType, predictionResultType, problemType, sheetsDataType } from '../../../interfaces/contractUploadType'
import { read, writeFileXLSX } from "xlsx";
import { useFunctions } from '../../../hooks/useFirebase'
import { FixedSizeList as List } from 'react-window';
import { useIntersection } from '@mantine/hooks'
import { Carousel } from '@mantine/carousel'

import "./UploadContentFromExcel.css"
import { contractContentType } from '../../../interfaces/contractContentType'
import ExcelLikeWatcher from '../ExcelLikeWatcher/ExcelLikeWatcher'
import { useStore } from '../../../hooks/useStore'
import { calculateContractPrice } from '../ContractContent'
import { useNumberWithSpaces } from '../../../hooks/utils'


interface UploadContentFromExcelProps {
    setAddModalOpened: (value: boolean) => void,
}

export default function UploadContentFromExcel({ setAddModalOpened }: UploadContentFromExcelProps) {

    const [excelAiUploaderModalIsOpened, setExcelAiUploaderModalIsOpened] = useState(false)

    const [activeSlideInStepper, setActiveSlideInStepper] = useState(0)

    const nextStep = () =>
        setActiveSlideInStepper((current) => {
            if(current === 3){
                setExcelAiUploaderModalIsOpened(false)
            }
            return current < 3 ? current + 1 : current
        });
    const prevStep = () => 
        setActiveSlideInStepper((current) => {
            if(current === 0){
                setExcelAiUploaderModalIsOpened(false)
            }
            return current > 0 ? current - 1 : current
        });

    const [sheetsData, setSheetsData] = useState<sheetsDataType>([])
    const [sheetnames, setSheetnames] = useState<string[]>([])

    const [selectedSheetNames, setSelectedSheetNames] = useState<string[]>([])

    const [classificationResult, setClassificationResult] = useState<classificationResultType>()
    const [isProcessingExcelFile, setIsProcessingExcelFile] = useState(false)

    const [columnAnswersBySheets, setColumnAnswersBySheets] = useState<answerType[]>([])

    const [problems, setProblems] = useState<problemType[]>([])

    const [isUploading, setIsUploading] = useState(false)


    function fileRejected() {
        showNotification({
            title: "Fájl elutasítva",
            message: "Ezt a fájltípust nem támogatjuk!, Kérlek tölts fel Excel fájlt!",
            icon: <X size={20} />,
            autoClose: false,
            color: "red",
        })
    }

    async function onFileDrop(files: FileWithPath[]) {
        // setIsProcessingExcelFile(true)

        const fileToUpload = files[0]
        console.log("File to upload: ", fileToUpload)

        const data = await fileToUpload.arrayBuffer()
        const workbook = read(data);
        console.log("Workbook: ", workbook)

        console.log("Sheets:", workbook.Sheets)

        const sheetsData = Object.entries(workbook.Sheets).map(([name, sheet]) => {
            console.log(name, sheet)

            // Object.keys(sheet).forEach((cell) => {
            //     let columnLetter = cell.slice(0, 1).toUpperCase()

            //     // filter out not cells
            //     if(cell.includes("!")) return
    
            //     // filter out not A cells
            //     if(columnLetter !== "A") return
    
            //     console.log("Cell Name:", cell)
            // })
            const ref = sheet['!ref']
            console.log(ref)


            // getting the column letters
            const columns: string[] = []
            Object.keys(sheet).forEach((column) => {
                let columnLetter = column.slice(0, 1).toUpperCase()
                if(columnLetter === "!" || columns.includes(columnLetter)) return
                columns.push(columnLetter)
            })
            columns.sort()
            console.log("Columns: ", columns)


            // getting the number of rows
            let numberOfRows = 0
            // numberOfRows = Number(ref!.split(":")[1]);
            const to = ref!.split(":")[1]
            let numbers = ""
            for (let i = 0; i < to.length; i++) {
                if(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(to[i])){
                    numbers += to[i]
                }
            }
            numberOfRows = Number(numbers)
            console.log("Number of rows: ", numberOfRows, numbers)


            // creating the row strings
            const rowStringsArray = []
            const rowArray = []
            for (let index = 0; index < numberOfRows; index++) {
                const columnCellsInThatRow: string[] = []
                columns.forEach((column) => {
                    columnCellsInThatRow.push(column + (index + 1))
                    // console.log(sheet[column + (index + 1)])
                    // console.log(column + (index + 1))
                })

                const rowString = columnCellsInThatRow.map((cellName) => {
                    if(sheet[cellName] === undefined) return "-"
                    return sheet[cellName].v
                }).filter((t) => t !==  "-").join(" ")

                // console.log(rowString)
                rowStringsArray.push(rowString)

                const rows: string[] = columnCellsInThatRow.map((cellName) => {
                    if(sheet[cellName] === undefined) return " "
                    return sheet[cellName].v
                })//.filter((t) => t !==  "-")

                rowArray.push(rows)
            }

            console.log("Row strings: ", rowStringsArray)
            return { sheetName: name, rowStrings: rowStringsArray, rows: rowArray }
        })

        console.log("Sheets Data:", sheetsData)
        setSheetsData(sheetsData)
        setSheetnames(sheetsData.map((sheet) => sheet.sheetName))
        setSelectedSheetNames(sheetsData.map((sheet) => sheet.sheetName))

        setExcelAiUploaderModalIsOpened(true)
        setActiveSlideInStepper(0)
    }

    function closeUploadModals() {
        setExcelAiUploaderModalIsOpened(false)
        setAddModalOpened(false)
    }


    useEffect(() => {
      setClassificationResult(undefined)
    }, [selectedSheetNames])

    useEffect(() => {
      setColumnAnswersBySheets([])
    }, [classificationResult])
    
    


  return (
    <>
        <Dropzone
            onDrop={(files) => onFileDrop(files)}
            onReject={(files) => fileRejected()}
            // maxSize={3 * 1024 ** 2}
            accept={MS_EXCEL_MIME_TYPE}
        >
        <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
            <Dropzone.Accept>
            <Upload
                size={50}
            />
            </Dropzone.Accept>
            <Dropzone.Reject>
            <X
                size={50}
            />
            </Dropzone.Reject>
            <Dropzone.Idle>
            <CloudUpload size={50} />
            </Dropzone.Idle>

            <div>
            <Text size="xl" inline>
                Húzd ide az excel fájlt vagy kattints a kiválasztáshoz
            </Text>
            {/* <Text size="sm" color="dimmed" inline mt={7}>
                Attach as many files as you like, each file should not exceed 5mb
            </Text> */}
            </div>
        </Group>
        </Dropzone>

        <Modal
        opened={excelAiUploaderModalIsOpened}
        onClose={() => setExcelAiUploaderModalIsOpened(false)}
        size={"90vw"}
        withCloseButton={false}
        closeOnClickOutside={false}
        title={
            <>
            <div style={{ width: "calc(90vw - 40px)", padding: "10px 10px 0px 10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            
            <Button
                variant='default'
                color={"gray"}
                onClick={prevStep}
                style={{width: "95px"}}
                disabled={isProcessingExcelFile}
            >
                {activeSlideInStepper > 0 ? "Vissza" : "Bezár"}
            </Button>

            <Stepper active={activeSlideInStepper} onStepClick={setActiveSlideInStepper} breakpoint="sm" allowNextStepsSelect={true} style={{width: "70%"}}>
            <Stepper.Step label="Első lépés" description="Munkalapok kiválasztása" allowStepSelect={false}>
            {/* Step 1 content: Create an account */}
            </Stepper.Step>
            <Stepper.Step label="Második lépés" description="Sorok kiválasztása" allowStepSelect={false}>
            {/* Step 2 content: Verify email */}
            </Stepper.Step>
            <Stepper.Step label="Harmadik lépés" description="Oszlopok kiválasztása" allowStepSelect={false}>
            {/* Step 3 content: Get full access */}
            </Stepper.Step>
            <Stepper.Completed>
            {/* Step 4 content: Check */}
            </Stepper.Completed>
            </Stepper>

            <div style={{ display: "flex", alignItems: "center", gap: "50px"}}>
                {activeSlideInStepper < 3 ? (
                    <Button
                        variant='light'
                        color={"green"}
                        onClick={nextStep}
                        style={{width: "95px"}}
                        disabled={isProcessingExcelFile}
                    >
                        Tovább
                    </Button>
                ) : (
                    <div style={{width: "95px"}}></div>
                )}
                
                <CloseButton title="Feltöltő ablak bezásása" size="lg" iconSize={20} onClick={() => setExcelAiUploaderModalIsOpened(false)}/>
            </div>

            </div>
            
            </>
        }
        // fullScreen
        >
            <div style={{ height: "calc(72vh - 10px)", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>

            {activeSlideInStepper === 0 ? (
                <SelectingSheets sheetNames={sheetnames} selectedSheetNames={selectedSheetNames} setSelectedSheetNames={setSelectedSheetNames}></SelectingSheets>
            ) : null}

            {activeSlideInStepper === 1 ? (
                <SelectingRows
                    sheetsData={sheetsData}
                    selectedSheetNames={selectedSheetNames}
                    classificationResult={classificationResult}
                    setClassificationResult={setClassificationResult}
                    isProcessingExcelFile={isProcessingExcelFile}
                    setIsProcessingExcelFile={setIsProcessingExcelFile}
                ></SelectingRows>
            ) : null}
            
            {activeSlideInStepper === 2 ? (
                <SelectingColumns
                    classificationResult={classificationResult}
                    sheetsData={sheetsData}
                    selectedSheetNames={selectedSheetNames}
                    columnAnswersBySheets={columnAnswersBySheets}
                    setColumnAnswersBySheets={setColumnAnswersBySheets}
                ></SelectingColumns>
            ) : null}

            {activeSlideInStepper === 3 ? (
                <CheckUploadData
                    classificationResult={classificationResult}
                    columnAnswersBySheets={columnAnswersBySheets}
                    setActiveSlideInStepper={setActiveSlideInStepper}
                    problems={problems}
                    setProblems={setProblems}
                    sheetsData={sheetsData}
                    selectedSheetNames={selectedSheetNames}
                    closeUploadModals={closeUploadModals}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                ></CheckUploadData>
            ) : null}

            </div>
        </Modal>

    </>
  )
}



function SelectingSheets({ sheetNames, selectedSheetNames, setSelectedSheetNames }: { sheetNames: string[], selectedSheetNames: string[], setSelectedSheetNames: React.Dispatch<React.SetStateAction<string[]>> }) {
    
    return (
        <div style={{ width: "70%", display: "flex", justifyContent: "center", gap: "15px", flexWrap: "wrap" }}>
            {sheetNames.map((sheetName) => (
                <Card
                    key={sheetName}
                    p="xl"
                    withBorder
                    shadow="sm"
                    radius="md"
                    style={{ display: "flex", alignItems: "center", gap: "15px", cursor: "pointer" }}
                    onClick={() => setSelectedSheetNames((prev) => prev.includes(sheetName) ? prev.filter((item) => item !== sheetName) : [...prev, sheetName])}
                >
                    <Checkbox
                        checked={selectedSheetNames.includes(sheetName)}
                        onChange={() => null}
                    />
                    <Text>{sheetName}</Text>
                </Card>
            ))}
        </div>
    )
}



function SelectingRows({ sheetsData, selectedSheetNames, classificationResult, setClassificationResult, isProcessingExcelFile, setIsProcessingExcelFile }: { sheetsData: sheetsDataType, selectedSheetNames: string[], classificationResult: classificationResultType | undefined, setClassificationResult: React.Dispatch<React.SetStateAction<classificationResultType | undefined>>, isProcessingExcelFile: boolean, setIsProcessingExcelFile: React.Dispatch<React.SetStateAction<boolean>> }) {

    const theme = useMantineTheme()

    const [currentlySelectedSheet, setcurrentlySelectedSheet] = useState("")
    // const [isLoading, setIsLoading] = useState(false)

    const viewport = useRef<HTMLDivElement>(null);

    let dataSendingToPrediction = useMemo(() => {
        return selectedSheetNames.length ? sheetsData.filter((sheet) => selectedSheetNames.includes(sheet.sheetName)) : undefined
    }, [])

    let currentSheetData = useMemo(() => {
        return dataSendingToPrediction?.find((sheet) => sheet.sheetName === currentlySelectedSheet)
    }, [currentlySelectedSheet])

    let currentSheetIndex = useMemo(() => {
        return dataSendingToPrediction?.findIndex((sheet) => sheet.sheetName === currentlySelectedSheet)
    }, [currentlySelectedSheet])
    // let dataSendingToPrediction = selectedSheetNames.length ? sheetsData.filter((sheet) => selectedSheetNames.includes(sheet.sheetName)) : undefined
    // let currentSheetData = dataSendingToPrediction?.find((sheet) => sheet.sheetName === currentlySelectedSheet)
    // let currentSheetIndex = dataSendingToPrediction?.findIndex((sheet) => sheet.sheetName === currentlySelectedSheet)

    async function processPredictions() {
        if(!dataSendingToPrediction){
            setTimeout(() => {
                setIsProcessingExcelFile(false)
            }, 200);
            return
        }
        const decideIfStringIsRowOrNot = useFunctions("decideIfStringIsRowOrNot")

        let result = await decideIfStringIsRowOrNot({ sheets: dataSendingToPrediction })
        let predictions = result.data as classificationResultType

        setTimeout(() => {
            setClassificationResult(predictions)
            setcurrentlySelectedSheet(predictions[0].sheetName)
            setIsProcessingExcelFile(false)
            console.log("Result:", result)
        }, 300);
    }

    function handleOnSelectChange(newValue: string | null, rowIndex: number) {
        // const newObject = JSON.parse(JSON.stringify(classificationResult))
        const newObject = classificationResult
        console.log("newValue", newValue)
        console.log("newObject", newObject)
        console.log("currentSheetIndex", currentSheetIndex)
        if(newValue && newObject && currentSheetIndex !== undefined){
            newObject[currentSheetIndex].predictions[rowIndex].result = newValue
            setClassificationResult([...newObject])
        }
    }

    const getBiggestColspanInCurrentSheet = useCallback(function getBiggestColspanInCurrentSheet(rowLength: number) {
        let biggestColspan = 0

        currentSheetData?.rows.forEach((row) => {
            if (row.length > biggestColspan) {
                biggestColspan = row.length
            }
        })

        if(rowLength === 0) return biggestColspan
        return biggestColspan - rowLength+1
    }, [currentlySelectedSheet])

    function getRowTextColor(predictionResult: predictionResultType): DefaultMantineColor | "dimmed" | undefined{
        if(predictionResult === "cím"){
            return "cyan"
        } else if(predictionResult === "fejléc"){
            return "orange"
        } else if(predictionResult === "nem"){
            return "gray"
        } else {
            return "white"
        }
    }
    // const rowHeights = currentSheetData?.rows.map((row) => row.reduce((a, b) => a.toString().length + b.toString().length, 0)) ?? [];
 
    // const getItemSize = index => rowHeights[index];

    useLayoutEffect(() => {
        setIsProcessingExcelFile(true)
    }, [])

    useEffect(() => {
        if(classificationResult === undefined){
            processPredictions()
        } else {
            setcurrentlySelectedSheet(classificationResult[0].sheetName)
            setTimeout(() => {
                setIsProcessingExcelFile(false)
            }, 1000);
        }
    }, [])

    return (
        <>
        {isProcessingExcelFile ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                    <Loader size={'lg'}></Loader>
                    <h2>Feldolgozás...</h2>
                </div>
            ) : (
                <div>
                    {!currentSheetData ? (
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "10px" }}>
                            <Text size={"lg"}>Nincs munkalap kiválasztva!</Text>
                            <Text size={"md"}>Térj vissza az előző lépéshez, és válassz ki legalább egy munkalapot!</Text>
                        </div>
                    ) : (
                        <>
                        {/* <div>
                            {isLoading ? (
                                <LoadingOverlay visible={isLoading} overlayBlur={2} />
                            ) : null}
                        </div> */}
                        <ScrollArea h={"62.8vh"} w={"90vw"} style={{padding: "0px 30px"}} ref={viewport}>
                        <Table>
                            <thead style={{ position: "sticky", top: "0px", backgroundColor: theme.colors.dark[7], zIndex: 10 }}>
                                <tr>
                                    <th colSpan={getBiggestColspanInCurrentSheet(1)}>Sorok</th>
                                    <th>Felismert</th>
                                    {/* {currentSheetData?.rows[0].map((row, rowIndex) => {
                                        return <th>Sorok</th>
                                    })} */}
                                </tr>
                            </thead>
                            <tbody>
                            {/* <List
                                height={400}
                                itemCount={currentSheetData.rows.length}
                                itemSize={currentSheetData.rows[0].length}
                                width={window.innerWidth * 0.81}
                            >
                                {({ index, style }) => {
                                    const predictionResult = classificationResult?.[currentSheetIndex || 0].predictions[index].result as predictionResultType
                                    return (
                                        <PredictionRow
                                            key={index}
                                            row={currentSheetData?.rows[index] || []}
                                            predictionResult={predictionResult}
                                            rowIndex={index}
                                            getBiggestColspanInCurrentSheet={getBiggestColspanInCurrentSheet}
                                            getRowTextColor={getRowTextColor}
                                            handleOnSelectChange={handleOnSelectChange}
                                        />
                                    )
                                }} */}
                                {currentSheetData?.rows.map((row, rowIndex) => {
                                    const predictionResult = classificationResult?.[currentSheetIndex || 0].predictions[rowIndex].result as predictionResultType
                                    return <PredictionRow
                                        key={rowIndex}
                                        row={row}
                                        predictionResult={predictionResult}
                                        rowIndex={rowIndex}
                                        getBiggestColspanInCurrentSheet={getBiggestColspanInCurrentSheet}
                                        getRowTextColor={getRowTextColor}
                                        handleOnSelectChange={handleOnSelectChange}
                                    />
                                })}
                            {/* </List> */}
                            </tbody>
                        </Table>
                        </ScrollArea>
                        </>
                    )}

                    {dataSendingToPrediction ? (
                        <SegmentedControl
                            value={currentlySelectedSheet}
                            // onChange={(value) => { setIsLoading(true), setTimeout(() => { setcurrentlySelectedSheet(value), setIsLoading(false) }, 500) }}
                            onChange={(value) => setcurrentlySelectedSheet(value)}
                            data={dataSendingToPrediction.map((sheet) => sheet.sheetName)}
                            style={{margin: "15px 30px 0px 30px"}}
                        >
                        </SegmentedControl>
                    ) : null}
                </div>
            )}
        </>
    )
}
const PredictionRow = React.memo(function PredictionRow({ row, predictionResult, rowIndex, getBiggestColspanInCurrentSheet, getRowTextColor, handleOnSelectChange }: { row: string[], predictionResult: predictionResultType, rowIndex: number, getBiggestColspanInCurrentSheet: Function, getRowTextColor: Function, handleOnSelectChange: Function }) {
    const { ref, entry } = useIntersection({
        threshold: 0,
      });
    return (
        <tr key={rowIndex} ref={ref}>
            {row.map((cell, cellIndex) => (
                <td
                    key={cellIndex}
                    style={{ maxWidth: "15.5vw"}}
                    colSpan={cellIndex === row.length-1 ? getBiggestColspanInCurrentSheet(row.length) : 1}
                >
                    <Text color={getRowTextColor(predictionResult)}>{cell}</Text>
                </td>
            ))}
            {row.length === 0 ? null : (
                <td>
                    {entry?.isIntersecting ? (
                        <Select
                            data={["cím", "fejléc", "nem", "sor"]}
                            placeholder="Válassz!"
                            value={predictionResult}
                            onChange={(value) => handleOnSelectChange(value, rowIndex)}
                        ></Select>
                    ) : <div style={{ width: "100%", height: "36px" }}></div>}
                </td>
            )}
        </tr>
    )
}, (prevProps, nextProps) => prevProps.predictionResult === nextProps.predictionResult && prevProps.getBiggestColspanInCurrentSheet === nextProps.getBiggestColspanInCurrentSheet)



const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
function SelectingColumns({ classificationResult, sheetsData, selectedSheetNames, columnAnswersBySheets, setColumnAnswersBySheets }: { classificationResult: classificationResultType | undefined, sheetsData: sheetsDataType, selectedSheetNames: string[], columnAnswersBySheets: answerType[], setColumnAnswersBySheets: React.Dispatch<React.SetStateAction<answerType[]>> }) {

    const [headers, setHeaders] = useState<string[][]>([])

    let sheetsDataFilteredBySelectedWorksheets = useMemo(() => {
        return selectedSheetNames.length ? sheetsData.filter((sheet) => selectedSheetNames.includes(sheet.sheetName)) : undefined
    }, [])

    const [currentSheetIndex, setCurrentSheetIndex] = useState(0)


    function calcHeaders() {
        let headersArray: string[][] = []
        classificationResult?.forEach((sheet, sheetIndex) => {
            let headersInCurrentSheet: string[] = []

            sheet.predictions?.forEach((prediction, rowIndex) => {

                if(prediction.result === "fejléc"){
                    // prediction.rowString.split(" ").forEach((cell, cellIndex) => {
                    //     headersArray.push(cell)
                    // })

                    // console.log("sheet", sheet, sheetIndex, prediction, rowIndex)
                    // console.log("sheetsData[sheetIndex].rows[rowIndex]", sheetsData[sheetIndex].rows[rowIndex], sheetsData[sheetIndex].rows, rowIndex)
                    // console.log("sheetsData[sheetIndex].rows[rowIndex]", sheetsData[sheetIndex].rows[rowIndex], sheetsData[sheetIndex].rows, rowIndex)
                    if(sheetsDataFilteredBySelectedWorksheets){
                        sheetsDataFilteredBySelectedWorksheets[sheetIndex].rows[rowIndex].forEach((cell, cellIndex) => {
                            const item = `${letters[cellIndex]} oszlopban (${cell})`
                            if(!headersInCurrentSheet.includes(item)){
                                headersInCurrentSheet.push(item)
                            }
                        })
                    }
                }
            })

            headersArray.push(headersInCurrentSheet)
            // remove duplicates
            headersInCurrentSheet = Array.from(new Set(headersInCurrentSheet))
            // sort headers by first letter
            headersInCurrentSheet.sort((a, b) => {
                if (a[0] < b[0]) {
                    return -1
                }
                if (a[0] > b[0]) {
                    return 1
                }
                return 0
            })
        })

        return headersArray
    }

    function calcWhatIsSelected(headers: string[][]) {
        const object = {
            name: ["megnevezés", "tétel"],
            quantity: ["mennyiség", "menny"],
            unit: ["mértékegység", "mért", "egység", "egys"],
            all: ["összesen", "össz"]
        }
        const answers: answerType[] = []

        // headers.forEach((header, sheetIndex) => {

        //     Object.entries(object).forEach(([columnName, array]) => {

        //         let headerString: string | undefined = undefined
        //         array.forEach((item, index) => {
        //             headerString = header.find((headerItem) => headerItem.toLowerCase().includes(item.toLowerCase()))
        //         })

        //         if(headerString && answers[sheetIndex][columnName as keyof answerType] === null){
        //             answers[sheetIndex][columnName as keyof answerType] = headerString
        //         }

        //         console.log("header", header)
        //         console.log("headerString", headerString, sheetIndex, columnName, answers[sheetIndex][columnName as keyof answerType])
        //     })
        // })

        headers.forEach((header, index) => {
            // if(header.length === 0){
            //     answers.push({ name: null, quantity: null, unit: null, all: null })
            //     return
            // }

            console.log("header", header, index)
            
            let nameString: string | undefined = undefined
            let quantityString: string | undefined = undefined
            let unitString: string | undefined = undefined
            let allString: string | undefined = undefined

            object.name.forEach((item, index) => {
                if(item === undefined) return
                // let found = false
                header.forEach((headerItem) => {
                    if(headerItem.toLowerCase().includes(item.toLowerCase())){//} && !found){
                        nameString = headerItem
                        // found = true
                        return
                    }
                }) 
            })
            object.quantity.forEach((item, index) => {
                if(item === undefined) return
                let found = false
                header.forEach((headerItem) => {
                    if(headerItem.toLowerCase().includes(item.toLowerCase()) && !found){
                        quantityString = headerItem
                        found = true
                        return
                    }
                }) 
            })
            object.unit.forEach((item, index) => {
                if(item === undefined) return
                let found = false
                header.forEach((headerItem) => {
                    if(headerItem.toLowerCase().includes(item.toLowerCase()) && !found){
                        unitString = headerItem
                        found = true
                        return
                    }
                }) 
            })
            object.all.forEach((item, index) => {
                if(item === undefined) return
                // let found = false
                header.forEach((headerItem) => {
                    if(headerItem.toLowerCase().includes(item.toLowerCase())){//} && !found){
                        allString = headerItem
                        // found = true
                        return
                    }
                }) 
            })
            
            // if(nameString){
            answers.push({ name: nameString ? [nameString] : undefined, quantity: quantityString, unit: unitString, all: allString })
            // answers[index].name = headerString
            console.log("nameString", nameString)
            console.log("quantityString", quantityString)
            console.log("unitString", unitString)
            console.log("allString", allString)
            // }



            console.log("close", answers[index])
        })

        console.log("answers", answers)
        
        return answers
    }

    function handleOnChange(value: string | null, listValue: string[], sheetIndex: number, columnName: "name" | "quantity" | "unit" | "all") {
        setColumnAnswersBySheets((oldValue) => {
            const newValue = oldValue
            if(columnName === "name"){
                newValue[sheetIndex] = {...newValue[sheetIndex], name: listValue}
            } else {
                newValue[sheetIndex] = {...newValue[sheetIndex], [columnName]: value}
            }

            return [...newValue]
        })

    }


    useLayoutEffect(() => {
        const headers = calcHeaders()
        // console.log("headers", headers)
        setHeaders(headers)

        if(columnAnswersBySheets.length === 0){
            const answers = calcWhatIsSelected(headers)
            setColumnAnswersBySheets(answers)
        }
    }, [])

    return (
        <>
        <h1 style={{margin: 0}}>{sheetsDataFilteredBySelectedWorksheets ? sheetsDataFilteredBySelectedWorksheets[currentSheetIndex].sheetName : ""}</h1>

        <Carousel
            mx="auto"
            w="80vw"
            withIndicators
            draggable={false}
            onSlideChange={(value) => setCurrentSheetIndex(value)}
            styles={{
                indicator: {
                    width: 12,
                    height: 4,
                    transition: 'width 250ms ease',
        
                    '&[data-active]': {
                    width: 40,
                    },
                },
                control: {
                width: 32,
                height: 32,
                '&[data-inactive]': {
                    opacity: 0,
                    cursor: 'default',
                },
                },
            }}
        >
            {sheetsDataFilteredBySelectedWorksheets?.map((sheet, sheetIndex) => (
                <Carousel.Slide key={sheet.sheetName}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", padding: "2.5rem 0px 5rem 0px" }}>

                    <div style={{ width: "700px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: "20px" }}>
                        <Text>Melyik oszlopban található a megnevezés?</Text>
                        {headers[sheetIndex] && headers[sheetIndex].length > 0 ? (
                            <MultiSelect
                                w={"250px"}
                                placeholder="Válassz!"
                                maxDropdownHeight={150}
                                data={headers[sheetIndex]}
                                defaultValue={columnAnswersBySheets[sheetIndex].name}
                                onChange={(value) => handleOnChange(null, value, sheetIndex, "name")}
                            />
                        ) : (
                            <div style={{height: "36px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Text>Nem található fejléc ebben a munkalapban</Text>
                            </div>
                        )}
                    </div>
                    <div style={{ width: "700px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: "20px" }}>
                        <Text>Melyik oszlopban található a mennyiség?</Text>
                        {headers[sheetIndex] && headers[sheetIndex].length > 0 ? (
                            <Select
                                w={"250px"}
                                placeholder="Válassz!"
                                maxDropdownHeight={150}
                                data={headers[sheetIndex]}
                                defaultValue={columnAnswersBySheets[sheetIndex].quantity}
                                onChange={(value) => handleOnChange(value, [], sheetIndex, "quantity")}
                                clearable
                            />
                        ) : (
                            <div style={{height: "36px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Text>Nem található fejléc ebben a munkalapban</Text>
                            </div>
                        )}
                    </div>
                    <div style={{ width: "700px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: "20px" }}>
                        <Text>Melyik oszlopban található a mértékegység?</Text>
                        {headers[sheetIndex] && headers[sheetIndex].length > 0 ? (
                            <Select
                                w={"250px"}
                                placeholder="Válassz!"
                                maxDropdownHeight={150}
                                data={headers[sheetIndex]}
                                defaultValue={columnAnswersBySheets[sheetIndex].unit}
                                onChange={(value) => handleOnChange(value, [], sheetIndex, "unit")}
                                clearable
                            />
                        ) : (
                            <div style={{height: "36px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Text>Nem található fejléc ebben a munkalapban</Text>
                            </div>
                        )}
                    </div>
                    <div style={{ width: "700px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: "20px" }}>
                        <Text>Melyik oszlopban található az összesen?</Text>
                        {headers[sheetIndex] && headers[sheetIndex].length > 0 ? (
                            <Select
                                w={"250px"}
                                placeholder="Válassz!"
                                maxDropdownHeight={150}
                                data={headers[sheetIndex]}
                                defaultValue={columnAnswersBySheets[sheetIndex].all}
                                onChange={(value) => handleOnChange(value, [], sheetIndex, "all")}
                                clearable
                            />
                        ) : (
                            <div style={{height: "36px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Text>Nem található fejléc ebben a munkalapban</Text>
                            </div>
                        )}
                    </div>

                </div>
                </Carousel.Slide>
            ))}
            
            </Carousel>
        </>
    )
}



function CheckUploadData({ classificationResult, columnAnswersBySheets, setActiveSlideInStepper, problems, setProblems, sheetsData, selectedSheetNames, closeUploadModals, isUploading, setIsUploading }: { classificationResult: classificationResultType | undefined, columnAnswersBySheets: answerType[], setActiveSlideInStepper: React.Dispatch<React.SetStateAction<number>>, problems: problemType[], setProblems: React.Dispatch<React.SetStateAction<problemType[]>>, sheetsData: sheetsDataType, selectedSheetNames: string[], closeUploadModals: () => void, isUploading: boolean, setIsUploading: React.Dispatch<React.SetStateAction<boolean>> }) {

    const theme = useMantineTheme()

    const [errors, setErrors] = useState<problemType[]>([])
    const [warnings, setWarnings] = useState<problemType[]>([])

    const [dataToUpload, setDataToUpload] = useState<contractContentType>([])

    const [isChecking, setIsChecking] = useState(true)

    const contractContent: contractContentType = useStore((state:any) => state.contractContent)
    const setContractContent = useStore((state:any) => state.setContractContent)


    function set(setterFunction: React.Dispatch<React.SetStateAction<problemType[]>>, title: string, listItem: string, objectToSet: problemType) {
        setterFunction((oldValue) => {
            const foundOnIndex = oldValue.findIndex(p => p.title === title)
            // console.log("foundOnIndex", foundOnIndex, oldValue)
            if(foundOnIndex !== -1){
                // check if the sheet is already in the list
                if(oldValue[foundOnIndex].list.map(s => s.toLowerCase()).includes(listItem.toLowerCase())){
                    return oldValue
                } else {
                    const copy = [...oldValue]
                    copy[foundOnIndex].list.push(listItem)
                    return copy
                }
            }
            return [
                ...oldValue,
                objectToSet
            ]
        })
    }
    function getkDuplicates(uploadableContactContent: contractContentType, currentContractContent: contractContentType){
        const uploadableSheetNames = Array.from(new Set(uploadableContactContent.map(c => c.sheetName)))
        const currentSheetNames = Array.from(new Set(currentContractContent.map(c => c.sheetName)))

        const duplicates = uploadableSheetNames.filter(s => currentSheetNames.includes(s))
        return duplicates
    }


    function checkWorksheetsForWarnings(){
        const title = "Figyelmeztetés a munkalap kiválasztással kapcsolatban"
        let numberOfProblems = 0

        classificationResult?.forEach((sheet) => {
            const sheetName = sheet.sheetName

            let numberOfRowsThatWontBeUploaded = 0
            let numberOfRowsThatWillBeUploaded = 0
            sheet.predictions.forEach((prediction) => {
                if(prediction.result === "nem"){
                    numberOfRowsThatWontBeUploaded++
                }
                if(prediction.result === "sor"){
                    numberOfRowsThatWillBeUploaded++
                }
            })
            
            const ratio = 0.8
            if(numberOfRowsThatWontBeUploaded / sheet.predictions.length > ratio || numberOfRowsThatWillBeUploaded === 0){
                set(setWarnings, title, sheetName, {
                    title: title,
                    message: "A következő munkalapok kevés feltöltendő sort tartalmaznak, valószínüleg olyan munkalapot is kiválasztottál amit nem kellene feltöltened.",
                    list: [sheetName],
                    buttonText: "Vissza a munkalap kiválasztóhoz",
                    goBackToIndex: 0,
                    problemType: "warning"
                })
                numberOfProblems++
            }
        })

        return numberOfProblems
    }

    function checkRowsForHeadersForErrors(){
        const title = "Néhány munkalap nem tartalmaz fejlécet"
        let numberOfProblems = 0

        classificationResult?.forEach((sheet) => {
            const sheetName = sheet.sheetName

            let currentSheetHasHeaders = false
            sheet.predictions.forEach((prediction) => {
                if(prediction.result === "fejléc"){
                    currentSheetHasHeaders = true
                }
            })
            
            if(currentSheetHasHeaders === false){
                set(setErrors, title, sheetName, {
                    title: title,
                    message: "A következő munkalapok nem tartalmaznak fejlécet. Fejléc nélkül nem tudjuk folytatni a feltöltést.",
                    list: [sheetName],
                    buttonText: "Vissza a sor kiválasztóhoz",
                    goBackToIndex: 1,
                    problemType: "error"
                })
                numberOfProblems++
            }
        })

        return numberOfProblems
    }

    function checkMissingColumnsForErrors(){
        const title = "Néhány oszlop nem lett meghatározva"
        let numberOfProblems = 0

        const getObject = (sheetName: string, key: string) => {
            return {
                title: title,
                message: "A következő munkalapok oszlopai nem lettek meghatározva. Kérjük határozd meg az összes oszlopot!",
                list: [`${sheetName} -> ${key}`],
                buttonText: "Vissza az oszlop kiválasztóhoz",
                goBackToIndex: 2,
                problemType: "error"
            } as problemType
        }

        console.log("columnAnswersBySheets", columnAnswersBySheets)

        classificationResult?.forEach((sheet, sheetIndex) => {
            const sheetName = sheet.sheetName

            const nameValue = columnAnswersBySheets[sheetIndex].name
            const quantityValue = columnAnswersBySheets[sheetIndex].quantity
            const unitValue = columnAnswersBySheets[sheetIndex].unit
            const allValue = columnAnswersBySheets[sheetIndex].all

            let currentSheetHasHeaders = false
            sheet.predictions.forEach((prediction) => {
                if(prediction.result === "fejléc"){
                    currentSheetHasHeaders = true
                }
            })
            if(currentSheetHasHeaders === false) return
                
            if(nameValue === undefined || nameValue?.length === 0){
                set(setErrors, title, `${sheetName} -> Megnevezés`, getObject(sheetName, "Megnevezés"))
                numberOfProblems++
            }
            if(quantityValue === undefined || quantityValue === null){
                set(setErrors, title, `${sheetName} -> Mennyiség`, getObject(sheetName, "Mennyiség"))
                numberOfProblems++
            }
            if(unitValue === undefined || unitValue === null){
                set(setErrors, title, `${sheetName} -> Mértékegység`, getObject(sheetName, "Mértékegység"))
                numberOfProblems++
            }
            if(allValue === undefined || allValue === null){
                set(setErrors, title, `${sheetName} -> Összesen`, getObject(sheetName, "Összesen"))
                numberOfProblems++
            }

        })

        return numberOfProblems
    }

    function checkCurrentContentWithUploadableContentForDuplicatedSheets(uploadableContactContent: contractContentType, currentContractContent: contractContentType){
        const duplicates = getkDuplicates(uploadableContactContent, currentContractContent)

        if(duplicates.length > 0){
            const title = "Duplikált munkalapok"

            duplicates.forEach((sheetName) => {
                set(setWarnings, title, sheetName, {
                    title: title,
                    message: "A következő munkalapok már szerepelnek a feltöltött szerződésben, ezeket nem fogjuk újra feltölteni",
                    list: [sheetName],
                    buttonText: "Vissza a munkalap kiválasztóhoz",
                    goBackToIndex: 1,
                    problemType: "warning"
                })
            })
        }
    }

    function uploadContractContent(uploadableContractContent: contractContentType){
        const currentContractContent = contractContent
        const duplicates = getkDuplicates(uploadableContractContent, currentContractContent)

        dataToUpload.forEach((content) => {
            
            if(duplicates.includes(content.sheetName)) return

            currentContractContent.push(content)
        })

        setIsUploading(true)

        setTimeout(() => {
            setContractContent([...currentContractContent])
            closeUploadModals()
        }, 1000);

        setTimeout(() => {
            showNotification({
                autoClose: 3000,
                title: "Excel feltöltve",
                message: 'Sikeresen feltöltöttük az általad kért excelt',
                color: 'green',
                icon: <Check />,
              });
        }, 1300);
    }


    useEffect(() => {
      console.log("warnings", warnings)
      console.log("errors", errors)

      setProblems(warnings.concat(errors))
    }, [warnings, errors])
    

    useEffect(() => {
        const numberOfWorksheetProblems = checkWorksheetsForWarnings()
        const numberOfRowsForHeadersProblem = checkRowsForHeadersForErrors()
        const numberOfMissingColumnsProblem = checkMissingColumnsForErrors()

        if((numberOfRowsForHeadersProblem + numberOfMissingColumnsProblem) === 0){

            if(!classificationResult) return
            const filteredSheetsData = selectedSheetNames.length ? sheetsData.filter((sheet) => selectedSheetNames.includes(sheet.sheetName)) : undefined
            if(!filteredSheetsData) return

            const uploadableContractContent = convertToContractContent(
                classificationResult,
                filteredSheetsData,
                columnAnswersBySheets,
                set,
                setWarnings,
                setErrors
            )
            setDataToUpload(uploadableContractContent)

            checkCurrentContentWithUploadableContentForDuplicatedSheets(uploadableContractContent, contractContent)
        }

        setTimeout(() => {
            setIsChecking(false)
        }, 1000);

    }, [])
    


    if(isChecking){
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                <Loader size={'lg'}></Loader>
                <h2>Ellenőrzés...</h2>
            </div>
        )
    }

    if(problems.length > 0){
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <AlertCircle size={'100px'} color={errors.length > 0 ? theme.colors.red[7] : theme.colors.yellow[7]}></AlertCircle>
                    <h3 style={{ fontSize: "1.5rem", margin: "20px 0px 10px 0px", fontWeight: "normal" }}>A következő problémákat találtuk:</h3>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        {errors.length > 0 ? <h3 style={{ fontSize: "1.5rem", margin: 0, color: theme.colors.red[7] }}>{errors.length} probléma {warnings.length > 0 ? "," : ""}</h3> : null}
                        {warnings.length > 0 ? <h3 style={{ fontSize: "1.5rem", margin: 0, color: theme.colors.yellow[7] }}>{warnings.length} figyelmeztetés</h3> : null}
                    </div>
                </div>

                <div style={{ width: "50%" }}>
                    <div style={{ maxHeight: "50vh", overflowY: "scroll" }} className="contract-excel-upload-scrollable-div">
                    <Accordion defaultValue="">
                        {problems.map((problem, index) => (
                            <Accordion.Item value={problem.title} key={problem.title}>
                                <Accordion.Control>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <AlertTriangle
                                            color={problem.problemType === "warning" ? theme.colors.yellow[7] : theme.colors.red[7]}
                                            style={{ marginTop: 2}}>
                                        </AlertTriangle>
                                        <Text
                                            color={problem.problemType === "warning" ? theme.colors.yellow[7] : theme.colors.red[7]}
                                            >
                                                {problem.title}
                                        </Text>
                                    </div>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <Text>{problem.message}</Text>
                                        <Button variant='light' ml={20} onClick={() => setActiveSlideInStepper(problem.goBackToIndex)}>{problem.buttonText}</Button>
                                    </div>
                                    <ul>
                                        {problem.list.map((item, index) => (
                                            <li key={item}>{item}</li>
                                        ))}
                                    </ul>
                                </Accordion.Panel>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                    </div>
                    
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "30px", marginTop: "30px"}}>
                        <PreviewModal buttonWidth={'50%'} previewData={dataToUpload}></PreviewModal>
                        <Button
                            variant='light'
                            w={"50%"}
                            disabled={errors.length > 0}
                            onClick={() => uploadContractContent(dataToUpload)}
                            loading={isUploading}
                        >
                            Szerződés feltöltése
                        </Button>
                    </div>
                </div>

            </div>
        )        
    } else {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <CircleCheck size={'100px'} color={theme.colors.green[7]}></CircleCheck>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                    <h3 style={{ fontSize: "1.5rem", margin: "20px 0px 10px 0px" }}>Minden rendben!</h3>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "30px"}}>
                        <PreviewModal buttonWidth={'264px'} previewData={dataToUpload}></PreviewModal>
                        <Button
                            leftIcon={<FileUpload />}
                            onClick={() => uploadContractContent(dataToUpload)}
                            loading={isUploading}
                        >
                            Szerződéses tartalom feltöltése
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

}
function PreviewModal({ buttonWidth, previewData }: { buttonWidth: string, previewData: contractContentType }) {

    const [isModalOpened, setIsModalOpened] = useState(false)

    return (
        <>
        <Button variant='default' w={buttonWidth} onClick={() => setIsModalOpened(true)}>Előnézet</Button>

        <Modal
            title={`Szerződés előnézete (összesen: ${useNumberWithSpaces(Math.round(calculateContractPrice(previewData)[0]))} Ft)`}
            opened={isModalOpened}
            onClose={() => setIsModalOpened(false)}
            size={"90vw"}
        >
            <ExcelLikeWatcher modeIsEdit={false} modeIsWatch={true} contractContentIsNull={false} previewData={previewData} modeIsOwnContracts={false} setIsLoading={() => null}></ExcelLikeWatcher>
        </Modal>
        </>
    )
}




function convertToContractContent(classificationResult: classificationResultType, sheetsData: sheetsDataType, columnAnswersBySheets: answerType[], set: (setterFunction: React.Dispatch<React.SetStateAction<problemType[]>>, title: string, listItem: string, objectToSet: problemType) => void, setWarnings: React.Dispatch<React.SetStateAction<problemType[]>>, setErrors: React.Dispatch<React.SetStateAction<problemType[]>>): contractContentType {

    type TableItem = "sor" | "nem" | "fejléc" | "cím";

    interface TableRow {
    type: "sor";
    value: string[];
    }

    interface TableHeader {
    type: "fejléc";
    value: string[];
    }

    interface TableName {
    type: "cím";
    value: string[];
    }

    type TableElement = TableRow | TableHeader | TableName;

    function buildNestedTableArray(inputArray: TableItem[], sheetIndex: number): TableElement[][] {
        let nestedArray: TableElement[][] = [];
        let currentTable: TableElement[] = [];

        inputArray.forEach((item, rowIndex) => {
            if (item === "cím") {
                // If it's the name of the table, start a new table
                if (currentTable.length > 0) {
                    nestedArray.push(currentTable);
                }
                currentTable = [{ type: item, value: sheetsData![sheetIndex].rows[rowIndex] }];

            } else if (item === "sor" && currentTable.length > 0) {
                // If it's a row and there's a current table, add it to the current table
                currentTable.push({ type: item, value: sheetsData![sheetIndex].rows[rowIndex] });
            }
            // else if (item === "fejléc" && currentTable.length > 0) {
            //     // If it's a header and there's a current table, add it to the current table
            //     currentTable.push({ type: item, value: "Header" });
        })

        if (currentTable.length > 0) {
            nestedArray.push(currentTable);
        }

        // remove tables with only a name
        nestedArray = nestedArray.filter((table) => {
            if(table.length === 1 && table[0].type === "cím") {
                return false;
            }
            return true;
        });

        return nestedArray;
    }

    function buildTableStrucutre(tables: TableElement[][], sheetIndex: number, sheetName: string){
        const sheetId = String(new Date().getTime() + sheetIndex)

        const newSheet = {
            id: sheetId,
            sheetName: sheetName,
            sheetData: [] as { id: string; is_after_work: boolean; tableName: string; tableData: { id: string; name: string; quantity: number; unit: string; total: number; measuredQuantity: number; forint_readiness: number; percentage_readiness: number }[] }[],
        }

        tables.forEach((table, tableIndex) => {

            const nameColumnLetters = columnAnswersBySheets[sheetIndex].name?.map(n => n.slice(0, 1));
            const quantityColumnLetter = columnAnswersBySheets[sheetIndex].quantity?.slice(0, 1);
            const unitColumnLetter = columnAnswersBySheets[sheetIndex].unit?.slice(0, 1);
            const allColumnLetter = columnAnswersBySheets[sheetIndex].all?.slice(0, 1);

            if(!nameColumnLetters || !quantityColumnLetter || !unitColumnLetter || !allColumnLetter) return

            const nameColumnIndexes = nameColumnLetters.map(nameColumnLetter => letters.findIndex(letter => letter === nameColumnLetter));
            const quantityColumnIndex = letters.findIndex(letter => letter === quantityColumnLetter);
            const unitColumnIndex = letters.findIndex(letter => letter === unitColumnLetter);
            const allColumnIndex = letters.findIndex(letter => letter === allColumnLetter);

            const tableNameCollumns = [...nameColumnIndexes, quantityColumnIndex, unitColumnIndex, allColumnIndex];

            // getting the name of the table
            const tableNameArray = table[0].value
            let tableName = ""
            tableNameArray.forEach((cell, cellIndex) => {

                // test if cell only contains numbers
                if(/^\d+$/.test(cell)) return

                // check if column was selected by user
                if(tableNameCollumns.includes(cellIndex)) {
                    tableName += cell + " "
                }
            })
            tableName = tableName.trim()

            // test empty table names
            if(tableName.length === 0){
                const title = "Üres tábla nevek"
                const listItem = `${sheetName} -> ${tableIndex}. tábla`
                set(setWarnings, title, listItem, {
                    title: title,
                    message: "Az alábbi munkalapok tábláinak nevei üresek.",
                    list: [listItem],
                    buttonText: "Vissza az oszlop kiválasztóhoz",
                    goBackToIndex: 2,
                    problemType: "warning"
                })
            }
            // test tableName if contains numbers
            if(/\d/.test(tableName)){
                const title = "Hibás tábla nevek"
                const listItem = `${sheetName} -> ${tableIndex}. tábla (táblanév: ${tableName})`
                set(setWarnings, title, listItem, {
                    title: title,
                    message: "Az alábbi munkalapok tábláinak nevei számokat is tartalmaznak. Lehetséges hogy rossz sor lett címként megjelölve.",
                    list: [listItem],
                    buttonText: "Vissza a sor kiválasztóhoz",
                    goBackToIndex: 1,
                    problemType: "warning"
                })
            }
            
            const tableData = {
                id: String(Number(sheetId) + tableIndex + 1),
                is_after_work: false,
                tableName: tableName,
                tableData: [] as { id: string; name: string; quantity: number; unit: string; total: number; measuredQuantity: number; forint_readiness: number; percentage_readiness: number }[],
            }

            // getting the data of the table
            table.forEach((row, rowIndex) => {
                if(row.type === "sor") {

                    // if(nameColumnLetters && quantityColumnLetter && unitColumnLetter && allColumnLetter) {

                        const nameValue = nameColumnIndexes.map(nameColumnIndex => row.value[nameColumnIndex]).join(" ");
                        const quantityValue = row.value[quantityColumnIndex];
                        const unitValue = row.value[unitColumnIndex];
                        const allValue = row.value[allColumnIndex];

                        let thereWasAnError = false

                        // nameValue is always a string because of the join
                        const nameValueString = String(nameValue).trim()
                        // test if name is empty or only contains spaces
                        if(nameValueString.length === 0 || /^\s+$/.test(nameValueString)){
                            const title = "Néhány sor megnevezése üres"
                            const listItem = `${sheetName} -> ${tableIndex}. tábla -> ${rowIndex}. sora`
                            set(setWarnings, title, listItem, {
                                title: title,
                                message: "A következő sorok megnevezései üresek, ez azért fordulhatott elő, mert a megnevezés oszlop megadásakor, rossz oszlop lett kiválasztva, vagy a feltöltendő excel üres cellákat tartalmaz",
                                list: [listItem],
                                buttonText: "Vissza az oszlop kiválasztóhoz",
                                goBackToIndex: 2,
                                problemType: "warning"
                            })
                        }
                        // test if name only contains numbers
                        if(/^\d+$/.test(nameValueString)){
                            const title = "Néhány sor megnevezése csak tételszámot tartalmaz"
                            const listItem = `${sheetName} -> ${tableIndex}. tábla -> ${rowIndex}. sora (megnevezés: ${nameValueString})`
                            set(setWarnings, title, listItem, {
                                title: title,
                                message: "A következő sorok megnevezései csak tételszámot tartalmaznak, ez azért fordulhatott elő, mert a megnevezés oszlop megadásakor, rossz oszlop lett kiválasztva",
                                list: [listItem],
                                buttonText: "Vissza az oszlop kiválasztóhoz",
                                goBackToIndex: 2,
                                problemType: "warning"
                            })
                        }


                        const quantityValueString = String(quantityValue).trim()
                        // test if quantity is empty or only contains spaces
                        if(quantityValueString.length === 0 || /^\s+$/.test(quantityValueString)){
                            const title = "Néhány sor mennyiség cellája üres"
                            const listItem = `${sheetName} -> ${tableIndex}. tábla -> ${rowIndex}. sora`
                            set(setWarnings, title, listItem, {
                                title: title,
                                message: "A következő sorok mennyiség cellái üresek, ez azért fordulhatott elő, mert a mennyiség oszlop megadásakor, rossz oszlop lett kiválasztva, vagy a feltöltendő excel üres cellákat tartalmaz",
                                list: [listItem],
                                buttonText: "Vissza az oszlop kiválasztóhoz",
                                goBackToIndex: 2,
                                problemType: "warning"
                            })
                        }
                        // test if quantity contains other characters than numbers
                        if(/\D/.test(quantityValueString.replace(".", ""))){
                            const title = "Néhány sor mennyiség cellája szöveget is tartalmaz"
                            const listItem = `${sheetName} -> ${tableIndex}. tábla -> ${rowIndex}. sora (mennyiség: ${quantityValueString.slice(0, 15)}${quantityValueString.length > 15 ? "..." : ""})`
                            set(setErrors, title, listItem, {
                                title: title,
                                message: "A következő sorok mennyiség cellái szöveget is tartalmaznak, ez azért fordulhatott elő, mert a mennyiség oszlop megadásakor, rossz oszlop lett kiválasztva",
                                list: [listItem],
                                buttonText: "Vissza az oszlop kiválasztóhoz",
                                goBackToIndex: 2,
                                problemType: "error"
                            })
                            thereWasAnError = true
                        }


                        const unitValueString = String(unitValue).trim()
                        // test if unit is empty or only contains spaces
                        if(unitValueString.length === 0 || /^\s+$/.test(unitValueString)){
                            const title = "Néhány sor mértékegység cellája üres"
                            const listItem = `${sheetName} -> ${tableIndex}. tábla -> ${rowIndex}. sora`
                            set(setWarnings, title, listItem, {
                                title: title,
                                message: "A következő sorok mértékegység cellái üresek, ez azért fordulhatott elő, mert a mértékegység oszlop megadásakor, rossz oszlop lett kiválasztva, vagy a feltöltendő excel üres cellákat tartalmaz",
                                list: [listItem],
                                buttonText: "Vissza az oszlop kiválasztóhoz",
                                goBackToIndex: 2,
                                problemType: "warning"
                            })
                        }
                        // test if unit only contains numbers
                        if(/^\d+$/.test(unitValueString)){
                            const title = "Néhány sor mértékegység cellája csak számot tartalmaz"
                            const listItem = `${sheetName} -> ${tableIndex}. tábla -> ${rowIndex}. sora (mértékegység: ${unitValueString})`
                            set(setErrors, title, listItem, {
                                title: title,
                                message: "A következő sorok mértékegység cellái csak számot tartalmaznak, ez azért fordulhatott elő, mert a mértékegység oszlop megadásakor, rossz oszlop lett kiválasztva",
                                list: [listItem],
                                buttonText: "Vissza az oszlop kiválasztóhoz",
                                goBackToIndex: 2,
                                problemType: "error"
                            })
                            thereWasAnError = true
                        }


                        const allValueString = String(allValue).trim()
                        // test if all is empty or only contains spaces
                        if(allValueString.length === 0 || /^\s+$/.test(allValueString)){
                            const title = "Néhány sor összesen cellája üres"
                            const listItem = `${sheetName} -> ${tableIndex}. tábla -> ${rowIndex}. sora`
                            set(setWarnings, title, listItem, {
                                title: title,
                                message: "A következő sorok összesen cellái üresek, ez azért fordulhatott elő, mert az összesen oszlop megadásakor, rossz oszlop lett kiválasztva, vagy a feltöltendő excel üres cellákat tartalmaz",
                                list: [listItem],
                                buttonText: "Vissza az oszlop kiválasztóhoz",
                                goBackToIndex: 2,
                                problemType: "warning"
                            })
                        }
                        // test if all contains other characters than numbers
                        if(/\D/.test(allValueString.replace(".", ""))){
                            const title = "Néhány sor összesen cellája szöveget is tartalmaz"
                            const listItem = `${sheetName} -> ${tableIndex}. tábla -> ${rowIndex}. sora (összesen: ${allValueString.slice(0, 15)}${allValueString.length > 15 ? "..." : ""})`
                            set(setErrors, title, listItem, {
                                title: title,
                                message: "A következő sorok összesen cellái szöveget is tartalmaznak, ez azért fordulhatott elő, mert az összesen oszlop megadásakor, rossz oszlop lett kiválasztva",
                                list: [listItem],
                                buttonText: "Vissza az oszlop kiválasztóhoz",
                                goBackToIndex: 2,
                                problemType: "error"
                            })
                            thereWasAnError = true
                        }

                        // dont continue if there was a problem
                        if(thereWasAnError) return
                        

                        const name = nameValueString
                        const quantity = parseFloat(Number(quantityValueString).toFixed(2))
                        const unit = unitValueString
                        const all = parseFloat(Number(allValueString).toFixed(2))

                        tableData.tableData.push({
                            id: String(Number(sheetId) + tableIndex + rowIndex + 2),
                            name: name,
                            quantity: quantity,
                            unit: unit,
                            total: all,
                            measuredQuantity: 0,
                            forint_readiness: 0,
                            percentage_readiness: 0
                        })
                    // }

                }
            })
            newSheet.sheetData.push(tableData)

        })
        return newSheet
    }


    const contractContent: contractContentType = []

    classificationResult.forEach((prediction, sheetIndex) => {

        const predictionsInThisSheet = prediction.predictions.map((p) => p.result) as TableItem[];

        // const inputArray: TableItem[] = ["cím", "fejléc", "sor", "sor", "fejléc", "sor", "cím", "sor", "fejléc"];
        const nestedTableArray = buildNestedTableArray(predictionsInThisSheet, sheetIndex);

        const newSheet = buildTableStrucutre(nestedTableArray, sheetIndex, prediction.sheetName);
        contractContent.push(newSheet)
    
        console.log("nestedTableArray", nestedTableArray);
    })

    console.log("contractContent", contractContent);

    return contractContent
}