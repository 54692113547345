import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Dashboard from './components/dashboard'
import Login from './auth/login'
import Register from './auth/register_organization'
import { ProtectedRoute } from './auth/login/ProtectedRoute'

import HomeScreen from './pages/HomeScreen'
import DailyPosts from './pages/DailyPosts'
import Datatables from './pages/Datatables'
import UploadToDatabase from './pages/UploadToDatabase'
import AI from './pages/AI'
import PlannedCosts from './pages/PlannedCosts'
import OwnContracts from './pages/OwnContracts'
import SubcontractorContracts from './pages/SubcontractorContracts'
import ProjectNumbers from './pages/ProjectNumbers'
import Settings from './pages/Settings'
import Help from './pages/Help'

import TableSort from './pages/Datatables/components/Table'
import PaymentSuccess from './payment/PaymentSuccess'
import PaymentFail from './payment/PaymentFail'
import ConfirmEmail from './auth/register_organization/ConfirmEmail'
import DailyPostsNewDay from './components/DailyPostsNewDay'
import ContractContent from './components/ContractContent'
import ErrorPage from './error/ErrorPage'
import { Page404 } from './error/404'
import Statistics from './pages/Statistics'
import OwnContractProgress from './pages/OwnContractProgress'
import SubcontractorContractProgress from './pages/SubcontractorContractProgress'
import QueryGraphs from './components/QueryGraphs'

function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />}/>
        <Route path='/register-organization' element={<Register />}/>
        <Route path='/confirm-organization-email/:docId' element={<ConfirmEmail />}/>
        <Route path='/payment-success' element={<PaymentSuccess />}/>
        <Route path='/payment-fail' element={<PaymentFail />}/>
        <Route path='/error' element={<ErrorPage />}/>
        
        <Route path='/' element={ <div><h1>Üdvözöl a builty</h1><a href="/home"><button>Tovább a főoldalra</button></a></div>}/>


        <Route element={ <ProtectedRoute/> }>

          <Route element={ <Dashboard/> }>

            <Route path='home' element={ <HomeScreen /> }/>
            <Route path='statistics' element={<Statistics />}/>
            <Route path='daily-posts' element={ <DailyPosts /> }/>

            <Route path='database/tables' element={ <Datatables /> }/>
            <Route path='database/tables/:tableParam' element={ <TableSort /> }/>
            
            <Route path='database/upload' element={ <UploadToDatabase /> }/>
            <Route path='database/ai' element={ <AI /> }/>
            
            <Route path='planned-costs' element={ <PlannedCosts /> }/>

            <Route path='own-contracts' element={ <OwnContracts /> }/>
            <Route path='subcontractor-contracts' element={ <SubcontractorContracts /> }/>
            <Route path='own-contract-progress' element={<OwnContractProgress />}/>
            <Route path='subcontractor-contract-progress' element={<SubcontractorContractProgress />}/>
          
            <Route path='project-numbers' element={ <ProjectNumbers /> }/>
            <Route path='settings' element={ <Settings /> }/>
            <Route path='help' element={ <Help /> }/>
          
          </Route>

          <Route path='daily-posts/new-day' element={ <DailyPostsNewDay mode={'dailyPost'} /> }/>
          <Route path='daily-posts/watch/:docId' element={ <DailyPostsNewDay mode={'watchDailyPost'} /> }/>
          

          <Route path='planned-costs/new-plan' element={ <DailyPostsNewDay mode={'plannedCost'}/> }/>
          <Route path='planned-costs/watch/:docId' element={ <DailyPostsNewDay mode={'watchPlannedCost'}/> }/>

          <Route path='own-contract-progress/new-day/:contractDocId' element={<ContractContent modeIsEdit={false} modeIsWatch={false} modeIsOwnContracts={true} />}/>
          <Route path='own-contract-progress/watch/:contractProgressDocId' element={<ContractContent modeIsEdit={false} modeIsWatch={true} modeIsOwnContracts={true} />}/>

          <Route path='subcontractor-contract-progress/new-day/:contractDocId' element={<ContractContent modeIsEdit={false} modeIsWatch={false} modeIsOwnContracts={false} />}/>
          <Route path='subcontractor-contract-progress/watch/:contractProgressDocId' element={<ContractContent modeIsEdit={false} modeIsWatch={true} modeIsOwnContracts={false} />}/>


          <Route path='own-contracts/:docId' element={ <ContractContent modeIsEdit={true} modeIsWatch={false} modeIsOwnContracts={true} /> }/>

          <Route path='subcontractor-contracts/:docId' element={ <ContractContent modeIsEdit={true} modeIsWatch={false} modeIsOwnContracts={false} /> }/>

          <Route path="/statistics/query-graphs" element={<QueryGraphs />}/>

        </Route>

        <Route path='*' element={<Page404/>}/>
        
      </Routes>
    </BrowserRouter>
  )
}

export default Routing