import { Button, Chip, Group, Paper, Select, useMantineTheme, Text, ScrollArea, Space, RingProgress, Center } from '@mantine/core'
import { useSessionStorage } from '@mantine/hooks'
import React, { useState } from 'react'
import { Plus } from 'tabler-icons-react'
import ChartAndList from './components/ChartAndList'
import Header from './components/Header'




export default function OwnContractProgress() {

  const [selectedProjectNumber, setSelectedProjectNumber] = useSessionStorage<string | null>({ key: "own-selectedProjectNumber", defaultValue: null });
  // const [selectedProjectNumber, setSelectedProjectNumber] = useState<string | null>(null)
  
  const [selectedContract, setSelectedContract] = useSessionStorage<string | null>({ key: "own-selectedContract", defaultValue: null });
  // const [selectedContract, setSelectedContract] = useState<string | null>(null)

  const [selectedContractDocId, setSelectedContractDocId] = useState("")

  const [modeIsDelete, setModeIsDelete] = useState(false)

  return (
    <>
      <Header modeIsOwnContracts={true} selectedProjectNumber={selectedProjectNumber} setSelectedProjectNumber={setSelectedProjectNumber} selectedContract={selectedContract} setSelectedContract={setSelectedContract} selectedContractDocId={selectedContractDocId} setSelectedContractDocId={setSelectedContractDocId} setModeIsDelete={setModeIsDelete}/>
      <ChartAndList modeIsOwnContracts={true} selectedProjectNumber={selectedProjectNumber} selectedContract={selectedContract} setSelectedProjectNumber={setSelectedProjectNumber} setSelectedContract={setSelectedContract} selectedContractDocId={selectedContractDocId} setSelectedContractDocId={setSelectedContractDocId} modeIsDelete={modeIsDelete}/>
    </>
  )
}








// function Chips(){
//   return (
//     <div>
//       <h3>Munkaszámok</h3>
//       <div>

//       <Chip.Group>
//         <Group position="center">
//           <Chip value="1">Single chip</Chip>
//           <Chip value="2">Can be selected</Chip>
//           <Chip value="3">At a time</Chip>
//           <Chip value="1">Single chip</Chip>
//           <Chip value="2">Can be selected</Chip>
//           <Chip value="3">At a time</Chip>
//           <Chip value="1">Single chip</Chip>
//           <Chip value="2">Can be selected</Chip>
//           <Chip value="3">At a time</Chip>
//           <Chip value="1">Single chip</Chip>
//           <Chip value="2">Can be selected</Chip>
//           <Chip value="3">At a time</Chip>
//           <Chip value="1">Single chip</Chip>
//           <Chip value="2">Can be selected</Chip>
//           <Chip value="3">At a time</Chip>
//           <Chip value="1">Single chip</Chip>
//           <Chip value="2">Can be selected</Chip>
//           <Chip value="3">At a time</Chip>
//           <Chip value="1">Single chip</Chip>
//           <Chip value="2">Can be selected</Chip>
//           <Chip value="3">At a time</Chip>
//           <Chip value="1">Single chip</Chip>
//           <Chip value="2">Can be selected</Chip>
//           <Chip value="3">At a time</Chip>
//           <Chip value="1">Single chip</Chip>
//           <Chip value="2">Can be selected</Chip>
//           <Chip value="3">At a time</Chip>
//         </Group>
//       </Chip.Group>

//       </div>
//     </div>
//   )
// }
