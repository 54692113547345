import { Button } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import React, { useState } from 'react'
import { AlertCircle, Check, Plus } from 'tabler-icons-react'
import { useAuth, useFirestore, useUserCompany } from '../../../../hooks/useFirebase'
import CreateEditModal from '../Modal'


interface ListProps {
  projectNumbers: {value:string, label:string}[],
  modeIsOwnContrats: boolean
}


export default function Header({ projectNumbers, modeIsOwnContrats }: ListProps) {

  const [modalOpened, setModalOpened] = useState(false)

  const [projectNumber, setProjectNumber] = useState<string | null>("")
  const [buyerName, setBuyerName] = useState("")
  const [contractorName, setContractorName] = useState("")
  const [comment, setComment] = useState("")


  async function addContract(){

    try {
      const db = useFirestore()
      const userCompany = await useUserCompany()
      const user = useAuth()
      // Add a new document with a generated id.
      const docRef = await addDoc(collection(db, "contract"), {
        after_work: 0,
        comment: comment,
        content: [],
        contract_price: 0,
        contractor: modeIsOwnContrats ? userCompany : contractorName,
        created_at: serverTimestamp(),
        last_modified: serverTimestamp(),
        customer: modeIsOwnContrats ? buyerName : userCompany,
        forint_readiness: 0,
        project_number: projectNumber,
        user_id: user.uid
      });
      console.log("Document written with ID: ", docRef.id);

      setModalOpened(false)

      showNotification({
        id: 'noti',
        color: "green",
        title: `Szerződés sikeresen létrehozva`,
        icon: <Check size={16} />,
        message: 'A szerződést sikeresen létrehoztuk, és hozzárendeltük a fiókodhoz, és cégedhez.',
        autoClose: 5000,
      });

    } catch (error) {
      console.log(error)
      showNotification({
        id: 'noti',
        color: "red",
        title: `Hiba történt`,
        icon: <AlertCircle size={16} />,
        message: 'Nem sikerült létrehozni a szerződésed, kérlek később próbáld újra!',
        autoClose: false,
    });
    }

  }

  
  return (
    <>
    <div style={{"display": "flex", "alignItems": "center", "justifyContent": "space-between", "margin": "-5px 0px 20px 0px"}}>
          <h2 style={{"margin": "0px"}}>{modeIsOwnContrats ? "Saját" : "Alvállalkozói"} szerződések</h2>
          <div style={{"display": "flex", "gap": "20px"}}>
            <Button leftIcon={<Plus size={20}/>} onClick={() => setModalOpened(true)} color={modeIsOwnContrats ? "cyan" : "green"}>
                Új szerződés létrehozása
            </Button>
          </div>

    </div>

    <CreateEditModal
        modeIsOwnContrats={modeIsOwnContrats}
        modeIsCreate={true}
        modalOpened={modalOpened}
        onButtonClick={addContract}
        setModalOpened={setModalOpened}
        projectNumber={projectNumber}
        setProjectNumber={setProjectNumber}
        project_numbers={projectNumbers}
        buyerName={buyerName}
        setBuyerName={setBuyerName}
        contractorName={contractorName}
        setContractorName={setContractorName}
        comment={comment}
        setComment={setComment}
      />
    </>
  )
}
