import { Modal, Text } from '@mantine/core';
import { useScrollIntoView } from '@mantine/hooks';
import { addDoc, collection, doc, DocumentData, getDoc, getDocs, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { AlertCircle } from 'tabler-icons-react';
import { useAuth, useFirestore, useUserCompany } from '../../hooks/useFirebase';
import { useStore } from '../../hooks/useStore';
import { useCapitalizeFirstLetter, weekday } from '../../hooks/utils';
import { contractContentType } from '../../interfaces/contractContentType';
import { DailyPostType } from '../../interfaces/dailyPostType';
import { formModeType, FormSchemeType, FormStateType } from '../../interfaces/formTypes';
import Contract from '../../pages/OwnContracts/components/Contract';
import { contractType } from '../../pages/OwnContracts/components/List/List';
import ContractContent from '../ContractContent';
import Form from './Form'
import Header from './Header'


interface DailyPostsNewDayProps {
  mode: formModeType
}

function DailyPostsNewDay({ mode }: DailyPostsNewDayProps) {

  let { docId } = useParams();
  const [savedDoc, setSavedDOc] = useState(docId || "")
  // const [formScheme, setFormScheme] = useState([])
  // const [formState, setFormState] = useState([])
  // const formState: FormStateType = useStore((state:any) => state.formState)
  const setFormState = useStore((state:any) => state.setFormState)
  // const formScheme: FormSchemeType = useStore((state:any) => state.formScheme)
  const setFormScheme = useStore((state:any) => state.setFormScheme)
  
  const setFormFinalPrice = useStore((state:any) => state.setFormFinalPrice)
  const setFormProjectNumber = useStore((state:any) => state.setFormProjectNumber)
  const setFormDate = useStore((state:any) => state.setFormDate)
  const setFormComment = useStore((state:any) => state.setFormComment)
  
  const setFormTitle = useStore((state:any) => state.setFormTitle)
  const selectedProjectNumber = useStore((state:any) => state.formProjectNumber)

  const formMode = useStore((state:any) => state.formMode)
  const setFormMode = useStore((state:any) => state.setFormMode)


  const viewport = useRef<HTMLDivElement>();
  const navigate = useNavigate()

  const [post, setPost] = useState<DailyPostType>()

  // if you want to change the names, you only have to rename in this array
  // const switcherData = ["Napi Költségek", "Szerződések"]
  // const [switcherIndex, setSwitcherIndex] = useState(0)

  // const [contractSelectorModalIsOpened, setContractSelectorModalIsOpened] = useState(false)

  // const [ownContractsBasedOnSelectedProjectNumber, setOwnContractsBasedOnSelectedProjectNumber] = useState<contractType[]>([])
  // const [subcontractorContractsBasedOnSelectedProjectNumber, setSubcontractorContractsBasedOnSelectedProjectNumber] = useState<contractType[]>([])

  // const [localContractContent, setLocalContractContent] = useState<contractContentType>([])
  // const [selectedContractType, setSelectedContractType] = useState<"own" | "sub">()
  // const [selectedContractOtherCompany, setSelectedContractOtherCompany] = useState("")
  // const [selectedContractHistoryDocId, setSelectedContractHistoryDocId] = useState("")
  // const [selectedContractDocId, setSelectedContractDocId] = useState("")

  // const formDailyIncomeObject = useStore((state:any) => state.formDailyIncomeObject)
  // const setFormDailyIncomeObject = useStore((state:any) => state.setFormDailyIncomeObject)
  // const formDailySubcontractorCostObject = useStore((state:any) => state.formDailySubcontractorCostObject)
  // const setFormDailySubcontractorCostObject = useStore((state:any) => state.setFormDailySubcontractorCostObject)

  // const [dailyIncome, setDailyIncome] = useState(0)
  // const [subcontractorContractPrice, setSubcontractorContractPrice] = useState(0)

  // const [dailyIncome, setDailyIncome] = useState(0)


  async function fetchPost(collection: string){

    const db = useFirestore()
    const docRef = doc(db, collection, docId || "");
    const docSnap = await getDoc(docRef);

    // console.log(docSnap.data())
    return docSnap.data() as DailyPostType
  }

  async function loadingState(){
    let post: DailyPostType | undefined
    
    if(mode === "watchDailyPost"){
      post = await fetchPost("daily")

    } else if(mode === "watchPlannedCost"){
      post = await fetchPost("plan")

    }
    setPost(post)

    // console.log(post)
    setFormScheme(JSON.parse(post!.scheme))
    setFormState(JSON.parse(post!.data))
    
    setFormFinalPrice(post!.final_price)
    setFormProjectNumber(post!.project_number)
    setFormDate(new Date(post!.date.seconds*1000))
    setFormComment(post!.comment)

    setFormTitle(`${weekday[new Date(post!.date.seconds*1000).getDay()]} ${useCapitalizeFirstLetter(new Intl.DateTimeFormat('hu-HU', { month: "long", day: "2-digit"}).format(post!.date.seconds*1000)).replace(".", "")}`)
    
    // setDailyIncome(post!.daily_income)
    // setSubcontractorContractPrice(post!.subcontractor_contracts_price)
    // else if(
    //   mode === "dailyPost" && localStorage.getItem("daily-form-scheme") != null &&
    //   mode === "dailyPost" && localStorage.getItem("daily-form-scheme") != "[]" &&
    //   mode === "dailyPost" && localStorage.getItem("daily-form") != null &&
    //   mode === "dailyPost" && localStorage.getItem("daily-form") != "[]"
    //   ){
    //     // console.log("i ran")
    //     console.log(JSON.parse(localStorage.getItem("daily-form-scheme") || "[]"), JSON.parse(localStorage.getItem("daily-form") || "[]"))
    //     await setFormScheme(JSON.parse(localStorage.getItem("daily-form-scheme") || "[]"))
    //     await setFormState(JSON.parse(localStorage.getItem("daily-form") || "[]"))
    //     await setFormFinalPrice(JSON.parse(localStorage.getItem("daily-form-final-price") || "0"))
    //     // localStorage.setItem("form-already-loaded-from-localstorage", "true")
    // } else if(
    //   mode === "plannedCost" && localStorage.getItem("planned-form-scheme") != null &&
    //   mode === "plannedCost" && localStorage.getItem("planned-form-scheme") != "[]" &&
    //   mode === "plannedCost" && localStorage.getItem("planned-form") != null &&
    //   mode === "plannedCost" && localStorage.getItem("planned-form") != "[]"
    //   ){
    //     console.log(JSON.parse(localStorage.getItem("planned-form-scheme") || "[]"))
    //     await setFormScheme(JSON.parse(localStorage.getItem("planned-form-scheme") || "[]"))
    //     await setFormState(JSON.parse(localStorage.getItem("planned-form") || "[]"))
    //     await setFormFinalPrice(JSON.parse(localStorage.getItem("planned-form-final-price") || "0"))
    //     // localStorage.setItem("form-already-loaded-from-localstorage", "true")
    // }
  }

  // async function fetchContractsUnderSelectedProjectNumber(){
  //   const db = useFirestore()
  //   const user = useAuth()
  //   const company = await useUserCompany()

  //   let q = query(collection(db, "contract"), where("user_id", "==", user.uid), where("contractor", "==", company), where("project_number", "==", selectedProjectNumber));
  //   let querySnapshot = await getDocs(q);

  //   const ownContracts: contractType[] = []
  //   querySnapshot.forEach((doc) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     const contract = doc.data() as contractType
  //     console.log(doc.id, " => ", contract);
  //     ownContracts.push({ id: doc.id, ...doc.data() as any })
  //   });

  //   q = query(collection(db, "contract"), where("user_id", "==", user.uid), where("customer", "==", company), where("project_number", "==", selectedProjectNumber));
  //   querySnapshot = await getDocs(q);

  //   const subContractorContracts: contractType[] = []
  //   querySnapshot.forEach((doc) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     const contract = doc.data() as contractType
  //     console.log(doc.id, " => ", contract);
  //     subContractorContracts.push({ id: doc.id, ...doc.data() as any })
  //   });

  //   setOwnContractsBasedOnSelectedProjectNumber(ownContracts)
  //   setSubcontractorContractsBasedOnSelectedProjectNumber(subContractorContracts)
  // }

  // function getContractContentFromDailyForm(){

  // }

  // async function handleSelectContract(contract: contractType){
    
  //   const db = useFirestore()
  //   // const q = query(collection(db, "contract-history"), where("daily_id", "==", savedDoc), where("contract_id", "==", selectedContractID));
    
  //   // check if contract history has been saved to this daily
  //   const docRef = doc(db, "contract-history", `${savedDoc}-${contract.id}`);
  //   const docSnap = await getDoc(docRef);

  //   const company = await useUserCompany()
  //   const contractIsOwn = contract.contractor == company

  //   if (docSnap.exists()) {
  //     console.log("Document data:", docSnap.data());
  //     setLocalContractContent(docSnap.data().contract_content)
  //     setContractSelectorModalIsOpened(false)

  //     setSelectedContractType(contractIsOwn ? "own" : "sub")
  //     setSelectedContractOtherCompany(contractIsOwn ? contract.customer : contract.contractor)
  //     setSelectedContractHistoryDocId(docSnap.id)
  //     setSelectedContractDocId(contract.id)



  //     if(contractIsOwn){
  //       const copy = formDailyIncomeObject
  //       copy[`${contract.id}`] = {"initial": docSnap.data().forint_readiness, "changed": 0}
  //       setFormDailyIncomeObject(copy)
  
  //       console.log(copy)
  //     } else {
  //       const copy = formDailySubcontractorCostObject
  //       copy[`${contract.id}`] = {"initial": docSnap.data().forint_readiness, "changed": 0}
  //       setFormDailySubcontractorCostObject(copy)
  
  //       console.log(copy)
  //     }



  //   } else {
  //     // doc.data() will be undefined in this case
  //     console.log("No such document!");
  //     const user = useAuth()
  //     // Add a new document with a generated id.
  //     await setDoc(doc(db, "contract-history", `${savedDoc}-${contract.id}`), {
  //       company: company,
  //       contract_content: contract.content,
  //       contract_id: contract.id,
  //       contractor: contract.contractor,
  //       forint_readiness: contract.forint_readiness,
  //       percentage_readiness: contract.percentage_readiness,
  //       created_at: serverTimestamp(),
  //       daily_id: savedDoc,
  //       user_id: user.uid
  //     });
  //     console.log("Document written with ID: ", `${savedDoc}${contract.id}`);
  //     setLocalContractContent(contract.content)
  //     setContractSelectorModalIsOpened(false)
      
  //     // const company = await useUserCompany()
  //     // const contractIsOwn = contract.contractor == company
  //     setSelectedContractType(contractIsOwn ? "own" : "sub")
  //     setSelectedContractOtherCompany(contractIsOwn ? contract.customer : contract.contractor)
  //     setSelectedContractHistoryDocId(docSnap.id)
  //     setSelectedContractDocId(contract.id)



  //     if(contractIsOwn){
  //       const copy = formDailyIncomeObject
  //       copy[`${contract.id}`] = {"initial": contract.forint_readiness, "changed": 0}
  //       setFormDailyIncomeObject(copy)
  
  //       console.log(copy)
  //     } else {
  //       const copy = formDailySubcontractorCostObject
  //       copy[`${contract.id}`] = {"initial": contract.forint_readiness, "changed": 0}
  //       setFormDailySubcontractorCostObject(copy)
  
  //       console.log(copy)
  //     }
  //   }



  //   // found the contract history version
  //   // if(contracts.length != 0){
  //   //   setContractContent()
  //   // } else {
  //   // }


  // }

  useLayoutEffect(() => {
    setFormMode(mode)

    // console.log(mode)
    
  }, [])
  
  // const ref:any = useRef()
  useEffect(() => {
    // if (ref.current) return;
    // ref.current = true;
    if(mode.toLowerCase().includes("watch")){
      loadingState()
    }

    return () => {
      // if(mode.toLowerCase().includes("watch".toLowerCase())){
      if(window.location.href.includes("watch") || window.location.href.includes("new-day")){
        console.warn("formScheme and formState wanted to be updated before component unmount")
      } else {
        setFormScheme([])
        setFormState([])
        setFormFinalPrice(0)
        setFormProjectNumber("")
        setFormDate("")
        setFormComment("")
        setFormTitle("")
      
        console.log("formstates have been cleared")
      }


        // setDailyIncome(0)
        // setSubcontractorContractPrice(0)
        // setFormDailyIncomeObject({})
        // setFormDailySubcontractorCostObject({})
      // }
    }
  }, [])


  // useEffect(() => {
  //   if(switcherIndex == 1){
  //     setContractSelectorModalIsOpened(true)
  //   } else {
  //     setContractSelectorModalIsOpened(false)
  //     setLocalContractContent([])
  //   }
  // }, [switcherIndex])
  
  // useEffect(() => {
  //   if(!contractSelectorModalIsOpened && localContractContent.length == 0){
  //     setSwitcherIndex(0)
  //   }
  // }, [contractSelectorModalIsOpened])
  


  // useEffect(() => {
  //   setSwitcherIndex(0)
  //   fetchContractsUnderSelectedProjectNumber()

  // }, [selectedProjectNumber])
  
  
  
  // console.log(formScheme)
  
  // {"title": "Mosógép", "data": {
  //   form: [
  //     {column: "Típus", title: "Gép típusa", initial_load: true, width_category: "3", type: "select"},
  //     {column: "Rendszám", title: "Rendszám", initial_load: true, width_category: "2", type: "select"},
  //     {column: "Cég", title: "Cég", initial_load: true, width_category: "3", type: "select"},
  //     {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //     {column: "Órák", title: "Órák száma", width_category: "1", type: "number"},
  //   ],
  //   filter_with: ["Típus", "Rendszám", "Cég"],
  //   count_with: ["Ár", "Órák"],
  //   columns: ["Típus", "Rendszám", "Cég", "Ár", "Óradíj/Napidíj"],
  //   table: "Munkagépek",
  //   icon: "https://lh3.googleusercontent.com/drive-viewer/AJc5JmQ4ikpheTaomI2VZacLIu3NsarMMHld8kkn0nPWHjwoBPGWstZnd7ge8kGm6flCDcq5X8e-3Z8=w1270-h892"}
  // }
  return (
    <>
    <div style={{"padding": "30px"}}>
      <Header
        viewport={viewport}
        // switcherData={switcherData}
        // switcherIndex={switcherIndex}
        // setSwitcherIndex={setSwitcherIndex}
        savedDoc={savedDoc}
        setSavedDOc={setSavedDOc}
        post={post}
        // dailyIncome={dailyIncome}
        // subcontractorContractPrice={subcontractorContractPrice}
      />

      {/* {switcherIndex == 0 ? ( */}
        <Form viewport={viewport}/>
      {/* ) : ""} */}

      {/* {switcherIndex == 1 && localContractContent.length != 0 ? (
        <ContractContent modeIsEdit={false} modeIsOwnContracts={true} contractContentFromDailyPostsNewDay={localContractContent} contractTypeFromDaiyPostsNewDay={selectedContractType} contractOtherCompanyFromDailyPostsNewDay={selectedContractOtherCompany} setContractSelectorModalIsOpened={setContractSelectorModalIsOpened} selectedContractHistoryDocId={selectedContractHistoryDocId} selectedContractDocId={selectedContractDocId}/>
      ) : ""} */}

    </div>
    
    {/* <Modal
      opened={contractSelectorModalIsOpened}
      onClose={() => setContractSelectorModalIsOpened(false)}
      title={selectedProjectNumber == "" || selectedProjectNumber == null ? "" : `Szerződések ${selectedProjectNumber} munkaszám alatt`}
      size={"70vw"}
    >
      {selectedProjectNumber == "" || selectedProjectNumber == null ? (
        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
          <AlertCircle color='red' size={"30px"}/>
          <h3 style={{textAlign: "center"}}>Kérlek előbb válassz munkaszámot hogy láthasd az ehhez a munkához tartozó szerződéseket!</h3>
        </div>
      ) : 
        // subContractorContractsBasedOnSelectedProjectNumber.map((contract) => <Contract modeIsOwnContracts={false} firestore_id={contract.id} project_number={contract.project_number} buyer={contract.customer} contractor={contract.contractor} contract_price={contract.contract_price} after_work_price={contract.after_work} forint_readiness={contract.forint_readiness} percentage_readiness={contract.percentage_readiness} project_numbers={projectNumbers} comment={contract.comment}/>)
        <div>
          <div>
            <h2>Saját szerződések</h2>
            {ownContractsBasedOnSelectedProjectNumber.length == 0 ? (
              <Text>Nincs saját szerződésed ezen a munkaszámon</Text>
              ) : 
              ownContractsBasedOnSelectedProjectNumber.map((contract) => <Contract key={contract.id} modeIsOwnContracts={true} firestore_id={contract.id} project_number={contract.project_number} buyer={contract.customer} contractor={contract.contractor} contract_price={contract.contract_price} after_work_price={contract.after_work} forint_readiness={contract.forint_readiness} percentage_readiness={contract.percentage_readiness} project_numbers={[]} comment={contract.comment} showEditButton={false} showSelectButtonInsteadOfContentButton={true} onSelectButtonClick={() => handleSelectContract(contract)}/>)
            }
          </div>
          <div>
            <h2>Alvállalkozói szerződések</h2>
            {subcontractorContractsBasedOnSelectedProjectNumber.length == 0 ? (
              <Text>Nincs alvállkozói szerződésed ezen a munkaszámon</Text>
              ) : 
              subcontractorContractsBasedOnSelectedProjectNumber.map((contract) => <Contract key={contract.id} modeIsOwnContracts={false} firestore_id={contract.id} project_number={contract.project_number} buyer={contract.customer} contractor={contract.contractor} contract_price={contract.contract_price} after_work_price={contract.after_work} forint_readiness={contract.forint_readiness} percentage_readiness={contract.percentage_readiness} project_numbers={[]} comment={contract.comment} showEditButton={false} showSelectButtonInsteadOfContentButton={true} onSelectButtonClick={() => handleSelectContract(contract)}/>)
            }
          </div>
        </div>
      }
    </Modal> */}
    
    </>
  )
}

export default DailyPostsNewDay