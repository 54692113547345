import { useEffect, useRef, useState } from 'react';
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  Button,
  Checkbox,
  Input,
  Modal,
  Pagination,
  Select,
  NumberInput,
} from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';
import { keys } from '@mantine/utils';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import './Table.css'
import { useParams } from 'react-router-dom';

import { collection, query, where, onSnapshot, doc, setDoc, deleteDoc, Unsubscribe, getDocs, serverTimestamp } from "firebase/firestore";
import { useFirestore, useAuth } from '../../../../hooks/useFirebase'
import { AlertCircle, Check } from 'tabler-icons-react';
import { useDebouncedState } from '@mantine/hooks';
import { useTableCache } from '../../../../hooks/useDatabaseCache';
import { cacheRowType } from '../../../../interfaces/databaseTypes';
import SearchCompany from '../../../../components/SearchCompany/SearchCompany';
import { useNumberWithSpaces } from '../../../../hooks/utils';

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

// interface RowData {
//   name: string;
//   email: string;
//   company: string;
// }

// interface TableSortProps {
//   data: any;
// }

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text weight={500} size="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: any, search: string) {
  const query = search.toLowerCase().trim();
  // console.log(query)
  // console.log(data)

  // data.map((item: any) => {
  //   console.log(item["data"]["oszlop2"].includes(query))
  // })
  // console.log(keys(data[0]["data"]))
  // const pff = data.filter((item:any) =>
  //   keys(data[0]["data"]).some((key) => 
  //     // console.log("item",item["data"][key])
  //     // console.log("query",query)
  //     item["data"][key].toLowerCase().includes(query)
  //     // try{
  //     // } catch {
  //     //   console.log(item["data"][key])
  //     //   console.log(query)
  //     // }
  //   )
  // );
  // console.log("pff",pff)
  // console.log(data[0]["data"]["oszlop1"])
  return data.filter((item:any) =>
    keys(data[0]["data"]).some((key) => // you must not {} here because it won't work and it does not give error
      // {console.log("item",item["data"][key])
      // console.log("query",query)}
      item["data"][key] ? item["data"][key].toLowerCase().includes(query) : false
      // try{
      // } catch {
      //   console.log(item["data"][key])
      //   console.log(query)
      // }
    )
  );
}

function sortData(
  data: any,
  payload: { sortBy: keyof any | null; reversed: boolean; search: string }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  // console.log(data)
  return filterData(
    [...data].sort((a, b) => {
      // console.log(sortBy)
      console.log(a, b, sortBy)
      if(sortBy == "Utoljára szerk."){
        const dateA = a.last_modified ? a.last_modified.seconds : a.created_at.seconds
        const dateB = b.last_modified ? b.last_modified.seconds : b.created_at.seconds
        
        // console.log(a.last_modified, b.last_modified)
        // console.log(a.created_at, b.created_at)

        if(payload.reversed){
          return dateB - dateA;
        }
        return dateA - dateB;
      }

      if(sortBy == "Ár"){
        const priceA = Number(a.data.Ár.replaceAll(" ", "").replaceAll("ft", "").replaceAll("Ft", "")) || 0
        const priceB = Number(b.data.Ár.replaceAll(" ", "").replaceAll("ft", "").replaceAll("Ft", "")) || 0

        if(payload.reversed){
          return priceB - priceA;
        }
        return priceA - priceB;
      }

      if (payload.reversed) {
        return b["data"][sortBy].localeCompare(a["data"][sortBy]);
      }
      return a["data"][sortBy].localeCompare(b["data"][sortBy]);
      
    }),
    payload.search
  );
}

export default function TableSort() { //---------------------------------components starts here-------------------------------------------
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState<object[]>([]);
  const [sortBy, setSortBy] = useState<keyof any | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  
  const [isLoading, setisLoading] = useState(true)
  const [columns, setcolumns] = useState<ColumnType[]>([])
  const [rowsDataGotFromFirebase, setrowsDataGotFromFirebase] = useState<cacheRowType[]>([])

  const [isEditMode, setIsEditMode] = useState(false)
  // const [rowsdata, setrowsdata] = useState<any>()
  const [selection, setSelection] = useState<any>([]);
  const [changesHaveBeenSaved, setChangesHaveBeenSaved] = useState(true)
  const [rowChangesMadeInTable, setRowChangesMadeInTable] = useState<object[]>([])

  const [showUnsavedWarningModal, setshowUnsavedWarningModal] = useState(false)
  const [showDeleteWarningModal, setshowDeleteWarningModal] = useState(false)

  const showingOnlyXItemsOnThePage = 30
  const [activePage, setActivePage] = useState(1)
  const [itemsToShowPagination, setItemsToShowPagination] = useState([])

  const { tableParam } = useParams()

  const viewport = useRef<any>();


  const toggleRow = (id: string) => {
    console.log(id)
    setSelection((current: any) =>
    current.includes(id) ? current.filter((item: any) => item !== id) : [...current, id]
    );
    // setTimeout(() => {
    //   console.log(selection)
    // }, 5000);
  }
  const toggleAll = () => {
    if (selection.length === sortedData.length) {
      setSelection([]);
      //console.log(selection); // valamiért buggos mert a hiába van a console.log a setSelection után, valamiért nem ír ki semmit pedig az érték változik
    } else {
      console.log(sortedData.length);
      setSelection(sortedData.map((row: any, rowindex: number) => `${row.id}`));
      //console.log(selection); // valamiért buggos mert a hiába van a console.log a setSelection után, valamiért nem ír ki semmit pedig az érték változik
    }
    // setTimeout(() => {
    //   console.log(selection)
    // }, 5000);
  };


  const setSorting = (field: any) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(rowsDataGotFromFirebase, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setActivePage(1)
    setSortedData(sortData(rowsDataGotFromFirebase, { sortBy, reversed: reverseSortDirection, search: value }));
    // if(value !== ""){
    // } else {
    //   setSortedData(rowsDataGotFromFirebase)
    // }
  };


  function setFormValue(inputText:string, rowId: string, key:string){
    console.warn("setFormValue run")
    // console.log(inputText)
    // console.log(rowindex)
    // console.log(cellindex)
    // console.log(key)

    const innerState: any = sortedData
    // const row: any = innerState[rowindex]
    // console.log(row["data"][key])
    innerState.map((row:any, rowindex:number) => {
      if(row.id === rowId){
        innerState[rowindex]["data"][key] = inputText
        return
      }
    })
    // console.log(innerState)
    setSortedData([...innerState])
    console.log("data has been changed")
  }

  function fetchColumnNames(){
    const db = useFirestore()
    const user = useAuth()
    console.log(tableParam)
    // const q = query(collection(db, "user"), where("user_id", "==", user.uid));
    const unsubscribe = onSnapshot(doc(db, "table", tableParam || ""), (snapshot:any) => {
      // snapshot.docs.forEach((doc) => {
        // console.log(snapshot.data().database_columns)
        let database_columns = snapshot.data().columns
        database_columns.push({ name: "Utoljára szerk.", type: "date"})
        setcolumns(database_columns)
        // for(const i in database_columns){
        //   // console.log(database_columns[i])
        //   if(database_columns[i].table == tableParam){
        //     // console.log(database_columns[i].columns)
        //     setcolumns(database_columns[i].columns)
        //   }
        // }
        // // let columns = Object.entries(database_columns).filter((key, value) => key === tableParam)
        // Object.entries(database_columns).forEach(([key, item]) => {
        //   console.log(Object.keys(item)[0] as any)
        //   // if(key === tableParam){
        //   //   settable(value)
        //   // }
        // })
        // console.log(table)
      // })
    });
    return unsubscribe
  }

  async function fetchRows(){
    const db = useFirestore()
    const user = useAuth()
    console.log("tableparam", tableParam)
    
    const rows = await useTableCache(tableParam)
    setSortedData(rows)
    setrowsDataGotFromFirebase(rows)
  }

  function saveChangesToDatabase(){
    console.log(sortedData)
    console.log(rowsDataGotFromFirebase)
    console.log(rowChangesMadeInTable)
    // rowChangesMadeInTable.map(() => {
    // })
    const db = useFirestore()
    const documentRef = collection(db, "database");

    // setSortedData([])
    // setrowsDataGotFromFirebase([])
    showNotification({
      id: 'load-data',
      loading: true,
      title: `Módosítások mentése`,
      message: 'Az adatok mentése a fiókodba folyamatban van',
      autoClose: false,
      disallowClose: true,
    });

    try {
      rowChangesMadeInTable.map(async (change:any) => {
        console.log(change.id)
        console.log(change.changes)

        const priceChanged = decideIfPriceChanged(change, tableParam)
        
        // updating data in cloud firestore
        const document: any = {
          data: change.changes,
        }
        if(priceChanged){
          document["last_modified"] = serverTimestamp()
        }
        await setDoc(doc(documentRef, change.id), document, {merge: true});
      
        // updating data in cache
        const rows: cacheRowType[] = JSON.parse(sessionStorage.getItem(tableParam!) || "[]")
        rows.forEach((row, rowindex: number) => {
          if(row.id == change.id){

            rows[rowindex].data = change.changes;

            if(priceChanged){
              rows[rowindex].last_modified = { seconds: new Date().getTime() / 1000 }
            }

          }
        })
        sessionStorage.setItem(tableParam!, JSON.stringify(rows))
        
        setSortedData(rows)

      })
      setChangesHaveBeenSaved(true)
      setIsEditMode(false)
      setRowChangesMadeInTable([])

      setTimeout(() => {
        updateNotification({
          id: 'load-data',
          title: 'Siker!',
          message: 'Az adatok sikeresen módosítva lettek az adatbázisodban',
          icon: <Check size={16} />,
          autoClose: 5000,
        });
      }, 2000);
    } catch (error) {
      console.log(error)
      setTimeout(() => {
        updateNotification({
          id: 'load-data',
          color: 'red',
          title: 'Hiba történt!',
          message: 'Az adatok nem lettek módosítva az adatbázisodban',
          icon: <AlertCircle size={16} />,
          autoClose: false,
        });
      }, 2000);
    }
  }

  function removeCheckedRows(){
    const db = useFirestore()
    console.log(selection)

    showNotification({
      id: 'load-data',
      loading: true,
      title: `Adatok törlése`,
      message: 'Az adatok törlése a folyamatban',
      autoClose: false,
      disallowClose: true,
    });

    try {
      selection.map(async (row_id: string) => {
        
        // deleting document in cloud firestore
        await deleteDoc(doc(db, "database", row_id));

      })

      // deleting document in cache
      const rows: cacheRowType[] = JSON.parse(sessionStorage.getItem(tableParam!) || "[]")
      const filteredArray = rows.filter((obj) => !selection.includes(obj.id));
      sessionStorage.setItem(tableParam!, JSON.stringify(filteredArray))

      // deleting document in state
      setSortedData(filteredArray)
      setrowsDataGotFromFirebase(filteredArray)
      setSearch("")

      setTimeout(() => {
        updateNotification({
          id: 'load-data',
          title: 'Siker!',
          message: 'Az adatok sikeresen törölve lettek az adatbázisodban',
          icon: <Check size={16} />,
          autoClose: 5000,
        });
      }, 2000);

      setSelection([])
      setIsEditMode(false)

    } catch (error){
      console.log(error)
      setTimeout(() => {
        updateNotification({
          id: 'load-data',
          color: 'red',
          title: 'Hiba történt!',
          message: 'Az adatok nem lettek törölve az adatbázisodban',
          icon: <AlertCircle size={16} />,
          autoClose: false,
        });
      }, 2000);
    }
  }

  function handleFormValueChanged(inputText: string, row: cacheRowType, column: ColumnType){
    setFormValue(inputText, row.id, column.name)
    setChangesHaveBeenSaved(false)
    // if(rowChangesMadeInTable.includes())
    const itemAlreadyInList = rowChangesMadeInTable.some((rowInList:any) => rowInList.id === row.id);
    if(!itemAlreadyInList) {
      setRowChangesMadeInTable([...rowChangesMadeInTable, {"id": row.id, "changes": row.data}])
    }
  }


  useEffect(() => {
    // this slices the sortedData into smaller chunks
    const perChunk = showingOnlyXItemsOnThePage // items per chunk    
    const inputArray = sortedData
    const result = inputArray.reduce((resultArray:any, item:any, index:number) => { 
      const chunkIndex = Math.floor(index/perChunk)
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])
    // console.log(result); 
    // console.log(activePage)
    if(result.length != 0){
      setItemsToShowPagination(result[activePage-1])
    } else {
      setItemsToShowPagination([])
    }
    // setTimeout(() => {
    //   // console.log(viewport)
    //   viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });
    // }, 100);
  }, [sortedData, activePage])
  

  useEffect(() => {
    const unsubscribeFromFetchColumnNames = fetchColumnNames()
    fetchRows()

    setTimeout(() => {
      setisLoading(false)
    }, 200);

    return () => {
      unsubscribeFromFetchColumnNames()
      console.warn("unsubscribed from column names fetching")
      // ezt azért kellett kikommentelni mert különben sosem frissítené az adatokat az adatbázisból
      // if(unsubscribeFromFetchRows){
      //   unsubscribeFromFetchRows()
      //   console.warn("unsubscribed from rows fetching")
      // }
    }
  }, [])
  

  const rows = itemsToShowPagination.map((row: cacheRowType, rowindex: number) => (
    <tr key={row.id}>
      <>
      {isEditMode ? 
        <td>
        <Checkbox
          checked={selection.includes(`${row.id}`)}
          onChange={() => toggleRow(`${row.id}`)}
          transitionDuration={0}
        />
        </td>
      : "" }
      {columns.map((column: ColumnType, columnindex:number) => 
        isEditMode && column.name !== "Utoljára szerk." ?
          <td key={column.name}>
            {column.type == "company" ? (
              <SearchCompany
                value={row.data[column.name]}
                onChange={(value) => handleFormValueChanged(value || "", row, column)}
                canSelectItself={true}
              />
            ) : ""}
            {column.type == "select" ? (
              <Select
                label=""
                placeholder="Válassz egységet!"
                data={column.select_from || []}
                maxDropdownHeight={150}
                value={row.data[column.name]}
                onChange={(value) => handleFormValueChanged(value || "", row, column)}
              />
            ) : ""}
            {column.type == "number" ? (
              <NumberInput
                value={Number(row.data[column.name])}
                onChange={(value) => handleFormValueChanged(String(value || 0), row, column)}
                parser={(value:any) => value.replace(/\Ft\s?|( *)/g, '')}
                formatter={(value:any) =>
                    !Number.isNaN(parseFloat(value))
                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                    : ''}
              />
            ) : ""}
            {column.type == "text" ? (
              <Input
                value={row.data[column.name]}
                onChange={(event) => handleFormValueChanged(event.currentTarget.value, row, column)}
              ></Input>
            ) : ""}
          </td>
          :
          // <div>{JSON.stringify(column)}</div>
          <td key={column.name} style={{"height": "43px"}}>
            {column.name === "Utoljára szerk." ? 
            new Date(row.last_modified ? row.last_modified?.seconds*1000 : row.created_at?.seconds*1000).toLocaleString("hu-HU")
            : (column.type == "number" ? useNumberWithSpaces(row.data[column.name]) : row.data[column.name])}
          </td>
      )}
      </>
    </tr>
    )
  )
      // {/* {console.log(row)} */}
      // {columns.map((column:string, index:number) => (
      //   <td key={index}>{column}</td>
      // )
      //   // console.log(cellobject)
      // })}
  // ));

  return (
    <>
    <div style={{display: "flex", flexDirection: 'column'}}>

        <div>
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "30px"}}>
            <Button variant='light' onClick={() => {changesHaveBeenSaved ? history.back() : setshowUnsavedWarningModal(true)}} style={{width:"80px"}}>
                Vissza
            </Button>
            <Text style={{fontWeight: "bold", fontSize: "24px"}}>{tableParam}</Text>

            <div>
              {changesHaveBeenSaved ? "" : <Button variant='outline' color={"green"} onClick={() => saveChangesToDatabase()} style={{marginRight: "25px"}}>Szerkesztettek mentése</Button>}

              {isEditMode ? (
              <>
                <Button variant='outline' color={"red"} style={{marginRight: "25px"}} onClick={() => setshowDeleteWarningModal(true)}>Kijelöltek törlése</Button>
              </>
              ) : ""}

              <Button variant='default' onClick={() => {setIsEditMode(!isEditMode)}}>
                {isEditMode ? "Normál mód" : "Szerkesztés mód"}
              </Button>
            </div>

            {/* <div>

              {isEditMode ? (<>
                <Button variant='outline' color={"green"} style={{marginRight: "25px"}}>Szerkesztettek mentése</Button>
                <Button variant='outline' color={"red"} style={{marginRight: "25px"}} onClick={() => handleDeleteRow()}>Kijelöltek törlése</Button>
              </>) : ""}
              <Button variant='default' onClick={() => setisEditMode(!isEditMode)}>
              {isEditMode ? 
                "Normál mód" : "Szerkesztés mód"}
              </Button>
            </div> */}
          </div>
          <TextInput
            placeholder="Keress a táblában..."
            mb="md"
            icon={<IconSearch size={14} stroke={1.5} />}
            value={search}
            onChange={handleSearchChange}
          />
        </div>

        <ScrollArea viewportRef={viewport}>
          <div className={'table-page'}>
            <Table
              highlightOnHover
              horizontalSpacing="md"
              verticalSpacing="xs"
              sx={{ tableLayout: 'fixed', minWidth: 700 }}
            >
              <thead className='table-header'>
                <tr>
                  {isEditMode ? 
                  <th style={{width: "66px", height: "66px"}}>
                  <Checkbox
                    onChange={toggleAll}
                    checked={selection.length === sortedData.length}
                    indeterminate={selection.length > 0 && selection.length !== sortedData.length}
                    transitionDuration={0}
                  />
                  </th>
                  : "" }
                  {columns.map((item: ColumnType, index: number) =>
                    <Th
                      key={item.name}
                      sorted={sortBy === item.name}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting(columns[index].name)}
                    >
                      {item.name}
                    </Th>
                  )}
                </tr>
              </thead>
              <tbody>
                {rows.length > 0 ? (
                  rows
                ) : (
                  <tr>
                    <td colSpan={columns.length}>
                      <Text weight={500} align="center">
                        Nincs találat
                      </Text>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div className='pagination'>
              <Pagination page={activePage} onChange={setActivePage} total={Math.ceil(sortedData.length/showingOnlyXItemsOnThePage)} />
            </div>
          </div>
        </ScrollArea>
    </div>

    <Modal
        opened={showUnsavedWarningModal}
        onClose={() => setshowUnsavedWarningModal(false)}
        title="Szeretnéd menteni a változtatásokat?"
        centered
      >
        <div>
          <div style={{"display": "flex", "gap": "20px"}}>
            <Button variant='light' color={"gray"} onClick={() => history.back()}>Elvetés</Button>
            <Button variant='light' onClick={() => {
              saveChangesToDatabase()
              history.back()
            }}>Mentés</Button>
          </div>
        </div>
      </Modal>
      <Modal
        opened={showDeleteWarningModal}
        onClose={() => setshowDeleteWarningModal(false)}
        title="Biztosan szeretnéd törölni a kijelölt elemeket?"
        centered
      >
        <div>
          <div style={{"display": "flex", "gap": "20px"}}>
            <Button variant='light' color={"gray"} onClick={() => setshowDeleteWarningModal(false)}>Mégsem</Button>
            <Button variant='light' color={"red"} onClick={() => {
              setshowDeleteWarningModal(false)
              removeCheckedRows()
            }}>Törlés</Button>
          </div>
        </div>
      </Modal>

    { isLoading ? <div className='loader-backdrop dashboard'><div className="loader"></div></div> : "" }
    </>
  );
}



function decideIfPriceChanged(change: {id:string, changes: any}, tableParam: string | undefined){

  const cachedRows: cacheRowType[] = JSON.parse(sessionStorage.getItem(tableParam!) || "[]")

  const changedPrice = change.changes.Ár
  const initialPrice = cachedRows.find((row) => row.id == change.id)?.data.Ár


  console.log("changedPrice", changedPrice, "initialPrice", initialPrice)

  if(changedPrice != initialPrice){
    console.log("price has changed")
    return true
  } else {
    console.log("price has not changed")
    return false
  }

}


export type ColumnType = { name: string, type: string, select_from?: string[] }