import { ActionIcon, Button, Modal, Select } from '@mantine/core'
import { collection, doc, DocumentData, getDoc, getDocs, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Edit, Plus } from 'tabler-icons-react'
import { useAuth, useFirestore, useUserCompany } from '../../../../hooks/useFirebase'
import { useSessionStorage } from '@mantine/hooks';


interface HeaderProps {
    modeIsOwnContracts: boolean
    selectedProjectNumber: string | null
    setSelectedProjectNumber: React.Dispatch<React.SetStateAction<string | null>>
    selectedContract: string | null
    setSelectedContract: React.Dispatch<React.SetStateAction<string | null>>,
    selectedContractDocId: string,
    setSelectedContractDocId: React.Dispatch<React.SetStateAction<string>>,
    setModeIsDelete: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Header({ modeIsOwnContracts, selectedProjectNumber, setSelectedProjectNumber, selectedContract, setSelectedContract, selectedContractDocId, setSelectedContractDocId, setModeIsDelete }: HeaderProps) {

  const navigate = useNavigate()

  // const [projectNumbersList, setProjectNumbersList] = useState<string[]>([])
  // const [contractsList, setContractsList] = useState<{id:string, customer: string}[]>([])

  const [projectNumbersList, setProjectNumbersList] = useSessionStorage<[]>({ key: `${modeIsOwnContracts ? "own" : "subcontractor"}-cacheProjectNumbersList`, defaultValue: [] });
  const [contractsList, setContractsList] = useSessionStorage<{id:string, customer: string}[]>({ key: `${modeIsOwnContracts ? "own" : "subcontractor"}-cacheContractsList`, defaultValue: [] });


  const [addContractProgressModalOpened, setAddContractProgressModalOpened] = useState(false)

  const [firstRun, setFirstRun] = useState(true)



  async function getProjectNumbers(){
    const db = useFirestore()
    const user = useAuth()

    const docRef = doc(db, "user", user.uid);
    const docSnap = await getDoc(docRef);

    const projectNumbers = docSnap.data()!.project_numbers
    setProjectNumbersList(projectNumbers)
  }

  async function getContracts(){
    const db = useFirestore()
    const company = await useUserCompany()
   
    const q = query(collection(db, "contract"), where("project_number", "==", selectedProjectNumber), where(`${modeIsOwnContracts ? "contractor" : "customer"}`, "==", company));

    const querySnapshot = await getDocs(q);

    let contracts: {id:string, customer: string}[] = []
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());

        if(modeIsOwnContracts){
            contracts.push({id: doc.id, customer: doc.data().customer as string})
        } else {
            contracts.push({id: doc.id, customer: doc.data().contractor as string})
        }
    });

    setContractsList(contracts)
  }


  useEffect(() => {
    getProjectNumbers()

    setTimeout(() => {
      setFirstRun(false)
    }, 500);
  }, [])


  useEffect(() => {
      if(selectedProjectNumber !== null){
        getContracts()
      }
      if(!firstRun){
        setSelectedContract(null)
      }
  }, [selectedProjectNumber])

  useEffect(() => {
    if(selectedProjectNumber !== null && selectedContract !== null){
        const docId = contractsList.find((item) => item.customer === selectedContract)!.id;
        console.log("docId", docId)
        setSelectedContractDocId(docId)
    }
  }, [selectedProjectNumber, selectedContract])

  

  return (
    <>
        <div style={{"display": "flex", "alignItems": "center", "justifyContent": "space-between", "margin": "-5px 0px 20px 0px"}}>
        <h2 style={{"margin": "0px"}}>{modeIsOwnContracts ? "Saját" : "Alvállalkozói"} szerződéses haladások</h2>
        <div style={{"display": "flex", "gap": "20px", justifyContent: "center"}}>
            <ActionIcon size={36} variant="default" onClick={() => setModeIsDelete((modeIsDelete) => !modeIsDelete)}>
              <Edit size={16} />
            </ActionIcon>
            <Button leftIcon={<Plus size={20}/>} onClick={() => setAddContractProgressModalOpened(true)} color={modeIsOwnContracts ? "cyan" : "green"}>
                Új szerződéses haladás rögzítése
            </Button>
        </div>

        </div>

        <div style={{ display: "flex", alignItems: "start", flexDirection: "column"}}>

        <div style={{display: "flex", gap: "20px", marginBottom: "20px"}}>
        <Select
            label=""
            placeholder="Munkaszám szűrő"
            searchable
            clearable
            nothingFound="Nincs találat"
            maxDropdownHeight={280}
            data={projectNumbersList}
            value={selectedProjectNumber}
            onChange={(value) => setSelectedProjectNumber(value)}
            style={{
                width: "250px",
            }}
            styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor:
                        modeIsOwnContracts ? theme.colors.cyan[8] : theme.colors.green[8],
                    },
                  },
        
                  // applies styles to hovered item (with mouse or keyboard)
                  '&[data-hovered]': {},
                },
                input: {
                  ":focus-within": {
                    borderColor: modeIsOwnContracts ? theme.colors.cyan[8] : theme.colors.green[8],
                  }
                }
              })}
            />
        <Select
            label=""
            placeholder="Szerződés szűrő"
            searchable
            clearable
            nothingFound="Nincs találat"
            maxDropdownHeight={280}
            data={contractsList.map((item) => item.customer)}
            value={selectedContract}
            onChange={(value) => setSelectedContract(value)}
            disabled={selectedProjectNumber === null}
            style={{
                width: "250px",
            }}
            styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor:
                        modeIsOwnContracts ? theme.colors.cyan[8] : theme.colors.green[8],
                    },
                  },
        
                  // applies styles to hovered item (with mouse or keyboard)
                  '&[data-hovered]': {},
                },
                input: {
                  ":focus-within": {
                    borderColor: modeIsOwnContracts ? theme.colors.cyan[8] : theme.colors.green[8],
                  }
                }
              })}
            />
        </div>
        </div>

        <Modal opened={addContractProgressModalOpened} onClose={() => setAddContractProgressModalOpened(false)} title={`${modeIsOwnContracts ? "Saját" : "Alv."} szerz. felmérés rögzítése`}>
                <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "space-between"}}>
                    <Select
                        label="Kérlek válassz munkaszámot!"
                        placeholder="Munkaszám szűrő"
                        searchable
                        clearable
                        nothingFound="Nincs találat"
                        maxDropdownHeight={280}
                        data={projectNumbersList}
                        value={selectedProjectNumber}
                        onChange={(value) => setSelectedProjectNumber(value)}
                        style={{
                            width: "330px",
                            paddingTop: "20px",
                        }}
                        styles={(theme) => ({
                            item: {
                              // applies styles to selected item
                              '&[data-selected]': {
                                '&, &:hover': {
                                  backgroundColor:
                                    modeIsOwnContracts ? theme.colors.cyan[8] : theme.colors.green[8],
                                },
                              },
                    
                              // applies styles to hovered item (with mouse or keyboard)
                              '&[data-hovered]': {},
                            },
                            input: {
                              ":focus-within": {
                                borderColor: modeIsOwnContracts ? theme.colors.cyan[8] : theme.colors.green[8],
                              }
                            }
                          })}
                        />
                    <Select
                        label="Kérlek válassz szerződést!"
                        placeholder="Szerződés szűrő"
                        searchable
                        clearable
                        nothingFound="Nincs találat"
                        maxDropdownHeight={280}
                        data={contractsList.map((item) => item.customer)}
                        value={selectedContract}
                        onChange={(value) => setSelectedContract(value)}
                        disabled={selectedProjectNumber === null}
                        style={{
                            width: "330px",
                            paddingTop: "20px",
                        }}
                        styles={(theme) => ({
                            item: {
                              // applies styles to selected item
                              '&[data-selected]': {
                                '&, &:hover': {
                                  backgroundColor:
                                    modeIsOwnContracts ? theme.colors.cyan[8] : theme.colors.green[8],
                                },
                              },
                    
                              // applies styles to hovered item (with mouse or keyboard)
                              '&[data-hovered]': {},
                            },
                            input: {
                              ":focus-within": {
                                borderColor: modeIsOwnContracts ? theme.colors.cyan[8] : theme.colors.green[8],
                              }
                            }
                          })}
                    />
                    <Button
                        disabled={selectedProjectNumber == null || selectedContract == null}
                        style={{marginTop: "45px"}}
                        onClick={() => navigate(`/${modeIsOwnContracts ? "own" : "subcontractor"}-contract-progress/new-day/${selectedContractDocId}`)}
                        color={modeIsOwnContracts ? "cyan" : "green"}
                    >
                        Felmérés rögzítése a kiválasztott szerződéshez
                    </Button>
                </div>
        </Modal>
    </>
  )
}
