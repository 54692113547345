import { FirebaseApp, initializeApp } from "firebase/app";
import { getToken, initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { doc, Firestore, getDoc, getFirestore } from "firebase/firestore";
import { Functions, getFunctions, httpsCallable, connectFunctionsEmulator  } from "firebase/functions";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";
import { useEffect } from "react";

const firebaseConfig_dev = {
    apiKey: "AIzaSyAtpIeBhE1gStn1oHgMltNbWHNcH4Ykgpw",
    authDomain: "builty-2-react-firebase.firebaseapp.com",
    projectId: "builty-2-react-firebase",
    storageBucket: "builty-2-react-firebase.appspot.com",
    messagingSenderId: "760489365642",
    appId: "1:760489365642:web:39be3f1600698f2aa15d73",
    measurementId: "G-QK18Y21S5T"
  };

  const firebaseConfig_prod = {
    apiKey: "AIzaSyCVGhFsEofYDWAwz-w-TXSvX2X74l38D_Q",
    authDomain: "builty-2-react-firebase-prod.firebaseapp.com",
    projectId: "builty-2-react-firebase-prod",
    storageBucket: "builty-2-react-firebase-prod.appspot.com",
    messagingSenderId: "208163767492",
    appId: "1:208163767492:web:4f4010398fb7d414ecb591",
    measurementId: "G-GTMX8ZSQXS"
  };

  
  
  
// declare const window: any;
let database: Firestore
let app: FirebaseApp
let functions: Functions
if(location.hostname == "localhost" || location.hostname == "builty-2-react-firebase.web.app" || location.hostname == "dev.app.builty.hu"){
// if(location.hostname !== "localhost" && location.hostname !== "builty-2-react-firebase.web.app" && location.hostname !== "dev.app.builty.hu"){
    const app_dev = initializeApp(firebaseConfig_dev);
    app = app_dev
    database = getFirestore(app_dev);
    functions = getFunctions(app_dev, "europe-west1");

    // remove this line in production
    // connectFunctionsEmulator(functions, "127.0.0.1", 5001);

  } else {
    const app_prod = initializeApp(firebaseConfig_prod);
    app = app_prod
    const analytics = getAnalytics(app_prod);
    const perf = getPerformance(app_prod);
    database = getFirestore(app_prod);
    functions = getFunctions(app_prod, "europe-west1");
}

const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence)
.catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;

    console.log("Cannot set persistence", errorCode, errorMessage);
});
     
      



const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdSse4hAAAAAIFCqhKo8IGksGTaNenL8Mva4nJQ'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
getToken(appCheck)
.then(() => {
    console.log('success')
  })
  .catch((error) => {
      console.log(error.message)
})







export function useAuth(){
  const auth: { displayName: string, email: string, lastLoginAt: string, photoURL: string, uid: string } = JSON.parse(localStorage.getItem("user")!)
  return auth
}

export function useFirestore(){
    const db = database
    return db
}

let userCompany: string
export async function useUserCompany(): Promise<string>{
  if(userCompany){
    return userCompany
  }
  const db = useFirestore()
  const user = useAuth()
  const docRef = doc(db, "user", user.uid);
  const docSnap = await getDoc(docRef);

  return docSnap.data()?.company
}

export function useFunctions(name: string) {
  const firebaseFunction = httpsCallable(functions, name);
  return firebaseFunction
}