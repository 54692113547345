import React, { useEffect, useState } from 'react'
import { useMantineTheme, Text, Tooltip } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import BuiltyBarChart from '../../../../components/Charts/BuiltyBarChart';
import BuiltyLineChart from '../../../../components/Charts/BuiltyLineChart';
import { IconDatabaseExclamation } from '@tabler/icons-react';
import { InfoCircle } from 'tabler-icons-react';


interface DashboardChartBoxProps {
    SECONDARY_COL_HEIGHT: number,
    classes: any,
    charthData: { project_number: string, labels: string[], data: number[] }[] | undefined,
    chartType: "line" | "bar"
    title: string,
    tooltipText: string
}


export default function DashboardChartBox({SECONDARY_COL_HEIGHT, classes, charthData, chartType, title, tooltipText}: DashboardChartBoxProps) {

  const theme = useMantineTheme();

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  useEffect(() => {
    console.log("currentSlideIndex", currentSlideIndex)
    console.log("chartData", charthData)
    // console.log("charthData[currentSlideIndex]", charthData ? charthData[currentSlideIndex] : undefined)
  }, [currentSlideIndex])
  

  
  if(charthData === undefined){
    return (
      <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center", alignItems: "center" }}>
        <IconDatabaseExclamation size={32} />
        <Text>Nincs adat</Text>
      </div>
    )
  }

  return (
    <>
      <div style={{display: "flex", alignItems: "center", gap: "15px"}}>
        <Tooltip label={tooltipText}>
          <h2 style={{ margin: "0px" }}>{title.replace("#", charthData ? `${charthData[currentSlideIndex].project_number.slice(0, 15)}${charthData[currentSlideIndex].project_number.length > 15 ? "..." : ""}` : "#")}</h2>
        </Tooltip>
        
        {/* <div style={{ paddingTop: "0px"}}>
          <InfoCircle></InfoCircle>
        </div> */}
      </div>

      <Carousel
          mx="auto"
          // withIndicators
          height={SECONDARY_COL_HEIGHT - 60}
          classNames={classes}
          loop
          onSlideChange={(value) => setCurrentSlideIndex(value)}
          withControls={charthData != undefined && charthData.length > 1}
          >
              {charthData?.map((chart) => (
                  <Carousel.Slide key={chart.project_number}>
                    {chartType === "line" ?
                    <BuiltyLineChart maxHeight={SECONDARY_COL_HEIGHT-40-37} width={'100%'} padding={'20px 0px 0px 0px'} label={''} labels={chart.labels} data={chart.data}></BuiltyLineChart>
                    :
                    <BuiltyBarChart maxHeight={SECONDARY_COL_HEIGHT-40-37} width={'100%'} padding={'20px 0px 0px 0px'} label={''} labels={chart.labels} data={chart.data}></BuiltyBarChart>
                    }
                  </Carousel.Slide>
              ))}
      </Carousel>
    </>
  )
}
