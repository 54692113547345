import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useAuth, useFirestore } from '../../../../hooks/useFirebase';
import TableSort from './TableSort'

interface TablProps {
  isEditing: boolean,
  setChangesHaveBeenSaved: Function,
  rowChangesMadeInTable: object[]
  setRowChangesMadeInTable: Function
}

function Table({ isEditing, setChangesHaveBeenSaved, rowChangesMadeInTable, setRowChangesMadeInTable }: TablProps) {

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<object[]>()


  function getProjectNumbers(){
    const db = useFirestore()
    const user = useAuth()
    const q = query(collection(db, "project"), where("user_id", "==", user.uid));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const snapshot: object[] = []
      // console.log(querySnapshot.docs)
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        snapshot.push({
          id: doc.id,
          data: {
            comment: doc.data().comment,
            created_at: doc.data().created_at,
            project_number: doc.data().project_number,
            user_id: doc.data().user_id
          }
        })
      });
      // console.log(snapshot)  
      setData(snapshot)
    })
    setTimeout(() => {
      setIsLoading(false)
    }, 200);
    return unsubscribe
  }

  
  useEffect(() => {
    const unsubscribe = getProjectNumbers()

    return () => {
      unsubscribe();
      console.warn("unsubscribed from getProjectNumbers") }
  }, [])
  
  return (
    <div>
      { data ? ( <TableSort data={data} isEditing={isEditing} setChangesHaveBeenSaved={setChangesHaveBeenSaved} rowChangesMadeInTable={rowChangesMadeInTable} setRowChangesMadeInTable={setRowChangesMadeInTable}></TableSort>) : ""}
      { isLoading ? <div className='loader-backdrop dashboard'><div className="loader"></div></div> : "" }
    </div>
  )
}

export default Table