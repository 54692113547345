import { ScrollArea } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React, { useEffect } from 'react'
import { useStore } from '../../../hooks/useStore';
import { FormSchemeType, FormStateType } from '../../../interfaces/formTypes';
import FormItem from './FormBlock'


// interface FormProps {
//     // formScheme: { title: any; data: {form: ({ column: string; title: string; initial_load: boolean; width_category: string; type: string; } | { column: string; title: string; width_category: string; type: string; initial_load?: boolean; })[], filter_with: string[], count_with: string[], columns: string[], table: string, icon: string} }[]
//     // setFormScheme: Function,
//     // formState: {},
//     // setFormState: Function
//     scrollableRef: any
// }

function Form({ viewport }:any) {

  const formState: FormStateType = useStore((state:any) => state.formState)
  const setFormState = useStore((state:any) => state.setFormState)
  const formScheme: FormSchemeType = useStore((state:any) => state.formScheme)
  const setFormScheme = useStore((state:any) => state.setFormScheme)

  const formMode = useStore((state:any) => state.formMode)
  // const formModeIsDaily = formMode.toLowerCase().includes('dailyPost'.toLowerCase())

  useEffect(() => {
    console.log(formScheme)
    console.log(formState)
  }, [])
  

  function removeTable(tableToRemove:string){
    //console.log(tableToRemove)
    //console.log(formState)

    const innerState = formState
    const innerScheme = formScheme

    // the update of the scheme also updates the formScheme
    const tableRemovedScheme = innerScheme.filter(tableBlock => tableBlock.title !== tableToRemove)
    const tableRemovedState = innerState.filter(tableBlock => tableBlock.tableName !== tableToRemove)
    // let innerObject:any = Object.entries(formState).filter(([table, value]) => table != tableToRemove)
    // innerObject = Object.fromEntries(innerObject)
    console.log(tableRemovedScheme, tableRemovedState)
    setFormScheme(tableRemovedScheme)
    setFormState(tableRemovedState)

    // settin formScheme to localstorage
    if(formMode === "dailyPost"){
      localStorage.setItem("daily-form-scheme", JSON.stringify(tableRemovedScheme))
    } else if(formMode === "plannedCost"){
      localStorage.setItem("planned-form-scheme", JSON.stringify(tableRemovedScheme))
    }
  }

  return (
    <div>
        <ScrollArea style={{"height": "82vh"}} offsetScrollbars viewportRef={viewport}>
            <div>
                {formState.map((tableBlock, index) =>
                    <FormItem key={index} index={index} title={tableBlock.tableName} removeTable={(() => removeTable(tableBlock.tableName))}></FormItem>
                )}
            </div>
        </ScrollArea>
    </div>
  )
}

export default Form