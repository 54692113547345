import { ActionIcon, Button, Input, NumberInput, ScrollArea, Select, Text, Tooltip } from '@mantine/core'
import { SetValues } from '@mantine/form/lib/types';
import { useDebouncedValue, useFocusReturn, useFocusTrap } from '@mantine/hooks';
import { collection, FieldValue, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import { Cell, Minus, Plus, ReportMoney, Rotate } from 'tabler-icons-react'
import { yellow_light_button_bg, yellow_light_button_hover, yellow_light_button_text } from '../../../../App';
import { useTableCache } from '../../../../hooks/useDatabaseCache';

import { useStore } from '../../../../hooks/useStore';
import { FormSchemeType, FormStateType } from '../../../../interfaces/formTypes';
import { calculateFinalPrice } from '../FormBlock/FormBlock';

import FormItemCell from '../FormItemCell';


interface FormItemRowProps {
    title: string,
    rowArray: {
        value: string | number | null;
        column: string;
        initial_load: boolean;
        title: string;
        type: string;
        width_category: string;
    }[],
    rowindex: number
}

export default function FormItemRow({ title, rowArray, rowindex }:FormItemRowProps){

    const [rowData, setRowData] = useState<{ id: string; created_at: object; data: any; }[]>([])
    const [initially_loaded_row_data, setInitially_loaded_row_data] = useState([])

    const formState: FormStateType = useStore((state:any) => state.formState)
    const setFormState = useStore((state:any) => state.setFormState)
    const formScheme: FormSchemeType = useStore((state:any) => state.formScheme)
    
    const setFormFinalPrice: Function = useStore((state:any) => state.setFormFinalPrice)

    const formMode = useStore((state:any) => state.formMode)

    // const { ref, inView, entry } = useInView({
    //     /* Optional options */
    //     threshold: 0,
    //     delay: 0,
    //   });

    // const returnFocus = useFocusReturn({
    //     opened: true,
    //     shouldReturnFocus: false,
    //   });


    async function getInitialData(){
        const tableParam = title
        let rows
        console.log("tableparam", tableParam)
        // const localstorageRowData: { tableBlock: string, rowIndex: number, data: { id: string; created_at: object; data: any; }[] }[] = JSON.parse(localStorage.getItem("rowData") || "[]")
        // const localstorageRowDataIndex = localstorageRowData.findIndex((rowdata) => rowdata.tableBlock === title && rowdata.rowIndex === rowindex)
        // console.log(localstorageRowDataIndex)
        
        // // if(localStorage.getItem("rowData") !== null){
        // if(localstorageRowDataIndex !== -1 && localstorageRowData[localstorageRowDataIndex].data.length !== 0){
        //     rows = localstorageRowData
        //     rows = rows[localstorageRowDataIndex].data
        // } else {
        // }
        rows = await useTableCache(tableParam)
        // } else {
        console.log(rows)
        // }
        setRowData(rows)
        setInitially_loaded_row_data(rows)
        // console.log("előtte", initially_loaded_row_data)
    }


    const onchange = useCallback(async (changedText: string | number, column: string, rowindex: number, table:string) => {
        console.log('%cFormItem.tsx line:154 changedText', 'color: #007acc;', changedText);
        //console.log("rowindex", rowindex)
        
        const innerState = formState
        //console.log('%cFormItem.tsx line:158 innerState', 'color: #007acc;', innerState);

        
        // innerState.map((tableBlock) => {
            //     //console.log(Object.keys(tableBlock.data[0][0]))
            // })
            
            innerState.forEach((tableBlock) => {
                if(tableBlock.tableName == title){
                    tableBlock.data[rowindex].forEach((cell, cellIndex) => {
                        if(cell.column === column){
                            // //console.log(cell.column, column)
                            // //console.log(rowindex, rowindex)
                            
                            tableBlock.data[rowindex][cellIndex].value = changedText
                            // //console.log("changed", rowindex, column)
                        }
                })
            }
        })
        //console.log("innerstate", innerState)


        const filteredInnerState = await filterRow(innerState, table, rowindex, column)
        const calculatedInnerState = await calculatePrices(filteredInnerState, table, rowindex, column)
        const finalPrice = calculateFinalPrice(innerState, formMode)
        setFormFinalPrice(finalPrice)
        

        // localStorage.setItem("table_changed_in_form", title)
        // useFocusTrap(true);
        setFormState([...calculatedInnerState])
        // returnFocus()
        
    }, [rowArray])


    function resetRow(table: string, rowindex: number){
        const innerState = formState
        const tableIndexInFormState = innerState.findIndex(x => x.tableName === table)
    
        const row = innerState[tableIndexInFormState].data[rowindex]
        console.log(row)
        const modified_row = row.map((cell, cellindex) => {
            const row = innerState[tableIndexInFormState].data[rowindex][cellindex]
            if(typeof cell.value == "string"){
                row.value = null
                return row
            } else if(typeof cell.value == "number"){
                row.value = 0
                return row
            }
        })
        console.log(modified_row)
        setFormState([...innerState])
        const finalPrice = calculateFinalPrice(innerState, formMode)
        setFormFinalPrice(finalPrice)
        setRowData(initially_loaded_row_data)
        // console.log("utánna", initially_loaded_row_data)
      }

    async function filterRow(innerState: FormStateType, table: string, rowindex: number, column: string){
        
        //looping over the state
        // innerState.forEach(async (tableBlock) => {
        //     if(tableBlock.tableName == table){
            const tableIndex = innerState.findIndex(x => x.tableName === table)
            const tableBlock = innerState[tableIndex]
                const row = tableBlock.data[rowindex]
                console.log("row",row)
                console.log("row formScheme", formScheme)

                const tableScheme = formScheme.filter((table) => table.title == table.title)
                const filterWith = tableScheme[tableIndex].data.filter_with
                const countWith = tableScheme[tableIndex].data.count_with
                console.log("row filterWith", filterWith)
                console.log("row countWith", countWith)

                const tableIndexInFormState = formState.findIndex(x => x.tableName === table);
                
                if(filterWith.includes(column)){
                    // let columnsAreNotnull = true
                    // filterWith.map((columnToCheckIfNotNull) => {

                    //     const row = formState[tableIndexInFormState].data[rowindex]
                    //     const cellIndexInFormState = row.findIndex(x => x.column === columnToCheckIfNotNull)
                    //     const valueToCheck = row[cellIndexInFormState].value
                    //     // console.log(valueToCkeck)
                    //     // console.log(columnToCheckIfNotNull)
                    //     if(valueToCheck == "" || valueToCheck == null){
                    //         columnsAreNotnull = false
                    //     }
                    // })

                    // console.log(columnsAreNotnull)
                    // if(columnsAreNotnull){
                        // try {
                            // const db = useFirestore()
                            // const q = query(collection(db, "database"), where("table_name", "==", table), where(`data.${column}`, "==", ));
                            // const querySnapshot = await getDocs(q);
                            // querySnapshot.forEach((doc) => {
                            // // doc.data() is never undefined for query doc snapshots
                            // console.log(doc.id, " => ", doc.data());
                            // });
                            console.log("columnsAreNotNull")
                            let rows: {id:string, created_at: object, data: any}[] = await useTableCache(table)
                            console.log(rows)
                            // const price = rows.map((rowInDatabase: any) => {
                                filterWith.map((columnToFilterWith) => {
                                    // const cellsArray = formState[tableIndexInFormState].data[rowindex]
                                    const cellIndexInFormState = row.findIndex((x: { column: string; }) => x.column === columnToFilterWith)
                                    console.log(formState[tableIndexInFormState].data[rowindex], cellIndexInFormState, columnToFilterWith)
                                    const valueToCheck = formState[tableIndexInFormState].data[rowindex][cellIndexInFormState].value
                                    // console.log(formState[tableIndexInFormState].data)
                                    // for (let index = 0; index < rows.length; index++) {
                                    //     console.log(rows[index].data[columnToFilterWith], valueToCheck)
                                    // }
                                    // console.log(valueToCheck == "")
                                    if(valueToCheck !== "" && valueToCheck !== null){
                                        rows = rows.filter((row) => row.data[columnToFilterWith] === valueToCheck) // row.data[columnToFilterWith] is undefined
                                        // console.log("filtered")
                                    }
                                    // if(rowInDatabase.data[columnToFilterWith] === valueToCheck){
                                    //     price = rowInDatabase.data.Ár
                                    //     console.log(price)
                                    //     rowFound = true
                                    // } else {
                                    //     rowFound = false
                                    // }
                                })
                                console.log("rows", rows)
                                if(rows.length == 1){
                                    let price
                                    try{
                                        price = rows[0].data.Ár
                                        price = price.replace("Ft", "").replace("ft", "").replace(/\s/g, '');
                                        price = parseInt(price)
                                    } catch {
                                        price = 0
                                    }
                                    console.log("price", price)

                                    // check if filter by columns are not null
                                    let columnsAreNotnull = true
                                    filterWith.map((columnToCheckIfNotNull) => {
                                        const row = formState[tableIndexInFormState].data[rowindex]
                                        const cellIndexInFormState = row.findIndex(x => x.column === columnToCheckIfNotNull)
                                        const valueToCheck = row[cellIndexInFormState].value
                                        // console.log(valueToCkeck)
                                        // console.log(columnToCheckIfNotNull)
                                        if(valueToCheck == "" || valueToCheck == null){
                                            columnsAreNotnull = false
                                        }
                                    })
                                    // set price if no columns is null
                                    const cellIndexInFormState = row.findIndex((x: { column: string; }) => x.column === "Ár")
                                    const innerState = formState
                                    if(columnsAreNotnull){
                                        innerState[tableIndexInFormState].data[rowindex][cellIndexInFormState].value = price
                                        setFormState([...innerState])
                                    // if a column is null setting the price back to 0
                                    } else {
                                        innerState[tableIndexInFormState].data[rowindex][cellIndexInFormState].value = 0
                                        setFormState([...innerState])
                                    }
                                }
                                setRowData(rows)
                                // if(rowFound){
                                //     console.log("price", price)
                                //     return price
                                // }
                            // })
                        // } catch {alert("Hiba történt")}
                    // }
                }
        //     }
        // })

        return innerState
    }

    function calculatePrices(innerState: FormStateType, table: string, rowindex: number, column: string){
        // console.log(formScheme)
        const tableIndex = innerState.findIndex(x => x.tableName === table)
        const tableScheme = formScheme.filter((table) => table.title == table.title)
        const countWith = tableScheme[tableIndex].data.count_with

        console.log(countWith, column)
        // if(countWith.includes(column)){
            console.log("SZÁMOLOK....")
            const tableBlock = innerState[tableIndex]
            const row = tableBlock.data[rowindex]
            // const number = 
            // console.log(number)
            let number = 1
            countWith.map((column) => {
                const cellIndexInFormState = row.findIndex((x: { column: string; }) => x.column === column)
                number *= tableBlock.data[rowindex][cellIndexInFormState].value as number
            })
            // console.log("number", number)
            const cellIndexInFormState = row.findIndex((x: { column: string; }) => x.column === "counted_price")
            innerState[tableIndex].data[rowindex][cellIndexInFormState].value = number
        // }


        // I have to debounce the writting beacuse the price input loses focus when writing because of rerenders
        // const myPromise: Promise<FormStateType> = new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //       resolve(innerState);
        //     }, 2000);
        //   });
        return innerState
    }

    // useEffect(() => {
    //   const innerState: { tableBlock: string, rowIndex: number, data: { id: string; created_at: object; data: any; }[] }[] = JSON.parse(localStorage.getItem("rowData") || "[]")

    //   const localstorageRowDataIndex = innerState.findIndex((rowdata) => rowdata.tableBlock === title && rowdata.rowIndex === rowindex)
    //   if(innerState.length === 0 || localstorageRowDataIndex === -1){
    //     innerState.push({ tableBlock: title, rowIndex: rowindex, data: [] })
    //   }

    //   innerState.map((rowdata, index) => {
    //     innerState[index].data = rowData
    //   })

    // //   const tableIndexInInnerState = innerState.findIndex(table => table.tableBlock == title)
    // //   //   console.log(tableIndexInInnerState)
    // //   //   console.log(title)
    // //   if(tableIndexInInnerState !== -1){ // -1 means that findindex did not found it
    // //       innerState[tableIndexInInnerState].rowsData = rowData
    // //   }

    //   localStorage.setItem("rowData", JSON.stringify(innerState))
    //   console.log(innerState)
    // }, [rowData])
    

    useEffect(() => {
        getInitialData()
    }, [])
    

    return (
    <div style={{"display": "flex", "flexDirection": "row", "gap": "20px"}}>
        <Tooltip label={"Sor alaphelyzetbe állítása"} position="bottom" withArrow>
            <div>
                <ActionIcon
                    variant="light"
                    style={{rotate: "180deg"}}
                    className={rowindex > 0 ? "otherrow-undo-icon" : "firstrow-undo-icon"}
                    onClick={() => resetRow(title, rowindex)}
                >
                    <Rotate size={20}></Rotate>
                </ActionIcon>
            </div>
        </Tooltip>

        {rowArray.map((cell, cellindex) => {
            // console.log('%cFormItem.tsx line:32 cell', 'color: #007acc;', cell);
            const tableIndexInFormState = formState.findIndex(x => x.tableName === title)
            const row = formState[tableIndexInFormState].data[rowindex]
            return (
                    <FormItemCell key={cellindex} cell={cell} cellValue={cell.value} rowindex={rowindex} onchange={onchange} table={title} rowData={rowData} row={row}/>
                    
            )
        })}
    </div>
    );
}