import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartDataset } from 'chart.js';

import ChartDataLabels from "chartjs-plugin-datalabels";
// import * as helpers from 'chart.js/helpers';
// window.Chart = ChartJS;
// Chart.helpers = helpers;
// import('chart.js-plugin-labels-dv');

import { Pie } from 'react-chartjs-2';
import { useMantineTheme } from '@mantine/core';
import { useNumberWithSpaces } from '../../../hooks/utils';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);


interface builtyPieChartProps {
  height: number | string,
  width: number | string,
  padding: string,
  label: string,
  labels: string[],
  data: number[],
  backgroundColor?: any,
  borderColor?: any,
  stack?: string,
  datasets?: ChartDataset<"pie">[]
}
export default function BuiltyPieChart({ height, width, padding, label, labels, data, backgroundColor, borderColor, stack, datasets }: builtyPieChartProps) {

  const theme = useMantineTheme()
  
  return (
    <Pie
        style={{maxHeight: height, minHeight: height, padding: padding, width: width}}
        options={{
            layout: {
                padding: {
                    left: 0,//100,
                    right: 0,//70,
                    top: 0,
                    bottom: 0
                }
            },
            font: {
                size: 50
            },
            plugins: {
                // htmlLegendPlugin: {
                //     id: "htmlLegend",
                //     afterUpdate(chart) {
                //         const items = chart.options.plugins.legend.labels.generateLabels(chart);
                //         const ul = document.createElement("ul");
                //         items.forEach(item => {
                //             const li = document.createElement("li");
                //             const boxSpan = document.createElement("span");
                //             boxSpan.style.background = item.fillStyle;
                //             li.appendChild(boxSpan);
                //             li.appendChild(document.createTextNode(item.text));
                //             ul.appendChild(li);
                //         });
                //         const jsLegend = document.getElementById("js-legend");
                //         jsLegend.appendChild(ul);
                //     }
                // },
                legend: {
                    position: "bottom",
                    labels: {
                        color: '#fff',
                        font: {
                            size: 14
                        }
                    },
                    title: {
                        color: '#fff',
                    },
                    display: false  
                },
                datalabels: {
                    font: {
                        size: 20
                    },
                    anchor: "center",
                    formatter: (value, ctx) => {
                        let sum = 0;
                        let dataArr = ctx.chart.data.datasets[0].data;
                        dataArr.map((data: any) => {
                          sum += data;
                        });
                        let percentage = ((value * 100) / sum).toFixed(0) + "%";
                        // return percentage;
                        if(parseInt(width.toString()) === 280){
                            return parseInt(percentage) > 10 ? `${useNumberWithSpaces(value)} Ft` : "";

                        } else if(parseInt(width.toString()) === 420){
                            return parseInt(percentage) > 5 ? `${useNumberWithSpaces(value)} Ft` : "";

                        } else if(parseInt(width.toString()) === 620){
                            return parseInt(percentage) > 3 ? `${useNumberWithSpaces(value)} Ft` : "";

                        } else {
                            return parseInt(percentage) > 10 ? `${useNumberWithSpaces(value)} Ft` : "";
                        }
                      },
                      color: "#FFF",
                },
                tooltip: {
                  titleFont: {
                      size: 16
                  },
                  bodyFont: {
                      size: 16
                  },
                  callbacks: {
                      label: function(context) {
                        let label = context.dataset.label || '';
  
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed !== null) {
                            label += new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits: 0 }).format(context.parsed);
                        }
                        return label;
                      }    
                    }
              },
                // labels: {
                //     position: "outside",
                //     render: (value: { value: string, percentage: number }) => {
                //         // return `${value.value} 000 Ft`;
                //         return null
                //     },
                //     fontSize: 20,
                //     textMargin: 10,
                //     fontStyle: 'bold',
                //     fontColor: '#fff',
                //     // fontFamily: '"Lucida Console", Monaco, monospace'
                //   }
            }
        }}
        data={{
          labels: labels,
          datasets: datasets ? datasets : [{
              label: label,
              data: data,
              backgroundColor: backgroundColor ? backgroundColor : theme.colors.green[1],
              borderColor: borderColor ? borderColor : theme.colors.green[8],
              stack: stack ? stack : undefined,
          }]
      }}
    />
  )
}
