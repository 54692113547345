import { useEffect, useState } from 'react';
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  Checkbox,
} from '@mantine/core';
import { keys } from '@mantine/utils';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons';
import { useStore } from '../../hooks/useStore';

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

// interface RowData {
//   data: {string: any}
// }

interface TableSortProps {
  data: { data: any; company: string; table_name: string; _firestore_id: string; }[];
}

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text weight={500} size="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: any[], search: string) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
  );
}

function sortData(
  data: any[],
  payload: { sortBy: keyof any | null; reversed: boolean; search: string }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        // console.log(a.data[sortBy], b.data[sortBy], sortBy)
        return b.data[sortBy].localeCompare(a.data[sortBy]);
      }

      // console.log(a.data[sortBy], b.data[sortBy], sortBy)
      return a.data[sortBy].localeCompare(b.data[sortBy]);
    }),
    payload.search
  );
}

export function TableSort({ data }: TableSortProps) {
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState<keyof any | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const [selection, setSelection] = useState<any>([]);
  const selectionState = useStore((state:any) => state.selection)
  const setSelectionState = useStore((state:any) => state.setSelection)
  

  const setSorting = (field: keyof any) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search }));
  };


  function toggleRowGlobal(id: string){
    const index = selectionState.indexOf(id);
    if (index === -1) {
      setSelectionState([...selectionState, id]);
    } else {
      setSelectionState([...selectionState.slice(0, index), ...selectionState.slice(index + 1)]);
    }
  }
  const toggleRow = (id: string) => {
    console.log(id)
    setSelection((current: any) =>
    current.includes(id) ? current.filter((item: any) => item !== id) : [...current, id]
    );
    toggleRowGlobal(id)
  }
  const toggleAll = () => {
    if (selection.length === sortedData.length) {
      setSelection([]);
      let selectionStateCopy = selectionState
      sortedData.forEach((row: any) => {
        if (selectionStateCopy.includes(row._firestore_id)) { // working on this
          selectionStateCopy = selectionStateCopy.filter((item: any) => item !== row._firestore_id);
        }
      });
      setSelectionState([...selectionStateCopy])
      //console.log(selection); // valamiért buggos mert a hiába van a console.log a setSelection után, valamiért nem ír ki semmit pedig az érték változik
    } else {
      console.log(sortedData.length);
      setSelection(sortedData.map((row: any, rowindex: number) => `${row._firestore_id}`));
      let selectionStateCopy = selectionState
      sortedData.forEach((row: any) => {
        if (!selectionStateCopy.includes(row._firestore_id)) { // working on this
          selectionStateCopy.push(row._firestore_id)
        }
      });
      setSelectionState([...selectionStateCopy])
    
      //console.log(selection); // valamiért buggos mert a hiába van a console.log a setSelection után, valamiért nem ír ki semmit pedig az érték változik
    }
  };


  const rows = sortedData.map(row => (
    <tr key={row._firestore_id}>
      <td>
        <Checkbox
          checked={selection.includes(`${row._firestore_id}`)}
          onChange={() => toggleRow(`${row._firestore_id}`)}
          transitionDuration={0}
        />
      </td>
      {Object.values(row.data).map((cell, index) => <td key={index}>{cell as string}</td>)}
    </tr>
  ))

  return (
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        sx={{ tableLayout: 'fixed', minWidth: 700 }}
        highlightOnHover
      >
        <thead>
          <tr>
            <th style={{ width: "50px"}}>
            <Checkbox
              onChange={toggleAll}
              checked={selection.length === sortedData.length}
              indeterminate={selection.length > 0 && selection.length !== sortedData.length}
              transitionDuration={0}
            />
            </th>
            {Object.keys(data[0].data).map((header_cell, index) => {
              return (
                <Th
                  key={index}
                  sorted={sortBy === header_cell}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting(header_cell)}
                >
                  {header_cell}
                </Th>
            )})}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              <td colSpan={Object.keys(data[0]).length}>
                <Text weight={500} align="center">
                  Nothing found
                </Text>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
  );
}