import { Modal, Text, Space, Slider, NumberInput, Button, ActionIcon, Tooltip } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import React, { useState } from 'react'
import { Calculator, Check, Edit, Minus, Plus, Switch3 } from 'tabler-icons-react'
import { useStore } from '../../../hooks/useStore'
import { contractContentType } from '../../../interfaces/contractContentType'


interface GroupChangeModalProps {
    modeIsOwnContracts: boolean,
    setIsLoading: Function,
    tableIndexToChange?: { tableIndex: number, sheetIndex: number, tableNameToChange: string },
    sheetIndexToChange?: number,
    showOnlyIcon?: boolean
}

export default function GroupChangeModal({ modeIsOwnContracts, setIsLoading, showOnlyIcon, tableIndexToChange, sheetIndexToChange }: GroupChangeModalProps) {

  const [changeContractContentInGroupModalIsOpened, setChangeContractContentInGroupModalIsOpened] = useState(false)
  const [percentageValue, setPercentageValue] = useState(50)

  const setContractContent = useStore((state:any) => state.setContractContent)
  const contractContent: contractContentType = useStore((state:any) => state.contractContent)


  function changeRowsAtOnce(value: number, mode: "set" | "add" | "subtract") {
    const newContractContent = contractContent
    let numberOfRowsUpdated = 0
    // let object = { newContractContent, numberOfRowsUpdated }

    // if(sheetIndexToChange === undefined && tableIndexToChange !== undefined){
    //     object = changeAllRows(newContractContent, value, mode, numberOfRowsUpdated)
    // } else if(sheetIndexToChange !== undefined && tableIndexToChange === undefined){
    //     object = changeSheet(newContractContent, value, mode, numberOfRowsUpdated, sheetIndexToChange)
    // }

    newContractContent.forEach((sheet, sheetIndex) => {

        if(sheetIndexToChange !== undefined && sheetIndex !== sheetIndexToChange) return

        if(tableIndexToChange !== undefined && sheetIndex !== tableIndexToChange.sheetIndex) return

        sheet.sheetData.forEach((table, tableIndex) => {

            if(tableIndexToChange !== undefined && tableIndex !== tableIndexToChange.tableIndex) return

            table.tableData.forEach((row, rowIndex) => {

                let newRow: any
                if(mode === "set"){
                    newRow = {
                        ...row,
                        percentage_readiness: value,
                        forint_readiness: row.total / 100 * value,
                        measuredQuantity: row.quantity / 100 * value
                    }
                } else if(mode === "add"){
                    const newValue = row.percentage_readiness + value
                    newRow = {
                        ...row,
                        percentage_readiness: newValue,
                        forint_readiness: row.total / 100 * newValue,
                        measuredQuantity: row.quantity / 100 * newValue
                    }
                } else if(mode === "subtract"){
                    const newValue = (row.percentage_readiness - value) < 0 ? 0 : (row.percentage_readiness - value)
                    newRow = {
                        ...row,
                        percentage_readiness: newValue,
                        forint_readiness: row.total / 100 * newValue,
                        measuredQuantity: row.quantity / 100 * newValue
                    }
                }
                newContractContent[sheetIndex].sheetData[tableIndex].tableData[rowIndex] = newRow
                numberOfRowsUpdated++
            })
        })
    })
    
    setIsLoading(true)
    setContractContent([...newContractContent])

    setTimeout(() => {
        setIsLoading(false)

        showNotification({
            title: `Sikeres módosítás!`,
            message: `${numberOfRowsUpdated} sor frissítve`,
            icon: <Check size={16} />,
            color: "green",
            autoClose: 5000
        })
    }, 500);

  }

//   function changeAllRows(newContractContent: contractContentType, value: number, mode: "set" | "add" | "subtract", numberOfRowsUpdated: number) {
//     newContractContent.forEach((sheet, sheetIndex) => {
//         sheet.sheetData.forEach((table, tableIndex) => {
//             table.tableData.forEach((row, rowIndex) => {

//                 let newRow: any
//                 if(mode === "set"){
//                     newRow = {
//                         ...row,
//                         percentage_readiness: value,
//                         forint_readiness: row.total / 100 * value,
//                         measuredQuantity: row.quantity / 100 * value
//                     }
//                 } else if(mode === "add"){
//                     const newValue = row.percentage_readiness + value
//                     newRow = {
//                         ...row,
//                         percentage_readiness: newValue,
//                         forint_readiness: row.total / 100 * newValue,
//                         measuredQuantity: row.quantity / 100 * newValue
//                     }
//                 } else if(mode === "subtract"){
//                     const newValue = (row.percentage_readiness - value) < 0 ? 0 : (row.percentage_readiness - value)
//                     newRow = {
//                         ...row,
//                         percentage_readiness: newValue,
//                         forint_readiness: row.total / 100 * newValue,
//                         measuredQuantity: row.quantity / 100 * newValue
//                     }
//                 }
//                 newContractContent[sheetIndex].sheetData[tableIndex].tableData[rowIndex] = newRow
//                 numberOfRowsUpdated++
//             })
//         })
//     })
//     return {newContractContent, numberOfRowsUpdated}
//   }
//   function changeSheet(newContractContent: contractContentType, value: number, mode: "set" | "add" | "subtract", numberOfRowsUpdated: number, sheetIndexToChange: number) {
//     return {newContractContent, numberOfRowsUpdated}
//   }


  const getTooltipText = () => {
      if(sheetIndexToChange !== undefined){
        return `${contractContent[sheetIndexToChange].sheetName} csoportos módosítása`
      } else if(tableIndexToChange !== undefined){
        return `${tableIndexToChange.tableNameToChange} csoportos módosítása`
      } else {
        return "Csoportos változtatás"
      }
  }
  return (
    <>

    {showOnlyIcon ? (
        <Tooltip label={getTooltipText()}>
            <ActionIcon
                variant="outline"
                color={modeIsOwnContracts ? "cyan" : "green"}
                size={35}
                onClick={(e) => {
                    e.stopPropagation()
                    setChangeContractContentInGroupModalIsOpened(true)
                }}
                style={{marginRight: "20px", marginLeft: "20px"}}
            >
                <Switch3 size={16} />
            </ActionIcon>
        </Tooltip>
    ) : (
        <Button
            color={modeIsOwnContracts ? "cyan" : "green"}
            variant="light"
            leftIcon={<Switch3 size={16} />}
            onClick={() => setChangeContractContentInGroupModalIsOpened(true)}
        >
            Csoportos változtatás
        </Button>
    )}


    <Modal
        opened={changeContractContentInGroupModalIsOpened}
        onClose={() => setChangeContractContentInGroupModalIsOpened(false)}
        title={getTooltipText()}
        size={"570px"}
    >
    <div>
        <Text>Ennek a funkciónak a segítségével egyszerre tudod a szerződés több sorát módosítani, egyetlen gombnyomással.</Text>
        
        <Space h="50px" />
        
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Slider
                style={{ width: "420px" }}
                labelAlwaysOn
                value={percentageValue}
                onChange={(value) => setPercentageValue(value)}
                />
            <NumberInput
                style={{width: "80px"}}
                value={percentageValue}
                onChange={(value) => setPercentageValue(value || 0)}
            ></NumberInput>
        </div>

        <Space h="35px" />

        <div style={{ display: "flex", flexDirection: "column", gap: "25px" }}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <Button
                    color={"gray"}
                    variant='light'
                    leftIcon={<Minus size={20} />}
                    onClick={() => { changeRowsAtOnce(percentageValue, "subtract"), setChangeContractContentInGroupModalIsOpened(false) }}
                    >Csökkentés {percentageValue}% készültséggel</Button>
                <Button
                    color={"gray"}
                    variant='light'
                    leftIcon={<Plus size={20} />}
                    onClick={() => { changeRowsAtOnce(percentageValue, "add"), setChangeContractContentInGroupModalIsOpened(false) }}
                    >Növelés {percentageValue}% készültséggel</Button>
            </div>
            <Button
                color={"green"}
                variant='light'
                leftIcon={<Calculator size={20} />}
                onClick={() => { changeRowsAtOnce(percentageValue, "set"), setChangeContractContentInGroupModalIsOpened(false) }}
                >Beállítás {percentageValue}% készültségre</Button>
        </div>
    </div>
</Modal>
</>
  )
}
