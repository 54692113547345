import React, { useEffect, useState } from 'react'
import { Modal, Button, Group, Input, Select, Container, SimpleGrid, Grid, useMantineTheme, Paper, RingProgress, Center, Text, createStyles, Loader } from '@mantine/core';
import { useStore } from '../../hooks/useStore'
import Dashboard from '../../components/dashboard'

import './HomeScreen.css'
import { collection, deleteDoc, doc, DocumentData, getDocs, limit, orderBy, query, setDoc, where } from 'firebase/firestore';
import { useAuth, useFirestore, useUserCompany } from '../../hooks/useFirebase';
import { showNotification, updateNotification } from '@mantine/notifications';
import { AlertCircle, Check, ReportMoney } from 'tabler-icons-react';

import { useNavigate } from 'react-router-dom';

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ChartOptions,
  LineController
  } from 'chart.js';
import { Bar, Chart } from 'react-chartjs-2'
import { Carousel } from '@mantine/carousel';
import DashboardChartBox from './components/DashboardChartBox';
import { useAddContractProgressToDaily } from '../../hooks/useAddContractProgressToDaily';
import { DailyPostType } from '../../interfaces/dailyPostType';
import { contractProgressType } from '../../interfaces/contractProgressTypes';
import { IconDatabaseExclamation } from '@tabler/icons-react';

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  LineController
 );

// function getImageUrl(name:string) {
//   return new URL(`"../../../assets/database_images/${name}.png`, import.meta.url).href
// }
// console.log(getImageUrl("excavator"))
// import munkagepek from "./database_images/excavator.png"
// import teherautok from "./database_images/truck (1).png"
// import munkaero from "./database_images/worker.png"
// import kis_szolgaltatok from "./database_images/toilet-paper.png"
// import nagy_szolgaltatok from "./database_images/contract.png"
// import terko from "./database_images/paving.png"
// import szorodo_anyag from "./database_images/soil.png"
// import beton from "./database_images/concrete-mixer (1).png"
// import aszfalt from "./database_images/road-with-broken-line.png"
// import szegely from "./database_images/border.png"
// import kandelaber from "./database_images/street-light.png"
// import utcabutor from "./database_images/bench.png"
// import beton_elemek from "./database_images/sewer.png"
// import csatorna_cso from "./database_images/plumbing.png"
// import nyomo_cso from "./database_images/pipeline.png"
// import kerteszet from "./database_images/gardening.png"
// import termeszetes_ko from "./database_images/stones.png"
// const munkagepek = location.host + "/assets/database_images/excavator.png"
// const teherautok = location.host + "/assets/database_images/truck (1).png"
// const munkaero = location.host + "/assets/database_images/worker.png"
// const kis_szolgaltatok = location.host + "/assets/database_images/toilet-paper.png"
// const nagy_szolgaltatok = location.host + "/assets/database_images/contract.png"
// const terko = location.host + "/assets/database_images/paving.png"
// const szorodo_anyag = location.host + "/assets/database_images/soil.png"
// const beton = location.host + "/assets/database_images/concrete-mixer (1).png"
// const aszfalt = location.host + "/assets/database_images/road-with-broken-line.png"
// const szegely = location.host + "assets/database_images//border.png"
// const kandelaber = location.host + "/assets/database_images/street-light.png"
// const utcabutor = location.host + "/assets/database_images/bench.png"
// const beton_elemek = location.host + "/assets/database_images/sewer.png"
// const csatorna_cso = location.host + "/assets/database_images/plumbing.png"
// const nyomo_cso = location.host + "/assets/database_images/pipeline.png"
// const kerteszet = location.host + "/assets/database_images/gardening.png"
// const termeszetes_ko = location.host + "/assets/database_images/stones.png"
// import munkagepek from "../../../assets/database_images/excavator.png"
// import teherautok from "../../../assets/database_images/truck (1).png"
// import munkaero from "../../../assets/database_images/worker.png"
// import kis_szolgaltatok from "../../../assets/database_images/toilet-paper.png"
// import nagy_szolgaltatok from "../../../assets/database_images/contract.png"
// import terko from "../../../assets/database_images/paving.png"
// import szorodo_anyag from "../../../assets/database_images/soil.png"
// import beton from "../../../assets/database_images/concrete-mixer (1).png"
// import aszfalt from "../../../assets/database_images/road-with-broken-line.png"
// import szegely from "../../../assets/database_images/border.png"
// import kandelaber from "../../../assets/database_images/street-light.png"
// import utcabutor from "../../../assets/database_images/bench.png"
// import beton_elemek from "../../../assets/database_images/sewer.png"
// import csatorna_cso from "../../../assets/database_images/plumbing.png"
// import nyomo_cso from "../../../assets/database_images/pipeline.png"
// import kerteszet from "../../../assets/database_images/gardening.png"
// import termeszetes_ko from "../../../assets/database_images/stones.png"



const useStyles = createStyles((_theme, _params, getRef) => ({
  controls: {
    ref: getRef('controls'),
    transition: 'opacity 150ms ease',
    opacity: 0,
  },

  root: {
    '&:hover': {
      [`& .${getRef('controls')}`]: {
        opacity: 1,
      },
    },
  },
}));



function HomeScreen() {

  // const { formState, setFormState } = useStore((state:any) => ({ formState: state.formState, setFormState: state.setFormState}))
  // const { formScheme, setFormScheme } = useStore((state:any) => ({ formScheme: state.formScheme, setFormScheme: state.setFormScheme}))
  const formState = useStore((state:any) => state.formState)
  const setFormState = useStore((state:any) => state.setFormState)
  const formScheme = useStore((state:any) => state.formScheme)
  const setFormScheme = useStore((state:any) => state.setFormScheme)
  
  console.log(formState)
  console.log(formScheme)

  const theme = useMantineTheme();
  const PRIMARY_COL_HEIGHT = window.innerHeight - 58 - 15 - 32;
  const SECONDARY_COL_HEIGHT = PRIMARY_COL_HEIGHT / 100*50 - 10;
  const THIRD_COL_HEIGHT = PRIMARY_COL_HEIGHT / 100*42 - 10

  const navigate = useNavigate()
  const { classes } = useStyles();

  const [dataIsLoaded, setDataIsLoaded] = useState(false)

  const [onGoingProjects, setOnGoingProjects] = useState<string[]>([])
  const [costCharthData, setCostChartData] = useState<{ project_number: string, labels: string[], data: number[] }[] | undefined>(undefined)
  const [incomeCharthData, setIncomeChartData] = useState<{ project_number: string, labels: string[], data: number[] }[] | undefined>(undefined)


  async function fetchNewDayPosts(){
    const db = useFirestore()
    const user = useAuth()

    // // this filters out posts that haven't been created in the last 30 days
    const today = new Date()
    let todayMinus30Days = new Date()
    todayMinus30Days.setDate(todayMinus30Days.getDate() - 30)
    todayMinus30Days.setHours(0)
    todayMinus30Days.setMinutes(0)
    todayMinus30Days.setSeconds(0)

    console.log(new Date(todayMinus30Days))
    const q = query(collection(db, "daily"), where("user_id", "==", user.uid), where("date", ">", new Date(todayMinus30Days)), orderBy("date", "desc"));

    let data: DailyPostType[] = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      data.push(doc.data() as DailyPostType)
    });

    // data = []
    if(data.length == 0){
      return undefined
    }

    let dataWithAddedContractProgresses = await useAddContractProgressToDaily(data, await useUserCompany(), "returnListWithAllDaysEvenWhenDailyPostHasNotBeenMade",)// new Date(todayMinus30Days), new Date(today))
    
    const date = new Date(todayMinus30Days).getTime()
    dataWithAddedContractProgresses = dataWithAddedContractProgresses?.filter((post) => post.created_at.seconds*1000 > date)

    return dataWithAddedContractProgresses
  }

  async function fetchOwnContractProgresses(){
    const db = useFirestore()
    const user = useAuth()
    const company = await useUserCompany()

    // // this filters out posts that haven't been created in the last 30 days
    const today = new Date()
    const todayMinus30Days = today.setDate(today.getDate() - 30)

    console.log(new Date(todayMinus30Days))
    const q = query(collection(db, "contract-progress"), where("company", "==", company), where("contractor", "==", company), where("created_at", ">", new Date(todayMinus30Days)), orderBy("created_at", "desc"));

    let data: contractProgressType[] = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      data.push(doc.data() as contractProgressType)
    });
    // console.log("data", data)
    
    // data = data.filter((progress) => progress.created_at.seconds*1000 > todayMinus30Days)
    // data.sort((a, b) => new Date(b.created_at.seconds*1000).getTime() - new Date(a.created_at.seconds*1000).getTime())
    // console.log("data 2", data)

    console.log("contractProgressses length", data.length)

    if(data.length == 0){
      return undefined
    }

    return data
  }



  function calcProjectNumbers(data: DocumentData[]){
    let projectNumbers: string[] = data.map((post) => {
      return post.project_number
    })
  
    projectNumbers = projectNumbers.filter((projectNumber) => projectNumber != "" && projectNumber != null && projectNumber != undefined)
  
    // remove duplicates from projectNumbers
    projectNumbers = [...new Set(projectNumbers)]
  
    if(projectNumbers.length !== 0){
      setOnGoingProjects(projectNumbers)
    }
  }
  
  function calcSlideshowCostCharts(fetchedData: DailyPostType[]){
  
    const sortedFetchedData = fetchedData.sort((a, b) => new Date(b.date.seconds*1000).getTime() - new Date(a.date.seconds*1000).getTime())
    console.log("sortedFetchedData", sortedFetchedData)
  
    let project_numbers_array = sortedFetchedData.map((post) => post.project_number)
    // filter duplicates from project_numbers_array
    project_numbers_array = [...new Set(project_numbers_array)]
    // remove null, undefined and empty strings from project_numbers_array
    project_numbers_array = project_numbers_array.filter((project_number) => project_number != "" && project_number != null && project_number != undefined)

    console.log("project_numbers_array", project_numbers_array)
  
  
    const chartData: { project_number: string, labels: string[], data: number[] }[] = []
    const formatDate = (post: DailyPostType) => {
      // console.log("post.date", post.date)
      return `${new Date(post.date.seconds*1000).getMonth()+1}-${new Date(post.date.seconds*1000).getDate()}`
    }
  
    project_numbers_array.forEach((project_number_item, index) => {
  
      const dailysWithCurrentProjectNumber = sortedFetchedData.filter((post) => post.project_number == project_number_item)
      console.log("dailysWithCurrentProjectNumber", dailysWithCurrentProjectNumber)
      
      let data: any = {}
  
      let labels = dailysWithCurrentProjectNumber.map((post) => formatDate(post))
      labels = labels.reverse()
      labels = [...new Set(labels)];
      // console.log("labels", labels)
    
      // setting object values to zero
      labels.forEach((label) => {
        data[label] = 0
        // console.log("data[label]", data[label], data)
      })
    
      dailysWithCurrentProjectNumber.forEach(post => {
        const date = formatDate(post)
        data[date] = data[date] + post.final_price
      });
    
      data = Object.values(data)
      console.log("chartDataItem", { project_number: project_number_item, labels: labels, data: data })
  
      chartData.push({ project_number: project_number_item, labels: labels, data: data })
    })
  
    if(chartData.length !== 0){
      setCostChartData(chartData)
    }
    console.log("costChartData", chartData)
  }

  function calcSlideshowIncomeCharts(fetchedData: contractProgressType[]){
    const sortedFetchedData = fetchedData.sort((a, b) => new Date(b.created_at.seconds*1000).getTime() - new Date(a.created_at.seconds*1000).getTime())
    console.log("sortedFetchedData", sortedFetchedData)
  
    let project_numbers_array = sortedFetchedData.map((post) => post.project_number)
    // filter duplicates from project_numbers_array
    project_numbers_array = [...new Set(project_numbers_array)]
    // remove null, undefined and empty strings from project_numbers_array
    project_numbers_array = project_numbers_array.filter((project_number) => project_number != "" && project_number != null && project_number != undefined)

    console.log("project_numbers_array", project_numbers_array)

    const chartData: { project_number: string, labels: string[], data: number[] }[] = []
    const formatDate = (post: contractProgressType) => {
      // console.log("post.date", post.date)
      return `${new Date(post.created_at.seconds*1000).getMonth()+1}-${new Date(post.created_at.seconds*1000).getDate()}`
    }
  
    project_numbers_array.forEach((project_number_item, index) => {
  
      const dailysWithCurrentProjectNumber = sortedFetchedData.filter((post) => post.project_number == project_number_item)
      console.log("dailysWithCurrentProjectNumber", dailysWithCurrentProjectNumber)
      
      let data: any = {}
  
      let labels = dailysWithCurrentProjectNumber.map((post) => formatDate(post))
      labels = labels.reverse()
      labels = [...new Set(labels)];
      // console.log("labels", labels)
    
      // setting object values to zero
      labels.forEach((label) => {
        data[label] = 0
        // console.log("data[label]", data[label], data)
      })
    
      dailysWithCurrentProjectNumber.forEach(post => {
        const date = formatDate(post)

        if(data[date] === 0){
          data[date] = data[date] + post.forint_readiness
        }
      });
    
      data = Object.values(data)
      console.log("chartDataItem", { project_number: project_number_item, labels: labels, data: data })
  
      chartData.push({ project_number: project_number_item, labels: labels, data: data })
    })
  
    if(chartData.length !== 0){
      setIncomeChartData(chartData)
    }
    console.log("incomeChartData", chartData)
  }


  useEffect(() => {
    const f = async () => {
      const fetchedDaily = await fetchNewDayPosts()
      console.log("fetchedDaily", fetchedDaily)

      if(fetchedDaily){
        calcProjectNumbers(fetchedDaily)
        calcSlideshowCostCharts(fetchedDaily)
      }

      const fetchedOwnContractProgresses = await fetchOwnContractProgresses()
      if(fetchedOwnContractProgresses){
        calcSlideshowIncomeCharts(fetchedOwnContractProgresses)
      }

      setTimeout(() => {
        setDataIsLoaded(true)
      }, 200);
  
    }
    f()
  }, [])
  

  return (
    <>
        {/* <h1>Főoldal</h1> */}
        {/* <Select
          label="What item is the best?"
          style={{"width": "250px"}}
          placeholder="Pick one"
          searchable
          nothingFound="No options"
          maxDropdownHeight={280}
          data={["riuohgfduirsfighefuilgherutgershitpsehgjdkflghsdghfdsghfodlsgsdfgerg", "hello", "bello", "hello bello"]}
        />
        <Button onClick={() => setFormState(["TV", "rádió"])}>set state</Button>
        <Button onClick={() => setFormScheme("boci")}>set scheme</Button> */}
        {/* <h1>{formState.toString()}</h1> */}

        <Container fluid my={0} mx={0} px={0}>
          <div style={{ display: "flex", gap: "20px", width: "calc(100vw - 300px - 60px - 32px)", height: "calc(100vh - 58px - 15px - 32px)" }}>
            <div style={{ width: "40%", height: "100%" }}>
              <div style={{ height: PRIMARY_COL_HEIGHT, backgroundColor: theme.colors.dark[6], borderRadius: "20px", padding: "20px"}}>

                {onGoingProjects.length === 0 && dataIsLoaded ? (
                  <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                    <IconDatabaseExclamation size={32} />
                    <Text>Nincsenek folyamatban levő projektek</Text>
                  </div>
                ) : (
                  <h2 style={{ margin: "0px" }}>Folyamatban levő projektek</h2>
                )}

                <div style={{paddingTop: "20px", display: "flex", flexDirection: "column", gap: "20px", height: "100%"}}>
                  {dataIsLoaded && onGoingProjects.length > 0 ? onGoingProjects.map((projectNumber, index) => (
                    <Button
                      key={index}
                      variant="default"
                      style={{width: "100%", height: "50px"}}
                      onClick={() => {
                        sessionStorage.setItem("dailyPost-selectedProjectNumber", projectNumber)
                        navigate("/daily-posts")
                      }}>
                      {projectNumber}
                    </Button>
                  )) : <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "40px", paddingLeft: "15px"}}>
                        <Loader size={'lg'}></Loader>
                      </div>}
                </div>

              </div>
            </div>
            <div style={{ display: "flex", flexDirection: 'column', gap: "20px", width: "58%"}}>

              <div style={{ height: SECONDARY_COL_HEIGHT, backgroundColor: theme.colors.dark[6], borderRadius: "20px", padding: "20px"}}>
                {dataIsLoaded ?
                <DashboardChartBox
                  chartType="bar"
                  title={"Költségek # munkaszámon"}
                  tooltipText='Az elmúlt 30 nap költségei'
                  SECONDARY_COL_HEIGHT={SECONDARY_COL_HEIGHT}
                  classes={classes}
                  charthData={costCharthData}
                />
                : <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Loader size={'lg'}></Loader>
                  </div>}
              </div>
              
              <div style={{ height: SECONDARY_COL_HEIGHT, backgroundColor: theme.colors.dark[6], borderRadius: "20px", padding: "20px"}}>
                {dataIsLoaded ?
                <DashboardChartBox
                  chartType="line"
                  SECONDARY_COL_HEIGHT={SECONDARY_COL_HEIGHT}
                  title={"Bevétel # munkaszámon"}
                  tooltipText='Az elmúlt 30 nap bevételei göngyölten'
                  classes={classes}
                  charthData={incomeCharthData}
                />
                : <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Loader size={'lg'}></Loader>
                  </div>}
              </div>

            </div>
          </div>
        </Container>
      <FirstLoginModal />
    </>
  )
}

export default HomeScreen










function FirstLoginModal() {

  const [opened, setOpened] = useState(false)
  const [slidePage, setSlidePage] = useState(0)

  const [codeDocumentId, setCodeDocumentId] = useState("")
  const [name, setName] = useState("")
  const [company, setCompany] = useState("")
  const [role, setRole] = useState("")

  const slidesData = [
    {
      title: "👋 Üdvözöl a Builty!",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis culpa explicabo impedit libero. Quis cumque est ratione officia? Magni voluptatibus iste dolorum incidunt nemo? Quaerat, et quam. Sit, in eius?",
      button: "Vágjunk bele!"
    },
    {
      title: "📅 Tervezett Költségek",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis culpa explicabo impedit libero. Quis cumque est ratione officia? Magni voluptatibus iste dolorum incidunt nemo? Quaerat, et quam. Sit, in eius?",
      button: "Tovább",
    },
    {
      title: "💰 Költség Összesítő",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis culpa explicabo impedit libero. Quis cumque est ratione officia? Magni voluptatibus iste dolorum incidunt nemo? Quaerat, et quam. Sit, in eius?",
      button: "Tovább",
    },
    {
      title: "📜 Projekt Szerződések",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis culpa explicabo impedit libero. Quis cumque est ratione officia? Magni voluptatibus iste dolorum incidunt nemo? Quaerat, et quam. Sit, in eius?",
      button: "Tovább",
    },
    {
      title: "🧑 Alap adatok rólad",
      description: "",
      button: "Tovább",
    },
    {
      title: "💾 Adattáblák",
      description: ``,
      button: "Befejezés",
    },
  ]

  const database = ["Munkagépek", "Teherautók", "Munkaerő", "Kis Szolgáltatók", "Nagy Szolgáltatók", "Térkő", "Szóródó Anyag", "Beton", "Aszfalt", "Szegély", "Kandeláber", "Utcabútor", "Beton Elemek", "Csatorna Cső", "Nyomó Cső", "Kertészet", "Természetes Kő"]
  
  // const database_columns = [
  //   {
  //     form: [
  //       {column: "Típus", title: "Gép típusa", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Rendszám", title: "Rendszám", initial_load: true, width_category: "2", type: "select"},
  //       {column: "Cég", title: "Cég", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Órák", title: "Órák száma", width_category: "1", type: "number"},
  //     ],
  //     filter_with: ["Típus", "Rendszám", "Cég"],
  //     count_with: ["Ár", "Órák"],
  //     columns: ["Típus", "Rendszám", "Cég", "Ár", "Óradíj/Napidíj"],
  //     table: "Munkagépek"
  //   },
  //   {
  //     form: [
  //       {column: "Típus", title: "Teherautó típusa", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Rendszám", title: "Rendszám", initial_load: true, width_category: "2", type: "select"},
  //       {column: "Cég", title: "Cég", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Órák", title: "Órák száma", width_category: "1", type: "number"},
  //     ],
  //     filter_with: ["Típus", "Rendszám", "Cég"],
  //     count_with: ["Ár", "Órák"],
  //     columns: ["Típus", "Rendszám", "Cég", "Ár", "Óradíj/Napidíj"],
  //     table: "Teherautók"
  //   },
  //   {
  //     form: [
  //       {column: "Cég", title: "Cég", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Tevékenység", title: "Tevékenység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Egység", title: "Egység (óra)", width_category: "2", type: "number"},
  //       {column: "Létszám", title: "Létszám", width_category: "1", type: "number"},
  //     ],
  //     filter_with: ["Cég", "Tevékenység"],
  //     count_with: ["Ár", "Egység", "Létszám"],
  //     columns: ["Cég", "Tevékenység", "Ár"],
  //     table: "Munkaerő"
  //   },
  //   {
  //     form: [
  //       {column: "Cég", title: "Cég", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Tevékenység", title: "Tevékenység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Órák", title: "Órák száma", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Cég", "Tevékenység"],
  //     count_with: ["Ár", "Órák"],
  //     columns: ["Cég", "Tevékenység", "Ár", "Óradíj/Napidíj"],
  //     table: "Kis Szolgáltatók"
  //   },
  //   {
  //     form: [
  //       {column: "Cég", title: "Cég", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Tevékenység", title: "Tevékenység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Mértékegység", title: "Mértékegység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Mennyiség", title: "Mennyiség", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Cég", "Tevékenység", "Mértékegység"],
  //     count_with: ["Ár", "Mennyiség"],
  //     columns: ["Cég", "Tevékenység", "Mértékegység", "Ár"],
  //     table: "Nagy Szolgáltatók"
  //   },
  //   {
  //     form: [
  //       {column: "Gyártó", title: "Gyártó", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Típus", title: "Típus", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Mértékegység", title: "Mértékegység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Mennyiség", title: "Mennyiség", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Gyártó", "Típus", "Mértékegység"],
  //     count_with: ["Ár", "Mennyiség"],
  //     columns: ["Gyártó", "Típus", "Mértékegység", "Ár"],
  //     table: "Térkő"
  //   },
  //   {
  //     form: [
  //       {column: "Gyártó", title: "Gyártó", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Típus", title: "Típus", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Mértékegység", title: "Mértékegység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Mennyiség", title: "Mennyiség", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Gyártó", "Típus", "Mértékegység"],
  //     count_with: ["Ár", "Mennyiség"],
  //     columns: ["Gyártó", "Típus", "Mértékegység", "Ár"],
  //     table: "Szóródó Anyag"
  //   },
  //   {
  //     form: [
  //       {column: "Gyártó", title: "Gyártó", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Típus", title: "Típus", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Mértékegység", title: "Mértékegység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Mennyiség", title: "Mennyiség", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Gyártó", "Típus", "Mértékegység"],
  //     count_with: ["Ár", "Mennyiség"],
  //     columns: ["Gyártó", "Típus", "Mértékegység", "Ár"],
  //     table: "Beton"
  //   },
  //   {
  //     form: [
  //       {column: "Gyártó", title: "Gyártó", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Típus", title: "Típus", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Mértékegység", title: "Mértékegység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Mennyiség", title: "Mennyiség", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Gyártó", "Típus", "Mértékegység"],
  //     count_with: ["Ár", "Mennyiség"],
  //     columns: ["Gyártó", "Típus", "Mértékegység", "Ár"],
  //     table: "Aszfalt"
  //   },
  //   {
  //     form: [
  //       {column: "Gyártó", title: "Gyártó", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Típus", title: "Típus", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Mértékegység", title: "Mértékegység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Mennyiség", title: "Mennyiség", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Gyártó", "Típus", "Mértékegység"],
  //     count_with: ["Ár", "Mennyiség"],
  //     columns: ["Gyártó", "Típus", "Mértékegység", "Ár"],
  //     table: "Szegély"
  //   },
  //   {
  //     form: [
  //       {column: "Gyártó", title: "Gyártó", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Típus", title: "Típus", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Mértékegység", title: "Mértékegység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Mennyiség", title: "Mennyiség", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Gyártó", "Típus", "Mértékegység"],
  //     count_with: ["Ár", "Mennyiség"],
  //     columns: ["Gyártó", "Típus", "Mértékegység", "Ár"],
  //     table: "Kandeláber"
  //   },
  //   {
  //     form: [
  //       {column: "Gyártó", title: "Gyártó", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Típus", title: "Típus", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Mértékegység", title: "Mértékegység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Mennyiség", title: "Mennyiség", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Gyártó", "Típus", "Mértékegység"],
  //     count_with: ["Ár", "Mennyiség"],
  //     columns: ["Gyártó", "Típus", "Mértékegység", "Ár"],
  //     table: "Utcabútor"
  //   },
  //   {
  //     form: [
  //       {column: "Gyártó", title: "Gyártó", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Típus", title: "Típus", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Mértékegység", title: "Mértékegység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Mennyiség", title: "Mennyiség", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Gyártó", "Típus", "Mértékegység"],
  //     count_with: ["Ár", "Mennyiség"],
  //     columns: ["Gyártó", "Típus", "Mértékegység", "Ár"],
  //     table: "Beton Elemek"
  //   },
  //   {
  //     form: [
  //       {column: "Gyártó", title: "Gyártó", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Típus", title: "Típus", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Mértékegység", title: "Mértékegység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Mennyiség", title: "Mennyiség", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Gyártó", "Típus", "Mértékegység"],
  //     count_with: ["Ár", "Mennyiség"],
  //     columns: ["Gyártó", "Típus", "Mértékegység", "Ár"],
  //     table: "Csatorna Cső"
  //   },
  //   {
  //     form: [
  //       {column: "Gyártó", title: "Gyártó", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Típus", title: "Típus", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Mértékegység", title: "Mértékegység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Mennyiség", title: "Mennyiség", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Gyártó", "Típus", "Mértékegység"],
  //     count_with: ["Ár", "Mennyiség"],
  //     columns: ["Gyártó", "Típus", "Mértékegység", "Ár"],
  //     table: "Nyomó Cső"
  //   },
  //   {
  //     form: [
  //       {column: "Gyártó", title: "Gyártó", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Típus", title: "Típus", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Mértékegység", title: "Mértékegység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Mennyiség", title: "Mennyiség", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Gyártó", "Típus", "Mértékegység"],
  //     count_with: ["Ár", "Mennyiség"],
  //     columns: ["Gyártó", "Típus", "Mértékegység", "Ár"],
  //     table: "Kertészet"
  //   },
  //   {
  //     form: [
  //       {column: "Gyártó", title: "Gyártó", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Típus", title: "Típus", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Mértékegység", title: "Mértékegység", initial_load: true, width_category: "3", type: "select"},
  //       {column: "Ár", title: "Ár", width_category: "2", type: "price"},
  //       {column: "Mennyiség", title: "Mennyiség", width_category: "2", type: "number"},
  //     ],
  //     filter_with: ["Gyártó", "Típus", "Mértékegység"],
  //     count_with: ["Ár", "Mennyiség"],
  //     columns: ["Gyártó", "Típus", "Mértékegység", "Ár"],
  //     table: "Természetes Kő"
  //   },
  // ] // this is also in the upload project
  
  const db = useFirestore()
  const user = useAuth()

  async function getUserDataFromCode(){
    const q = query(collection(db, "code"), where("email", "==", user.email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      setCodeDocumentId(doc.id)

      setName(doc.data().name)
      setCompany(doc.data().company)
      setRole(doc.data().role)
    });
  }
  async function setUserDataToUsersTable(){
    showNotification({
      id: 'load-data',
      loading: true,
      title: `Módosítások mentése, adattáblák létrehozása`,
      message: 'Az adatok mentése a fiókodba folyamatban van',
      autoClose: false,
      disallowClose: true,
    });
    try {
      // get id of user's document
      const q = query(collection(db, "user"), where("email", "==", user.email));
      const querySnapshot = await getDocs(q);
      let docId
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        docId = doc.id
      });
  
      // update user's document with user data and datatables data
      await setDoc(doc(db, "user", `${docId}`), {
        name: name,
        company: company,
        role: role,
        database: database,
        project_numbers: [],
        settings: { active_user: true }
      }, { merge: true });

      // deleting code document
      await deleteDoc(doc(db, "code", codeDocumentId));

      setTimeout(() => {
        updateNotification({
          id: 'load-data',
          title: `Adatok frissítve és adattáblák létrehozva`,
          icon: <Check size={16} />,
          message: 'Az adatok mentése a fiókodba megtörtént!',
          autoClose: false,
        });
        setOpened(false)
        localStorage.removeItem("newuser")
      }, 2000);
    } catch (error) {
      console.log(error)
      updateNotification({
        id: 'load-data',
        color: "red",
        title: `Hiba történt`,
        icon: <AlertCircle size={16} />,
        message: 'Nem sikerült menteni az adatokat, kérlek frissísd az oldalt és próbáld újra',
        autoClose: false,
      });
    }
  }

  useEffect(() => {
    if(localStorage.getItem("newuser") == "true"){
      setOpened(true)
      getUserDataFromCode()
    }
  }, [])

  // useEffect(() => {
  //   if(slidePage == slidesData.length-1){

  //   }
  // }, [slidePage])
  

  return (
    <Modal
        opened={opened}
        onClose={() => {}}//setOpened(false)}
        withCloseButton={false}
        closeOnClickOutside={false}
        title={`${slidesData[slidePage].title} (${slidePage+1}/${slidesData.length})`}
        centered
      >
        <p>{slidesData[slidePage].description}</p>
        {/* <div style={{"display": "flex", "gap": "15px"}}> */}
        {slidePage !== 5-1 ? "" : (
          <>
            <Input.Wrapper label="Neved" required>
              <Input value={name} onChange={(event:any) => setName(event.target.value)}/>
            </Input.Wrapper>
            <br />
            <Input.Wrapper label="Céged" required>
              <Input disabled value={company}/>
            </Input.Wrapper>
            <br />
            <Input.Wrapper label="Szerepköröd" required>
              <Input disabled value={role}/>
            </Input.Wrapper>
            <br />
          </>
        )}
        <div>
          {slidePage == slidesData.length-1 ? (
            <>
              <p>A Builty-ben jelenleg a következő adattáblák érhetőek el:</p>
              <p>Munkagépek, Teherautók, Munkaerő, Kis Szolgáltatók, Nagy Szolgáltatók, Térkő, Szóródó Anyag, Beton, Aszfalt, Szegély, Kandeláber, Utcabútor, Beton Elemek, Csatorna Cső, Nyomó Cső, Kertészet, Természetes Kő</p>
              <p>A zöld gombra kattintva mented a beállításaidat és létrehozod a fent említett adattáblákat a saját adatbázisodban is</p>
              <Button variant='gradient' gradient={{ from: 'teal', to: 'green', deg: 105 }} onClick={() => setUserDataToUsersTable()} style={{"marginTop": "16px", "width": "100%"}}>Beállítások mentése</Button>
            </>
          ) : (
            <Button variant='outline' onClick={() => setSlidePage(slidePage+1)} style={{"marginTop": "16px", "width": "100%"}}>{slidesData[slidePage].button}</Button>
          )}
          {slidePage == 0 ? "" : <Button color={"gray"} variant='outline' onClick={() => setSlidePage(slidePage-1)} style={{"marginTop": "16px", "width": "100%"}}>Vissza</Button>}
        </div>
      </Modal>
  )
}
