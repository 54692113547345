import { createStyles, Card, Text, SimpleGrid, UnstyledButton, Anchor, Group } from '@mantine/core';
import {
  IconCreditCard,
  IconBuildingBank,
  IconRepeat,
  IconReceiptRefund,
  IconReceipt,
  IconReceiptTax,
  IconReport,
  IconCashBanknote,
  IconCoin,
} from '@tabler/icons';
import { Link } from 'react-router-dom';

// const mockdata = [
//   { title: 'Credit cards', icon: IconCreditCard, color: 'green' },
//   { title: 'Banks nearby', icon: IconBuildingBank, color: 'green' },
//   { title: 'Transfers', icon: IconRepeat, color: 'green' },
//   { title: 'Refunds', icon: IconReceiptRefund, color: 'green' },
//   { title: 'Receipts', icon: IconReceipt, color: 'green' },
//   { title: 'Taxes', icon: IconReceiptTax, color: 'green' },
//   { title: 'Reports', icon: IconReport, color: 'green' },
//   { title: 'Payments', icon: IconCoin, color: 'green' },
//   { title: 'Cashback', icon: IconCashBanknote, color: 'green' },
//   { title: 'Credit cards2', icon: IconCreditCard, color: 'green' },
//   { title: 'Banks nearby2', icon: IconBuildingBank, color: 'green' },
//   { title: 'Transfers2', icon: IconRepeat, color: 'green' },
//   { title: 'Refunds2', icon: IconReceiptRefund, color: 'green' },
//   { title: 'Receipts2', icon: IconReceipt, color: 'green' },
//   { title: 'Taxes2', icon: IconReceiptTax, color: 'green' },
//   { title: 'Reports2', icon: IconReport, color: 'green' },
//   { title: 'Payments2', icon: IconCoin, color: 'green' },
//   { title: 'Cashback2', icon: IconCashBanknote, color: 'green' },
// ];

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: theme.radius.md,
    height: 90,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease, transform 100ms ease',

    '&:hover': {
      boxShadow: `${theme.shadows.md} !important`,
      transform: 'scale(1.05)',
    },
  },
}));

export default function ActionsGrid({ tables }: any) {
  const { classes, theme } = useStyles();

  const items = tables.map((item:{title:string, icon:string}, index:number) => (
    <Link to={`/database/tables/${item.title}`} key={index}>
      <UnstyledButton className={classes.item} style={{"height": "165px", "width": "165px"}}>
        <img src={item.icon} width={"23%"} height={"23%"}/>
        <Text size="md" mt={7}>
          {item.title}
        </Text>
      </UnstyledButton>
    </Link>
  ));

  return (
    <Card withBorder radius="md" className={classes.card}>
      <Group position="apart">
        <Text className={classes.title}>Adattáblák</Text>
        {/* <Anchor size="xs" color="dimmed" sx={{ lineHeight: 1 }}>
          + 21 other services
        </Anchor> */}
      </Group>
      <SimpleGrid cols={6} mt="md">
        {items}
      </SimpleGrid>
    </Card>
  );
}