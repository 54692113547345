import React, { useEffect, useState } from 'react'
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconX } from '@tabler/icons';
import TableBlock from '../../TableBlock'
import { Loader, TextInput } from '@mantine/core';
import { useFunctions } from '../../../../hooks/useFirebase';
import { useDebouncedState } from '@mantine/hooks';
import { useStore } from '../../../../hooks/useStore';


interface ModalContentProps {
    importToggleValue: string,
}

export default function ModalContent({ importToggleValue }: ModalContentProps) {

  const [isLoadingData, setIsLoadingData] = useState(true)
  const [searchValue, setSearchValue] = useDebouncedState("", 500)
  const [rows, setRows] = useState<{ [key: string]: { data: any; company: string, table_name: string, _firestore_id: string; }[] }>({})

  const setAllRowsForImporting = useStore((state:any) => state.setAllRowsForImporting)



  function groupByTableName(list: { data: any; company: string, table_name: string, _firestore_id: string; }[]): { [key: string]: { data: any; company: string, table_name: string, _firestore_id: string; }[] } {
    const result: { [key: string]: { data: any; company: string, table_name: string, _firestore_id: string; }[] } = {};
    list.forEach(item => {
      if (!result[item.table_name]) {
        result[item.table_name] = [];
      }
      result[item.table_name].push(item);
    });
    return result;
  }
  
  function removeDuplicateObjects(array: any[]) {
    let seenObjects = new Set();
    let result: any[] = [];
    array.forEach(function(item) {
      let temp = JSON.parse(JSON.stringify(item.data));
    //   delete temp._firestore_id;
      if (!seenObjects.has(JSON.stringify(temp))) {
        seenObjects.add(JSON.stringify(temp));
        result.push(item);
      }
    });
    return result;
  }
  

  useEffect(() => {
    if(searchValue.length >= 2){
        setRows({})
        setIsLoadingData(true)
        const searchInMeilisearch = useFunctions("getBuiltyDatabase")
        console.log({ search: searchValue, company: importToggleValue })

        searchInMeilisearch({ search: searchValue, company: importToggleValue }).then((result) => {
            let response = result.data as {hits: [{data: any; company: string, table_name: string, _firestore_id: string;}]}
            let rows = response.hits
            rows = removeDuplicateObjects(rows) as any

            let grouped_by_table_name = groupByTableName(rows)
            console.log(grouped_by_table_name)

            setRows(grouped_by_table_name)
            setAllRowsForImporting(rows)
            console.log(result)
            
            setTimeout(() => {
                setIsLoadingData(false)
            }, 1500);
        })
    } else {
        setRows({})
    }
  }, [searchValue, importToggleValue])

  
  useEffect(() => {
    return () => {
        setAllRowsForImporting([])
    }
  }, [])
  


  return (
    <>
    <TextInput
        placeholder="Keress bármire..."
        mb="md"
        icon={<IconSearch size={14} stroke={1.5} />}
        // value={searchValue}
        onChange={(event) => setSearchValue(event.currentTarget.value)}
    />
    
    {searchValue.length < 2 ? <SearchIsNull /> : (Object.keys(rows).length != 0 ? "" : (
     isLoadingData ?
        <div style={{ display: "flex", height: "60vh", width: "100%", alignItems: "center", justifyContent: "center"}}>
            <Loader/>
        </div> : <NoResult/> 
    ))}

    {Object.entries(rows).map(([key, rowArray]) => (
        <div key={key}>
          <TableBlock rowArray={rowArray} table_name={key}/>          
        </div>
    ))}

    </>
  )
}


function SearchIsNull(){
    return (
        <div style={{ display: "flex", height: "60vh", width: "100%", alignItems: "center", justifyContent: "center"}}>
            <div style={{ display: "flex", flexDirection: "column",alignItems: "center"}}>
                <IconSearch size={26}/>
                <h4>Használd a fenti keresőmezőt, hogy keress az adatbázisban.</h4>
            </div>
        </div>
    )
}

function NoResult(){
    return (
        <div style={{ display: "flex", height: "60vh", width: "100%", alignItems: "center", justifyContent: "center"}}>
            <div style={{ display: "flex", flexDirection: "column",alignItems: "center"}}>
                <IconX size={26}/>
                <h4>Nincs találat az adatbázisban. Próbálj meg másra rákeresni!</h4>
            </div>
        </div>
    )
}
