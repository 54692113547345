import { Button, Drawer, Loader, Modal, SegmentedControl, Select, Text, Textarea, Tooltip, useMantineTheme } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { showNotification } from '@mantine/notifications'
import { addDoc, collection, doc, DocumentData, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AlertCircle, ArrowForward, ArrowNarrowRight, Check, ChevronDown, ChevronRight, CircleCheck, DotsVertical, List, Notes, Plus } from 'tabler-icons-react'
import { useAuth, useFirestore, useUserCompany } from '../../../hooks/useFirebase'
import { useStore } from '../../../hooks/useStore'
import { formModeType, FormSchemeType, FormStateType } from '../../../interfaces/formTypes'
import { ActionsGrid } from "../../../pages/UploadToDatabase/UploadToDatabase"

import 'dayjs/locale/hu';
import { yellow_button, yellow_light_button_bg, yellow_light_button_hover, yellow_light_button_text } from '../../../App'
import "./Header.css"
import { useDebouncedValue } from '@mantine/hooks'
import { useCapitalizeFirstLetter, useNumberWithSpaces, weekday } from '../../../hooks/utils'
import { contractType } from '../../../pages/OwnContracts/components/List/List'
import Contract from '../../../pages/OwnContracts/components/Contract'
import { calculateFinalPrice } from '../Form/FormBlock/FormBlock'
import { DailyPostType } from '../../../interfaces/dailyPostType'
import { useAddContractProgressToDaily } from '../../../hooks/useAddContractProgressToDaily'
import { useAddSubcontractorContractsToPlan } from '../../../hooks/useAddSubcontractorContractsToPlan'


interface HeaderProps {
  // formScheme: { title: any; data: {form: ({ column: string; title: string; initial_load: boolean; width_category: string; type: string; } | { column: string; title: string; width_category: string; type: string; initial_load?: boolean; })[], filter_with: string[], count_with: string[], columns: string[], table: string, icon: string} }[]
  // setFormScheme: Function,
  // formState: {}
  // switcherData: string[],
  // switcherIndex: number,
  // setSwitcherIndex: Function
  viewport: any,
  savedDoc: string,
  setSavedDOc: Function,
  post: DailyPostType | undefined,
  // dailyIncome: number,
  // subcontractorContractPrice: number
}

function Header({ viewport, savedDoc, setSavedDOc, post }: HeaderProps) {

  let { docId } = useParams();

  // const [isSaving, setIsSaving] = useState(false)
  const isSaving = useStore((state:any) => state.isSaving)
  const setIsSaving = useStore((state:any) => state.setIsSaving)
  
  const formState: FormStateType = useStore((state:any) => state.formState)
  const setFormState = useStore((state:any) => state.setFormState)
  const formScheme: FormSchemeType = useStore((state:any) => state.formScheme)
  const setFormScheme = useStore((state:any) => state.setFormScheme)
  
  const formFinalPrice: number = useStore((state:any) => state.formFinalPrice)
  const setFormFinalPrice = useStore((state:any) => state.setFormFinalPrice)

  const selectedProjectNumber = useStore((state:any) => state.formProjectNumber)
  const selectedDate = useStore((state:any) => state.formDate)
  const comment = useStore((state:any) => state.formComment)
  const setSelectedProjectNumber = useStore((state:any) => state.setFormProjectNumber)
  const setSelectedDate = useStore((state:any) => state.setFormDate)
  const setComment = useStore((state:any) => state.setFormComment)
  
  const formTitle = useStore((state:any) => state.formTitle)
  const setFormTitle = useStore((state:any) => state.setFormTitle)

  const formMode: formModeType = useStore((state:any) => state.formMode)

  const [modalOpened, setModalOpened] = useState(false)
  const [drawerOpened, setDrawerOpened] = useState(false)
  const [subcontractsModalOpened, setSubcontractsModalOpened] = useState(false)
  const [dataTableNames, setDataTableNames] = useState([])
  
  const [projectNumbers, setProjectNumbers] = useState([])

  const [currentDailyContractProgressesFinalPrice, setCurrentDailyContractProgressesFinalPrice] = useState(0)
  const [currentPlanContractFinalPrice, setCurrentPlanContractFinalPrice] = useState(0)

  // const [subContractorContractsBasedOnSelectedProjectNumber, setSubContractorContractsBasedOnSelectedProjectNumber] = useState<contractType[]>([])
  // const [subContractorContractsFinalPrice, setSubContractorContractsFinalPrice] = useState(0)
  // const [selectedProjectNumber, setSelectedProjectNumber] = useState<string | null>()
  // const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())
  // const [comment, setComment] = useState("")
  
  const [projectNumberIsError, setProjectNumberIsError] = useState(false)
  const [dateIsError, setDateIsError] = useState(false)
  
  const [debouncedformState] = useDebouncedValue(formState, 3000);
  const [debouncedselectedProjectNumber] = useDebouncedValue(selectedProjectNumber, 3000);
  const [debouncedselectedDate] = useDebouncedValue(selectedDate, 3000);
  const [debouncedcomment] = useDebouncedValue(comment, 3000);


  const [firstRun, setFirstRun] = useState(true)



  const theme = useMantineTheme()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [isErrorOnSave, setIsErrorOnSave] = useState(false)

  const formModeIsDaily = formMode.toLowerCase().includes('dailyPost'.toLowerCase())


  // const formDailyIncomeObject = useStore((state:any) => state.formDailyIncomeObject)
  // const formDailySubcontractorCostObject = useStore((state:any) => state.formDailySubcontractorCostObject)

  // useEffect(() => {
  //   console.log(formDailyIncomeObject)
  // }, [formDailyIncomeObject])
  // useEffect(() => {
  //   console.log(formDailySubcontractorCostObject)
  // }, [formDailySubcontractorCostObject])
  

  // let componentLoadedFirstTime = useRef(true)


  function numberWithSpaces(x:number | string) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  async function getProjectNumbers(){
    const db = useFirestore()
    const user = useAuth()
    const docRef = doc(db, "user", user.uid);
    const docSnap = await getDoc(docRef);
    
    const projectNumbers = docSnap.data()?.project_numbers

    setProjectNumbers(projectNumbers)
  }

  async function loadDatatables(){
    const db = useFirestore()
    const user = useAuth()
    console.log(user)

    const docRef = doc(db, "user", `${user.uid}`);
    const docSnap = await getDoc(docRef);
    const tables = docSnap.data()?.database

    let icon
    tables.map(async (tableName:string, index:number) => {

      // cache icons in sessionstorage
      const iconsInSessionStorage = sessionStorage.getItem(`${tableName}-icon`)

      if(iconsInSessionStorage){
        // console.log("icon got from cache")
        icon = iconsInSessionStorage
      } else {
        
        // console.log("icon got from firestore")
        const docRef = doc(db, "table", tableName);
        const docSnap = await getDoc(docRef);
        icon = docSnap.data()?.icon

        // saving to cache
        sessionStorage.setItem(`${tableName}-icon`, icon)
      }


      tables[index] = { title: tableName, icon: icon }
    })

    setDataTableNames(tables)
    // setisLoading(false)
  }

  async function addTableSchemeData(tableName:string){

    // checking if table has already been added or not
    let contains = false
    formState.forEach((tablaBlock) => {
      if(tablaBlock.tableName == tableName){
        contains = true
        // scrolling to that element and making the title of that block red for 2 seconds
        document.getElementById(tableName)!.style.transition = "all 200ms";
        setTimeout(() => {
          document.getElementById(tableName)!.style.backgroundColor = "#C92A2A";
        }, 600);
        let scrollHeight = document.getElementById(tableName)?.offsetTop;
        viewport.current.scrollTo({ top: scrollHeight, behavior: 'smooth' });
        setTimeout(() => {
          document.getElementById(tableName)!.style.backgroundColor = "rgb(37, 38, 43)";
        }, 2000);
        return // this stops only the forEach method
      }
    });
    if(contains){
      showNotification({
        title: 'Tábla már hozzáadva',
        message: 'A táblát már korábban hozzáadtad!',
        autoClose: 5000
      })
      return "Table has already been added"
    } else {
      // const scrollHeight = document.getElementById(tableName)?.offsetTop
      console.log(viewport.current)
      setTimeout(() => {
        try {
          viewport.current.scrollTo({ top: 99999999, behavior: "smooth" }); // scrolling to the bottom of the page
        } catch {}
      }, 500);
    }

    //geting table sheme data from firestore table
    const db = useFirestore()
    const docRef = doc(db, "table", tableName);
    const docSnap = await getDoc(docRef);
    console.log(docSnap.data())
    const table = docSnap.data()?.table
    const data = docSnap.data()
    
    const innerObject = {"title": table, "data": data}

    // console.log([...formScheme, innerObject])
    setFormScheme([...formScheme, innerObject])
  }

  function updateFormStateIfFormSchemeChanges(){
    console.log(formState)
    // let state: any = formState
    console.log(formScheme)
    formScheme.map((scheme) => {
      let innerstate: { tableName: string, data: any}
      innerstate = { tableName: scheme.title, data: []}
      console.log(scheme)

      innerstate.tableName = scheme.title
      // innerstate[scheme.title] = {}

      const tableformscheme = scheme.data.form
      // //console.log(tableformscheme)
      let innerRowData: { value: string | number | null; column: string; initial_load: boolean; title: string; type: string; width_category: string; }[] = []
      // //console.log(tableformscheme)
      tableformscheme.map(((cell) => {
        let inneCellsData: { value: string | number | null; column: string; initial_load: boolean; title: string; type: string; width_category: string; } = { value: "", column: "", initial_load: false, title: "", type: "", width_category: "" }
        inneCellsData.value = cell.type !== "select" ? 0 : null
        inneCellsData.column = cell.column
        inneCellsData.initial_load = cell.initial_load || false
        inneCellsData.title = cell.title
        inneCellsData.type = cell.type
        inneCellsData.width_category = cell.width_category

        innerRowData.push(inneCellsData)
      }))
      // this adds the counting value to the state
      innerRowData.push({ value: 0, column: "counted_price", initial_load: false, title: "Kiszámolt ár", type: "text", width_category: "2"})
      // adding state to innerstate
      innerstate.data.push(innerRowData)

      console.log(innerstate)
      console.log(innerstate, formState)

      // checking if table already exists in state or not
      let exists = false
      formState.forEach((tableBlock) => {
        console.log(tableBlock.tableName == innerstate.tableName)
        if(tableBlock.tableName == innerstate.tableName){
          exists = true
        }
      })

      //setting the innerstate to the actual state
      if(!exists){
        console.log(formState)
        // innerState.push(innerstate)
        // innerState = [...innerState, innerstate]
        setFormState([...formState , innerstate])
        // state.push(innerstate)
        console.log([...formState , innerstate])
      }

      // settin formScheme to localstorage
      if(formMode === "dailyPost"){
        localStorage.setItem("daily-form-scheme", JSON.stringify(formScheme))
      } else if(formMode === "plannedCost"){
        localStorage.setItem("planned-form-scheme", JSON.stringify(formScheme))
      }
      // localStorage.setItem("form-scheme", JSON.stringify(formScheme))
    })
    // setFormState([...innerState])
    // if(state.length !== 0){
    //   setFormState(state)
    // }
    console.log('%cForm.tsx line:84 formScheme', 'color: white; background-color: #26bfa5;', formScheme);
  }

  async function saveChangesToFirestore(){
    const db = useFirestore()
    const collectionToQuery = formMode.toLowerCase().includes("daily") ? "daily" : "plan"
    console.log(collectionToQuery)
    console.log(savedDoc)
    
    try {
      const companyNames = extractCompanyNames(formState)
      const tableNames = extractTableNames(formState)

      if(location.pathname.toLowerCase().includes("watch") || savedDoc !== ""){
        // updating the saved document
        const docRef = doc(db, collectionToQuery, savedDoc);
        await updateDoc(docRef, {
          project_number: selectedProjectNumber,
          date: selectedDate || new Date(),
          last_modified: serverTimestamp(),
          comment: comment,
          data: JSON.stringify(formState),
          scheme: JSON.stringify(formScheme),
          final_price: formFinalPrice, // + calculateDailySubcontractorCost(),
          companies_inside: companyNames,
          tables_inside: tableNames,
          // daily_income: calculateDailyIncome(),
          // subcontractor_contracts_price: calculateDailySubcontractorCost()
        });
        console.log(savedDoc, " updated")
      } else {
        // Add a new document with a generated id.
        const user = useAuth()
        const company = await useUserCompany()
        const docRef = await addDoc(collection(db, collectionToQuery), {
          user_id: user.uid,
          company: company,
          project_number: selectedProjectNumber,
          date: selectedDate || new Date(),
          created_at: serverTimestamp(),
          last_modified: serverTimestamp(),
          comment: comment,
          data: JSON.stringify(formState),
          scheme: JSON.stringify(formScheme),
          final_price: formFinalPrice, // + calculateDailySubcontractorCost(),
          companies_inside: companyNames,
          tables_inside: tableNames,
          // daily_income: calculateDailyIncome(),
          // subcontractor_contracts_price: calculateDailySubcontractorCost()
        });
        // console.log(docId, "document set")
        setSavedDOc(docRef.id)
        console.log(docRef.id)
        console.log("Document written with ID: ", docRef.id);
      }
      setIsErrorOnSave(false)
    } catch (error) {
      console.log(error)
      setIsErrorOnSave(true)
    }
    
  }

  async function calculateDailyFinalPriceWithAddedContractProgresses(){
    // console.log("calculateDailyFinalPriceWithAddedContractProgresses called")
    if(selectedProjectNumber != "" && selectedProjectNumber != null && selectedProjectNumber != undefined && selectedDate != undefined && selectedDate != null && post){

      // if(currentDailyContractProgressesFinalPrice === 0){
        let dateTo = new Date(selectedDate.getTime())
        // let dateFrom = new Date(post.date.seconds * 1000)

        dateTo.setHours(23, 59, 59, 59)
        // dateFrom.setDate(dateFrom.getDate() - 30)
        // dateFrom.setHours(0, 0, 0, 0)

        let modifiedPost: DailyPostType = JSON.parse(JSON.stringify(post))
        modifiedPost.date.seconds = selectedDate.getTime() / 1000 // i had to divide by 1000, to keep the format like it would come from firestore
        modifiedPost.project_number = selectedProjectNumber
        modifiedPost.final_price = formFinalPrice
        console.log("modifiedPost", modifiedPost)
        console.log("modifiedPost date", new Date(modifiedPost.date.seconds), dateTo)

        const list: DailyPostType[] | undefined = await useAddContractProgressToDaily([modifiedPost], await useUserCompany(), "returnListWithAllDaysEvenWhenDailyPostHasNotBeenMade", undefined, dateTo)
        console.log("list", list)
        if(list){
          
          list.forEach((post) => {
            console.log(new Date(post.date.seconds))
          })

          // saving only the contract progress price
          setCurrentDailyContractProgressesFinalPrice(Math.round(list[0].final_price - formFinalPrice))
          console.log(post.final_price)
          console.log(list[0].final_price)
          console.log(formFinalPrice)
          console.log("calculateDailyFinalPriceWithAddedContractProgresses run")
        }
  
      // }

    } else {
      setCurrentDailyContractProgressesFinalPrice(0)
      // console.log("calculateDailyFinalPriceWithAddedContractProgresses did not run")
    }
  }

  async function calculatePlannedCostFinalPriceWithAddedSubcontractorContracts(){
    if(selectedProjectNumber != "" && selectedProjectNumber != null && selectedProjectNumber != undefined && selectedDate != undefined && selectedDate != null && post){
      let modifiedPost: DailyPostType = JSON.parse(JSON.stringify(post))
      modifiedPost.project_number = selectedProjectNumber
      console.log("modifiedPost", modifiedPost)

      const list = await useAddSubcontractorContractsToPlan([modifiedPost])
      console.log("list", list)

      // saving only the contract price
      setCurrentPlanContractFinalPrice(Math.round(list[0].final_price - formFinalPrice))

      console.log(post.final_price)
      console.log(list[0].final_price)
      console.log(formFinalPrice)
      console.log("calculatePlannedCostFinalPriceWithAddedSubcontractorContracts run")

    } else {
      setCurrentPlanContractFinalPrice(0)
    }
  }
  // async function saveForm(){
  //   if(typeof selectedProjectNumber !== "string" || selectedProjectNumber == ""){
  //     setProjectNumberIsError(true)
  //   } else if(typeof selectedDate !== "object" || selectedDate == null){
  //     setDateIsError(true)
  //   } else {

  //     try {
  //       // Add a new document with a generated id.
  //       const db = useFirestore()
  //       const user = useAuth()
  //       const company = await useUserCompany()
  //       const docRef = await addDoc(collection(db, formMode == "dailyPost" ? "daily" : "plan"), {
  //         user_id: user.uid,
  //         company: company,
  //         project_number: selectedProjectNumber,
  //         date: selectedDate,
  //         created_at: serverTimestamp(),
  //         comment: comment,
  //         data: JSON.stringify(formState),
  //         scheme: JSON.stringify(formScheme),
  //         final_price: formFinalPrice
  //       });
  //       console.log("Document written with ID: ", docRef.id);

  //       // if(formMode === "dailyPost"){
  //       //   localStorage.removeItem("daily-form")
  //       //   localStorage.removeItem("daily-form-scheme")
  //       //   localStorage.removeItem("daily-form-final-price")
  //       // } else if(formMode === "plannedCost"){
  //       //   localStorage.removeItem("planned-form")
  //       //   localStorage.removeItem("planned-form-scheme")
  //       //   localStorage.removeItem("planned-form-final-price")
  //       // }
  //       setFormState([])
  //       setFormScheme([])
  //       navigate(formMode == "dailyPost" ? "/daily-posts" : "/planned-costs")

  //       setTimeout(() => {
  //         showNotification({
  //           id: 'load-data',
  //           title: 'Siker!',
  //           message: `${formMode == "dailyPost" ? "Az összesítő" : "A tervezett"} sikeresen mentve lett`,
  //           icon: <Check size={16} />,
  //           autoClose: 5000,
  //         });
  //       }, 1000);
        
  //     } catch (err){
  //       console.log(err)
  //         showNotification({
  //           id: 'load-data',
  //           title: 'Hiba történt!',
  //           color: 'red',
  //           message: 'Az adatok nem mentve lettek metnve az adatbázisodban',
  //           icon: <AlertCircle size={16} />,
  //           autoClose: false,
  //         });
  //     }
  //   }
  // }

  // async function getSubContractorContractsForSelectedProjectNumber(){
  //   const db = useFirestore()
  //   const user = useAuth()
  //   const company = await useUserCompany()

  //   const q = query(collection(db, "contract"), where("user_id", "==", user.uid), where("customer", "==", company), where("project_number", "==", selectedProjectNumber));
  //   const querySnapshot = await getDocs(q);

  //   const contracts: contractType[] = []
  //   let constractsAllPrice = 0
  //   querySnapshot.forEach((doc) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     const contract = doc.data() as contractType
  //     console.log(doc.id, " => ", contract);
  //     contracts.push({ id: doc.id, ...doc.data() as any })
  //     constractsAllPrice += contract.contract_price
  //   });
  //   // this only for the plan page
  //   setSubContractorContractsBasedOnSelectedProjectNumber(contracts)
  //   setSubContractorContractsFinalPrice(constractsAllPrice)

  //   const formPrice = calculateFinalPrice(formState, formMode)
  //   setFormFinalPrice(formPrice + constractsAllPrice)
  // }

  // function calculateDailyIncome(){
  //   let counter = 0
  //   Object.values(formDailyIncomeObject).forEach((object: any) => {
  //     if(object.changed != 0){
  //       counter += object.changed - object.initial    
  //     }
  //   })
  //   return counter
  // }
  // function calculateDailySubcontractorCost(){
  //   let counter = 0
  //   Object.values(formDailySubcontractorCostObject).forEach((object: any) => {
  //     if(object.changed != 0){
  //       counter += object.changed - object.initial    
  //     }
  //   })
  //   return counter
  // }
  // function calculateFinalPriceToSaveCorrectFinalPrice(){
  //   const sbucontractCosts = calculateDailySubcontractorCost()
  //   setFormFinalPrice(formFinalPrice + sbucontractCosts)
  //   return formFinalPrice + sbucontractCosts
  // }

  useEffect(() => {
    
      updateFormStateIfFormSchemeChanges()

  }, [formScheme])


  useEffect(() => {
    if(formState.length != 0 && !firstRun){// && componentLoadedFirstTime.current == false){
      console.log("debounced")
      saveChangesToFirestore()
      setIsSaving(false)

      console.log(extractCompanyNames(formState))
      console.log(extractTableNames(formState))
    }
  }, [debouncedformState, debouncedselectedProjectNumber, debouncedselectedDate, debouncedcomment ])
  
  useEffect(() => {
    // console.log(componentLoadedFirstTime)
    if(formState.length != 0 && !firstRun){// && componentLoadedFirstTime.current == false){
      setIsSaving(true)

    }
  }, [formState, selectedProjectNumber, selectedDate, comment])
  

  useEffect(() => {
    if(formTitle != ""){
      setFormTitle(`${weekday[new Date(selectedDate).getDay()]} ${useCapitalizeFirstLetter(new Intl.DateTimeFormat('hu-HU', { month: "long", day: "2-digit"}).format(selectedDate)).replace(".", "")}`)
    }
  }, [selectedDate])


  useEffect(() => {
    if(formModeIsDaily){
      calculateDailyFinalPriceWithAddedContractProgresses()
    } else {
      calculatePlannedCostFinalPriceWithAddedSubcontractorContracts()
    }
  }, [post, formFinalPrice, selectedProjectNumber, selectedDate])
  
  
  // useEffect(() => {
  //   if(!formModeIsDaily){
  //     getSubContractorContractsForSelectedProjectNumber()
  //   }
  // }, [selectedProjectNumber])
  
  // useEffect(() => {
  //   setDateIsError(false)
  // }, [selectedDate])

  useEffect(() => {
    loadDatatables()
    getProjectNumbers()

    if(selectedDate == ""){
      setSelectedDate(new Date())
    }

    setTimeout(() => {
      // it is prohibiting the save on component mount when it is unnecesary
        setFirstRun(false)
    }, 4000);

    setTimeout(() => {
      setIsLoading(false)
    }, 2000);

  }, [])
  

  return (
    <>
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "30px" }}>
          {isSaving ? (
            <Button variant='light' color={'gray'} loading>Változtatások mentése...</Button>
          ) : (
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", gap: "20px"}}>
              <Button
              variant='light'
              onClick={() => {history.back()} }
              style={{ width: "80px" }}
              styles={() => ({
                root: {
                  color: formModeIsDaily ? "rgb(178, 242, 187)" : yellow_light_button_text,
                  backgroundColor: formModeIsDaily ? "rgba(47, 158, 68, 0.2)" : yellow_light_button_bg,
                  "&:hover": {
                    backgroundColor: formModeIsDaily ? "rgba(55, 178, 77, 0.25)" : yellow_light_button_hover
                  }
                },
              })}
              >
                  Vissza
              </Button>
              {formState.length != 0 ? (
                <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                    {isErrorOnSave ? (
                        <>
                        <AlertCircle color='red'/>
                        <h4 style={{margin: "0px"}}>Hiba történt! Nincs mentve</h4>
                        </>
                    ) : (
                        <>
                        <Check color='green'/>
                        <h4 style={{margin: "0px"}}>{formModeIsDaily ? "Összesítő" : "Tervezett"} mentve</h4>
                        </>
                    )}
                </div>
              ) : ""}

              {/* {formModeIsDaily ? (
                <>
                <Tooltip label={<><div>A napi költségek oldalon meg tudod adni az anyagköltségeid és óradíjban, napidíjban stb.-ben elszámolt költségeid.</div><div>A szerződések oldalon a szerződéses haladásaidat tudod rögzíteni és a Builty ezeket elszámolja neked napi költségként</div></>}>
                  <SegmentedControl
                    data={switcherData}
                    value={switcherData[switcherIndex]}
                    onChange={(value) => setSwitcherIndex(switcherData.findIndex((item) => item == value))}
                  />
                </Tooltip>
                </>
              ) : (
                <Button
                  leftIcon={<Notes/>}
                  color={"gray"}
                  variant={"outline"}
                  onClick={() => setSubcontractsModalOpened(true)}
                >
                  Alvállalkozói szerződések
                </Button>
              )} */}

            </div>
          )}

          <h1 style={{ fontWeight: "bold" }}>{formTitle == "" ? (formModeIsDaily ? "Napi költség összesítő hozzáadása" : "Költség tervezett hozzáadása") : formTitle}</h1>

          {/* <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
          <Check color='green'/>
          <h4 style={{margin: "0px"}}>Mentve</h4>
          </div> */}
          {/* <h2>Végösszeg:</h2> */}

          <div style={{"display": "flex", "gap":"20px"}}>
            {formState.length >= 1 ? (
              <Button variant='filled' leftIcon={<ChevronRight size={20}/>} color={'cyan'} onClick={() => setDrawerOpened(true)}>Folytatás</Button>
            ) : ""}
              <Button variant='outline' style={{color: formModeIsDaily ? "" : yellow_light_button_text, borderColor: formModeIsDaily ? "" : yellow_light_button_text}}
                      onClick={() => {setModalOpened(true)} }
                      leftIcon={<Plus size={20} />}>
                  Tábla hozzáadás
              </Button>
          </div>
    </div>


    <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title="Válassz adattáblát aminek a költségeit szeretnéd megadni!"
        centered
        size={"95%"}
    >
        <ActionsGrid tables={dataTableNames} onclick={(event:any) => {addTableSchemeData(event.target.innerText), setModalOpened(false)}}/>
    </Modal>
    

    <Drawer
        opened={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        title={<h2>{formModeIsDaily ? "Összesítő" : "Tervezett"} részletei</h2>}
        padding="xl"
        size="xl"
        position="right"
      >
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "calc(100vh - 140px)"}}>
          <div style={{ display: "flex", flexDirection: "column", gap: "20px", paddingTop: "0px"}}>
            <Select
              label="Munkaszám"
              placeholder="Válassz munkaszámot"
              searchable
              clearable
              nothingFound="Nincs találat!"
              maxDropdownHeight={280}
              value={selectedProjectNumber}
              onChange={(selected) => setSelectedProjectNumber(selected)}
              error={projectNumberIsError ? "Nem választottál ki munkaszámot!" : ""}
              data={projectNumbers}
              styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor:
                      formModeIsDaily ? theme.colors.green[8] : yellow_button,
                    },
                  },
        
                  // applies styles to hovered item (with mouse or keyboard)
                  '&[data-hovered]': {},
                },
                input: {
                  ":focus-within": {
                    borderColor: formModeIsDaily ? theme.colors.green[8] : yellow_button,
                  }
                }
              })}
            />
            <DatePicker
            placeholder="Válassz dátumot!"
            label={`${formModeIsDaily ? "Összesítő" : "Tervezett"} Dátuma`}
            locale="hu"
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            error={dateIsError ? "Kérlek válassz dátumot!" : ""}
            className={formModeIsDaily ? "" : "selected-yellow-datepicker"}
            styles={(theme) => ({
              item: {
                // applies styles to selected item
                '&[data-selected]': {
                  '&, &:hover': {
                    backgroundColor:
                    formModeIsDaily ? theme.colors.green[8] : yellow_button,
                  },
                },
      
                // applies styles to hovered item (with mouse or keyboard)
                '&[data-hovered]': {},
              },
              input: {
                ":focus-within": {
                  borderColor: formModeIsDaily ? theme.colors.green[8] : yellow_button,
                }
              },
            })}
            />
            <Textarea
              placeholder="Itt megadhatsz plusz információkat"
              label="Megjegyzés"
              value={comment}
              onInput={(text: { currentTarget: { value: any } }) => setComment(text.currentTarget.value)}
              autosize
              minRows={2}
              maxRows={4}
              styles={(theme: { colors: { green: any[] } }) => ({
                item: {
                  // applies styles to selected item
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor:
                      formModeIsDaily ? theme.colors.green[8] : yellow_button,
                    },
                  },
        
                  // applies styles to hovered item (with mouse or keyboard)
                  '&[data-hovered]': {},
                },
                input: {
                  ":focus-within": {
                    borderColor: formModeIsDaily ? theme.colors.green[8] : yellow_button,
                  }
                }
              })}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column"}}>
            {formModeIsDaily ? (
              <>
              {/* <h4 style={{margin:"0px"}}>Napi bevétel: {useNumberWithSpaces(calculateDailyIncome())} Ft</h4>
              <h4 style={{margin:"0px"}}>Napi alvállalkozói költségek: {numberWithSpaces(calculateDailySubcontractorCost())} Ft</h4>
              <h4 style={{margin:"0px"}}>Napi költségek: {numberWithSpaces(formFinalPrice)} Ft</h4> */}
              {/* <h4 style={{margin:"0px"}}>Napi bevétel: {useNumberWithSpaces(dailyIncome)} Ft</h4>
              <h4 style={{margin:"0px"}}>Napi alvállalkozói költségek: {numberWithSpaces(subcontractorContractPrice)} Ft</h4>
              <h4 style={{margin:"0px"}}>Napi költségek: {numberWithSpaces(formFinalPrice)} Ft</h4> */}
              <h4 style={{margin:"0px"}}>Napi összesítő költségek: {numberWithSpaces(formFinalPrice)} Ft</h4>
              <h4 style={{margin:"0px"}}>Alvállalkozói költségek: {numberWithSpaces(currentDailyContractProgressesFinalPrice)} Ft</h4>
              <h2 style={{marginBottom: '15px'}}>Összes költség: {numberWithSpaces(currentDailyContractProgressesFinalPrice + formFinalPrice)} Ft</h2>
              </>
            ) : (
              <>
              {/* <h4 style={{margin:"0px"}}>Tervezett összeg: {useNumberWithSpaces(formFinalPrice - subContractorContractsFinalPrice)} Ft</h4>
              <h4 style={{margin:"0px"}}>Alvállalkozói szerződések: {numberWithSpaces(subContractorContractsFinalPrice)} Ft</h4> */}
              <h4 style={{margin:"0px"}}>Tervezett költségek: {numberWithSpaces(formFinalPrice)} Ft</h4>
              <h4 style={{margin:"0px"}}>Alvállalkozói szerződések: {numberWithSpaces(currentPlanContractFinalPrice)} Ft</h4>
              <h2 style={{marginBottom: '15px'}}>Összes költség: {numberWithSpaces(currentPlanContractFinalPrice + formFinalPrice)} Ft</h2>
              </>
            )}
            {isSaving ? (
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <Loader color={"white"} size={"sm"}/>
                <h3>{formModeIsDaily ? "Összesítő" : "Tervezett"} mentése folyamatban...</h3>
              </div>
            ) : (
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <Check color='green'></Check>
                <h3>{formModeIsDaily ? "Összesítő" : "Tervezett"} sikeresen mentve!</h3>
              </div>
            )}
            {/* <Button
            onClick={saveForm}
            style={{backgroundColor: formModeIsDaily ? theme.colors.green[8] : yellow_button}}
            >
              { formModeIsDaily ? "Összesítő" : "Tervezett"} mentése
            </Button> */}
          </div>
        </div>
    </Drawer>


    {/* <Modal
      opened={subcontractsModalOpened}
      onClose={() => setSubcontractsModalOpened(false)}
      title={selectedProjectNumber == "" || selectedProjectNumber == null ? "" : `Alvállalkozói szerződések ${selectedProjectNumber} munkaszám alatt`}
      size={"70vw"}
    >
      {selectedProjectNumber == "" || selectedProjectNumber == null ? (
        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
          <AlertCircle color='red' size={"30px"}/>
          <h3 style={{textAlign: "center"}}>Kérlek előbb válassz munkaszámot hogy láthasd az ehhez a munkához tartozó alvállalkozói szerződéseket!</h3>
        </div>
      ) : 
        subContractorContractsBasedOnSelectedProjectNumber.map((contract) => <Contract modeIsOwnContracts={false} firestore_id={contract.id} project_number={contract.project_number} buyer={contract.customer} contractor={contract.contractor} contract_price={contract.contract_price} after_work_price={contract.after_work} forint_readiness={contract.forint_readiness} percentage_readiness={contract.percentage_readiness} project_numbers={projectNumbers} comment={contract.comment} showEditButton={false} showSelectButtonInsteadOfContentButton={false} onSelectButtonClick={() => {}}/>)
      }
    </Modal> */}
    
      { isLoading ? <div className='loader-backdrop dashboard'><Loader size={'xl'} color={formModeIsDaily ? "green" : yellow_button}/></div> : "" }
    </>
  )
}

export default Header






function extractCompanyNames(input: {
  tableName: string,
  data: { value: string | number | null, column: string, initial_load: boolean, title: string, type: string, width_category: string }[][]
}[]): string[] {
  let companys: string[] = [];

  for (const table of input) {
      for (const row of table.data) {
          for (const cell of row) {
              if (cell.column === 'cég' || cell.column === 'Cég' || cell.column === 'gyártó' || cell.column === 'Gyártó') {
                companys.push(cell.value ? cell.value.toString() : '');
              }
          }
      }
  }

  companys = companys.filter((company) => company !== '');

  return companys;
}

function extractTableNames(input: {
  tableName: string,
  data: { value: string | number | null, column: string, initial_load: boolean, title: string, type: string, width_category: string }[][]
}[]): string[] {
  const tableNames: string[] = [];

  for (const table of input) {
      tableNames.push(table.tableName);
  }

  return tableNames;
}
