import { useEffect, useState } from 'react';
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  Input,
  Textarea,
  Pagination,
} from '@mantine/core';
import { keys } from '@mantine/utils';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons';
import "./TableSort.css"

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

// interface RowData {
//   id: string,
//   data: object
// }

interface TableSortProps {
  data: any[],
  isEditing: boolean,
  setChangesHaveBeenSaved: Function,
  rowChangesMadeInTable: object[],
  setRowChangesMadeInTable: Function
}

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text weight={500} size="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: any, search: string) {
  const query = search.toLowerCase().trim();
  return data.filter((item:any) => //console.log(data[0])
    keys(data[0]["data"]).some((key) => item["data"][key].toString().toLowerCase().includes(query))
  );
}

function sortData(
  data: any,
  payload: { sortBy: keyof any | null; reversed: boolean; search: string }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        if(sortBy == "created_at"){
          // console.log(b.data[sortBy].seconds)
          return b.data[sortBy].seconds.toString().localeCompare(a.data[sortBy].seconds.toString());
        }
        return b.data[sortBy].localeCompare(a.data[sortBy]);
      }

      if(sortBy == "created_at"){
        return a.data[sortBy].seconds.toString().localeCompare(b.data[sortBy].seconds.toString());
      }
      return a.data[sortBy].localeCompare(b.data[sortBy]);
    }),
    payload.search
  );
}

export default function TableSort({ data, isEditing, setChangesHaveBeenSaved, rowChangesMadeInTable, setRowChangesMadeInTable }: TableSortProps) {
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState<keyof any | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const showingOnlyXItemsOnThePage = 10
  const [activePage, setActivePage] = useState(1)
  const [itemsToShowPagination, setItemsToShowPagination] = useState([])

  useEffect(() => {
    setSortedData([...data])
    // console.log("sorted data set in useeffect")
    // console.log(data)
  }, [data])


  useEffect(() => {
    // this slices the sortedData into smaller chunks
    const perChunk = showingOnlyXItemsOnThePage // items per chunk    
    const inputArray = sortedData
    const result = inputArray.reduce((resultArray:any, item:any, index:number) => { 
      const chunkIndex = Math.floor(index/perChunk)
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])
    // console.log(result); 
    // console.log(activePage)
    if(result.length != 0){
      setItemsToShowPagination(result[activePage-1])
    } else {
      setItemsToShowPagination([])
    }
  }, [sortedData, activePage])



  const setSorting = (field: keyof any) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setActivePage(1)
    setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search: value }));
  };
  
  function setFormValue(inputText:string, rowId: string, key:string){
    console.warn("setFormValue run")
    // console.log(inputText)
    // console.log(rowindex)
    // console.log(cellindex)
    // console.log(key)

    const innerState: any = sortedData
    // const row: any = innerState[rowindex]
    // console.log(row["data"][key])
    innerState.map((row:any, rowindex:number) => {
      if(row.id === rowId){
        innerState[rowindex]["data"][key] = inputText
        return
      }
    })
    // console.log(innerState)
    setSortedData([...innerState])
    console.log("data has been changed")
  }

  const rows = itemsToShowPagination.map((row: any, index: number) => {
    // console.log(row.data)
    let date
    try {
      date = new Date(row.data.created_at.seconds * 1000);
    } catch {
      date = new Date()
    }
    if(isEditing){
      return (
        <tr key={index}>
          <td className='project-number-row-number'>
            
            {row.data.project_number}
            {/* <Input
            value={row.data.project_number}
            onChange={(event:any) => {
              setFormValue(event.currentTarget.value, row.id, "project_number")
              setChangesHaveBeenSaved(false)
              // if(rowChangesMadeInTable.includes())
              const itemAlreadyInList = rowChangesMadeInTable.some((rowInList:any) => rowInList.id === row.id);
              if(!itemAlreadyInList) {
                setRowChangesMadeInTable([...rowChangesMadeInTable, {"id": row.id, "changes": row.data}])
              }
            }}></Input> */}
          </td>
          <td className='project-number-row-created'>{date.toDateString()}</td>
          <td className='project-number-row-comment'>
            <Textarea
              autosize
              minRows={1}
              maxRows={4}
              value={row.data.comment}
              onChange={(event:any) => {
                setFormValue(event.currentTarget.value, row.id, "comment")
                setChangesHaveBeenSaved(false)
                // if(rowChangesMadeInTable.includes())
                const itemAlreadyInList = rowChangesMadeInTable.some((rowInList:any) => rowInList.id === row.id);
                if(!itemAlreadyInList) {
                  setRowChangesMadeInTable([...rowChangesMadeInTable, {"id": row.id, "changes": row.data}])
                }
              }}
            />
          </td>
        </tr>
      )
    } else {
      return (
        <tr key={index}>
          <td className='project-number-row-number'>{row.data.project_number}</td>
          <td className='project-number-row-created'>{date.toDateString()}</td>
          <td className='project-number-row-comment'>{row.data.comment}</td>
        </tr>
      )
    }
});

  return (
    <ScrollArea>
      <TextInput
        placeholder="Keress bármi alapján"
        mb="md"
        icon={<IconSearch size={14} stroke={1.5} />}
        value={search}
        onChange={handleSearchChange}
      />
      <div className='project-number-table'>
        <Table
          highlightOnHover
          horizontalSpacing="md"
          verticalSpacing="xs"
          sx={{ tableLayout: 'unset', minWidth: 700 }}
        >
          <thead className='table-header'>
            <tr>
              <Th
                sorted={sortBy === 'project_number'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('project_number')}
              >
                Munkaszám
              </Th>
              <Th
                sorted={sortBy === 'created_at'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('created_at')}
              >
                Létrehozva
              </Th>
              <Th
                sorted={sortBy === 'comment'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('comment')}
              >
                Megjegyzés
              </Th>
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 ? (
              rows
            ) : (
              <tr>
                <td colSpan={3}>
                  <Text weight={500} align="center">
                    Nincs találat
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className='pagination'>
          <Pagination page={activePage} onChange={setActivePage} total={Math.ceil(sortedData.length/showingOnlyXItemsOnThePage)} />
        </div>
      </div>
    </ScrollArea>
  );
}