import React, { useEffect } from 'react'
import Lottie from "lottie-react";
import success from "../assets/lottie/success.json";
import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { deleteDoc, doc } from 'firebase/firestore';
import { useFirestore } from '../hooks/useFirebase';

function PaymentSuccess() {
    const navigate = useNavigate()

    async function deleteConfirmEmail(){
      const db = useFirestore()
      try {
        // deleting confirmed-email document
        await deleteDoc(doc(db, "confirmed-email", localStorage.getItem("confirmEmailDocId")!));
        localStorage.removeItem("confirmEmailDocId")

      } catch (error) { console.log(error) }
    }

    useEffect(() => {
      deleteConfirmEmail()
    }, [])
    
  return (
    <div style={{"width": "100vw", "height": "100vh", "display": "flex", "flexDirection": "column","alignItems": "center", "justifyContent": "center"}}>
        <Lottie animationData={success} loop={false} style={{"width": "200px"}}/>
        <h1>Sikeres fizetés</h1>
        <h3>Sikeresen regisztráltad a szervezeted</h3>
        <Button variant='light' style={{"background": "rgb(44 218 148 / 20%)"}} onClick={() => navigate("/login")}>Fiók regisztrálása</Button>
    </div>
  )
}

export default PaymentSuccess