import React, { useState } from 'react'
import { Group, Box, Collapse, ThemeIcon, Text, UnstyledButton, createStyles } from '@mantine/core';
import { Icon as TablerIcon, CalendarStats, ChevronLeft, ChevronRight } from 'tabler-icons-react';
import './LinksGroup.css'

import { Link } from "react-router-dom";


interface LinksGroupProps {
    icon: any;
    label: string;
    size: number,
    initiallyOpened?: boolean;
    links?: { label: string; link: string }[];
}

export default function LinksGroup({ icon: Icon, label, initiallyOpened, links, size }: LinksGroupProps) {
    const { classes, theme } = useStyles();
    const hasLinks = Array.isArray(links);
    const [opened, setOpened] = useState(initiallyOpened || false);
    const ChevronIcon = theme.dir === 'ltr' ? ChevronRight : ChevronLeft;
    const items = (hasLinks ? links : []).map((link) => (
      <Link to={`${link.link}`} key={link.label}>
        <Text
          // component="a"
          className={classes.link}
          // href={link.link}
          key={link.label}
          //onClick={(event) => event.preventDefault()}
        >
          {link.label}
        </Text>
      </Link>
    ));
  
    return (
      <>
        <UnstyledButton onClick={() => setOpened((o) => !o)} className={classes.control}>
          <Group position="apart" spacing={0}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ThemeIcon variant="light" size={size} className="dashboard-icon-background">
                <Icon size={18} />
              </ThemeIcon>
              <Box ml="md">{label}</Box>
            </Box>
            {hasLinks && (
              <ChevronIcon
                className={classes.chevron}
                size={14}
                style={{
                  transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
                }}
              />
            )}
          </Group>
        </UnstyledButton>
        {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
      </>
    );
  }
  
  // const mockdata = {
  //   label: 'Releases',
  //   icon: CalendarStats,
  //   links: [
  //     { label: 'Upcoming releases', link: '/' },
  //     { label: 'Previous releases', link: '/' },
  //     { label: 'Releases schedule', link: '/' },
  //   ],
  // };
  
  // export function NavbarLinksGroup() {
  //   return (
  //     <Box
  //       sx={(theme) => ({
  //         minHeight: 220,
  //         padding: theme.spacing.md,
  //         backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
  //       })}
  //     >
  //       <LinksGroup {...mockdata} />
  //     </Box>
  //   );
  // }

const useStyles = createStyles((theme) => ({
    control: {
      fontWeight: 500,
      display: 'block',
      width: '100%',
      padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
      fontSize: theme.fontSizes.sm,
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  
    link: {
      fontWeight: 500,
      display: 'block',
      textDecoration: 'none',
      padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
      paddingLeft: 31,
      marginLeft: 30,
      fontSize: theme.fontSizes.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
      borderLeft: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  
    chevron: {
      transition: 'transform 200ms ease',
    },
  }));
