import { useState } from 'react';
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  useMantineTheme,
  Tooltip,
  Space,
} from '@mantine/core';
import { keys } from '@mantine/utils';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconPointFilled } from '@tabler/icons-react';
import { month, useNumberWithSpaces } from '../../../../../../hooks/utils';
import { ArrowDown, ArrowUp, InfoCircle } from 'tabler-icons-react';
import { generateColorFromSeed } from '../BuiltyQueryCard/BuiltyQueryCard';

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: window.innerWidth < 1400 ? '2rem' : '3.5rem',
    height: window.innerWidth < 1400 ? '2rem' : '3.5rem',
    borderRadius: 21,
  },
}));

interface RowData {
    customer: string | number | {from: Date, to: Date},
    cost: number,
    income: number,
    resultPercentage: number,
    resultPrice: number,
    overheadCost: number,
    otherCost: number
}


interface TableSortProps {
  data: RowData[];
  sum: number;
  nameType: "text" | "monthIndex" | "weekRange"
  height?: string;
  showAllColumns?: boolean;
  showLines?: boolean;
}

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size="0.9rem" stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: RowData[], search: string) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys(data[0]).some((key) => item[key].toString().toLowerCase().includes(query))
  );
}

function sortData(
  data: RowData[],
  payload: { sortBy: keyof RowData | null; reversed: boolean; search: string }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {

        if(typeof a[sortBy] === "number" && typeof b[sortBy] === "number"){
          return Number(b[sortBy]) - Number(a[sortBy]);
        } else {
          return String(b[sortBy]).localeCompare(String(a[sortBy]));
        }
      }

      if(typeof a[sortBy] === "number" && typeof b[sortBy] === "number"){
        return Number(a[sortBy]) - Number(b[sortBy]);
      } else {
        return String(a[sortBy]).localeCompare(String(b[sortBy]));
      }

    }),
    payload.search
  );
}





export default function QueryCardYearlyTable({ data, sum, height, showAllColumns, showLines, nameType }: TableSortProps) {
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const theme = useMantineTheme();

  const setSorting = (field: keyof RowData) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search: value }));
  };

  const rows = sortedData.map((row) => (
    <tr key={row.customer.toString()}>
      <td style={{ width: showAllColumns ? (showLines ? "28%" : "35%") : "40%", paddingLeft: "0px", paddingRight: "20px"}}>
        {/* <Tooltip label="hello bello"> */}
          <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
              <IconPointFilled size={34} style={{color: generateColorFromSeed(String(row.customer), false, 0.6)}}></IconPointFilled>
              {nameType == "text" ?
                <Text size={'md'}>
                  {String(row.customer).slice(0, 35)}{String(row.customer).length > 35 ? "..." : ""}
                </Text>
              : null}
              {nameType == "monthIndex" ?
                <Text size={'md'}>
                  {month[Number(row.customer) - 1]}{String(row.customer).length > 35 ? "..." : ""}
                </Text>
              : null}
              {nameType == "weekRange" ?
                <Text size={'md'}>
                  {typeof row.customer == "object" ?
                    `${month[row.customer.from.getMonth()]} ${row.customer.from.getDate()} - ${month[row.customer.to.getMonth()]} ${row.customer.to.getDate()}`
                  : row.customer}{String(row.customer).length > 35 ? "..." : ""}
                </Text>
              : null}
          </div>
        {/* </Tooltip> */}
      </td>

      {showAllColumns ? (
        <>
        <td style={{ paddingLeft: "0px"}}>
          <Text size={"md"}>{useNumberWithSpaces(row.income)} Ft</Text>
        </td>

        <td style={{ paddingLeft: "0px"}}>
          <Text size={"md"}>{useNumberWithSpaces(row.cost)} Ft</Text>
        </td>
        </>
      ) : ""}

      <td style={{ paddingLeft: "0px"}}>
        <Text size={"md"} weight={"bold"}>{useNumberWithSpaces(row.resultPrice)} Ft</Text>
      </td>

      <td style={{ paddingLeft: "0px"}}>
        <div style={{display: "flex", alignItems: "center", marginTop: "0px"}}>
            {row.resultPercentage > 0 ? (
              <ArrowUp size={20} color={theme.colors.green[6]}></ArrowUp>
            ) : (
              <ArrowDown size={20} color={theme.colors.red[6]}></ArrowDown>
            )}
            <Text size={'md'} color={row.resultPercentage > 0 ? theme.colors.green[6] : theme.colors.red[6]}>{useNumberWithSpaces(row.resultPercentage).replace(".", ",")} %</Text>        
        </div>
      </td>

      {showLines ? (
        <td style={{ paddingLeft: "0px", paddingRight: "0px"}}>
          {/* <div style={{width: "200px", height: "15px", backgroundColor: theme.colors.gray[8], borderRadius: "10px", overflow: "hidden"}}>
              <div style={{ width: (row.resultPrice / sum * 100).toFixed(2)  + "vw", height:"100%", borderRadius: "10px", backgroundColor: generateColorFromSeed(row.customer, false, 0.65)}}></div>
          </div> */}
        </td>
      ) : null}

      <td style={{width: "50px"}}>
        <Tooltip
          style={{border: `1px solid ${theme.colors.green[7]}`, padding: "15px"}}
          label={<Text size="md">

          {!showAllColumns ?
            <>
            Bevétel: {useNumberWithSpaces(row.income)} Ft
            <br />
            Költség: {useNumberWithSpaces(row.cost)} Ft
            
            <div style={{height: "10px"}}></div>
            </>
           : null}

          Rezsiköltség: {useNumberWithSpaces(row.overheadCost)} Ft
          <br />
          Egyéb költségek: {useNumberWithSpaces(row.otherCost)} Ft

        </Text>}>
          <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <InfoCircle></InfoCircle>
          </div>
        </Tooltip>
      </td>


    </tr>
  ));

  return (
    <ScrollArea h={height || "300px"} style={{ padding: "0px 0px 0px 10px"}}>
      {/* <TextInput
        placeholder="Keress..."
        mb="md"
        icon={<IconSearch size="0.9rem" stroke={1.5} />}
        value={search}
        onChange={handleSearchChange}
      /> */}
      <Table horizontalSpacing="xs" verticalSpacing="sm">
        <thead>
          <tr>
            <Th
              sorted={sortBy === 'customer'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('customer')}
            >
              Megnevezés
            </Th>
            {showAllColumns ? (
              <>
              <Th
                sorted={sortBy === 'income'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('income')}
              >
                Bevétel
              </Th>
              <Th
                sorted={sortBy === 'cost'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('cost')}
              >
                Költség
              </Th>
              </>
            ) : ""}
            <Th
              sorted={sortBy === 'resultPrice'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('resultPrice')}
            >
              Eredmény (Ft)
            </Th>
            <Th
              sorted={sortBy === 'resultPercentage'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('resultPercentage')}
            >
              Eredmény (%)
            </Th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              <td colSpan={Object.keys(data).length === 0 ? 1 : Object.keys(data[0]).length}>
                <Text weight={500} align="center">
                  Nincs találat
                </Text>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}