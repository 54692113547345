import React, { useEffect, useRef, useState } from 'react'
import { AuthenticationForm } from './AuthenticationForm'
import { getAuth, signInWithPopup, GoogleAuthProvider, sendSignInLinkToEmail, signInWithEmailLink, isSignInWithEmailLink, User, setPersistence, browserSessionPersistence, browserLocalPersistence } from "firebase/auth";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useFirestore } from '../../hooks/useFirebase'
import { useStore } from '../../hooks/useStore';
import { Button, Input, Modal } from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';
import { AlertCircle, Check } from 'tabler-icons-react';





function Login() {
    
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    let navigate = useNavigate();
    const db = useFirestore()
    let userAlreadyRegistered = false
    let user_at_google_login: User

    const [email, setEmail] = useState(localStorage.getItem("emailForSignIn") || "")
    const [opened, setOpened] = useState(false)
    const [codeValue, setCodeValue] = useState("")
    const [isLoading, setisLoading] = useState(false)

    const [aszf, setAszf] = useState(true)
    const [emailError, setEmailError] = useState(false)
    const recaptchaRef: any = useRef()

    let reCaptchaPassed = true

    
    function loginWithGoogle(){
        console.log("loginwithgoogle")
        setisLoading(true)
        signInWithPopup(auth, provider)
        .then(async (result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential?.accessToken;
            // The signed-in user info.
            localStorage.setItem("emailForSignIn", result.user.email as string)
            // await setDoc(doc(db, "user", user.uid), {
            //     user_id: user.uid,
            //     name: user.displayName,
            //     email: user.email
            //     // role: "user",
            //     // company: "példa company",
            //     // settings: [{ active_user: false }],
            //     // database: [{title: "table1", icon: ""}, {title: "table2", icon: ""}, {title: "table3", icon: ""}, {title: "table4", icon: ""}]
            //     // database_columns: [{ "table1": [ "oszlop1", "oszlop2" ]}],
            //     // project_numbers: []
            // }, {merge: true}); // this makes sure that it dont fully overwrite the document
            console.log(result.user)
            const user = result.user
            // localStorage.setItem("user", JSON.stringify(result.user))
            user_at_google_login = user
            checkIfUserAlreadyRegistered(result)

            if(userAlreadyRegistered){ // to function
                const displayName = user.displayName == null ? user.email?.split("@")[0] : user.displayName
                let lastSignInTime =  user.metadata ? user.metadata.lastSignInTime : new Date
        
                console.warn("the user object that gets saved doesn't include every info about user")
                localStorage.setItem("user", JSON.stringify({uid: user.uid, email: user.email, lastLoginAt: lastSignInTime, photoURL: user.photoURL, displayName: user.displayName}))
                localStorage.setItem("photoURL", JSON.stringify(user.photoURL))
                localStorage.setItem("displayName", JSON.stringify(displayName))
            } // else is handled in registerAndLoginUser function

            // console.log(user)
            // console.warn("the user object that gets saved doesn't include every info about user")
            // localStorage.setItem("user", JSON.stringify({uid: user.uid, email: user.email, lastLoginAt: user.metadata.lastSignInTime, photoURL: user.photoURL, displayName: user.displayName}))
            // localStorage.setItem("photoURL", JSON.stringify(user.photoURL))
            // localStorage.setItem("displayName", JSON.stringify(user.displayName))
            // setisLoading(false)
            // navigate("/home")
            // ...
        }).catch((error) => {
            // Handle Errors here.
            setisLoading(false)
            console.error(error)
            const errorCode = error.code;
            const errorMessage = error.message;
            showNotification({
                id: 'sending',
                color: "red",
                title: `Hiba történt`,
                icon: <AlertCircle size={16} />,
                message: 'Nem sikerült téged bejelentkeztetni / regisztrálni',
                autoClose: false,
            });
            // The email of the user's account used.
            // const email = error.customData.email;
            // // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
    }
    
    async function checkForm(){
        console.log("checkform")
        if(/^\S+@\S+$/.test(email) == false){
          setEmailError(true)
          return false
        } else {
          setEmailError(false)
        }
        if(aszf !== true){
          showNotification({
            id: 'load-data',
            color: "red",
            title: `Ászf probléma`,
            message: 'Kérlek fogadd el az általános szerződési feltételeket a folytatáshoz!',
            autoClose: false,
          });
          return false
        }
        recaptchaRef.current.execute().then(() => {
            // console.log(reCaptchaPassed)
            if(reCaptchaPassed){
                loginWithEmailPasswordless()
            } else {
                alert("Recaptcha error")
            }
        })
    }

    async function loginWithEmailPasswordless(){
        console.log("loginwithemailandpassword")
        showNotification({
            id: 'sending',
            color: "green",
          title: `Email küldése`,
          loading: true,
          icon: <AlertCircle size={16} />,
          message: 'Az általad megadott email címre kiküldtük a megerősítő linket',
          autoClose: false,
        });
        const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: window.location.origin + "/login",
            // This must be true.
            handleCodeInApp: true,
        };
        const auth = getAuth();
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem('emailForSignIn', email);
            updateNotification({
                id: 'sending',
                title: `Email elküldve`,
                icon: <Check size={16} />,
                message: 'Kiküldtünk az általad megadott email címre egy megerősítő linket amire kattinva be is tudsz jelentkezni. Kérlek ellenőrizd a spam és kuka mappákat is! Az email megérkezése esetenként akár 30 másodpercbe is telhet.',
                autoClose: false,
            });
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            updateNotification({
                id: 'sending',
                color: "red",
                title: `Hiba történt`,
                icon: <AlertCircle size={16} />,
                message: 'Nem sikerült kiküldeni az általad megadott email címre egy megerősítő linket',
                autoClose: false,
            });
            // ...
        });
    }
    
    function confirmLoginEmailPasswordless(){
        console.log("confirmLoginEmailPasswordless")
        setisLoading(true)
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email:any = window.localStorage.getItem('emailForSignIn');
        if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Kérlekk add meg az email címed amivel belépni / regisztrálni szeretnél');
        }
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email, window.location.href)
        .then(async (result) => {

            console.log(result.user)
            localStorage.setItem("user", JSON.stringify(result.user))
            checkIfUserAlreadyRegistered(result)

        })
        .catch((error: { code: any; message: any; }) => {
            setisLoading(false)
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            showNotification({
            id: 'load-data',
            color: "red",
            title: `Hiba történt`,
            icon: <AlertCircle size={16} />,
            message: 'Nem sikerült bejelentkezned / regisztrálnod',
            autoClose: false,
            });
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
        });
    }

    async function checkIfUserAlreadyRegistered(result:any){
        console.log("checkIfUserAlreadyRegistered")
        console.log(result.user)
        // // check if user already registered
        const q1 = query(collection(db, "user"), where("email", "==", result.user.email));
        const querySnapshot1 = await getDocs(q1);
        console.log(querySnapshot1.docs.length)
        if(querySnapshot1.docs.length == 0){
          setisLoading(false)
          setOpened(true)
          userAlreadyRegistered = false
        } else {
          userAlreadyRegistered = true
          registerAndLoginUser()
        }

        // localStorage.setItem("user", JSON.stringify(result.user))
    }

    async function checkRegistrationCode(){
        console.log("checkRegistrationCode")
        // checking registration code from database
        const email = localStorage.getItem("emailForSignIn") || ""
        console.log(email)
        const q2 = query(collection(db, "code"), where("code", "==", codeValue), where("email", "==", email));
        const querySnapshot2 = await getDocs(q2);
        // console.log(querySnapshot.docs.length)
        if(querySnapshot2.docs.length !== 1){
          updateNotification({
            id: 'load-data',
            color: "red",
            title: `Kód nem található`,
            icon: <AlertCircle size={16} />,
            message: 'Az általad megadott regisztrációs kód nem található a rendszerben, kérlek érdeklődj a rendszergazdádnál',
            autoClose: false,
          });
          return false
        } else {
            setTimeout(() => {
                updateNotification({
                  id: 'load-data',
                  title: 'Siker!',
                  message: 'Átírányítunk a főoldalra...',
                  icon: <Check size={16} />,
                  autoClose: 3000,
                });
            }, 1000);
        }
    }

    async function registerAndLoginUser(){
        console.log("registerAndLoginUser")
        if(!userAlreadyRegistered){
            showNotification({
                id: 'load-data',
                loading: true,
                title: `Kód ellenőrzése`,
                message: 'Ellenőrizzük a kódot hogy létezik-e',
                autoClose: false,
                disallowClose: true,
              });
            localStorage.setItem("newuser", "true")
            const result = await checkRegistrationCode()
            if(result === false){return false}
        }
        try {
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            const user = user_at_google_login
            // const user = await JSON.parse(localStorage.getItem("user")!)
            console.log(user)
            await setDoc(doc(db, "user", user.uid), {
                user_id: user.uid,
                email: user.email
            }, {merge: true}); // this makes sure that it dont fully overwrite the document

            // console.log('%cLogin.tsx line:247 user.metadata', 'color: #007acc;', user.metadata);
            const displayName = user.displayName == null ? user.email?.split("@")[0] : user.displayName
            let lastSignInTime =  user.metadata ? user.metadata.lastSignInTime : new Date
    
            console.warn("the user object that gets saved doesn't include every info about user")
            localStorage.setItem("user", JSON.stringify({uid: user.uid, email: user.email, lastLoginAt: lastSignInTime, photoURL: user.photoURL, displayName: user.displayName}))
            localStorage.setItem("photoURL", JSON.stringify(user.photoURL))
            localStorage.setItem("displayName", JSON.stringify(displayName))
            setTimeout(() => {
                console.log("navigating /home")
                navigate(`/home`)
                // Clear email from storage.
                window.localStorage.removeItem('emailForSignIn');
            }, 3000);
        } catch (error){
            // Handle Errors here.
            setisLoading(false)
            console.error(error)
            showNotification({
                id: 'sending',
                color: "red",
                title: `Hiba történt`,
                icon: <AlertCircle size={16} />,
                message: 'Nem sikerült téged bejelentkeztetni / regisztrálni. Kérlek próbáld újra!',
                autoClose: false,
            });
        }
    }
    
    useEffect(() => {
        // Confirm the link is a sign-in with email link.
        if (isSignInWithEmailLink(auth, window.location.href)) {
            confirmLoginEmailPasswordless()
        }
    }, [])


    return (
        <>
            <div style={{"display": "flex", "justifyContent": "center", "alignItems": "center", "height": '100vh'}}>
                <AuthenticationForm
                    loginwithgoogle={loginWithGoogle}
                    email={email}
                    setEmail={setEmail}
                    emailError={emailError}
                    recaptchaRef={recaptchaRef}
                    reCaptchaPassed={reCaptchaPassed}
                    aszf={aszf}
                    setAszf={setAszf}
                    checkForm={checkForm}
                />
            </div>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title="Regisztrációs kód"
                centered
                closeOnClickOutside={false}
                withCloseButton={false}
            >
                <h4>A regisztrációhoz szükséged lesz egy kódra amit emailben kaptál meg tőlönk</h4>
                <Input
                value={codeValue}
                onChange={(event:any) => setCodeValue(event.target.value)}
                />
                <br />
                <Button style={{"width": "100%"}} variant={"gradient"} gradient={{ from: 'teal', to: 'green', deg: 105 }} onClick={registerAndLoginUser}>Regisztrálok</Button>
            </Modal>
            { isLoading ? <div className='loader-backdrop dashboard'><div className="loader"></div></div> : "" }
        </>
    )
}

export default Login