import { ScrollArea } from '@mantine/core'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import Dashboard from '../../components/dashboard'
import { useAuth, useFirestore } from '../../hooks/useFirebase'
import Header from './components/Header'
import List from './components/List'

function OwnContracts() {

  const [projectNumbers, setProjectNumbers] = useState<{value:string, label:string}[]>([])

  function getProjectNumbers(){
    const db = useFirestore()
    const user = useAuth()
    const q = query(collection(db, "project"), where("user_id", "==", user.uid));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const snapshot: {value:string, label:string}[] = []
      // console.log(querySnapshot.docs)
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        // snapshot.push({
        //   id: doc.id,
        //   data: {
        //     comment: doc.data().comment,
        //     created_at: doc.data().created_at,
        //     project_number: doc.data().project_number,
        //     user_id: doc.data().user_id
        //   }
        // })
        snapshot.push({
          value: doc.data().project_number,
          label: doc.data().project_number
        })
      });
      // console.log(snapshot)
      setProjectNumbers(snapshot)
    })
    return unsubscribe
  }
  

  useEffect(() => {
    const unsubscribe = getProjectNumbers()
    return () => unsubscribe()
  }, [])
  

  return (
    <>

      <Header projectNumbers={projectNumbers} modeIsOwnContrats={true}/>
      <List projectNumbers={projectNumbers} modeIsOwnContracts={true}/>
      
    </>
  )
}

export default OwnContracts