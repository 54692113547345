import { Loader, Overlay, Tabs } from '@mantine/core'
import { useSessionStorage } from '@mantine/hooks';
import React, { useEffect, useState } from 'react'
import { Coin, CurrencyDollar, Notebook, Search } from 'tabler-icons-react'
import CostBudget from './components/CostBudget'
import { useAuth, useFirestore, useUserCompany } from '../../hooks/useFirebase'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import CostResult from './components/CostResult'
import { DailyPostType } from '../../interfaces/dailyPostType'
import { ContractType } from '../../interfaces/contractType'
import Query from './components/Query'
import { TabsValue } from '@mantine/core/lib/Tabs';

export default function Statistics() {

  const [projectNumbersList, setProjectNumbersList] = useState<string[]>([])
  const [selectedProjectNumber, setSelectedProjectNumber] = useState<string | null>(null)

  const [dailyPosts, setDailyPosts] = useState<DailyPostType[]>([])
  const [plannedCosts, setPlannedCosts] = useState<DailyPostType[]>([])
  const [ownContracts, setOwnContracts] = useState<ContractType[]>([])
  const [subcontractorContracts, setSubcontractorContracts] = useState<ContractType[]>([])

  const [isLoading, setIsLoading] = useState(false)

  const [currentlySelectedTab, setCurrentlySelectedTab] = useSessionStorage<TabsValue>({ key: 'statistics-currentlySelectedTab', defaultValue: 'costBudget' });


  async function getProjectNumbers(){
    const db = useFirestore()
    const user = useAuth()

    const docRef = doc(db, "user", user.uid);
    const docSnap = await getDoc(docRef);

    const projectNumbers = docSnap.data()!.project_numbers
    setProjectNumbersList(projectNumbers)

  }

  async function getDataByProjectNumber(){

    const db = useFirestore()
    const company = await useUserCompany()


    // -------------------------------------------------get dailyPosts data--------------------------------------------------
    let q = query(collection(db, "daily"), where("company", "==", company), where("project_number", "==", selectedProjectNumber));

    let querySnapshot = await getDocs(q);
    let dailyPostsList: DailyPostType[] = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      dailyPostsList.push({ id: doc.id, ...doc.data() } as DailyPostType)
    });


    // ------------------------------------------------get plannedCosts data----------------------------------------------------
    q = query(collection(db, "plan"), where("company", "==", company), where("project_number", "==", selectedProjectNumber));

    querySnapshot = await getDocs(q);
    let plannedCostsList: DailyPostType[] = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      plannedCostsList.push({ id: doc.id, ...doc.data() } as DailyPostType)
    });


    // ------------------------------------------------get ownContracts data----------------------------------------------------
    q = query(collection(db, "contract"), where("contractor", "==", company), where("project_number", "==", selectedProjectNumber));

    querySnapshot = await getDocs(q);
    let ownContractsList: ContractType[] = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      ownContractsList.push({ id: doc.id, ...doc.data() } as ContractType)
    });


    // ------------------------------------------------get subcontractorContracts data----------------------------------------------------
    q = query(collection(db, "contract"), where("customer", "==", company), where("project_number", "==", selectedProjectNumber));

    querySnapshot = await getDocs(q);
    let subcontractorContractsList: ContractType[] = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      subcontractorContractsList.push({ id: doc.id, ...doc.data() } as ContractType)
    });

    
    setDailyPosts(dailyPostsList)
    setPlannedCosts(plannedCostsList)
    setOwnContracts(ownContractsList)
    setSubcontractorContracts(subcontractorContractsList)
  }


  useEffect(() => {
    getProjectNumbers()
  }, [])

  useEffect(() => {
    if(selectedProjectNumber){
      setIsLoading(true)
      setTimeout(() => {
        getDataByProjectNumber()
      }, 100);
    } 
  }, [selectedProjectNumber])
  
  

  return (
    <>
    <Tabs color="green" radius="md" defaultValue={JSON.parse(sessionStorage.getItem("statistics-currentlySelectedTab") || '"costBudget"')} keepMounted={true} onTabChange={setCurrentlySelectedTab}>
    <Tabs.List>
      {/* set underline color by: color="indigo" */}
      <Tabs.Tab value="costBudget" icon={<CurrencyDollar size="1rem" />}>Költség keret</Tabs.Tab>
      <Tabs.Tab value="costResult" icon={<Notebook size="1rem" />}>Eredmény</Tabs.Tab>
      <Tabs.Tab value="costQuery" icon={<Search size="1rem" />}>Lekérdező</Tabs.Tab>
    </Tabs.List>

    <Tabs.Panel value="costBudget" pt="xs">
        <CostBudget
          projectNumbersList={projectNumbersList}
          selectedProjectNumber={selectedProjectNumber}
          setSelectedProjectNumber={setSelectedProjectNumber}
          dailyPosts={dailyPosts}
          plannedCosts={plannedCosts}
          ownContracts={ownContracts}
          subcontractorContracts={subcontractorContracts}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
    </Tabs.Panel>

    <Tabs.Panel value="costResult" pt="xs">
        <CostResult
          projectNumbersList={projectNumbersList}
          selectedProjectNumber={selectedProjectNumber}
          setSelectedProjectNumber={setSelectedProjectNumber}
          dailyPosts={dailyPosts}
          plannedCosts={plannedCosts}
          ownContracts={ownContracts}
          subcontractorContracts={subcontractorContracts}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
    </Tabs.Panel>

    <Tabs.Panel value="costQuery" pt="xs">
      <Query></Query>
    </Tabs.Panel>
  </Tabs>
  </>
  )
}
