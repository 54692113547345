import { collection, getDocs } from "firebase/firestore";
import { useFirestore } from "./useFirebase";

 export async function useAuthMyServer(){
    const db = useFirestore()
    // get the token from database to make request to my server
    let token = ""
    const auth_backend = await getDocs(collection(db, "auth-backend"));
    auth_backend.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        token = doc.data().token;
    });
    return token
 }