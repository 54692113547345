import { Loader, ScrollArea } from '@mantine/core'
import { collection, DocumentData, getDocs, limit, onSnapshot, orderBy, query, Unsubscribe, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Template } from 'tabler-icons-react'
import { yellow_button } from '../../../../App'
import { useAddContractProgressToDaily } from '../../../../hooks/useAddContractProgressToDaily'
import { useAddSubcontractorContractsToPlan } from '../../../../hooks/useAddSubcontractorContractsToPlan'
import { useAuth, useFirestore } from '../../../../hooks/useFirebase'
import { DailyPostType } from '../../../../interfaces/dailyPostType'
import BuiltyCard from "./Card"

interface CardsProps {
  posts: DailyPostType[],
  setPosts: React.Dispatch<React.SetStateAction<DailyPostType[]>>,
  // projectNumbers: object[],
  selectedProjectNumber: string | null,
  mode: string,
  modeIsDelete: boolean,
  // withAddedContractProgress: boolean
}

function Cards({ posts, setPosts, selectedProjectNumber, mode, modeIsDelete }: CardsProps) {

  // const [postsWithAddedContractProgress, setPostsWithAddedContractProgress] = useState<DailyPostType[]>([])
  const [isLoading, setIsLoading] = useState(true)

  async function fetchDailyPosts(){
    const db = useFirestore()
    const user = useAuth()

    let q
    if(selectedProjectNumber == null){
      console.log("selectedProjectNumber", selectedProjectNumber)
      // checking the mode
      q = query(collection(db, mode == "dailyPost" ? "daily" : "plan"), where("user_id", "==", user.uid), orderBy("date", "desc"), limit(20));
    } else {
      console.log("lefutott projectNumber", selectedProjectNumber)
      q = query(collection(db, mode == "dailyPost" ? "daily" : "plan"), where("user_id", "==", user.uid), where("project_number", "==", selectedProjectNumber));
    }

    // const querySnapshot = await getDocs(q);
    // let list: { id: string, data: any}[] = []
    // querySnapshot.forEach((doc) => {
    //   // doc.data() is never undefined for query doc snapshots
    //   console.log(doc.id, " => ", doc.data());
    //   list.push({ id: doc.id, data: doc.data() })
    // });
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {

      let list: DailyPostType[] = []
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log("jaja", doc.id, " => ", doc.data());
        // console.log(`returned docs: ${querySnapshot.size}`);
        list.push({ id: doc.id, ...doc.data() } as DailyPostType)
      });
      console.log("doc changes", querySnapshot.docChanges());

      // sort array by date
      list = list.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        const result = Number(new Date(b.date.seconds*1000)) - Number(new Date(a.date.seconds*1000));
        return result;
      });
  
      // if(mode == "dailyPost"){
      //   list = await useAddContractProgressToDaily(list, "returnOnlyInputDailys");
      // }
      if(mode == "plannedCost" && location.pathname == '/planned-costs' && list.length !== 0){
        if(selectedProjectNumber == sessionStorage.getItem("plannedCosts-selectedProjectNumber")){
          list = await useAddSubcontractorContractsToPlan(list)
        }
      }

      // console.log("querySnapshot.size", querySnapshot.size);
      setPosts(list)
      setTimeout(() => {
        setIsLoading(false)
      }, 300);

    });

    console.log(`subscribed to daily posts ${selectedProjectNumber}`)
    return unsubscribe

  }

  // useEffect(() => {
  //   const f = async () => {
  //     if(posts.length !== 0 && postsWithAddedContractProgress.length === 0){
  //       const p = JSON.parse(JSON.stringify(posts))
  //       const list: DailyPostType[] = await useAddContractProgressToDaily(p, "returnOnlyInputDailys");
  //       setPostsWithAddedContractProgress(list)
  //     }
  //   }
  //   f()
  // }, [withAddedContractProgress])
  
  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;

    setIsLoading(true)

    fetchDailyPosts().then((unsub) => {
      unsubscribe = unsub;
    })

    return () => {
      if(unsubscribe){
        unsubscribe()
        console.log(`unsubscribed from daily posts ${selectedProjectNumber} listening`);
      }
    }
  }, [selectedProjectNumber])
  

  // useEffect(() => {
  //   return () => {
  //     if(unsubscribe){
  //       unsubscribe()
  //       console.log("unsubscribed from daily posts listening");
  //     }
  //   }
  // }, [])
  
  return (
    <>
    <ScrollArea style={{"height": "77.5vh"}}>
        <div style={{ "paddingLeft": "0px","backgroundColor": "", "borderRadius": "30px", display: "flex", flexWrap: "wrap", gap: "2vw", padding: "20px 0 20px 0", justifyContent: "stretch"}}>
            {/* {withAddedContractProgress
              ?
              postsWithAddedContractProgress.map((post) =>
                <BuiltyCard key={post.id} data={post} mode={mode} modeIsDelete={modeIsDelete} docid={post.id} withAddedContractProgress={withAddedContractProgress}></BuiltyCard>)
                : } */}
            {
              isLoading ?
              <div style={{height: "70vh", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <Loader size={"xl"} color={mode == "dailyPost" ? "green" : yellow_button}></Loader>
              </div> :
              posts.map((post, index) => 
                <BuiltyCard key={post.id} data={post} docid={post.id} mode={mode} modeIsDelete={modeIsDelete}></BuiltyCard>)
            }
            {posts.length === 0 && isLoading == false ? (
              <div style={{height: "70vh", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <Template size={30}/>
                <h3>Nincs találat</h3>
              </div>
            ) : ""}
        </div>
    </ScrollArea>
    </>
  )
}

export default Cards


