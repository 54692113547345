import { Button, Center, Paper, RingProgress, Text, Space, ActionIcon, Modal } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { deleteDoc, doc } from 'firebase/firestore'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AlertCircle, Check, Trash } from 'tabler-icons-react'
import { useFirestore } from '../../../../hooks/useFirebase'
import { useCapitalizeFirstLetter, useNumberWithSpaces, weekday } from '../../../../hooks/utils'
import AnimatedRingProgress from '../../../../components/Charts/AnimatedRingProgress/AnimatedRingProgress'


interface ContractProgressCardProps {
    modeIsOwnContracts: boolean,
    contractProgressDocId: string,
    projectNumber: string,
    contractor: string,
    customer: string,
    forint_readiness: number,
    percentage_readiness: number,
    created_at: {
        seconds: number,
        nanoseconds: number
    },
    modeIsDelete: boolean,
    deleteDataFromFirestore: (contractProgressDocId: string) => void
}

export default function ContractProgressCard({ modeIsOwnContracts, contractProgressDocId, projectNumber, contractor, customer, forint_readiness, percentage_readiness, created_at, modeIsDelete, deleteDataFromFirestore }: ContractProgressCardProps) {
  
    const navigate = useNavigate()

    const [deleteModalIsOpened, setDeleteModalIsOpened] = useState(false)

    
    return (
    <>
    <Paper shadow="xs" p="md" withBorder style={{ backgroundColor: "#25262b", flex: 1, maxWidth: "500px"}}>
    <div style={{display: "flex", gap: "20px", justifyContent: "space-between"}}>
        <div>
            <div style={{display: "flex", gap: "15px", alignItems: "center"}}>
                <h3 style={{margin:'0px', marginBottom: "5px"}}>
                    {useCapitalizeFirstLetter(new Intl.DateTimeFormat('hu-HU', { month: "long", day: "2-digit"}).format(created_at.seconds ? created_at.seconds*1000 : Date.now())).replace(".", "")}
                    <br />
                    {weekday[new Date(created_at.seconds*1000).getDay()]}
                </h3>
            </div>
            <Text>{projectNumber}</Text>
            <Text>{modeIsOwnContracts ? customer : contractor}</Text>
            <Space h={"md"}/>
            <Text>Rögzítéskori készültség:</Text>
            <Text weight={"bold"}>{useNumberWithSpaces(forint_readiness)} Ft</Text>
            <Space h={"lg"}/>
        </div>
        
        <div>
        {/* <RingProgress
        size={130}
        roundCaps
        thickness={12}
        sections={[{ value: percentage_readiness, color: modeIsOwnContracts ? "cyan" : "green" }]}
        label={
        <Center>
            <strong>
                <Text size={'xl'}>{Math.round(percentage_readiness)} %</Text>
            </strong>
        </Center>
        }
        /> */}
        <AnimatedRingProgress percentage_readiness={percentage_readiness} modeIsOwnContracts={modeIsOwnContracts}></AnimatedRingProgress>
        </div>
    </div>

    <div style={{display:"flex", gap: "20px"}}>
        <Button
            variant='light'
            fullWidth
            onClick={() => navigate(`/${modeIsOwnContracts ? "own" : "subcontractor"}-contract-progress/watch/${contractProgressDocId}`)}
            color={modeIsOwnContracts ? "cyan" : "green"}
        >
            Megnézem
        </Button>
        {modeIsDelete ? (
            <ActionIcon size={36} variant="light" color={"red"} onClick={() => setDeleteModalIsOpened(true)}>
                <Trash size={16} />
            </ActionIcon>
        ) : ""}
    </div>
    </Paper>

    <Modal opened={deleteModalIsOpened} onClose={() => setDeleteModalIsOpened(false)} title="Biztosan törlöd?">
        <div style={{display: "flex", gap: "20px"}}>
        <Button variant='light' color={"gray"} fullWidth onClick={() => setDeleteModalIsOpened(false)}>Mégsem</Button>
        <Button variant='light' color={"red"} fullWidth onClick={() => {setDeleteModalIsOpened(false); deleteDataFromFirestore(contractProgressDocId)}}>Törlés</Button>
        </div>
    </Modal>
    </>
  )
}
