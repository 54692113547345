import { Card, Space, Text, useMantineTheme, ScrollArea, Table, Tooltip, Loader, TextInput  } from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import { IconClock, IconMoneybag, IconSearch } from '@tabler/icons'
import { IconPointFilled } from '@tabler/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import { ArrowDown, ArrowUp, DeviceFloppy, DotsCircleHorizontal, DotsDiagonal, InfoCircle, InfoSquare } from 'tabler-icons-react'
import BuiltyBarChart from '../../../../../../components/Charts/BuiltyBarChart'
import BuiltyLineChart from '../../../../../../components/Charts/BuiltyLineChart'
import BuiltyPieChart from '../../../../../../components/Charts/BuiltyPieChart'
import { resultStatisticsObject } from '../../../../../../components/QueryGraphs/QueryGraphs'
import { month, useNumberWithSpaces, weekday } from '../../../../../../hooks/utils'
import QueryCardResultTable from '../QueryCardResultTable'
import QueryCardYearlyTable from '../QueryCardYearlyTable'

interface builtyQueryCardProps {
   type: "daily" | "weekly" | "monthly" | "yearly";
   mode: "costs" | "income" | "result";
   date: Date;
   data?: {
        [key: string]: number;
    };
   resultData?: {
        [key: string]: resultStatisticsObject;
    };
    showOnlyGraph?: boolean
}
export default function BuiltyQueryCard({ type, mode, date, data, resultData, showOnlyGraph }: builtyQueryCardProps) {

  const theme = useMantineTheme()

//   console.log("data in BuiltyQueryCard", data)
  

  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const alreadyRendered = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        
        // if(alreadyRendered.current) return
        setTimeout(() => {
            setIsVisible(entry.isIntersecting);
            
            // if(entry.isIntersecting){
            //     alreadyRendered.current = true;
            // }
        }, 500);

      },
      { threshold: 0.6, } // Adjust the threshold as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

//   const { ref, entry } = useIntersection({
//     // root: containerRef.current,
//     threshold: 1,
//   });
//   if(data === undefined){
//     return <NoQueryData></NoQueryData>
//   }

  if(type === "daily"){
    if(data){
        return (
         <Card shadow="sm" p="md" radius="md" withBorder style={{ backgroundColor: "#25262b", width: "calc(25% - 15px)", display: "flex", flexDirection: "column", alignItems: "center"}}>
             {/* <Text size={'xl'} style={{margin: 0, marginBottom: "10px", fontWeight: "bold"}}>Szerda, Szeptember 21. 2023</Text> */}
             <Text size={'xl'} style={{margin: 0, marginBottom: "10px", fontWeight: "bold"}}>{weekday[date.getDay()]}, {month[date.getMonth()]} {date.getDate()}. {date.getFullYear()}</Text>
             <Text size={'lg'} style={{margin: 0, marginBottom: "15px"}}>Összesen: {useNumberWithSpaces(Object.values(data).reduce((a, b) => a + b, 0))} Ft</Text>
             <div ref={ref}>
                 {isVisible ?
                 <BuiltyPieChart
                     width={"280px"}
                     height={"280px"}
                     padding={''}
                     label={''}
                     labels={Object.keys(data)}
                     data={[]}
                     datasets={[
                         {
                             data: Object.values(data),
                             backgroundColor: Object.keys(data).map(item => generateColorFromSeed(item)),
                             borderColor: Object.keys(data).map(item => generateColorFromSeed(item, true)),
                             borderWidth: 1,
                         }
                     ]}
                 /> : <div style={{width: "280px", height: "280px", display: "flex", justifyContent: "center", alignItems: "center"}}><Loader size={"lg"}></Loader></div>}
             </div>
 
             <Space mb={"md"}/>
 
             {/* <ScrollArea h={360} offsetScrollbars style={{paddingLeft: "7px", marginBottom: "-20px"}}> */}
 
                 {Object.entries(data).sort(([a, b], [c, d]) => d - b).map(([companyName, costNumber], index) => (
                     <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} key={index}>
                         <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                 <IconPointFilled size={34} style={{color: generateColorFromSeed(companyName, false, 0.6)}}></IconPointFilled>
                                 <Text size={'md'}>{companyName.slice(0, 30)}{companyName.length > 30 ? "..." : ""}</Text>
                         </div>
                         <Text style={{marginTop: "-2px", marginBottom: "10px", fontWeight: "bold"}}>{useNumberWithSpaces(costNumber)} Ft</Text>
                     </div>
                 ))}
 
         </Card>
       )
    }
  }

  if(type === "weekly"){

    const endDayOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 6);

    function getWeekNumber(date: Date): number {
        const onejan = new Date(date.getFullYear(), 0, 1);
        const millisecondsInWeek = 604800000; // 7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
        const weekNumber = Math.ceil(((date.getTime() - onejan.getTime()) / millisecondsInWeek) + onejan.getDay() / 7);
        return weekNumber;
    }

    if(mode === "result" && resultData !== undefined){
        return (
            <Card shadow="sm" p={"md"} radius="md" withBorder style={{ backgroundColor: "#25262b", width: "calc(50% - 15px)"}}>
            {/* <Text size={'xl'} style={{margin: 0, marginBottom: "10px", fontWeight: "bold"}}>Szerda, Szeptember 21. 2023 - Szerda, Szeptember 28. 2023 (12.hét)</Text> */}
            <div>
                <div style={{display: "flex", justifyContent: "flex-start"}}>
                    <Text size={'xl'} style={{margin: 0, marginBottom: "10px", fontWeight: "bold"}}>{weekday[date.getDay()]}, {month[date.getMonth()]} {date.getDate()} {date.getFullYear()}</Text>
                    <Text size={'xl'} style={{margin: 0, marginBottom: "10px", fontWeight: "bold", padding: "0 10px"}}>-</Text>
                    <Text size={'xl'} style={{margin: 0, marginBottom: "10px", fontWeight: "bold"}}>{weekday[endDayOfWeek.getDay()]}, {month[endDayOfWeek.getMonth()]} {endDayOfWeek.getDate()} {endDayOfWeek.getFullYear()}</Text>
                    <Text size={'xl'} style={{margin: 0, marginBottom: "10px", fontWeight: "bold", paddingLeft: "10px"}}>({getWeekNumber(date)}.hét)</Text>
                </div>
                <Text size={'lg'} style={{margin: 0, marginBottom: "15px"}}>Összesen: {useNumberWithSpaces(Object.values(resultData).reduce((a, b) => a + b.result, 0))} Ft</Text>
            </div>

            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around"}}>

            <div>
                <div ref={ref}>
                {isVisible ?
                <BuiltyBarChart
                    width={"43rem"}
                    maxHeight={"200px"}
                    padding={''}
                    label={''}
                    labels={Object.keys(resultData)}
                    data={[]}
                    showLabel={false}
                    hightlightZeroLine
                    datasets={[
                        {
                            data: Object.values(resultData).map(item => item.result),
                            backgroundColor: Object.keys(resultData).map(item => generateColorFromSeed(item)),
                            borderColor: Object.keys(resultData).map(item => generateColorFromSeed(item, true)),
                            borderWidth: 1,
                            maxBarThickness: 60,
                            stack: "1",
                        },
                    ]}
                />
                : <div style={{width: "43rem", height: "200px", display: "flex", justifyContent: "center", alignItems: "center"}}><Loader size={"lg"}></Loader></div>}

                <QueryCardResultTable
                    sum={Object.values(resultData).reduce((a, b) => a + b.result, 0)}
                    nameType='text'
                    data={Object.entries(resultData).map(([key, value]) => {
                        return {customer: key, cost: value.costs, income: value.income, resultPercentage: value.resultPercentage, resultPrice: value.result, overheadCost: value.overheadCosts, otherCost: value.otherCosts}
                    }).sort((a, b) => b.resultPrice - a.resultPrice)}
                />
                </div>
            </div>

            </div>
        </Card>
        )
    }

    if(data){
        return (
            <Card shadow="sm" p={"md"} radius="md" withBorder style={{ backgroundColor: "#25262b", width: "calc(50% - 15px)"}}>
              {/* <Text size={'xl'} style={{margin: 0, marginBottom: "10px", fontWeight: "bold"}}>Szerda, Szeptember 21. 2023 - Szerda, Szeptember 28. 2023 (12.hét)</Text> */}
              <div>
                  <div style={{display: "flex", justifyContent: "flex-start"}}>
                      <Text size={'xl'} style={{margin: 0, marginBottom: "10px", fontWeight: "bold"}}>{weekday[date.getDay()]}, {month[date.getMonth()]} {date.getDate()} {date.getFullYear()}</Text>
                      <Text size={'xl'} style={{margin: 0, marginBottom: "10px", fontWeight: "bold", padding: "0 10px"}}>-</Text>
                      <Text size={'xl'} style={{margin: 0, marginBottom: "10px", fontWeight: "bold"}}>{weekday[endDayOfWeek.getDay()]}, {month[endDayOfWeek.getMonth()]} {endDayOfWeek.getDate()} {endDayOfWeek.getFullYear()}</Text>
                      <Text size={'xl'} style={{margin: 0, marginBottom: "10px", fontWeight: "bold", paddingLeft: "10px"}}>({getWeekNumber(date)}.hét)</Text>
                  </div>
                  <Text size={'lg'} style={{margin: 0, marginBottom: "15px"}}>Összesen: {useNumberWithSpaces(Object.values(data).reduce((a, b) => a + b, 0))} Ft</Text>
              </div>
  
              <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around"}}>
  
              <div>
                  <div ref={ref}>
                  {isVisible ?
                  <BuiltyPieChart
                      width={"420px"}
                      height={"420px"}
                      padding={''}
                      label={''}
                      labels={Object.keys(data)}
                      data={[]}
                      datasets={[
                          {
                              data: Object.values(data),
                              backgroundColor: Object.keys(data).map(item => generateColorFromSeed(item)),
                              borderColor: Object.keys(data).map(item => generateColorFromSeed(item, true)),
                              borderWidth: 1,
                          }
                      ]}
                  />
                  : <div style={{width: "420px", height: "420px", display: "flex", justifyContent: "center", alignItems: "center"}}><Loader size={"lg"}></Loader></div>}
                  </div>
              </div>
  
              {/* <ScrollArea h={430} offsetScrollbars style={{paddingLeft: "7px", paddingTop: "5px"}}> */}
  
              <div>
                  {Object.entries(data).sort(([a, b], [c, d]) => d - b).map(([companyName, costNumber], index) => (
                      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} key={index}>
                          <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                  <IconPointFilled size={34} style={{color: generateColorFromSeed(companyName, false, 0.6)}}></IconPointFilled>
                                  <Text size={'md'}>{companyName.slice(0, 25)}{companyName.length > 25 ? "..." : ""}</Text>
                          </div>
                          <Text style={{marginTop: "-2px", marginBottom: "10px", fontWeight: "bold"}}>{useNumberWithSpaces(costNumber)} Ft</Text>
                      </div>
                  ))}
              </div>
  
              </div>
          </Card>
        )
    }
  }

  if(type == "monthly"){

    if(showOnlyGraph && mode === "result" && resultData !== undefined){
        const sum = Object.values(resultData).reduce((a, b) => a + b.result, 0)
        const min = Math.min(...Object.values(resultData).map(item => item.result))
        const max = Math.max(...Object.values(resultData).map(item => item.result))
    
        const averageCost = Math.round(sum / Object.values(resultData).length)
        const dispersion = max - min

        return (
            <Card shadow="sm" p={"md"} radius="md" withBorder style={{ backgroundColor: "#25262b", width: "100%"}}>

                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "20px 10px 0px 20px"}}>
                    <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "50px", paddingBottom: "10px"}}>
                        <h2 style={{margin: 0, fontWeight: "bold"}}>{date.getFullYear()} {month[date.getMonth()]}</h2>
                        {/* <h3 style={{fontWeight: "normal", margin: 0, paddingTop: "4px"}}>Összesen: {useNumberWithSpaces(sum)} Ft</h3> */}
                    </div>

                    <div ref={ref}>
                    {isVisible ? 
                    <BuiltyLineChart
                        width={"43rem"}
                        maxHeight={"200px"}
                        padding={''}
                        label={''}
                        labels={Object.keys(resultData).map(item => {
                            const startOfTheWeek = new Date(item)
                            const endOfTheWeek = new Date(startOfTheWeek.getFullYear(), startOfTheWeek.getMonth(), startOfTheWeek.getDate() + 6)
                            
                            const startString = startOfTheWeek.toLocaleDateString().replaceAll(". ", "-").replace(".", "").slice(5, 20)
                            const endString = endOfTheWeek.toLocaleDateString().replaceAll(". ", "-").replace(".", "").slice(5, 20)
                            return startString + " - " + endString
                        }).reverse()}
                        data={[]}
                        showLabel={true}
                        hightlightZeroLine
                        datasets={[
                            {
                                data: Object.values(resultData).map(item => item.result).reverse(),
                                    backgroundColor: theme.colors.green[0],
                                    borderColor: theme.colors.green[8],
                                    // borderWidth: 1,
                                    // maxBarThickness: 60,
                                    // stack: "1",
                                    pointRadius: 5,
                                    tension: 0.4,
                                    pointHitRadius: 40,
                                },
                            ]}
                    />
                    : <div style={{width: "100%", height: "200px", display: "flex", justifyContent: "center", alignItems: "center"}}><Loader size={"lg"}></Loader></div>}
                    </div>

                    <QueryCardResultTable
                        height={"300px"}
                        showAllColumns
                        sum={Object.values(resultData).reduce((a, b) => a + b.result, 0)}
                        nameType='weekRange'
                        data={Object.entries(resultData).map(([key, value]) => {
                            const startOfTheWeek = new Date(key)
                            const endOfTheWeek = new Date(startOfTheWeek.getFullYear(), startOfTheWeek.getMonth(), startOfTheWeek.getDate() + 6)

                            // const startString = startOfTheWeek.toLocaleDateString().replaceAll(". ", "-").replace(".", "")
                            // const endString = endOfTheWeek.toLocaleDateString().replaceAll(". ", "-").replace(".", "")

                            return {customer: {from: startOfTheWeek, to: endOfTheWeek}, cost: value.costs, income: value.income, resultPercentage: value.resultPercentage, resultPrice: value.result, overheadCost: value.overheadCosts, otherCost: value.otherCosts}
                        }).reverse()}
                    />
                    {/* <div>
                        {Object.entries(resultData).map(([key, value]) => 
                            <>
                            <Text>{key}: {useNumberWithSpaces(value.result)}</Text>
                            </>
                        )}
                    </div> */}

                </div>
            </Card>
        )
    }
    
    if(mode === "result" && resultData !== undefined){
        const sum = Object.values(resultData).reduce((a, b) => a + b.result, 0)
        const min = Math.min(...Object.values(resultData).map(item => item.result))
        const max = Math.max(...Object.values(resultData).map(item => item.result))
    
        const averageCost = Math.round(sum / Object.values(resultData).length)
        const dispersion = max - min

        return (
            <Card shadow="sm" p={"md"} radius="md" withBorder style={{ backgroundColor: "#25262b", width: "100%"}}>

            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px 10px 20px 20px"}}>
                <div>
                    <h2 style={{margin: 0, marginBottom: "10px", fontWeight: "bold"}}>{date.getFullYear()} {month[date.getMonth()]}</h2>

                    <div style={{marginBottom: "15px", display: "flex", alignItems: "center", gap: "15px"}}>
                        <Text size={'lg'} style={{margin: 0}}>Összesen: {useNumberWithSpaces(sum)} Ft</Text>
                        {/* <div style={{display: "flex", alignItems: "center"}}>
                            <ArrowUp size={26} color={theme.colors.red[6]}></ArrowUp>
                            <Text size={'lg'} color={theme.colors.red[6]}> +50%</Text>
                        </div>
                        <Text size={'lg'}>(eltérés az idei év átlagához képest)</Text> */}
                    </div>

                    <Space h={"lg"}></Space>

                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: "25px"}}>
                        
                        <div>
                            <Table verticalSpacing="sm">
                                <tbody>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Átlag:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(averageCost)} Ft</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Eloszlás:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(dispersion)} Ft</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Legkisebb érték:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(min)} Ft</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Legnagyobb érték:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(max)} Ft</Text>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <Text size={'lg'}>Változás az előző év <br></br> márciusához képest:</Text>
                                            <div style={{display: "flex", alignItems: "center", marginTop: "0px"}}>
                                                <Text size={'lg'} weight="bold" style={{marginRight: "10px"}}>- 553 000 200 Ft</Text>
                                                <ArrowDown size={20} color={theme.colors.green[6]}></ArrowDown>
                                                <Text size={'md'} color={theme.colors.green[6]}> -50%</Text>
                                            </div>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </Table>
                        </div>

                    </div>
                </div>


                <div ref={ref} style={{width: "75vw"}}>
                        {isVisible ?
                        <BuiltyBarChart
                            width={"71rem"}
                            maxHeight={"250px"}
                            padding={''}
                            label={''}
                            labels={Object.keys(resultData)}
                            data={[]}
                            showLabel={false}
                            hightlightZeroLine
                            datasets={[
                                {
                                    data: Object.values(resultData).map(item => item.result),
                                    backgroundColor: Object.keys(resultData).map(item => generateColorFromSeed(item)),
                                    borderColor: Object.keys(resultData).map(item => generateColorFromSeed(item, true)),
                                    borderWidth: 1,
                                    maxBarThickness: 60,
                                    stack: "1",
                                },
                            ]}
                        />
                        : <div style={{width: "71rem", height: "250px", display: "flex", justifyContent: "center", alignItems: "center"}}><Loader size={"lg"}></Loader></div>}
                        
                        <QueryCardResultTable
                            height={"350px"}
                            showAllColumns
                            sum={Object.values(resultData).reduce((a, b) => a + b.result, 0)}
                            nameType='text'
                            data={Object.entries(resultData).map(([key, value]) => {
                                return {customer: key, cost: value.costs, income: value.income, resultPercentage: value.resultPercentage, resultPrice: value.result, overheadCost: value.overheadCosts, otherCost: value.otherCosts}
                            }).sort((a, b) => b.resultPrice - a.resultPrice)}
                        />

                        </div>
            </div>
        </Card>
        )
    }

    if(data){
    const sum = Object.values(data).reduce((a, b) => a + b, 0)
    const min = Math.min(...Object.values(data).map(item => item))
    const max = Math.max(...Object.values(data).map(item => item))

    const averageCost = Math.round(sum / Object.values(data).length)
    const dispersion = max - min

    return (
            <Card shadow="sm" p={"md"} radius="md" withBorder style={{ backgroundColor: "#25262b", width: "100%"}}>
    
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px 20px 20px 20px"}}>
                    <div>
                        <h2 style={{margin: 0, marginBottom: "10px", fontWeight: "bold"}}>{date.getFullYear()} {month[date.getMonth()]}</h2>
    
                        <div style={{marginBottom: "15px", display: "flex", alignItems: "center", gap: "15px"}}>
                            <Text size={'lg'} style={{margin: 0}}>Összesen: {useNumberWithSpaces(sum)} Ft</Text>
                            {/* <div style={{display: "flex", alignItems: "center"}}>
                                <ArrowUp size={26} color={theme.colors.red[6]}></ArrowUp>
                                <Text size={'lg'} color={theme.colors.red[6]}> +50%</Text>
                            </div>
                            <Text size={'lg'}>(eltérés az idei év átlagához képest)</Text> */}
                        </div>
    
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: "25px"}}>
                            <ScrollArea h={520} offsetScrollbars style={{paddingLeft: "0px", marginTop: "0px", minWidth: "350px"}}>
                                    <Table verticalSpacing="sm">
                                        <tbody>
                                        {Object.entries(data).sort(([a, b], [c, d]) => d - b).map(([companyName, costNumber], index) => (
                                            <tr key={index}>
                                                <td style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                                        <IconPointFilled style={{color: generateColorFromSeed(companyName, false, 0.6)}}></IconPointFilled>
                                                        <Text size={'md'}>{companyName.slice(0, 30)}{companyName.length > 30 ? "..." : ""}</Text>
                                                </td>
                                                <td>
                                                    <Text size={'md'} style={{marginTop: "-2px", fontWeight: "bold"}}>{useNumberWithSpaces(costNumber)} Ft</Text>
                                                </td>
                                                {/* <td>
                                                    <Tooltip label={"Eltérés az idei év átlagához képest, ennél a cégnél"}>
                                                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                            <ArrowDown size={20} color={theme.colors.green[6]}></ArrowDown>
                                                            <Text size={'md'} color={theme.colors.green[6]}> -20%</Text>
                                                        </div>
                                                    </Tooltip>
                                                </td> */}
                                                {/* <td><InfoCircle style={{marginBottom: "-7px"}}></InfoCircle></td> */}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                            </ScrollArea>
                            
                            <div>
                                <Table verticalSpacing="sm">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Text size={'lg'}>Átlag:</Text>
                                                <Text size={'lg'} weight="bold">{useNumberWithSpaces(averageCost)} Ft</Text>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Text size={'lg'}>Eloszlás:</Text>
                                                <Text size={'lg'} weight="bold">{useNumberWithSpaces(dispersion)} Ft</Text>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Text size={'lg'}>Legkisebb érték:</Text>
                                                <Text size={'lg'} weight="bold">{useNumberWithSpaces(min)} Ft</Text>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Text size={'lg'}>Legnagyobb érték:</Text>
                                                <Text size={'lg'} weight="bold">{useNumberWithSpaces(max)} Ft</Text>
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td>
                                                <Text size={'lg'}>Változás az előző év <br></br> márciusához képest:</Text>
                                                <div style={{display: "flex", alignItems: "center", marginTop: "0px"}}>
                                                    <Text size={'lg'} weight="bold" style={{marginRight: "10px"}}>- 553 000 200 Ft</Text>
                                                    <ArrowDown size={20} color={theme.colors.green[6]}></ArrowDown>
                                                    <Text size={'md'} color={theme.colors.green[6]}> -50%</Text>
                                                </div>
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </Table>
                            </div>
                            
                        </div>
                    </div>
    
                    <div ref={ref}>
                    {isVisible ?
                    <BuiltyPieChart
                        width={"620px"}
                        height={"620px"}
                        padding={''}
                        label={''}
                        labels={Object.keys(data)}
                        data={[]}
                        datasets={[
                            {
                                data: Object.values(data),
                                backgroundColor: Object.keys(data).map(item => generateColorFromSeed(item)),
                                borderColor: Object.keys(data).map(item => generateColorFromSeed(item, true)),
                                borderWidth: 1,
                            }
                        ]}
                    /> : <div style={{width: "620px", height: "620px", display: "flex", justifyContent: "center", alignItems: "center"}}><Loader size={"lg"}></Loader></div>}
                    </div>
                </div>
            </Card>
        )
    }
  }

  if(type == "yearly"){

    if(showOnlyGraph && mode === "result" && resultData !== undefined){
        const sum = Object.values(resultData).reduce((a, b) => a + b.result, 0)
        const min = Math.min(...Object.values(resultData).map(item => item.result))
        const max = Math.max(...Object.values(resultData).map(item => item.result))
    
        const averageCost = Math.round(sum / Object.values(resultData).length)
        const dispersion = max - min

        return (
            <Card shadow="sm" p={"md"} radius="md" withBorder style={{ backgroundColor: "#25262b", width: "100%"}}>

                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "20px 10px 0px 20px"}}>
                    <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "50px", paddingBottom: "10px"}}>
                        <h2 style={{margin: 0, fontWeight: "bold", fontSize: "50px"}}>{date.getFullYear()}</h2>
                        <h3 style={{fontWeight: "normal", margin: 0, paddingTop: "4px"}}>Összesen: {useNumberWithSpaces(sum)} Ft</h3>
                    </div>

                    <div ref={ref}>
                    {isVisible ? 
                    <BuiltyLineChart
                        width={"43rem"}
                        maxHeight={"200px"}
                        padding={''}
                        label={''}
                        labels={Object.keys(resultData).map(item => {
                            return `${month[Number(item.split("-")[1]) - 1]}`
                            }).reverse()}
                            data={[]}
                            showLabel={true}
                            hightlightZeroLine
                            datasets={[
                                {
                                    data: Object.values(resultData).map(value => value.result).reverse(),
                                    backgroundColor: theme.colors.green[0],
                                    borderColor: theme.colors.green[8],
                                    // borderWidth: 1,
                                    // maxBarThickness: 60,
                                    // stack: "1",
                                    pointRadius: 5,
                                    tension: 0.4,
                                    pointHitRadius: 40,
                                },
                            ]}
                    />
                    : <div style={{width: "100%", height: "200px", display: "flex", justifyContent: "center", alignItems: "center"}}><Loader size={"lg"}></Loader></div>}
                    </div>

                    <QueryCardResultTable
                        height={"300px"}
                        showAllColumns
                        sum={Object.values(resultData).reduce((a, b) => a + b.result, 0)}
                        nameType='monthIndex'
                        data={Object.entries(resultData).map(([key, value]) => {
                            return {customer: Number(key.split("-")[1]), cost: value.costs, income: value.income, resultPercentage: value.resultPercentage, resultPrice: value.result, overheadCost: value.overheadCosts, otherCost: value.otherCosts}
                        }).reverse()}
                    />
                    {/* <div>
                        {Object.entries(resultData).map(([key, value]) => 
                            <>
                            <Text>{key}: {useNumberWithSpaces(value.result)}</Text>
                            </>
                        )}
                    </div> */}

                </div>
            </Card>
        )
    }

    if(mode === "result" && resultData !== undefined){
        const sum = Object.values(resultData).reduce((a, b) => a + b.result, 0)
        const min = Math.min(...Object.values(resultData).map(item => item.result))
        const max = Math.max(...Object.values(resultData).map(item => item.result))

        const averageCost = Math.round(sum / Object.values(resultData).length)
        const dispersion = max - min

        return (
            <Card shadow="sm" p={"md"} radius="md" withBorder style={{ backgroundColor: "#25262b", width: "100%", display: "flex", flexDirection: "column", alignItems: "start", padding: "36px"}}>
    
                    <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
    
                        <div>
                            <h1 style={{marginBottom: "40px", marginTop: "10px", fontSize: "50px"}}>{date.getFullYear()}</h1>
                            <Table verticalSpacing="xs" horizontalSpacing={"xs"} >
                                <tbody>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Összesen:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(sum)} Ft</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Átlag:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(averageCost)} Ft</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Eloszlás:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(dispersion)} Ft</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Legkisebb érték:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(min)} Ft</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Legnagyobb érték:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(max)} Ft</Text>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <Text size={'lg'}>Változás az előző <br></br> évhez képest:</Text>
                                            <div style={{display: "flex", alignItems: "center", marginTop: "0px"}}>
                                                <Text size={'lg'} weight="bold" style={{marginRight: "10px"}}>- 553 000 200 Ft</Text>
                                                <ArrowDown size={20} color={theme.colors.green[6]}></ArrowDown>
                                                <Text size={'md'} color={theme.colors.green[6]}> -50%</Text>
                                            </div>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </Table>
                        </div>
    
                        <ScrollArea h={590} style={{ width: "82%", marginLeft: "0px", marginRight: "0px"}}>
    
                            <QueryCardResultTable
                                height='590px'
                                showAllColumns
                                showLines
                                sum={sum}
                                nameType='text'
                                data={Object.entries(resultData).map(([key, value]) => {
                                    return {customer: key, cost: value.costs, income: value.income, resultPercentage: value.resultPercentage, resultPrice: value.result, overheadCost: value.overheadCosts, otherCost: value.otherCosts}
                                }).sort((a, b) => b.resultPrice - a.resultPrice)}
                            />
    
                        </ScrollArea>
                    </div>
            </Card>
        )
    }

    if(data){
    const sum = Object.values(data).reduce((a, b) => a + b, 0)
    const min = Math.min(...Object.values(data))
    const max = Math.max(...Object.values(data))

    const averageCost = Math.round(sum / Object.values(data).length)
    const dispersion = max - min

    return (
            <Card shadow="sm" p={"md"} radius="md" withBorder style={{ backgroundColor: "#25262b", width: "100%", display: "flex", flexDirection: "column", alignItems: "start", padding: "36px"}}>
                    
    
    
                    {/* <Table verticalSpacing="sm" horizontalSpacing="xl" style={{width: "90%"}}>
                        <thead>
                            <tr>
                                <th>
                                    <Text size={'lg'}>Összesen:</Text>
                                </th>
                                <th>
                                    <Text size={'lg'}>Átlag:</Text>
                                </th>
                                <th>
                                <Text size={'lg'}>Eloszlás:</Text>
                                </th>
                                <th>
                                <Text size={'lg'}>Legkisebb érték:</Text>
                                </th>
                                <th>
                                <Text size={'lg'}>Legnagyobb érték:</Text>
                                </th>
                                <th>
                                <Text size={'lg'}>Változás az előző évhez <br></br> képest:</Text>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    
                                    <Text size={'lg'} weight="bold">10 050 000 000 Ft</Text>
                                </td>
                                <td>
                                    
                                    <Text size={'lg'} weight="bold">150 000 000 Ft</Text>
                                </td>
                                <td>
                                    
                                    <Text size={'lg'} weight="bold">153 200 Ft</Text>
                                </td>
                                <td>
                                    
                                    <Text size={'lg'} weight="bold">13 000 Ft</Text>
                                </td>
                                <td>
                                    
                                    <Text size={'lg'} weight="bold">553 200 Ft</Text>
                                </td>
                                <td>
                                    
                                    <div style={{display: "flex", alignItems: "center", marginTop: "0px"}}>
                                        <Text size={'lg'} weight="bold" style={{marginRight: "10px"}}>- 553 000 200 Ft</Text>
                                        <ArrowDown size={20} color={theme.colors.green[6]}></ArrowDown>
                                        <Text size={'md'} color={theme.colors.green[6]}> -50%</Text>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table> */}
    
                    <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
    
                        <div>
                            <h1 style={{marginBottom: "40px", marginTop: "10px", fontSize: "50px"}}>{date.getFullYear()}</h1>
                            <Table verticalSpacing="xs" horizontalSpacing={"xs"} >
                                <tbody>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Összesen:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(sum)} Ft</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Átlag:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(averageCost)} Ft</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Eloszlás:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(dispersion)} Ft</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Legkisebb érték:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(min)} Ft</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Text size={'lg'}>Legnagyobb érték:</Text>
                                            <Text size={'lg'} weight="bold">{useNumberWithSpaces(max)} Ft</Text>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <Text size={'lg'}>Változás az előző <br></br> évhez képest:</Text>
                                            <div style={{display: "flex", alignItems: "center", marginTop: "0px"}}>
                                                <Text size={'lg'} weight="bold" style={{marginRight: "10px"}}>- 553 000 200 Ft</Text>
                                                <ArrowDown size={20} color={theme.colors.green[6]}></ArrowDown>
                                                <Text size={'md'} color={theme.colors.green[6]}> -50%</Text>
                                            </div>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </Table>
                        </div>
    
    
    
    
    
                        <ScrollArea h={590} style={{ width: "80%", marginLeft: "0px", marginRight: "0px"}}>
    
                            <QueryCardYearlyTable
                                sum={sum}
                                data={Object.entries(data).map(([key, value]) => {
                                    return {name: key,  percentage: Number((value / sum * 100).toFixed(2)), price: value, change: 50 }
                                }).sort((a, b) => b.price - a.price)}
                            />
    
                        </ScrollArea>
    
                        {/* <div ref={ref}>
                            {isVisible ? <BuiltyPieChart width={"90vw"} height={"90vw"} padding={''} label={''} labels={["sanyi", "béla", "laci", "martin", "kungfu", "játék", "pálinka", "bor", "vicc", "party"]} data={[1,2,3,4,5,6,7,8,9]} datasets={[{data: [1,2,3,4,5,6,7,8,9], backgroundColor: [generateColorFromSeed("sanyi"), generateColorFromSeed("béla"),generateColorFromSeed("laci"),generateColorFromSeed("martin"), generateColorFromSeed("kungfu"),generateColorFromSeed("játék"),generateColorFromSeed("pálinka"),generateColorFromSeed("bor"),generateColorFromSeed("vicc"),generateColorFromSeed("party")]}]}></BuiltyPieChart> : <div style={{width: "90vw", height: "90vw", display: "flex", justifyContent: "center", alignItems: "center"}}><Loader size={"lg"}></Loader></div>}
                            <BuiltyPieChart width={"400px"} height={"400px"} padding={''} label={''} labels={["sanyi", "béla", "laci", "martin", "kungfu", "játék", "pálinka", "bor", "vicc", "party"].map((item) => item + Math.random)} data={[1,2,3,4,5,6,7,8,9]} datasets={[{data: Array.from({length: 50}).map((_, index) => Math.random()*1000000), backgroundColor: [generateColorFromSeed("sanyi"), generateColorFromSeed("béla"),generateColorFromSeed("laci"),generateColorFromSeed("martin"), generateColorFromSeed("kungfu"),generateColorFromSeed("játék"),generateColorFromSeed("pálinka"),generateColorFromSeed("bor"),generateColorFromSeed("vicc"),generateColorFromSeed("party")]}]}></BuiltyPieChart>
                        </div> */}
    
                        {/* <BuiltyLineChart maxHeight={'600px'} width={'35%'} padding={''} label={''} labels={["január", "február", "március", "április", "majus", "júnus", "julius", "augusztus", "szeptember", "október", "november", "december"]} data={Array.from({length: 12}).map((_, index) => Math.random()*1000000)}></BuiltyLineChart> */}
                        {/* <BuiltyLineChart maxHeight={'600px'} maxWidth={'35%'} padding={''} label={''} labels={["Q1", "Q2", "Q3", "Q4"]} data={Array.from({length: 4}).map((_, index) => Math.random()*1000000)}></BuiltyLineChart> */}
    
                    </div>
            </Card>
        )
    }
  }

  return <div></div>
}


export function generateColorFromSeed(seed: string, border?: boolean, transparency?: number) {
    // DJB2 hash function
    let hash = 5381;
    for (let i = 0; i < seed.length; i++) {
        hash = (hash * 33) ^ seed.charCodeAt(i);
    }

    const hue = hash % 360; // Calculate the hue from the hash value

    // Adjust the saturation and lightness for a pastel effect
    const saturation = 95; // Decrease the saturation value
    const lightness = 65; // Increase the lightness value

    // Convert the HSL color to RGB
    const rgbColor = hslToRgb(hue, saturation, lightness);

    const transp = border ? 1 : 0.4;
    // Add the alpha value to the RGB color
    const rgbaColor = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${transparency ?? transp})`;

    return rgbaColor;
}

// Helper function to convert HSL to RGB
function hslToRgb(h: number, s: number, l: number) {
    h /= 360;
    s /= 100;
    l /= 100;

    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = Math.round(hue2rgb(p, q, h + 1 / 3) * 255);
        g = Math.round(hue2rgb(p, q, h) * 255);
        b = Math.round(hue2rgb(p, q, h - 1 / 3) * 255);
    }

    return { r, g, b };
}
    

export function NoQueryData(){
    return (
        <div style={{display: "flex", justifyContent: "center", gap: "10px", width: "100%"}}>
            <DeviceFloppy></DeviceFloppy>
            <Text>Nincs adat</Text>
        </div>
    )
}