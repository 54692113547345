import { Loader, MultiSelect } from '@mantine/core'
import { useDebouncedState } from '@mantine/hooks'
import React, { useEffect, useState } from 'react'
import { useFunctions, useUserCompany } from '../../hooks/useFirebase'

interface SearchMultipleCompanyProps {
    value: string[],
    onChange: (value: string[]) => void,
    canSelectItself: boolean,
    className?: string,
    placeholder?: string,
    styles?: any
}

export default function SearchMultipleCompany({ value, onChange, canSelectItself, className, placeholder, styles }: SearchMultipleCompanyProps) {

  const [companyList, setCompanyList] = useState<string[]>(value)
  const [searched, setSearched] = useState(false)
  const [isSearching, setIsSearching] = useState(false)

  // const [value, setValue] = useState('')
  // const [debouncedValue] = useDebouncedValue(value, 1000);
  const [searchValue, setSearchValue] = useDebouncedState('', 1000);

  const [firstRun, setFirstRun] = useState(true)
  const [userCompany, setUserCompany] = useState<string | null>(null)

  function searchForCompany() {
    setIsSearching(true)
    const searchCompanyName = useFunctions("searchCompanyName")
    
    searchCompanyName({ search: searchValue }).then(async (res) => {
        console.log(res)
      const data = res.data as { success: boolean, results: string[] }
      if(data.success){

          if(canSelectItself){
            // adding already selected companys to the companyList array
            let v: string[] = []
            data.results.forEach((company) => {
                v.push(company)
            })
            value.forEach((company) => {
                v.push(company)
            })
            setCompanyList(v)
          } else {

            let userCompanyToFilter = "";

            if(userCompany){
              userCompanyToFilter = userCompany
            } else {
              userCompanyToFilter = await useUserCompany()
              setUserCompany(userCompanyToFilter)
            }

            await useUserCompany()
            const filtered = data.results.filter((company) => company !== userCompanyToFilter)
            // adding already selected companys to the companyList array
            let v: string[] = []
            filtered.forEach((company) => {
                v.push(company)
            })
            value.forEach((company) => {
                v.push(company)
            })
            setCompanyList(v)
          }

          console.log(data.results.length)
          setSearched(true)
          setIsSearching(false)

      } else {
        console.log("Hiba történt")
      }
    })

  }

  useEffect(() => {
    if(!firstRun){
      if(searchValue.length >= 3){
          searchForCompany()
          console.log("runned")
      }
      if(searchValue == ""){
          setSearched(false)
      }
    } else {
      setTimeout(() => {
        setFirstRun(false)
      }, 500);
    }
  }, [searchValue])

  useEffect(() => {
    console.log(companyList)
  }, [])

  return (
    <div style={{ width: "100%" }}>
        <MultiSelect
            className={className}
            label="Cég(ek)"
            placeholder={placeholder ? placeholder : "Keress céget"}
            searchable
            nothingFound={isSearching ? <Loader></Loader> : (searched ? 'Nincs találat' : "Keress")}
            data={companyList}
            onSearchChange={(result) => setSearchValue(result)}
            // filter={(value, item) => true}
            maxDropdownHeight={150}
            onChange={(value) => {console.log(value);onChange(value)}}
            value={value}
            styles={styles}
        />
    </div>
  )
}
