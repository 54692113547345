import { Button, ScrollArea, Space, Table, Loader, Text, Drawer } from '@mantine/core'
import { DateRangePicker, DateRangePickerValue } from '@mantine/dates'
import { useSessionStorage, useWindowScroll } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { IconListSearch, IconX } from '@tabler/icons'
import { collection, getDocs, query, where } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Filter, Notes, Printer, Report } from 'tabler-icons-react'
import { useFirestore, useFunctions, useUserCompany } from '../../hooks/useFirebase'
import { contractProgressType } from '../../interfaces/contractProgressTypes'
import { DailyPostType } from '../../interfaces/dailyPostType'
import { FormStateType } from '../../interfaces/formTypes'
import { groupedBarChartData } from '../../interfaces/groupedBarChartData'
import { contractType } from '../../pages/OwnContracts/components/List/List'
import BuiltyQueryCard from '../../pages/Statistics/components/Query/components/BuiltyQueryCard'
import { NoQueryData } from '../../pages/Statistics/components/Query/components/BuiltyQueryCard/BuiltyQueryCard'
import TableOfContentsDrawer from './components/TableOfContentsDrawer'
import "./QueryGraphs.css"
import ShowPureResult from './components/ShowPureResult'


export let refs: React.RefObject<HTMLDivElement>[] = []


export default function QueryGraphs() {

  let [searchParams, setSearchParams] = useSearchParams();


  const isDateRangeString = searchParams.get('isDateRange')
  const dateRangeValueString: string | null = searchParams.get('dateRangeValue')
  const dateValueString = searchParams.get('dateValue')
  const companysListValueString: string | null = searchParams.get('companysListValue')
  const projectNumbersListValueString: string | null = searchParams.get('projectNumbersListValue')
  const datatablesListValueString: string | null = searchParams.get('datatablesListValue')
  const treeViewSelectedIdsString = searchParams.get('treeViewSelectedIds')

  console.log({ isDateRangeString, dateRangeValueString, dateValueString, companysListValueString, projectNumbersListValueString, datatablesListValueString, treeViewSelectedIdsString })

  let isDateRange: boolean = JSON.parse(isDateRangeString ? isDateRangeString : 'false')
  let dateRangeValue: any = dateRangeValueString ? dateRangeValueString.split(',').map((date) => date ? new Date(date) : null) : [null, null]
  let dateValue = dateValueString != null && dateValueString != 'null' ? new Date(dateValueString) : null
  let companysListValue: string[] = companysListValueString ? companysListValueString.split(',') : []
  let projectNumbersListValue: string[] = projectNumbersListValueString ? projectNumbersListValueString.split(',') : []
  let datatablesListValue: string[] = datatablesListValueString ? datatablesListValueString.split(',') : []
  let treeViewSelectedIds: number[] = treeViewSelectedIdsString ? treeViewSelectedIdsString.split(',').map((id => Number(id))) : []

  console.log({ isDateRange, dateRangeValue, dateValue, companysListValue, projectNumbersListValue, datatablesListValue, treeViewSelectedIds })


  
  // const [filteredDocuments, setFilteredDocuments] = useState<DailyPostType[]>([])
  const [costsDataByCompanys, setCostsDataByCompanys] = useState<{ [key in "daily" | "weekly" | "monthly" | "yearly"]: { [key: string]: { [key: string]: number;} } | undefined }>()
  const [costsDataByDatatables, setCostsDataByDatatables] = useState<{ [key in "daily" | "weekly" | "monthly" | "yearly"]: { [key: string]: { [key: string]: number;} } | undefined }>()
  const [costsDataByProjectNumbers, setCostsDataByProjectNumbers] = useState<{ [key in "daily" | "weekly" | "monthly" | "yearly"]: { [key: string]: { [key: string]: number;} } | undefined }>()

  const [incomeDataByContractors, setIncomeDataByContractors] = useState<{ [key in "daily" | "weekly" | "monthly" | "yearly"]: { [key: string]: { [key: string]: number;} } | undefined }>()
  const [incomeDataByProjectNumbers, setIncomeDataByProjectNumbers] = useState<{ [key in "daily" | "weekly" | "monthly" | "yearly"]: { [key: string]: { [key: string]: number;} } | undefined }>()
  
  const [resultDataByContractors, setResultDataByContractors] = useState<{ [key in "daily" | "weekly" | "monthly" | "yearly"]: { [key: string]: { [key: string]: resultStatisticsObject;} } | undefined }>()
  const [resultDataByProjectNumbers, setResultDataByProjectNumbers] = useState<{ [key in "daily" | "weekly" | "monthly" | "yearly"]: { [key: string]: { [key: string]: resultStatisticsObject;} } | undefined }>()
  
  const [resultData, setResultData] = useState<{ [key in "monthly" | "yearly"]: { [key: string]: { [key: string]: resultStatisticsObject } } | undefined }>()
  
  const [isSearching, setIsSearching] = useState(true)


  const costDocuments = useRef<DailyPostType[]>([])
  const incomeObject = useRef<incomeObjectType>({})


  async function search() {
    setTimeout(async () => {
        console.log({isDateRange, dateRangeValue, dateValue, companysListValue, projectNumbersListValue, datatablesListValue})
        try {
          let result = await searchInDatabaseAndReturnResults(isDateRange, dateRangeValue, dateValue, companysListValue, projectNumbersListValue, datatablesListValue)
          
          costDocuments.current = result.resultIWithAddedContractProgresses

          costDocuments.current = calc(costDocuments.current, datatablesListValue, companysListValue, projectNumbersListValue)
          
          const costStatisticsByCompanys = makeCostsDataByCompanysToShowableFormat(costDocuments.current)
          console.log("makeCostsDataToShowableFormat statisticsByCompanys result", costStatisticsByCompanys)

          const costStatisticsByDatatables = makeCostsDataByDatatablesToShowableFormat(costDocuments.current)
          console.log("makeCostsDataToShowableFormat statisticsByDatatables result", costStatisticsByDatatables)
          
          const costStatisticsByProjectNumbers = makeCostsDataByProjectNumbersToShowableFormat(costDocuments.current)
          console.log("makeCostsDataToShowableFormat statisticsByProjectNumbers result", costStatisticsByProjectNumbers)


          incomeObject.current = result.resultWithOwnContractProgressses as incomeObjectType

          const incomeStatisticsByCompanys = makeIncomeDataToShowableFormat(incomeObject.current, "byContractors")
          console.log("makeIncomeDataToShowableFormat statisticsByCompanys result", incomeStatisticsByCompanys)

          const incomeStatisticsByProjectNumbers = makeIncomeDataToShowableFormat(incomeObject.current, "byProjectNumbers")
          console.log("makeIncomeDataToShowableFormat statisticsByProjectNubmers result", incomeStatisticsByProjectNumbers)


          const { projectsList, ownContractsList } = await fetchProjectDetailsAndOwnContracts(costDocuments.current, incomeObject.current)

          const resultStatisticsByProjectNumbers = makeResultsToShowableFormat(projectsList, costDocuments.current, incomeObject.current)
          const resultStatisticsByCustomers = makeResultsToShowableFormat(projectsList, costDocuments.current, incomeObject.current, ownContractsList)
          console.log("makeResultsToShowableFormat statisticsByProjectNumbers result", resultStatisticsByProjectNumbers)
          console.log("makeResultsToShowableFormat statisticsByCustomers result", resultStatisticsByCustomers)

          const resultStatisticsInMonthly = combineStatistics(resultStatisticsByProjectNumbers.weekly, "monthly")
          console.log("combineStatistics resultStatisticsInMonthly", resultStatisticsInMonthly)

          const resultStatisticsInYearly = combineStatistics(resultStatisticsByProjectNumbers.monthly, "yearly")
          console.log("combineStatistics resultStatisticsInYearly", resultStatisticsInYearly)
          
          
          // setFilteredDocuments(documents)
          setIsSearching(false)
          setCostsDataByCompanys(costStatisticsByCompanys)
          setCostsDataByDatatables(costStatisticsByDatatables)
          setCostsDataByProjectNumbers(costStatisticsByProjectNumbers)

          setIncomeDataByContractors(incomeStatisticsByCompanys)
          setIncomeDataByProjectNumbers(incomeStatisticsByProjectNumbers)

          setResultDataByContractors(resultStatisticsByCustomers)
          setResultDataByProjectNumbers(resultStatisticsByProjectNumbers)
          setResultData({ monthly: resultStatisticsInMonthly, yearly: resultStatisticsInYearly })


          // throw new Error()
        } catch (error) {
          console.error(error)
          showNotification({
            autoClose: false,
            title: "Hiba történt!",
            message: 'Sajnos nem sikerült összeállítani a lekérdezést. Kérlek próbáld újra!',
            color: 'red',
            icon: <IconX />,
          });
        }

    }, 1000);
  }

  

  function makeCostsDataByCompanysToShowableFormat(dailysWithAddedContractProgresses: DailyPostType[]){
    const statisticsDaily: { [key: string]: { [key: string]: number } } = {};
    let statisticsWeekly: { [key: string]: { [key: string]: number } } = {};
    let statisticsMonthly: { [key: string]: { [key: string]: number } } = {};
    let statisticsYearly: { [key: string]: { [key: string]: number } } = {};

    // for testing
    // dailysWithAddedContractProgresses = dailysWithAddedContractProgresses.splice(0, 18)

    dailysWithAddedContractProgresses.forEach((daily) => {
      // if(daily.data == undefined || daily.data == null || daily.data == "") return
      // try {
      //   JSON.parse(daily.data)
      // } catch (error) {
      //   console.log(error)
      //   console.log(daily.data)
      // }

      let dailydata = undefined
      try {
        dailydata = JSON.parse(daily.data) as FormStateType;
      } catch {}

      // console.log("daily", daily)
      // console.log("dailydata", dailydata)

      const date = new Date(daily.date.seconds*1000)
      // console.log(date, date.toLocaleDateString())
      // const datestring = date.toISOString()//.split("T")[0] // dont use toISOString, because it changes the timezone to Greenwich time
      const datestring = date.toLocaleDateString().replaceAll(". ", "-").replace(".", "")


      if(dailydata){
        dailydata.forEach((table) => {
          const tableData = table.data
          
          tableData.forEach((row) => {

            let companyIndex = row.find((obj) => obj.column === "Cég")
            // if there is no Cég cell, then check for Gyártó cell
            if(!companyIndex){
              companyIndex = row.find((obj) => obj.column === "Gyártó")
            }
            const company = String(companyIndex ? companyIndex.value : "");
  
            const priceIndex = row.find((obj) => obj.column === "counted_price")
            const price = Number(priceIndex ? priceIndex.value : 0);
            if(price === 0) return
  
            if(company !== "" && company !== undefined && company !== null && company !== "null" && company !== "undefined"){

              // if(companysListValue.length !== 0 && companysListValue.includes(company) === false) return // ezt itt még át kell dolgozni
  
              if(!statisticsDaily.hasOwnProperty(datestring)){
                statisticsDaily[datestring] = {}
              }

              statisticsDaily[datestring][company] = (statisticsDaily[datestring][company] || 0) + price;

            }
            // else {
            //   console.log("company", company.length, row)
            // }
  
          })
        });
      }

      // adding subcontractor contract progress prices
      daily.companies_inside_added_progresses?.forEach((progress) => {
          
        if(!statisticsDaily.hasOwnProperty(datestring)){
            statisticsDaily[datestring] = {}
          }

          if(progress.price !== 0){
            statisticsDaily[datestring][progress.contractor] = (statisticsDaily[datestring][progress.contractor] || 0) + progress.price
          }
      })

      // console.log(daily.companies_inside_added_progresses)
    })

    console.log("statisticsDaily", statisticsDaily)



    //-------------------------- creating weekly statistics from daily statistics---------------------

    // Convert the daily report to weekly report
    statisticsWeekly = convertToWeeklyReport(statisticsDaily);

    console.log("statisticsWeekly", statisticsWeekly)



    //---------------------------- creating monthly statistics from daily statistics----------------------

    // Convert the daily report to monthly statistics
    statisticsMonthly = convertToMonthlyStatistics(statisticsDaily);

    console.log("statisticsMonthly", statisticsMonthly)



    // ---------------------------- creating yearly statistics from daily statistics----------------------

    // Convert the daily report to yearly report
    statisticsYearly = convertToYearlyReport(statisticsDaily);

    console.log("statisticsYearly", statisticsYearly)



    return {
      daily: Object.keys(statisticsDaily).length !== 0 ? statisticsDaily : undefined,
      weekly: Object.keys(statisticsWeekly).length !== 0 ? statisticsWeekly : undefined,
      monthly: Object.keys(statisticsMonthly).length !== 0 ? statisticsMonthly : undefined,
      yearly: Object.keys(statisticsYearly).length !== 0 ? statisticsYearly : undefined,
    };

    // const outerKeys = Object.keys(statistics);
    // outerKeys.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    // let innerKeys: string[] = []
    // outerKeys.forEach((key, index) => {
    //   const array = Object.keys(statistics[outerKeys[index]]);
    //   innerKeys.push(...array);
    // })

    // console.log("innerKeys.length", innerKeys.length)
    // const counts = innerKeys.reduce((acc: any, item) => {
    //   acc[item] = (acc[item] || 0) + 1;
    //   return acc;
    // }, {});
    // console.log(counts);

    // innerKeys = [...new Set(innerKeys)];

    // const data = outerKeys.map((outerKey) => innerKeys.map((innerKey) => statistics[outerKey][innerKey]).filter((element) => element !== undefined));

    // const datasets = innerKeys.map((company, companyIndex) => {

    //   const dataData = data.map((value, dateIndex) => {
    //     const returnValue: number | null = Object.values(statistics)[dateIndex][company] ?? null
    //     // console.log("returnValue", returnValue)
    //     return returnValue
    //     // value[companyIndex]
    //   }).reverse()

    //   console.log("dataData", company, dataData)

    //   return {
    //       label: company,
    //       data: dataData,
    //       backgroundColor: generateColorFromSeed(company),
    //       stack: company,
    //       skipNull: true
    //       // Add any other options such as color, borderWidth, etc.
    //   };
    // });

    // console.log("outerKeys", outerKeys)
    // console.log("innerKeys", innerKeys)
    // console.log("data", data)
    // console.log("datasets", datasets)

    // return {
    //   label: "Költségek",
    //   labels: outerKeys,
    //   datasets: datasets,
    // }

  }

  function makeCostsDataByDatatablesToShowableFormat(dailysWithAddedContractProgresses: DailyPostType[]){
    const statisticsDaily: { [key: string]: { [key: string]: number } } = {};
    let statisticsWeekly: { [key: string]: { [key: string]: number } } = {};
    let statisticsMonthly: { [key: string]: { [key: string]: number } } = {};
    let statisticsYearly: { [key: string]: { [key: string]: number } } = {};

    // for testing
    // dailysWithAddedContractProgresses = dailysWithAddedContractProgresses.splice(0, 18)

    dailysWithAddedContractProgresses.forEach((daily) => {
      // if(daily.data == undefined || daily.data == null || daily.data == "") return
      // try {
      //   JSON.parse(daily.data)
      // } catch (error) {
      //   console.log(error)
      //   console.log(daily.data)
      // }

      let dailydata = undefined
      try {
        dailydata = JSON.parse(daily.data) as FormStateType;
      } catch {}

      // console.log("daily", daily)
      // console.log("dailydata", dailydata)

      const date = new Date(daily.date.seconds*1000)
      // console.log(date, date.toLocaleDateString())
      // const datestring = date.toISOString()//.split("T")[0] // dont use toISOString, because it changes the timezone to Greenwich time
      const datestring = date.toLocaleDateString().replaceAll(". ", "-").replace(".", "")


      if(dailydata) {
        dailydata.forEach((table) => {
          const tableData = table.data
          const tableName = table.tableName
          // if(datatablesListValue.length !== 0 && datatablesListValue.includes(tableName) === false) return

          const tableSumPrice = tableData.map((row) => {
            const index = row.findIndex((obj) => obj.column === "counted_price")
            return Number(row[index].value)
          }).reduce((a, b) => a + b, 0)

          if(tableSumPrice === 0) return


          if(!statisticsDaily.hasOwnProperty(datestring)){
            statisticsDaily[datestring] = {}
          }

          statisticsDaily[datestring][tableName] = (statisticsDaily[datestring][tableName] || 0) + tableSumPrice;
  
        });
      }

      // adding subcontractor contract progress prices
      daily.companies_inside_added_progresses?.forEach((progress) => {
          
        if(!statisticsDaily.hasOwnProperty(datestring)){
            statisticsDaily[datestring] = {}
          }

          if(progress.price !== 0){
            statisticsDaily[datestring]["Alvállalkozói Szerződések"] = (statisticsDaily[datestring]["Alvállalkozói Szerződések"] || 0) + progress.price
          }
      })

      // console.log(daily.companies_inside_added_progresses)
    })

    console.log("statisticsDaily", statisticsDaily)



    //-------------------------- creating weekly statistics from daily statistics---------------------

    // Convert the daily report to weekly report
    statisticsWeekly = convertToWeeklyReport(statisticsDaily);

    console.log("statisticsWeekly", statisticsWeekly)



    //---------------------------- creating monthly statistics from daily statistics----------------------

    // Convert the daily report to monthly statistics
    statisticsMonthly = convertToMonthlyStatistics(statisticsDaily);

    console.log("statisticsMonthly", statisticsMonthly)



    // ---------------------------- creating yearly statistics from daily statistics----------------------

    // Convert the daily report to yearly report
    statisticsYearly = convertToYearlyReport(statisticsDaily);

    console.log("statisticsYearly", statisticsYearly)



    return {
      daily: Object.keys(statisticsDaily).length !== 0 ? statisticsDaily : undefined,
      weekly: Object.keys(statisticsWeekly).length !== 0 ? statisticsWeekly : undefined,
      monthly: Object.keys(statisticsMonthly).length !== 0 ? statisticsMonthly : undefined,
      yearly: Object.keys(statisticsYearly).length !== 0 ? statisticsYearly : undefined,
    };

    // const outerKeys = Object.keys(statistics);
    // outerKeys.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    // let innerKeys: string[] = []
    // outerKeys.forEach((key, index) => {
    //   const array = Object.keys(statistics[outerKeys[index]]);
    //   innerKeys.push(...array);
    // })

    // console.log("innerKeys.length", innerKeys.length)
    // const counts = innerKeys.reduce((acc: any, item) => {
    //   acc[item] = (acc[item] || 0) + 1;
    //   return acc;
    // }, {});
    // console.log(counts);

    // innerKeys = [...new Set(innerKeys)];

    // const data = outerKeys.map((outerKey) => innerKeys.map((innerKey) => statistics[outerKey][innerKey]).filter((element) => element !== undefined));

    // const datasets = innerKeys.map((company, companyIndex) => {

    //   const dataData = data.map((value, dateIndex) => {
    //     const returnValue: number | null = Object.values(statistics)[dateIndex][company] ?? null
    //     // console.log("returnValue", returnValue)
    //     return returnValue
    //     // value[companyIndex]
    //   }).reverse()

    //   console.log("dataData", company, dataData)

    //   return {
    //       label: company,
    //       data: dataData,
    //       backgroundColor: generateColorFromSeed(company),
    //       stack: company,
    //       skipNull: true
    //       // Add any other options such as color, borderWidth, etc.
    //   };
    // });

    // console.log("outerKeys", outerKeys)
    // console.log("innerKeys", innerKeys)
    // console.log("data", data)
    // console.log("datasets", datasets)

    // return {
    //   label: "Költségek",
    //   labels: outerKeys,
    //   datasets: datasets,
    // }

  }

  function makeCostsDataByProjectNumbersToShowableFormat(dailysWithAddedContractProgresses: DailyPostType[]){
    const statisticsDaily: { [key: string]: { [key: string]: number } } = {};
    let statisticsWeekly: { [key: string]: { [key: string]: number } } = {};
    let statisticsMonthly: { [key: string]: { [key: string]: number } } = {};
    let statisticsYearly: { [key: string]: { [key: string]: number } } = {};

    // for testing
    // dailysWithAddedContractProgresses = dailysWithAddedContractProgresses.splice(0, 18)

    dailysWithAddedContractProgresses.forEach((daily) => {
      // if(daily.data == undefined || daily.data == null || daily.data == "") return
      // try {
      //   JSON.parse(daily.data)
      // } catch (error) {
      //   console.log(error)
      //   console.log(daily.data)
      // }

      // let dailydata = undefined
      // try {
      //   dailydata = JSON.parse(daily.data) as FormStateType;
      // } catch {}

      const projectNumber = daily.project_number
      const finalPrice = daily.final_price

      // console.log("daily", daily)
      // console.log("dailydata", dailydata)

      const date = new Date(daily.date.seconds*1000)
      // console.log(date, date.toLocaleDateString())
      // const datestring = date.toISOString()//.split("T")[0] // dont use toISOString, because it changes the timezone to Greenwich time
      const datestring = date.toLocaleDateString().replaceAll(". ", "-").replace(".", "")

      if(finalPrice === 0) return


      if(!statisticsDaily.hasOwnProperty(datestring)){
        statisticsDaily[datestring] = {}
      }

      if(projectNumber === "" || projectNumber === undefined || projectNumber === null){

        statisticsDaily[datestring]["Ismeretlen munkaszám"] = (statisticsDaily[datestring]["Ismeretlen munkaszám"] || 0) + finalPrice
        
      } else {
        statisticsDaily[datestring][projectNumber] = (statisticsDaily[datestring][projectNumber] || 0) + finalPrice;
      }


      // adding subcontractor contract progress prices
      
      // costs is already added in finalPrice
      
      // daily.companies_inside_added_progresses?.forEach((progress) => {
          
      //   if(!statisticsDaily.hasOwnProperty(datestring)){
      //       statisticsDaily[datestring] = {}
      //     }

      //     if(progress.price !== 0){
      //       statisticsDaily[datestring][progress.project_number] = (statisticsDaily[datestring][progress.project_number] || 0) + progress.price
      //     }
      // })

      // console.log(daily.companies_inside_added_progresses)
    })

    console.log("statisticsDaily", statisticsDaily)



    //-------------------------- creating weekly statistics from daily statistics---------------------

    // Convert the daily report to weekly report
    statisticsWeekly = convertToWeeklyReport(statisticsDaily);

    console.log("statisticsWeekly", statisticsWeekly)



    //---------------------------- creating monthly statistics from daily statistics----------------------

    // Convert the daily report to monthly statistics
    statisticsMonthly = convertToMonthlyStatistics(statisticsDaily);

    console.log("statisticsMonthly", statisticsMonthly)



    // ---------------------------- creating yearly statistics from daily statistics----------------------

    // Convert the daily report to yearly report
    statisticsYearly = convertToYearlyReport(statisticsDaily);

    console.log("statisticsYearly", statisticsYearly)



    return {
      daily: Object.keys(statisticsDaily).length !== 0 ? statisticsDaily : undefined,
      weekly: Object.keys(statisticsWeekly).length !== 0 ? statisticsWeekly : undefined,
      monthly: Object.keys(statisticsMonthly).length !== 0 ? statisticsMonthly : undefined,
      yearly: Object.keys(statisticsYearly).length !== 0 ? statisticsYearly : undefined,
    };

    // const outerKeys = Object.keys(statistics);
    // outerKeys.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    // let innerKeys: string[] = []
    // outerKeys.forEach((key, index) => {
    //   const array = Object.keys(statistics[outerKeys[index]]);
    //   innerKeys.push(...array);
    // })

    // console.log("innerKeys.length", innerKeys.length)
    // const counts = innerKeys.reduce((acc: any, item) => {
    //   acc[item] = (acc[item] || 0) + 1;
    //   return acc;
    // }, {});
    // console.log(counts);

    // innerKeys = [...new Set(innerKeys)];

    // const data = outerKeys.map((outerKey) => innerKeys.map((innerKey) => statistics[outerKey][innerKey]).filter((element) => element !== undefined));

    // const datasets = innerKeys.map((company, companyIndex) => {

    //   const dataData = data.map((value, dateIndex) => {
    //     const returnValue: number | null = Object.values(statistics)[dateIndex][company] ?? null
    //     // console.log("returnValue", returnValue)
    //     return returnValue
    //     // value[companyIndex]
    //   }).reverse()

    //   console.log("dataData", company, dataData)

    //   return {
    //       label: company,
    //       data: dataData,
    //       backgroundColor: generateColorFromSeed(company),
    //       stack: company,
    //       skipNull: true
    //       // Add any other options such as color, borderWidth, etc.
    //   };
    // });

    // console.log("outerKeys", outerKeys)
    // console.log("innerKeys", innerKeys)
    // console.log("data", data)
    // console.log("datasets", datasets)

    // return {
    //   label: "Költségek",
    //   labels: outerKeys,
    //   datasets: datasets,
    // }

  }


  function makeIncomeDataToShowableFormat(incomeObject: incomeObjectType, by: "byContractors" | "byProjectNumbers"){
    // const statisticsDaily: {[key: string]: {projectNumber: string, ownContractor: string, contractProgresses: contractProgressType[], countedChange: number}[]} = {}
    const statisticsDaily: { [key: string]: { [key: string]: number } } = {};
    let statisticsWeekly: { [key: string]: { [key: string]: number } } = {};
    let statisticsMonthly: { [key: string]: { [key: string]: number } } = {};
    let statisticsYearly: { [key: string]: { [key: string]: number } } = {};
    
    Object.entries(incomeObject).forEach(([key, value]) => {
      const projectNumber = key.split('-----')[0]
      const dateString = key.split('-----')[1]
      const ownContractor = key.split('-----')[2]

      if(value.countedChange === 0) return


      if(!statisticsDaily.hasOwnProperty(dateString)){
        statisticsDaily[dateString] = {}
      }
      

      if(by === "byContractors"){
        if(!statisticsDaily[dateString].hasOwnProperty(ownContractor)){
          statisticsDaily[dateString][ownContractor] = 0
        }
  
        statisticsDaily[dateString][ownContractor] += value.countedChange
      }

      if(by === "byProjectNumbers"){
        if(!statisticsDaily[dateString].hasOwnProperty(ownContractor)){
          statisticsDaily[dateString][projectNumber] = 0
        }
  
        statisticsDaily[dateString][projectNumber] += value.countedChange
      }

    })

    console.log("statisticsDaily", statisticsDaily)

    //-------------------------- creating weekly statistics from daily statistics---------------------

    // Convert the daily report to weekly report
    statisticsWeekly = convertToWeeklyReport(statisticsDaily);

    console.log("statisticsWeekly", statisticsWeekly)



    //---------------------------- creating monthly statistics from daily statistics----------------------

    // Convert the daily report to monthly statistics
    statisticsMonthly = convertToMonthlyStatistics(statisticsDaily);

    console.log("statisticsMonthly", statisticsMonthly)



    // ---------------------------- creating yearly statistics from daily statistics----------------------

    // Convert the daily report to yearly report
    statisticsYearly = convertToYearlyReport(statisticsDaily);

    console.log("statisticsYearly", statisticsYearly)

    return {
      daily: Object.keys(statisticsDaily).length !== 0 ? statisticsDaily : undefined,
      weekly: Object.keys(statisticsWeekly).length !== 0 ? statisticsWeekly : undefined,
      monthly: Object.keys(statisticsMonthly).length !== 0 ? statisticsMonthly : undefined,
      yearly: Object.keys(statisticsYearly).length !== 0 ? statisticsYearly : undefined,
    };
  }


  function makeResultsToShowableFormat(projectList: projectType[], dailysWithAddedContractProgresses: DailyPostType[], incomeObject: incomeObjectType, ownContractsList?: contractType[], withoutBy?: boolean){
    let statisticsDaily: { [key: string]: { [key: string]: resultStatisticsObject } } = {};
    let statisticsWeekly: { [key: string]: { [key: string]: resultStatisticsObject } } = {};
    let statisticsMonthly: { [key: string]: { [key: string]: resultStatisticsObject } } = {};
    let statisticsYearly: { [key: string]: { [key: string]: resultStatisticsObject } } = {};

    // let costsArray: { projectNumber: string, dateString: string, costs: number }[] = []
    // console.log("dailysWithAddedContractProgresses length", dailysWithAddedContractProgresses.length)

    // setting costs
    dailysWithAddedContractProgresses.forEach((daily) => {
      if(daily.project_number === "" || daily.project_number === null) return

      const dailyDateString = `${new Date(daily.date.seconds*1000).getFullYear()}-${new Date(daily.date.seconds*1000).getMonth() + 1}-${new Date(daily.date.seconds*1000).getDate()}`

      if(!statisticsDaily.hasOwnProperty(dailyDateString)){
        statisticsDaily[dailyDateString] = {}
      }
      if(!statisticsDaily[dailyDateString].hasOwnProperty(daily.project_number)){
        statisticsDaily[dailyDateString][daily.project_number] = {costs: 0,income: 0,incomeMinusCosts: 0,overheadPercentage: 0,overheadCosts: 0,otherCosts: 0,result: 0,resultPercentage: 0}
      }

      statisticsDaily[dailyDateString][daily.project_number] = {
        costs: statisticsDaily[dailyDateString][daily.project_number].costs + daily.final_price,
        income: 0,
        incomeMinusCosts: 0,
        overheadPercentage: 0,
        overheadCosts: 0,
        otherCosts: 0,
        result: 0,
        resultPercentage: 0
      }
    })

    // setting incomes
    Object.entries(incomeObject).forEach(([key, value]) => {
      const projectNumber = key.split('-----')[0]
      const dateString = key.split('-----')[1]
      const ownContractor = key.split('-----')[2]

      if(!statisticsDaily.hasOwnProperty(dateString)){
        statisticsDaily[dateString] = {}
      }
      if(!statisticsDaily[dateString].hasOwnProperty(projectNumber)){
        statisticsDaily[dateString][projectNumber] = {costs: 0,income: 0,incomeMinusCosts: 0,overheadPercentage: 0,overheadCosts: 0,otherCosts: 0,result: 0,resultPercentage: 0}
      }

      statisticsDaily[dateString][projectNumber] = {
        costs: (statisticsDaily[dateString][projectNumber].costs || 0),
        income: statisticsDaily[dateString][projectNumber].income + value.countedChange,
        incomeMinusCosts: 0,
        overheadPercentage: 0,
        overheadCosts: 0,
        otherCosts: 0,
        result: 0,
        resultPercentage: 0
      }
    })
    // console.log("projectList", projectList)

    statisticsDaily = calculateOverheadAndOtherCosts(statisticsDaily, projectList)
    
    // Convert the daily report to weekly report
    statisticsWeekly = convertResultToWeeklyReport(statisticsDaily);
    statisticsWeekly = calculateOverheadAndOtherCosts(statisticsWeekly, projectList)
    
    // Convert the daily report to monthly statistics
    statisticsMonthly = convertResultToMonthlyStatistics(statisticsDaily);
    statisticsMonthly = calculateOverheadAndOtherCosts(statisticsMonthly, projectList)
    
    // Convert the daily report to yearly report
    statisticsYearly = convertResultToYearlyReport(statisticsDaily);
    statisticsYearly = calculateOverheadAndOtherCosts(statisticsYearly, projectList)
    
    
    if(ownContractsList){
      statisticsDaily = convertByProjectNumberToByContractor(statisticsDaily, ownContractsList)
      statisticsWeekly = convertByProjectNumberToByContractor(statisticsWeekly, ownContractsList)
      statisticsMonthly = convertByProjectNumberToByContractor(statisticsMonthly, ownContractsList)
      statisticsYearly = convertByProjectNumberToByContractor(statisticsYearly, ownContractsList)
    }


    console.log("statisticsDaily", statisticsDaily)
    console.log("statisticsWeekly", statisticsWeekly)
    console.log("statisticsMonthly", statisticsMonthly)
    console.log("statisticsYearly", statisticsYearly)

    // dailysWithAddedContractProgresses.forEach((daily) => {
    //   if(daily.project_number === "" || daily.project_number === null) return

    //   const dailyDate = new Date(daily.date.seconds*1000)
    //   const dailyString = `${dailyDate.getFullYear()}-${dailyDate.getMonth() + 1}-${dailyDate.getDate()}`

    //   const numberOfDailysWithTheSameProjectNumber = dailysWithAddedContractProgresses.filter((d) => d.project_number === daily.project_number && d.data !== "").length
    //   console.log("numberOfDailysWithTheSameProjectNumber", numberOfDailysWithTheSameProjectNumber, daily.project_number)

    //   const resultCost = (resultObject[daily.project_number] || 0) / numberOfDailysWithTheSameProjectNumber
    //   let costs

    //   if(daily.data === ""){
    //     costs = daily.final_price
    //   } else {
    //     costs = Math.round(daily.final_price + resultCost)
    //   }

    //   console.log("daily", dailyString, daily.project_number, daily.final_price, resultObject[daily.project_number], resultCost, costs)
      
    //   // it is for checking if the array already contains that value
    //   const index = costsArray.findIndex((cost) => cost.projectNumber === daily.project_number && cost.dateString === dailyString)
    //   if(index !== -1){
    //     costsArray[index].costs += costs
    //   } else {
    //     console.log("costs", daily.final_price, )
    //     costsArray.push({
    //       projectNumber: daily.project_number,
    //       dateString: dailyString,
    //       costs: costs
    //     })
    //   }
    // })

    // console.log("costsArray", costsArray)

    // console.log("incomeObject", incomeObject)

    // Object.entries(incomeObject).forEach(([key, value]) => {
    //   const projectNumber = key.split('-----')[0]
    //   const dateString = key.split('-----')[1]
    //   const ownContractor = key.split('-----')[2]

    //   if(value.countedChange === 0) return


    //   if(!statisticsDaily.hasOwnProperty(dateString)){
    //     statisticsDaily[dateString] = {}
    //   }

    //   if(!statisticsDaily[dateString].hasOwnProperty(projectNumber)){
    //     statisticsDaily[dateString][projectNumber] = 0
    //   }

    //   const costIndex = costsArray.findIndex((cost) => cost.projectNumber === projectNumber && cost.dateString === dateString)
    //   // console.log("costIndex", costIndex)

    //   if(costIndex !== -1){
    //     statisticsDaily[dateString][projectNumber] += value.countedChange - costsArray[costIndex].costs
    //     costsArray = costsArray.filter((_, index) => index !== costIndex)
        
    //   } else {
    //     statisticsDaily[dateString][projectNumber] += value.countedChange
    //   }
    // })
    
    // console.log("statisticsDaily result", JSON.parse(JSON.stringify(statisticsDaily)))
    
    // costsArray.forEach((cost) => {
    //   if(!statisticsDaily.hasOwnProperty(cost.dateString)){
    //     statisticsDaily[cost.dateString] = {}
    //   }
    //   statisticsDaily[cost.dateString][cost.projectNumber] = 0 - cost.costs
    // })

    //-------------------------- creating weekly statistics from daily statistics---------------------




    //---------------------------- creating monthly statistics from daily statistics----------------------




    // ---------------------------- creating yearly statistics from daily statistics----------------------

    return {
      daily: Object.keys(statisticsDaily).length !== 0 ? statisticsDaily : undefined,
      weekly: Object.keys(statisticsWeekly).length !== 0 ? statisticsWeekly : undefined,
      monthly: Object.keys(statisticsMonthly).length !== 0 ? statisticsMonthly : undefined,
      yearly: Object.keys(statisticsYearly).length !== 0 ? statisticsYearly : undefined,
    };

  }



  // Function to convert daily report to weekly report
  function convertToWeeklyReport(dailyReport: Record<string, Record<string, number>>): Record<string, Record<string, number>> {
    const weeklyReport: Record<string, Record<string, number>> = {};

    // Iterate over the daily report entries
    for (const [date, companies] of Object.entries(dailyReport)) {
      const weekStartDate = getWeekStartDate(date); // Get the start date of the week

      // Check if the week exists in the weekly report, if not, initialize it
      if (!weeklyReport.hasOwnProperty(weekStartDate)) {
        weeklyReport[weekStartDate] = {};
      }

      // Iterate over the companies in the daily report entry
      for (const [company, value] of Object.entries(companies)) {
        // Check if the company exists in the weekly report for the given week, if not, initialize it
        if (!weeklyReport[weekStartDate].hasOwnProperty(company)) {
          weeklyReport[weekStartDate][company] = 0;
        }

        // Accumulate the daily value into the weekly report
        weeklyReport[weekStartDate][company] += value;
      }
    }

    return weeklyReport;
  }
  function convertResultToWeeklyReport(dailyReport: Record<string, Record<string, resultStatisticsObject>>): Record<string, Record<string, resultStatisticsObject>> {
    const weeklyReport: Record<string, Record<string, resultStatisticsObject>> = {};

    // Iterate over the daily report entries
    for (const [date, companies] of Object.entries(dailyReport)) {
      const weekStartDate = getWeekStartDate(date); // Get the start date of the week

      // Check if the week exists in the weekly report, if not, initialize it
      if (!weeklyReport.hasOwnProperty(weekStartDate)) {
        weeklyReport[weekStartDate] = {};
      }

      // Iterate over the companies in the daily report entry
      for (const [company, value] of Object.entries(companies)) {
        // Check if the company exists in the weekly report for the given week, if not, initialize it
        if (!weeklyReport[weekStartDate].hasOwnProperty(company)) {
          weeklyReport[weekStartDate][company] = { costs: 0, income: 0, incomeMinusCosts: 0, overheadPercentage: 0, overheadCosts: 0, otherCosts: 0, result: 0, resultPercentage: 0 };
        }

        // Accumulate the daily value into the weekly report
        weeklyReport[weekStartDate][company] = { ...weeklyReport[weekStartDate][company], costs: weeklyReport[weekStartDate][company].costs + value.costs, income: weeklyReport[weekStartDate][company].income + value.income };
      }
    }

    return weeklyReport;
  }
  // Function to get the start date of the week for a given date
  function getWeekStartDate(dateString: string): string {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    const weekStartDate = new Date(date.setDate(diff));
    return weekStartDate.toLocaleDateString().replaceAll(". ", "-").replace(".", "");
  }


  // Function to convert daily report to monthly statistics
  function convertToMonthlyStatistics(dailyReport: Record<string, Record<string, number>>): Record<string, Record<string, number>> {
    const monthlyStatistics: Record<string, Record<string, number>> = {};

    // Iterate over the daily report entries
    for (const [date, companies] of Object.entries(dailyReport)) {
      const [year, month] = date.split('-');
      const monthKey = `${year}-${month}`; // Generate the month key in the format "YYYY-MM"

      // Check if the month exists in the monthly statistics, if not, initialize it
      if (!monthlyStatistics.hasOwnProperty(monthKey)) {
        monthlyStatistics[monthKey] = {};
      }

      // Iterate over the companies in the daily report entry
      for (const [company, value] of Object.entries(companies)) {
        // Check if the company exists in the monthly statistics for the given month, if not, initialize it
        if (!monthlyStatistics[monthKey].hasOwnProperty(company)) {
          monthlyStatistics[monthKey][company] = 0;
        }

        // Accumulate the daily value into the monthly statistics
        monthlyStatistics[monthKey][company] += value;
      }
    }

    return monthlyStatistics;
  }
  function convertResultToMonthlyStatistics(dailyReport: Record<string, Record<string, resultStatisticsObject>>): Record<string, Record<string, resultStatisticsObject>> {
    const monthlyStatistics: Record<string, Record<string, resultStatisticsObject>> = {};

    // Iterate over the daily report entries
    for (const [date, companies] of Object.entries(dailyReport)) {
      const [year, month] = date.split('-');
      const monthKey = `${year}-${month}`; // Generate the month key in the format "YYYY-MM"

      // Check if the month exists in the monthly statistics, if not, initialize it
      if (!monthlyStatistics.hasOwnProperty(monthKey)) {
        monthlyStatistics[monthKey] = {};
      }

      // Iterate over the companies in the daily report entry
      for (const [company, value] of Object.entries(companies)) {
        // Check if the company exists in the monthly statistics for the given month, if not, initialize it
        if (!monthlyStatistics[monthKey].hasOwnProperty(company)) {
          monthlyStatistics[monthKey][company] = { costs: 0, income: 0, incomeMinusCosts: 0, overheadPercentage: 0, overheadCosts: 0, otherCosts: 0, result: 0, resultPercentage: 0 };
        }

        // Accumulate the daily value into the monthly statistics
        monthlyStatistics[monthKey][company] = { ...monthlyStatistics[monthKey][company], costs: monthlyStatistics[monthKey][company].costs + value.costs, income: monthlyStatistics[monthKey][company].income + value.income };;
      }
    }

    return monthlyStatistics;
  }


  // Function to convert daily report to yearly report
  function convertToYearlyReport(dailyReport: Record<string, Record<string, number>>): Record<string, Record<string, number>> {
    const yearlyReport: Record<string, Record<string, number>> = {};

    // Iterate over the daily report entries
    for (const [date, companies] of Object.entries(dailyReport)) {
      const [year] = date.split('-');

      // Check if the year exists in the yearly report, if not, initialize it
      if (!yearlyReport.hasOwnProperty(year)) {
        yearlyReport[year] = {};
      }

      // Iterate over the companies in the daily report entry
      for (const [company, value] of Object.entries(companies)) {
        // Check if the company exists in the yearly report for the given year, if not, initialize it
        if (!yearlyReport[year].hasOwnProperty(company)) {
          yearlyReport[year][company] = 0;
        }

        // Accumulate the daily value into the yearly report
        yearlyReport[year][company] += value;
      }
    }

    return yearlyReport;
  }
  function convertResultToYearlyReport(dailyReport: Record<string, Record<string, resultStatisticsObject>>): Record<string, Record<string, resultStatisticsObject>> {
    const yearlyReport: Record<string, Record<string, resultStatisticsObject>> = {};

    // Iterate over the daily report entries
    for (const [date, companies] of Object.entries(dailyReport)) {
      const [year] = date.split('-');

      // Check if the year exists in the yearly report, if not, initialize it
      if (!yearlyReport.hasOwnProperty(year)) {
        yearlyReport[year] = {};
      }

      // Iterate over the companies in the daily report entry
      for (const [company, value] of Object.entries(companies)) {
        // Check if the company exists in the yearly report for the given year, if not, initialize it
        if (!yearlyReport[year].hasOwnProperty(company)) {
          yearlyReport[year][company] = { costs: 0, income: 0, incomeMinusCosts: 0, overheadPercentage: 0, overheadCosts: 0, otherCosts: 0, result: 0, resultPercentage: 0 };
        }

        // Accumulate the daily value into the yearly report
        yearlyReport[year][company] = { ...yearlyReport[year][company], costs: yearlyReport[year][company].costs + value.costs, income: yearlyReport[year][company].income + value.income };
      }
    }

    return yearlyReport;
  }



  function calculateOverheadAndOtherCosts(statistics: Record<string, Record<string, resultStatisticsObject>>, projectList: projectType[]): Record<string, Record<string, resultStatisticsObject>> {
    
    // setting income minus costs
    Object.entries(statistics).forEach(([date, projectObject]) => {
      Object.entries(projectObject).forEach(([projectNumber, object]) => {
        const income = object.income
        const costs = object.costs

        const incomeMinusCosts = income - costs
        statistics[date][projectNumber].incomeMinusCosts = incomeMinusCosts
      })
    })

    // calculate how many times a projecNumber exists
    const howManyTimesProjectNumbersExists: { [key: string]: number } = {}
    Object.entries(statistics).forEach(([date, projectObject]) => {
      Object.keys(projectObject).forEach((projectNumber) => {
        howManyTimesProjectNumbersExists[projectNumber] = (howManyTimesProjectNumbersExists[projectNumber] || 0) + 1
      })
    })
    // console.log("howManyTimesProjectNumbersExists", howManyTimesProjectNumbersExists)

    // setting overhead percentage and other costs
    Object.entries(statistics).forEach(([date, projectObject]) => {
      Object.entries(projectObject).forEach(([projectNumber, object]) => {
        const incomeMinusCosts = object.incomeMinusCosts
        
        const overheadPercentage = projectList.find((project) => project.project_number === projectNumber)?.overhead_percentage || 0
        // console.log("overheadPercentage", projectNumber, projectList)
        
        // let overheadCosts = incomeMinusCosts / 100 * overheadPercentage
        // overheadCosts = Math.abs(Math.round(overheadCosts))
        // if(overheadPercentage === 0){
        //   overheadCosts = 0
        // }

        let overheadCosts = projectList.find((project) => project.project_number === projectNumber)?.overhead_costs || 0
        overheadCosts = overheadCosts / howManyTimesProjectNumbersExists[projectNumber]
        overheadCosts = Math.round(overheadCosts)

        let otherCosts = projectList.find((project) => project.project_number === projectNumber)?.other_costs || 0
        otherCosts = otherCosts / howManyTimesProjectNumbersExists[projectNumber]
        otherCosts = Math.round(otherCosts)

        statistics[date][projectNumber].overheadPercentage = overheadPercentage
        statistics[date][projectNumber].overheadCosts = overheadCosts
        statistics[date][projectNumber].otherCosts = otherCosts

        const result = incomeMinusCosts - overheadCosts - otherCosts
        statistics[date][projectNumber].result = result

        const income = statistics[date][projectNumber].income
        const resultPercentage = income === 0 ? -100 : Number((result / (object.income || 1) * 100).toFixed(2))
        statistics[date][projectNumber].resultPercentage = resultPercentage
      })
    })

    return statistics
  }

  function convertByProjectNumberToByContractor( statistics: { [key: string]: { [key: string]: resultStatisticsObject }}, ownContractsList: contractType[] ){
    const newStatisticsObject: { [key: string]: { [key: string]: resultStatisticsObject }} = {}

    
    Object.entries(statistics).forEach(([date, projectObject]) => {

      // const howManyTimesContainsTheSameCustomer: { [key: string]: number } = {}
      // Object.keys(projectObject).forEach((projectNumber) => {
      //   howManyTimesContainsTheSameCustomer[projectNumber] = (howManyTimesContainsTheSameCustomer[projectNumber] || 0) + 1
      // })

      Object.entries(projectObject).forEach(([projectNumber, object]) => {
        let contractor = ownContractsList.find((contract) => contract.project_number === projectNumber)
        let customer = contractor?.customer
        // let contractor = ownContractsList.filter((contract) => contract.project_number === projectNumber)
        // let customer = ""
        // if(contractor.length === 1){
        //   customer = contractor[0].customer
        // } else if(contractor.length > 1){
        //   const customerZero = contractor[0].customer
        //   const customerOne = contractor[1].customer
        //   customer = `${customerZero.slice(0, 13)}${customerZero.length > 13 ? "..." : ""} és ${customerOne.slice(0, 13)}${customerOne.length > 13 ? "..." : ""}`
        // }
        
        if(contractor && customer){

          if(!newStatisticsObject[date]){
            newStatisticsObject[date] = {}
          }
          if(!newStatisticsObject[date][customer]){
            newStatisticsObject[date][customer] = {
              costs: object.costs,
              income: object.income,
              incomeMinusCosts: object.incomeMinusCosts,
              overheadPercentage: object.overheadPercentage,
              overheadCosts: object.overheadCosts,
              otherCosts: object.otherCosts,
              result: object.result,
              resultPercentage: object.income === 0 ? -100 : object.resultPercentage
            }
          } else {
            const item = newStatisticsObject[date][customer]
            newStatisticsObject[date][customer] = {
              costs: item.costs + object.costs,
              income: item.income + object.income,
              incomeMinusCosts: item.incomeMinusCosts + object.incomeMinusCosts,
              overheadPercentage: Math.abs(Number(((item.overheadCosts + object.overheadCosts) / ((item.incomeMinusCosts + object.incomeMinusCosts) || 1) * 100).toFixed(2))),
              overheadCosts: item.overheadCosts + object.overheadCosts,
              otherCosts: item.otherCosts + object.otherCosts,
              result: item.result + object.result,
              resultPercentage: (item.income + object.income) === 0 ? -100 : Number(((item.result + object.result) / ((item.income + object.income) || 1) * 100).toFixed(2))
            }
          }
        }
      })
    })

    console.log("newStatisticsObject", newStatisticsObject)

    return newStatisticsObject
  }


  function combineStatistics(statistics: { [key: string]: { [key: string]: resultStatisticsObject } } | undefined, mode: "monthly" | "yearly"){
    const newObject: { [key: string]: { [key: string]: resultStatisticsObject } } = {}

    if(!statistics){
      return undefined
    }

    Object.entries(statistics).forEach(([key, value]) => {

      const costs = Object.values(value).reduce((a, b) => a + b.costs, 0)
      const income = Object.values(value).reduce((a, b) => a + b.income, 0)
      const incomeMinusCosts = Object.values(value).reduce((a, b) => a + b.incomeMinusCosts, 0)
      const otherCosts = Object.values(value).reduce((a, b) => a + b.otherCosts, 0)
      const overheadPercentage = Object.values(value).reduce((a, b) => a + b.overheadPercentage, 0)
      const overheadCosts = Object.values(value).reduce((a, b) => a + b.overheadCosts, 0)
      const result = Object.values(value).reduce((a, b) => a + b.result, 0)
      const resultPercentage = Object.values(value).reduce((a, b) => a + b.resultPercentage, 0)
      
      if(mode === "monthly"){
        const month = `${key.split("-")[0]}-${key.split("-")[1]}`

        if(!newObject[month]){
          newObject[month] = {}
        }
        newObject[month][key] = {
          costs: costs,
          income: income,
          incomeMinusCosts: incomeMinusCosts,
          overheadPercentage: overheadCosts / incomeMinusCosts * 100,
          overheadCosts: overheadCosts,
          otherCosts: otherCosts,
          result: result,
          resultPercentage: income === 0 ? -100 : Number((result / (income || 1) * 100).toFixed(2)),
      }
      }
      if(mode === "yearly"){
        const year = key.split("-")[0]

        if(!newObject[year]){
          newObject[year] = {}
        }
        newObject[year][key] = {
          costs: costs,
          income: income,
          incomeMinusCosts: incomeMinusCosts,
          overheadPercentage: overheadCosts / incomeMinusCosts * 100,
          overheadCosts: overheadCosts,
          otherCosts: otherCosts,
          result: result,
          resultPercentage: income === 0 ? -100 : Number((result / (income || 1) * 100).toFixed(2)),
      }
      }
    })

    return newObject
  }



  function setupTableOfContents(){
    const tableOfContentsArray: { label: string; ref: string; order: number }[] = []
    refs = []

    if(treeViewSelectedIds.some(num => num >= 1 && num <= 16)){
      refs.push(costs)
      tableOfContentsArray.push({
        "label": "Költségek",
        "ref": "",
        "order": 1
      })
    }


    if(treeViewSelectedIds.some(num => num >= 2 && num <= 6)){
      refs.push(costsByCompany)
      tableOfContentsArray.push({
        "label": "Cég szerinti bontásban",
        "ref": "",
        "order": 2
      })
    }
    if(treeViewSelectedIds.includes(3)){
      refs.push(costsByCompanyDaily)
      tableOfContentsArray.push({
        "label": "Napi",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(4)){
      refs.push(costsByCompanyWeekly)
      tableOfContentsArray.push({
        "label": "Heti",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(5)){
      refs.push(costsByCompanyMontly)
      tableOfContentsArray.push({
        "label": "Havi",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(6)){
      refs.push(costsByCompanyYearly)
      tableOfContentsArray.push({
        "label": "Évi",
        "ref": "",
        "order": 3
      })
    }


    if(treeViewSelectedIds.some(num => num >= 7 && num <= 11)){
      refs.push(costsByDatatable)
      tableOfContentsArray.push({
        "label": "Adattábla szerinti bontásban",
        "ref": "",
        "order": 2
      })
    }
    if(treeViewSelectedIds.includes(8)){
      refs.push(costsByDatatableDaily)
      tableOfContentsArray.push({
        "label": "Napi",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(9)){
      refs.push(costsByDatatableWeekly)
      tableOfContentsArray.push({
        "label": "Heti",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(10)){
      refs.push(costsByDatatableMonthly)
      tableOfContentsArray.push({
        "label": "Havi",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(11)){
      refs.push(costsByDatatableYearly)
      tableOfContentsArray.push({
        "label": "Évi",
        "ref": "",
        "order": 3
      })
    }


    if(treeViewSelectedIds.some(num => num >= 12 && num <= 16)){
      refs.push(costsByProjectNumber)
      tableOfContentsArray.push({
        "label": "Munkaszám szerinti bontásban",
        "ref": "",
        "order": 2
      })
    }
    if(treeViewSelectedIds.includes(13)){
      refs.push(costsByProjectNumberDaily)
      tableOfContentsArray.push({
        "label": "Napi",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(14)){
      refs.push(costsByProjectNumberWeekly)
      tableOfContentsArray.push({
        "label": "Heti",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(15)){
      refs.push(costsByProjectNumberMonthly)
      tableOfContentsArray.push({
        "label": "Havi",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(16)){
      refs.push(costsByProjectNumberYearly)
      tableOfContentsArray.push({
        "label": "Évi",
        "ref": "",
        "order": 3
      })
    }





    if(treeViewSelectedIds.some(num => num >= 17 && num <= 25)){
      refs.push(income)
      tableOfContentsArray.push({
        "label": "Bevételek",
        "ref": "",
        "order": 1
      })
    }

    if(treeViewSelectedIds.includes(18)){
      refs.push(incomeByContractors)
      tableOfContentsArray.push({
        "label": "Megrendelő szerinti bontásban",
        "ref": "",
        "order": 2
      })
    }
    if(treeViewSelectedIds.includes(19)){
      refs.push(incomeByContractorsWeekly)
      tableOfContentsArray.push({
        "label": "Heti",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(20)){
      refs.push(incomeByContractorsMontly)
      tableOfContentsArray.push({
        "label": "Havi",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(21)){
      refs.push(incomeByContractorsYearly)
      tableOfContentsArray.push({
        "label": "Évi",
        "ref": "",
        "order": 3
      })
    }


    if(treeViewSelectedIds.includes(22)){
      refs.push(incomeByContractors)
      tableOfContentsArray.push({
        "label": "Munkaszám szerinti bontásban",
        "ref": "",
        "order": 2
      })
    }

    if(treeViewSelectedIds.includes(23)){
      refs.push(incomeByProjectNumbersWeekly)
      tableOfContentsArray.push({
        "label": "Heti",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(24)){
      refs.push(incomeByProjectNumbersMontly)
      tableOfContentsArray.push({
        "label": "Havi",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(25)){
      refs.push(incomeByProjectNumbersYearly)
      tableOfContentsArray.push({
        "label": "Évi",
        "ref": "",
        "order": 3
      })
    }


    if(treeViewSelectedIds.some(num => num >= 26 && num <= 36)){
      refs.push(result)
      tableOfContentsArray.push({
        "label": "Eredmények",
        "ref": "",
        "order": 1
      })
    }
    if(treeViewSelectedIds.includes(27)){
      refs.push(resultByMonthly)
      tableOfContentsArray.push({
        "label": "Havi",
        "ref": "",
        "order": 2
      })
    }
    if(treeViewSelectedIds.includes(28)){
      refs.push(resultByYearly)
      tableOfContentsArray.push({
        "label": "Éves",
        "ref": "",
        "order": 2
      })
    }

    if(treeViewSelectedIds.some(num => num >= 29 && num <= 32)){
      refs.push(resultByContractors)
      tableOfContentsArray.push({
        "label": "Megrendelő szerinti bontásban",
        "ref": "",
        "order": 2
      })
    }
    if(treeViewSelectedIds.includes(30)){
      refs.push(resultByContractorsWeekly)
      tableOfContentsArray.push({
        "label": "Heti",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(31)){
      refs.push(resultByContractorsMontly)
      tableOfContentsArray.push({
        "label": "Havi",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(32)){
      refs.push(resultByContractorsYearly)
      tableOfContentsArray.push({
        "label": "Évi",
        "ref": "",
        "order": 3
      })
    }

    if(treeViewSelectedIds.some(num => num >= 33 && num <= 36)){
      refs.push(resultByProjectNumbers)
      tableOfContentsArray.push({
        "label": "Munkaszám szerinti bontásban",
        "ref": "",
        "order": 2
      })
    }
    if(treeViewSelectedIds.includes(34)){
      refs.push(resultByProjectNumbersWeekly)
      tableOfContentsArray.push({
        "label": "Heti",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(35)){
      refs.push(resultByProjectNumbersMontly)
      tableOfContentsArray.push({
        "label": "Havi",
        "ref": "",
        "order": 3
      })
    }
    if(treeViewSelectedIds.includes(36)){
      refs.push(resultByProjectNumbersYearly)
      tableOfContentsArray.push({
        "label": "Évi",
        "ref": "",
        "order": 3
      })
    }


    return tableOfContentsArray

  }
  
  const queryMainViewport = useRef<HTMLDivElement>(null);

  const costs = useRef<HTMLDivElement>(null);

  const costsByCompany = useRef<HTMLDivElement>(null);
  const costsByCompanyDaily = useRef<HTMLDivElement>(null);
  const costsByCompanyWeekly = useRef<HTMLDivElement>(null);
  const costsByCompanyMontly = useRef<HTMLDivElement>(null);
  const costsByCompanyYearly = useRef<HTMLDivElement>(null);

  const costsByDatatable = useRef<HTMLDivElement>(null);
  const costsByDatatableDaily = useRef<HTMLDivElement>(null);
  const costsByDatatableWeekly = useRef<HTMLDivElement>(null);
  const costsByDatatableMonthly = useRef<HTMLDivElement>(null);
  const costsByDatatableYearly = useRef<HTMLDivElement>(null);

  const costsByProjectNumber = useRef<HTMLDivElement>(null);
  const costsByProjectNumberDaily = useRef<HTMLDivElement>(null);
  const costsByProjectNumberWeekly = useRef<HTMLDivElement>(null);
  const costsByProjectNumberMonthly = useRef<HTMLDivElement>(null);
  const costsByProjectNumberYearly = useRef<HTMLDivElement>(null);


  const income = useRef<HTMLDivElement>(null);
  const incomeByContractors = useRef<HTMLDivElement>(null);
  const incomeByContractorsWeekly = useRef<HTMLDivElement>(null);
  const incomeByContractorsMontly = useRef<HTMLDivElement>(null);
  const incomeByContractorsYearly = useRef<HTMLDivElement>(null);

  const incomeByProjectNumbers = useRef<HTMLDivElement>(null);
  const incomeByProjectNumbersWeekly = useRef<HTMLDivElement>(null);
  const incomeByProjectNumbersMontly = useRef<HTMLDivElement>(null);
  const incomeByProjectNumbersYearly = useRef<HTMLDivElement>(null);

  const result = useRef<HTMLDivElement>(null);
  const resultByMonthly = useRef<HTMLDivElement>(null);
  const resultByYearly = useRef<HTMLDivElement>(null);

  const resultByContractors = useRef<HTMLDivElement>(null);
  const resultByContractorsWeekly = useRef<HTMLDivElement>(null);
  const resultByContractorsMontly = useRef<HTMLDivElement>(null);
  const resultByContractorsYearly = useRef<HTMLDivElement>(null);

  const resultByProjectNumbers = useRef<HTMLDivElement>(null);
  const resultByProjectNumbersWeekly = useRef<HTMLDivElement>(null);
  const resultByProjectNumbersMontly = useRef<HTMLDivElement>(null);
  const resultByProjectNumbersYearly = useRef<HTMLDivElement>(null);

  

  useEffect(() => {
    search()
  }, [])
  

  if(isSearching){
    return (
      <div style={{height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column", gap: "10px", alignItems: "center"}}>
          <Loader size={"xl"}></Loader>
          <Text size="lg" weight={"bold"}>Lekérdezés összeállítása folyamatban...</Text>
        </div>
      </div>
    )
  }


  return (
    <>
    <div ref={queryMainViewport} style={{overflow: "auto", height: "100vh", padding: "20px"}} className="query-scrollable-div">
      {/* onScroll={() => onScroll()}> */}

      <Space h="xs" />
      <div style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
          <h2 style={{margin: "0px"}}>Kimutatás - {new Date().toLocaleDateString("hu-HU")}</h2>
          <div style={{marginLeft: "40px", display: "flex", gap: "15px"}}>
              <Button leftIcon={<Printer size={16} />}  variant={'light'} color={'gray'} onClick={() => {}}>Nyomtatás</Button>
              <Button leftIcon={<Filter size={16} />}  variant={'light'} onClick={() => {}}>Szűrés</Button>
              <ShowPureResult costDocuments={costDocuments.current} incomeObject={incomeObject.current} />
          </div>
      </div>


      {treeViewSelectedIds.some(num => num >= 1 && num <= 16) ? <h1 ref={costs} style={{margin: "30px 0px 10px 0px", fontSize: "48px", textAlign: "center" }}>Költségek</h1> : null}
      {treeViewSelectedIds.some(num => num >= 2 && num <= 6) ? <h2 ref={costsByCompany} style={{margin: "0px 0px 10px 0px", fontSize: "34px", textAlign: "center", fontWeight: "normal" }}>Cég szerinti csoportosítás</h2> : null}
      
      {treeViewSelectedIds.includes(3) ? (
        <div>
        <h3 ref={costsByCompanyDaily} style={{margin: "40px 0px 30px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Napi bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>
          {costsDataByCompanys?.daily ? Object.entries(costsDataByCompanys.daily).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'daily'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}
        </div>
        </div>
      ) : null}


      {treeViewSelectedIds.includes(4) ? (
        <>
        <h3 ref={costsByCompanyWeekly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Heti bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {costsDataByCompanys?.weekly ? Object.entries(costsDataByCompanys.weekly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'weekly'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(5) ? (
        <>
        <h3 ref={costsByCompanyMontly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Havi bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {costsDataByCompanys?.monthly ? Object.entries(costsDataByCompanys.monthly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'monthly'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(6) ? (
        <>
        <h3 ref={costsByCompanyYearly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Éves bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

        {costsDataByCompanys?.yearly ? Object.entries(costsDataByCompanys.yearly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'yearly'} mode={'result'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}
        </div>
        </>
      ) : null}






      {treeViewSelectedIds.some(num => num >= 7 && num <= 11) ? <h2 ref={costsByDatatable} style={{margin: "0px 0px 10px 0px", fontSize: "34px", textAlign: "center", fontWeight: "normal" }}>Adattábla szerinti csoportosítás</h2> : null}
      
      {treeViewSelectedIds.includes(8) ? (
        <>
        <h3 ref={costsByDatatableDaily} style={{margin: "0px 0px 30px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Napi bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {costsDataByDatatables?.daily ? Object.entries(costsDataByDatatables.daily).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'daily'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(9) ? (
        <>
        <h3 ref={costsByDatatableWeekly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Heti bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {costsDataByDatatables?.weekly ? Object.entries(costsDataByDatatables.weekly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'weekly'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(10) ? (
        <>
        <h3 ref={costsByDatatableMonthly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Havi bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {costsDataByDatatables?.monthly ? Object.entries(costsDataByDatatables.monthly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'monthly'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ): null}


      {treeViewSelectedIds.includes(11) ? (
        <>
        <h3 ref={costsByDatatableYearly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Éves bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

        {costsDataByDatatables?.yearly ? Object.entries(costsDataByDatatables.yearly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'yearly'} mode={'result'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}
        </div>
        </>
      ) : null}





      {treeViewSelectedIds.some(num => num >= 12 && num <= 16) ? <h2 ref={costsByProjectNumber} style={{margin: "0px 0px 10px 0px", fontSize: "34px", textAlign: "center", fontWeight: "normal" }}>Munkaszám szerinti csoportosítás</h2> : null}
      
      {treeViewSelectedIds.includes(13) ? (
        <>
        <h3 ref={costsByProjectNumberDaily} style={{margin: "0px 0px 30px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Napi bontásban</h3>
        
        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {costsDataByProjectNumbers?.daily ? Object.entries(costsDataByProjectNumbers.daily).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {

            return <BuiltyQueryCard type={'daily'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(14) ? (
        <>
        <h3 ref={costsByProjectNumberWeekly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Heti bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {costsDataByProjectNumbers?.weekly ? Object.entries(costsDataByProjectNumbers.weekly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'weekly'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(15) ? (
        <>
        <h3 ref={costsByProjectNumberMonthly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Havi bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {costsDataByProjectNumbers?.monthly ? Object.entries(costsDataByProjectNumbers.monthly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'monthly'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(16) ? (
        <>
        <h3 ref={costsByProjectNumberYearly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Éves bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

        {costsDataByProjectNumbers?.yearly ? Object.entries(costsDataByProjectNumbers.yearly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'yearly'} mode={'result'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}
        </div>
        </>
      ) : null}













      {treeViewSelectedIds.some(num => num >= 17 && num <= 25) ? <h1 ref={income} style={{margin: "30px 0px 10px 0px", fontSize: "48px", textAlign: "center" }}>Bevételek</h1> : null}
      {treeViewSelectedIds.some(num => num >= 18 && num <= 21) ? <h2 ref={incomeByContractors} style={{margin: "0px 0px 10px 0px", fontSize: "34px", textAlign: "center", fontWeight: "normal" }}>Megrendelő szerinti csoportosítás</h2> : null}
      
      {/* {treeViewSelectedIds.includes(13) ? (
        <>
        <h3 ref={costsByProjectNumberDaily} style={{margin: "0px 0px 30px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Napi bontásban</h3>
        
        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {Object.entries(incomeDataByContractors ? incomeDataByContractors.daily : {}).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'daily'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          })}

        </div>
        </>
      ) : null} */}


      {treeViewSelectedIds.includes(19) ? (
        <>
        <h3 ref={incomeByContractorsWeekly} style={{margin: "0px 0px 30px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Heti bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {incomeDataByContractors?.weekly ? Object.entries(incomeDataByContractors.weekly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'weekly'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(20) ? (
        <>
        <h3 ref={incomeByContractorsMontly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Havi bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {incomeDataByContractors?.monthly ? Object.entries(incomeDataByContractors.monthly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'monthly'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(21) ? (
        <>
        <h3 ref={incomeByContractorsYearly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Éves bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

        {incomeDataByContractors?.yearly ? Object.entries(incomeDataByContractors.yearly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'yearly'} mode={'result'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}
        </div>
        </>
      ) : null}




      {treeViewSelectedIds.some(num => num >= 22 && num <= 25) ? <h2 ref={incomeByProjectNumbers} style={{margin: "0px 0px 10px 0px", fontSize: "34px", textAlign: "center", fontWeight: "normal" }}>Munkaszám szerinti csoportosítás</h2> : null}
      
      {/* {treeViewSelectedIds.includes(13) ? (
        <>
        <h3 ref={costsByProjectNumberDaily} style={{margin: "0px 0px 30px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Napi bontásban</h3>
        
        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {Object.entries(incomeDataByProjectNumbers ? incomeDataByProjectNumbers.daily : {}).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'daily'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          })}

        </div>
        </>
      ) : null} */}


      {treeViewSelectedIds.includes(23) ? (
        <>
        <h3 ref={incomeByProjectNumbersWeekly} style={{margin: "0px 0px 30px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Heti bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {incomeDataByProjectNumbers?.weekly ? Object.entries(incomeDataByProjectNumbers.weekly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'weekly'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(24) ? (
        <>
        <h3 ref={incomeByProjectNumbersMontly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Havi bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {incomeDataByProjectNumbers?.monthly ? Object.entries(incomeDataByProjectNumbers.monthly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'monthly'} mode={'costs'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(25) ? (
        <>
        <h3 ref={incomeByProjectNumbersYearly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Éves bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

        {incomeDataByProjectNumbers?.yearly ? Object.entries(incomeDataByProjectNumbers.yearly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'yearly'} mode={'result'} date={new Date(key)} data={value} key={key} />
          }) : <NoQueryData />}
        </div>
        </>
      ) : null}








    {treeViewSelectedIds.some(num => num >= 26 && num <= 36) ? <h1 ref={income} style={{margin: "30px 0px 10px 0px", fontSize: "48px", textAlign: "center" }}>Eredmények</h1> : null}

    {treeViewSelectedIds.includes(27) ? (
        <>
        <h3 ref={resultByMonthly} style={{margin: "0px 0px 30px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Havi bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {resultData?.monthly ? Object.entries(resultData.monthly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {

            return <BuiltyQueryCard showOnlyGraph type={'monthly'} mode={'result'} date={new Date(key)} resultData={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}

      {treeViewSelectedIds.includes(28) ? (
        <>
        <h3 ref={resultByYearly} style={{margin: "0px 0px 30px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Éves bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {resultData?.yearly ? Object.entries(resultData.yearly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {

            return <BuiltyQueryCard showOnlyGraph type={'yearly'} mode={'result'} date={new Date(key)} resultData={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}

      

    {treeViewSelectedIds.some(num => num >= 29 && num <= 32) ? <h2 ref={resultByContractors} style={{margin: "0px 0px 10px 0px", fontSize: "34px", textAlign: "center", fontWeight: "normal" }}>Megrendelő szerinti csoportosítás</h2> : null}

      {treeViewSelectedIds.includes(30) ? (
        <>
        <h3 ref={resultByContractorsWeekly} style={{margin: "0px 0px 30px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Heti bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {resultDataByContractors?.weekly ? Object.entries(resultDataByContractors.weekly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'weekly'} mode={'result'} date={new Date(key)} resultData={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(31) ? (
        <>
        <h3 ref={resultByContractorsMontly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Havi bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {resultDataByContractors?.monthly ? Object.entries(resultDataByContractors.monthly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'monthly'} mode={'result'} date={new Date(key)} resultData={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(32) ? (
        <>
        <h3 ref={resultByContractorsYearly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Éves bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

        {resultDataByContractors?.yearly ? Object.entries(resultDataByContractors.yearly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'yearly'} mode={'result'} date={new Date(key)} resultData={value} key={key} />
          }) : <NoQueryData />}
        </div>
        </>
      ) : null}




      {treeViewSelectedIds.some(num => num >= 33 && num <= 36) ? <h2 ref={resultByProjectNumbers} style={{margin: "0px 0px 10px 0px", fontSize: "34px", textAlign: "center", fontWeight: "normal" }}>Munkaszámok szerinti csoportosítás</h2> : null}

      {treeViewSelectedIds.includes(34) ? (
        <>
        <h3 ref={resultByProjectNumbersWeekly} style={{margin: "0px 0px 30px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Heti bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {resultDataByProjectNumbers?.weekly ? Object.entries(resultDataByProjectNumbers.weekly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'weekly'} mode={'result'} date={new Date(key)} resultData={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(35) ? (
        <>
        <h3 ref={resultByProjectNumbersMontly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Havi bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {resultDataByProjectNumbers?.monthly ? Object.entries(resultDataByProjectNumbers.monthly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'monthly'} mode={'result'} date={new Date(key)} resultData={value} key={key} />
          }) : <NoQueryData />}

        </div>
        </>
      ) : null}


      {treeViewSelectedIds.includes(36) ? (
        <>
        <h3 ref={resultByProjectNumbersYearly} style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Éves bontásban</h3>

        <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>

          {resultDataByProjectNumbers?.yearly ? Object.entries(resultDataByProjectNumbers.yearly).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()).map(([key, value]) => {
            
            return <BuiltyQueryCard type={'yearly'} mode={'result'} date={new Date(key)} resultData={value} key={key} />
          }) : <NoQueryData />}
        </div>
        </>
      ) : null}




      {/* <h2 style={{margin: "0px 0px 10px 0px", fontSize: "34px", textAlign: "center", fontWeight: "normal" }}>Adattábla szerinti csoportosítás</h2>
      <h3 style={{margin: "0px 0px 30px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Napi bontásban</h3>
      
      <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>
      <BuiltyQueryCard type={'daily'}/>
      <BuiltyQueryCard type={'daily'}/>
      <BuiltyQueryCard type={'daily'}/>
      <BuiltyQueryCard type={'daily'}/>
      </div>
      <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>
      <BuiltyQueryCard type={'daily'}/>
      <BuiltyQueryCard type={'daily'}/>
      <BuiltyQueryCard type={'daily'}/>
      <BuiltyQueryCard type={'daily'}/>
      </div>

      <h3 style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Heti bontásban</h3>

      <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>
      <BuiltyQueryCard type={'weekly'}/>
      <BuiltyQueryCard type={'weekly'}/>
      </div>
      <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>
      <BuiltyQueryCard type={'weekly'}/>
      <BuiltyQueryCard type={'weekly'}/>
      </div>

      <h3 style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Havi bontásban</h3>

      <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>
      <BuiltyQueryCard type={'monthly'}/>
      </div>
      <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>
      <BuiltyQueryCard type={'monthly'}/>
      </div>

      <h3 style={{margin: "40px 0px 40px 0px", fontSize: "26px", textAlign: "center", fontWeight: "normal" }}>Éves bontásban</h3>

      <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>
      <BuiltyQueryCard type={'yearly'}/>
      </div>
      <div style={{display: "flex", flexWrap: "wrap", margin: "10px 0px 20px 0px", gap: "20px"}}>
      <BuiltyQueryCard type={'yearly'}/>
      </div> */}

      <Space h="lg" />
    </div>

    <TableOfContentsDrawer list={setupTableOfContents()} queryMainViewport={queryMainViewport}></TableOfContentsDrawer>

    </>
  )
}






type QueryReturnType = {
  companies_inside: string[],
  company: string,
  created_at: {_seconds: number, _nanoseconds: number,},
  data: string,
  date: {_seconds: number, _nanoseconds: number,},
  final_price: number,
  last_modified: {_seconds: number, _nanoseconds: number,},
  project_number: string,
  tables_inside: string[],
  user_id: string,
  _firestore_id: string
}
async function searchInDatabaseAndReturnResults(isDateRange: boolean, dateRangeValue: DateRangePickerValue, dateValue: Date | null, companysListValue: string[], projectNumbersListValue: string[], datatablesListValue: string[]) {
  
  const query = useFunctions("query")
  const userCompany = await useUserCompany()

  let results: any = await query({
      userCompany: userCompany,
      timezoneDifference: new Date().getTimezoneOffset() / 60 / -1,
      isDateRange: isDateRange,
      dateRangeValue: dateRangeValue,
      dateValue: dateValue,
      companysListValue: companysListValue,
      projectNumbersListValue: projectNumbersListValue,
      datatablesListValue: datatablesListValue
  })
  console.log("query results", results)


  const resultIWithAddedContractProgresses = results.data.resultIWithAddedContractProgresses.dailys as DailyPostType[]
  console.log("AddContractProgressToDaily results", results)

  let resultWithOwnContractProgressses = results.data.resultWithOwnContractProgressses.object
  console.log("resultWithOwnContractProgressses", resultWithOwnContractProgressses)


  return { "resultIWithAddedContractProgresses": resultIWithAddedContractProgresses, "resultWithOwnContractProgressses": resultWithOwnContractProgressses }

  // // setting dates
  // let dateFrom = isDateRange ? dateRangeValue[0] : dateValue
  // let dateTo = isDateRange ? dateRangeValue[1] : dateValue
  // if(dateTo !== null){
  //     let dateCopy = new Date(dateTo)
  //     dateCopy.setHours(dateCopy.getHours() + 23);
  //     dateCopy.setMinutes(dateCopy.getMinutes() + 59);
  //     dateCopy.setSeconds(dateCopy.getSeconds() + 59);
  //     dateTo = dateCopy
  // }
  // const dateIsNotNull = dateFrom !== null && dateTo !== null
  
  // // console.log(dateFrom)
  // // console.log(dateTo)

  // const db = useFirestore();
  // const company = await useUserCompany();

  // let filters = []
  // if(dateIsNotNull){
  //     filters.push(where("date", ">=", dateFrom))
  //     filters.push(where("date", "<=", dateTo))
  // }
  // if(companysListValue.length > 0){
  //     filters.push(where("companies_inside", "array-contains-any", companysListValue))
  // }
  // if(projectNumbersListValue.length > 0){
  //     filters.push(where("project_number", "in", projectNumbersListValue))
  // }
  // if(datatablesListValue.length > 0){
  //     filters.push(where("tables_inside", "array-contains-any", datatablesListValue))
  // }

  // let q = query(collection(db, "daily"), where("company", "==", company), ...filters);

  // const querySnapshot = await getDocs(q);
  // const results: DailyPostType[] = []
  // let index = 0
  // querySnapshot.forEach((doc) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     results.push({id: doc.id, ...doc.data()} as DailyPostType)
  //     console.log(index, doc.id, " => ", doc.data());
  //     index++
  // });

  // return results
  // if (dateIsNotNull && companysListValue.length > 0 && projectNumbersListValue.length > 0 && datatablesListValue.length > 0) {
  //     // Combination 1: [ 'Value1', 'Value2', 'Value3', 'Value4' ]

  //   } else if (dateIsNotNull && companysListValue.length > 0 && projectNumbersListValue.length > 0 && datatablesListValue.length === 0) {
  //     // Combination 2: [ 'Value1', 'Value2', 'Value3', null ]
  //     // Do something...
  //   } else if (dateIsNotNull && companysListValue.length > 0 && projectNumbersListValue.length === 0 && datatablesListValue.length > 0) {
  //     // Combination 3: [ 'Value1', 'Value2', null, 'Value4' ]
  //     // Do something...
  //   } else if (dateIsNotNull && companysListValue.length > 0 && projectNumbersListValue.length === 0 && datatablesListValue.length === 0) {
  //     // Combination 4: [ 'Value1', 'Value2', null, null ]
  //     // Do something...
  //   } else if (dateIsNotNull && companysListValue.length === 0 && projectNumbersListValue.length > 0 && datatablesListValue.length > 0) {
  //     // Combination 5: [ 'Value1', null, 'Value3', 'Value4' ]
  //     // Do something...
  //   } else if (dateIsNotNull && companysListValue.length === 0 && projectNumbersListValue.length > 0 && datatablesListValue.length === 0) {
  //     // Combination 6: [ 'Value1', null, 'Value3', null ]
  //     // Do something...
  //   } else if (dateIsNotNull && companysListValue.length === 0 && projectNumbersListValue.length === 0 && datatablesListValue.length > 0) {
  //     // Combination 7: [ 'Value1', null, null, 'Value4' ]
  //     // Do something...
  //   } else if (dateIsNotNull && companysListValue.length === 0 && projectNumbersListValue.length === 0 && datatablesListValue.length === 0) {
  //     // Combination 8: [ 'Value1', null, null, null ]
  //     // Do something...
  //   } else if (!dateIsNotNull && companysListValue.length > 0 && projectNumbersListValue.length > 0 && datatablesListValue.length > 0) {
  //     // Combination 9: [ null, 'Value2', 'Value3', 'Value4' ]
  //     // Do something...
  //   } else if (!dateIsNotNull && companysListValue.length > 0 && projectNumbersListValue.length > 0 && datatablesListValue.length === 0) {
  //     // Combination 10: [ null, 'Value2', 'Value3', null ]
  //     // Do something...
  //   } else if (!dateIsNotNull && companysListValue.length > 0 && projectNumbersListValue.length === 0 && datatablesListValue.length > 0) {
  //     // Combination 11: [ null, 'Value2', null, 'Value4' ]
  //     // Do something...
  //   } else if (!dateIsNotNull && companysListValue.length > 0 && projectNumbersListValue.length === 0 && datatablesListValue.length === 0) {
  //     // Combination 12: [ null, 'Value2', null, null ]
  //     // Do something...
  //   } else if (!dateIsNotNull && companysListValue.length === 0 && projectNumbersListValue.length > 0 && datatablesListValue.length > 0) {
  //     // Combination 13: [ null, null, 'Value3', 'Value4' ]
  //     // Do something…
  // } else if (!dateIsNotNull && companysListValue.length === 0 && projectNumbersListValue.length > 0 && datatablesListValue.length === 0) {
  //     // Combination 14: [ null, null, 'Value3', null ]
  //     // Do something…
  // } else if (!dateIsNotNull && companysListValue.length === 0 && projectNumbersListValue.length === 0 && datatablesListValue.length > 0) {
  //     // Combination 15: [ null, null, null, 'Value4' ]
  //     // Do something…
  // } else if (!dateIsNotNull && companysListValue.length === 0 && projectNumbersListValue.length === 0 && datatablesListValue.length === 0) {
  //     // Combination 16: [ null, null, null, null ]
  //     // Do something…
  // } else {
  //     // Handle any unexpected conditions…
  //     // this shouldnt happen
  //     showNotification({
  //         title: 'Hiba történt',
  //         message: 'Egy nem várt dolog következett be',
  //         color: 'red',
  //         icon: <IconExclamationCircle size={16} />
  //     })
  // }

  // Combination 1: [ 'Value1', 'Value2', 'Value3', 'Value4' ]
  // Combination 2: [ 'Value1', 'Value2', 'Value3', null ]
  // Combination 3: [ 'Value1', 'Value2', null, 'Value4' ]
  // Combination 4: [ 'Value1', 'Value2', null, null ]
  // Combination 5: [ 'Value1', null, 'Value3', 'Value4' ]
  // Combination 6: [ 'Value1', null, 'Value3', null ]
  // Combination 7: [ 'Value1', null, null, 'Value4' ]
  // Combination 8: [ 'Value1', null, null, null ]
  // Combination 9: [ null, 'Value2', 'Value3', 'Value4' ]
  // Combination 10: [ null, 'Value2', 'Value3', null ]
  // Combination 11: [ null, 'Value2', null, 'Value4' ]
  // Combination 12: [ null, 'Value2', null, null ]
  // Combination 13: [ null, null, 'Value3', 'Value4' ]
  // Combination 14: [ null, null, 'Value3', null ]
  // Combination 15: [ null, null, null, 'Value4' ]
  // Combination 16: [ null, null, null, null ]
}




function calc(documents: DailyPostType[], datatablesListValue: string[], companysListValue: string[], projectNumbersListValue: string[]) {

  let filteredDocuments: DailyPostType[] = JSON.parse(JSON.stringify(documents))

  // remove zero price
  filteredDocuments = filteredDocuments.filter((doc) => doc.final_price !== 0)


  // filtering by projectNumbersListValue
  if(projectNumbersListValue.length > 0){
    filteredDocuments = documents.filter((doc) => projectNumbersListValue.includes(doc.project_number))
  }

  // filtering by datatablesListValue
  if(datatablesListValue.length > 0){
    filteredDocuments.forEach((doc, docIndex) => {
      if(doc.data === "") return
      const data = JSON.parse(doc.data) as FormStateType
      
      const filteredData = data.filter((table) => datatablesListValue.includes(table.tableName))
      filteredDocuments[docIndex] = { ...doc, data: JSON.stringify(filteredData) }
    });
  }

  // filtering by companysListValue
  if(companysListValue.length > 0){
    console.log(filteredDocuments)
    filteredDocuments.forEach((doc, docIndex) => {
      if(doc.data === "") return
      const data = JSON.parse(doc.data) as FormStateType
      
      const filteredData = data.map((table, tableIndex) => {
          let filteredTable = table.data.map((row) => {
            const rowHasNecesarryCompany = row.some((x) => companysListValue.includes(String(x.value)))
            return rowHasNecesarryCompany ? row : null
          })
          filteredTable = filteredTable.filter((row) => row != null)

          return { tableName: table.tableName, data: filteredTable}
      })

      filteredDocuments[docIndex] = { ...doc, data: JSON.stringify(filteredData) }

    })
  }

  // update final_price
  filteredDocuments.forEach((doc, docIndex) => {
    if(doc.data === "") return

    let dailySum = 0
    
    // const formData = JSON.parse(doc.data) as FormStateType
    let formData = undefined
    // try {
      formData = JSON.parse(doc.data) as FormStateType;
    // } catch(error) { console.log(error, doc.data) }

    if(formData){
      formData.forEach((table) => {
        table.data.forEach((row) => {
          const rowPrice = row.find((x) => x.column === "counted_price")

          // console.log("rowPrice", rowPrice)
  
          if(rowPrice) {
            dailySum += Number(rowPrice.value)
          }
        })
      })
    }

    let contractProgressSum = 0;
    doc.companies_inside_added_progresses?.forEach((company) => {
      contractProgressSum += company.price
    })

    filteredDocuments[docIndex].final_price = dailySum + contractProgressSum
  })


  console.log("filteredDocuments", filteredDocuments)

  return filteredDocuments
}

async function fetchProjectDetailsAndOwnContracts(costDocuments: DailyPostType[], incomeObject: incomeObjectType){

  // get possible projectNumbers
  let projectNumbersToFilter = costDocuments.map((doc) => doc.project_number).filter((x) => x !== null && x !== "" && x !== undefined)
  Object.values(incomeObject).forEach((x) => {
    x.contractProgresses.forEach((y) => {
      projectNumbersToFilter.push(y.project_number)
    })
  })
  projectNumbersToFilter = [...new Set(projectNumbersToFilter)]

  const projectsList: projectType[] = []
  const ownContractsList: contractType[] = []

  if(projectNumbersToFilter.length !== 0){
    const db = useFirestore();
    const userCompany = await useUserCompany();
  
    // projectNumbersToFilter.forEach(async (projectNumber) => {
    for(const projectNumber of projectNumbersToFilter){

      // getting projects data
      let q = query(collection(db, "project"), where("company", "==", userCompany), where("project_number", "==", projectNumber));
      let querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
    
        const docData = doc.data() as projectType;
        projectsList.push(docData);
        // console.log("docData", docData, projectsList)
        // otherCostsAndOverheadCostsObject[docData.project_number] = (docData.other_costs || 0) + (docData.overhead_costs || 0);
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
      });

      // getting contracts data
      q = query(collection(db, "contract"), where("contractor", "==", userCompany), where("project_number", "==", projectNumber));
      querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
    
        const docData = doc.data() as contractType;
        ownContractsList.push(docData);
        // otherCostsAndOverheadCostsObject[docData.project_number] = (docData.other_costs || 0) + (docData.overhead_costs || 0);
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
      });

    }
  
    // console.log("projectsList", projectsList, projectNumbersToFilter)
  }


  return { projectsList, ownContractsList }

}





export type incomeObjectType = { [key: string]: { contractProgresses: contractProgressType[], countedChange: number } }
type resultObjectType = { [key: string]: number }

type projectType = { project_number: string, other_costs: number, overhead_costs: number, overhead_percentage: number }
export type resultStatisticsObject = { costs: number, income: number, incomeMinusCosts: number, overheadPercentage: number, overheadCosts: number, otherCosts: number, result: number, resultPercentage: number}