import { useState } from 'react';
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { keys } from '@mantine/utils';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import { useNumberWithSpaces } from '../../../../../../hooks/utils';
import { ArrowDown } from 'tabler-icons-react';
import { generateColorFromSeed } from '../BuiltyQueryCard/BuiltyQueryCard';

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: window.innerWidth < 1400 ? '2rem' : '3.5rem',
    height: window.innerWidth < 1400 ? '2rem' : '3.5rem',
    borderRadius: 21,
  },
}));

interface RowData {
  name: string;
  percentage: number;
  price: number;
  change: number;
}

interface TableSortProps {
  data: RowData[];
  sum: number;
}

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size="0.9rem" stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: RowData[], search: string) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys(data[0]).some((key) => item[key].toString().toLowerCase().includes(query))
  );
}

function sortData(
  data: RowData[],
  payload: { sortBy: keyof RowData | null; reversed: boolean; search: string }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {

        if(typeof a[sortBy] === "number" && typeof b[sortBy] === "number"){
          return Number(b[sortBy]) - Number(a[sortBy]);
        } else {
          return String(b[sortBy]).localeCompare(String(a[sortBy]));
        }
      }

      if(typeof a[sortBy] === "number" && typeof b[sortBy] === "number"){
        return Number(a[sortBy]) - Number(b[sortBy]);
      } else {
        return String(a[sortBy]).localeCompare(String(b[sortBy]));
      }

    }),
    payload.search
  );
}





export default function QueryCardYearlyTable({ data, sum }: TableSortProps) {
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const theme = useMantineTheme();

  const setSorting = (field: keyof RowData) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search: value }));
  };

  const rows = sortedData.map((row) => (
    <tr key={row.name}>

      <td style={{ width: "30%", paddingLeft: "0px"}}>
        <Text size={"md"}>{row.name}</Text>
      </td>

      <td style={{ paddingLeft: "0px"}}>
        <Text size={"md"}>{useNumberWithSpaces(row.percentage)} %</Text>
      </td>

      <td style={{ width: "15%", paddingLeft: "0px"}}>
        <Text size={"md"} weight={"bold"}>{useNumberWithSpaces(row.price)} Ft</Text>
      </td>

      {/* <td style={{ paddingLeft: "0px"}}>
        <div style={{display: "flex", alignItems: "center", marginTop: "0px"}}>
            <ArrowDown size={20} color={theme.colors.green[6]}></ArrowDown>
            <Text size={'md'} color={theme.colors.green[6]}>{row.change} %</Text>        
        </div>
      </td> */}

      <td style={{ paddingLeft: "0px", paddingRight: "0px"}}>
        <div style={{width: "350px", height: "15px", backgroundColor: theme.colors.gray[8], borderRadius: "10px"}}>
            <div style={{ width: (row.price / sum * 100).toFixed(2)  + "%", height:"100%", borderRadius: "10px", backgroundColor: generateColorFromSeed(row.name, false, 0.65)}}></div>
        </div>
      </td>

    </tr>
  ));

  return (
    <ScrollArea>
      <TextInput
        placeholder="Keress..."
        mb="md"
        icon={<IconSearch size="0.9rem" stroke={1.5} />}
        value={search}
        onChange={handleSearchChange}
      />
      <Table horizontalSpacing="xs" verticalSpacing="sm">
        <thead>
          <tr>
            <Th
              sorted={sortBy === 'name'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('name')}
            >
              Név
            </Th>
            <Th
              sorted={sortBy === 'percentage'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('percentage')}
            >
              Százalék
            </Th>
            <Th
              sorted={sortBy === 'price'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('price')}
            >
              Ár
            </Th>
            {/* <Th
              sorted={sortBy === 'change'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('change')}
            >
              Változás
            </Th> */}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              <td colSpan={Object.keys(data).length === 0 ? 1 : Object.keys(data[0]).length}>
                <Text weight={500} align="center">
                  Nincs találat
                </Text>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}