import React from 'react'
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ChartOptions,
    LineController,
    } from 'chart.js';
    import type { ChartData, ChartDataset } from 'chart.js';
  import { Bar, Chart } from 'react-chartjs-2'
import { useMantineTheme } from '@mantine/core';
  
  ChartJS.register(
    CategoryScale,
    BarElement,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
    LineController
   );


interface BuiltyCardProps {
    maxHeight?: number | string,
    height?: number | string,
    width: number | string,
    padding: string,
    label: string,
    labels: string[],
    data: number[],
    backgroundColor?: any,
    borderColor?: any,
    stack?: string,
    datasets?: ChartDataset<"bar">[]
    showLabel?: boolean,
    hightlightZeroLine?: boolean
}
export default function BuiltyBarChart({ maxHeight, height, width, padding, label, labels, data, backgroundColor, borderColor, stack, datasets, showLabel, hightlightZeroLine }: BuiltyCardProps) {

  const theme = useMantineTheme()

  return (
    <Bar
        style={{maxHeight: maxHeight, height: height, padding: padding, width: width}}
        data={{
            labels: labels,
            datasets: datasets ? datasets : [{
                label: label,
                data: data,
                backgroundColor: backgroundColor ? backgroundColor : theme.colors.green[1],
                borderColor: borderColor ? borderColor : theme.colors.green[8],
                maxBarThickness: 60,
                stack: stack ? stack : undefined,
            }]
        }}
        options={{
            plugins: {
                legend: {
                    display: false
                },
                datalabels: {
                    display: false
                },
                tooltip: {
                    titleFont: {
                        size: 16
                    },
                    bodyFont: {
                        size: 16
                    },
                    callbacks: {
                        label: function(context) {
                          let label = context.dataset.label || '';
    
                          if (label) {
                              label += ': ';
                          }
                          if (context.parsed.y !== null) {
                              label += new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits: 0 }).format(context.parsed.y);
                          }
                          return label;
                        }    
                      }
                },
            },
            scales: {
                y: {
                  grid: {
                    color(ctx, options) {
                        return hightlightZeroLine ? (ctx.tick.value === 0 ? "#fff" : "rgba(0, 0, 0, 0.1)") : "rgba(0, 0, 0, 0.1)";
                    },
                  },
                  beginAtZero: true,
                  ticks: {
                    font: {
                      size: 16
                    }
                  }
                },
                x: {
                  beginAtZero: true,
                  ticks: {
                    font: {
                      size: showLabel === false ? 0 : 16
                    },
                  },
                },
              },
        }}
    />
  )
}
