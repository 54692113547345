import { Accordion, Button, Checkbox, Container, createStyles, Menu, Modal, MultiSelect, Paper, ScrollArea, Table, Text, Title as AccordationTitle, useMantineTheme } from '@mantine/core'
import { DatePicker, DateRangePicker, DateRangePickerValue } from '@mantine/dates';
import { showNotification } from '@mantine/notifications';
import { IconExclamationCircle, IconX } from '@tabler/icons';
import { collection, doc, Firestore, getDoc, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { CirclePlus, Edit, Filter, Magnet, Pencil, Plus, Printer, Search, Trash, X } from 'tabler-icons-react';
import BuiltyBarChart from '../../../../components/Charts/BuiltyBarChart';
import SearchMultipleCompany from '../../../../components/SearchCompany/SearchMultipleCompany';
import { useAuth, useFirestore, useFunctions, useUserCompany } from '../../../../hooks/useFirebase';
import { DailyPostType } from '../../../../interfaces/dailyPostType';
import QueryResultItem from './components/QueryResultItem';








import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
import { useAddContractProgressToDaily } from '../../../../hooks/useAddContractProgressToDaily';
import { groupedBarChartData } from '../../../../interfaces/groupedBarChartData';
import { FormStateType } from '../../../../interfaces/formTypes';
import BuiltyPieChart from '../../../../components/Charts/BuiltyPieChart';
import BuiltyQueryCard from './components/BuiltyQueryCard';
import BuiltyTreeView from './components/BuiltyTreeView';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '@mantine/hooks';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  export const options = {
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Bar Chart - Stacked',
      },
    },
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  
  export const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: [1,2,3,4,5,6,7],
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
      {
        label: 'Dataset 3',
        data: [1,2,3,4,5,6,7],
        backgroundColor: 'rgb(53, 162, 235)',
        stack: 'Stack 1',
      },
    ],
  };







export default function Query() {

  const theme = useMantineTheme();
  const navigate = useNavigate();

  const [projectNumbers, setProjectNumbers] = useState<string[]>([])
  const [datatables, setDatatables] = useState<string[]>([])

  const today = new Date();
  // today.setHours(0, 0, 0, 0);
  const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

  const [isDateRange, setIsDateRange] = useState(false)
  const [dateRangeValue, setDateRangeValue] = useState<DateRangePickerValue>([lastWeek, today]);
  const [dateValue, setDateValue] = useState<Date | null>(null);
  const [companysListValue, setCompanysListValue] = useState<string[]>(JSON.parse(localStorage.getItem('query-companysListValue') ?? "[]"))
  const [projectNumbersListValue, setProjectNumbersListValue] = useState<string[]>([])
  const [datatablesListValue, setDatatablesListValue] = useState<string[]>([])
  
  const [treeViewSelectedIds, setTreeViewSelectedIds] = useState<number[]>(JSON.parse(localStorage.getItem('query-treeViewSelectedIds') ?? "[]"))
  
  const [formStateInitialized, setFormStateInitialized] = useState(false)


  const [addModalIsOpened, setAddModalIsOpened] = useState(false)

  const [reportModalIsOpened, setReportModalIsOpened] = useState(false)

  
  async function getProjectNumbersAndDatatables(){
    const db = useFirestore()
    const user = useAuth()
    const docRef = doc(db, "user", user.uid);
    const docSnap = await getDoc(docRef);
    
    let projectNumbers: string[] = []
    let datatables: string[] = []
    // projectNumbers.push("Összes munkaszám")
    docSnap.data()?.project_numbers.forEach((project:string) => projectNumbers.push(project))
    docSnap.data()?.database.forEach((datatable:string) => datatables.push(datatable))

    setProjectNumbers(projectNumbers)
    setDatatables(datatables)
  }

  function initFormState(){

    const localdateRange = localStorage.getItem('query-isDateRange') === 'true' ? true : false

    let localdateRangeValue = JSON.parse(localStorage.getItem('query-dateRangeValue') ?? "null")
    // console.log(localdateRangeValue ? localdateRangeValue[0] : "")
    // console.log(localdateRangeValue ? localdateRangeValue[1] : "")
    localdateRangeValue = localdateRangeValue ? [new Date(localdateRangeValue[0] ?? lastWeek), new Date(localdateRangeValue[1] ?? today)] : [lastWeek, today]
    console.log([lastWeek, today])

    let localdateValue = JSON.parse(localStorage.getItem('query-dateValue') ?? "null")
    localdateValue = localdateValue ? new Date(localdateValue) : null

    const localcompanysListValue = JSON.parse(localStorage.getItem('query-companysListValue') ?? "[]")
    const localprojectNumbersListValue = JSON.parse(localStorage.getItem('query-projectNumbersListValue') ?? "[]")
    const localdatatablesListValue = JSON.parse(localStorage.getItem('query-datatablesListValue') ?? "[]")
    const localtreeViewSelectedIds = JSON.parse(localStorage.getItem('query-treeViewSelectedIds') ?? "[]")

    setIsDateRange(localdateRange)
    setDateRangeValue(localdateRangeValue)
    setDateValue(localdateValue)
    setCompanysListValue(localcompanysListValue)
    setProjectNumbersListValue(localprojectNumbersListValue)
    setDatatablesListValue(localdatatablesListValue)
    setTreeViewSelectedIds(localtreeViewSelectedIds)

    console.log({localdateRange, localdateRangeValue, localdateValue, localcompanysListValue, localprojectNumbersListValue, localdatatablesListValue, localtreeViewSelectedIds})

    setFormStateInitialized(true)
  }

  function onCreateButtonClick(){
    if(treeViewSelectedIds.length === 0){
      showNotification({
        title: 'Nincs kiválasztva kimutatás típus',
        message: 'Nem választottad ki hogy milyen típusú kimutatást szeretnél! Kérlek válassz min. 1 típust a "Mit szeretnél látni?" szekcióból'!,
        color: 'red',
        autoClose: 10000
      })
    } else {
      navigate(`/statistics/query-graphs/?isDateRange=${isDateRange}&dateRangeValue=${dateRangeValue.map((date) => date ? date.toLocaleDateString() : null)}&dateValue=${dateValue ? dateValue.toLocaleDateString() : null}&companysListValue=${companysListValue.map((company) => encodeURIComponent(company))}&projectNumbersListValue=${projectNumbersListValue.map((project) => encodeURIComponent(project))}&datatablesListValue=${datatablesListValue.map((datatable) => encodeURIComponent(datatable))}&treeViewSelectedIds=${treeViewSelectedIds}`)
    }
    // navigate("/statistics/query-graphs")
  }


  useEffect(() => {
    getProjectNumbersAndDatatables()
    initFormState()
  }, [])

  useEffect(() => {
    if(formStateInitialized){
      localStorage.setItem('query-isDateRange', JSON.stringify(isDateRange))
      localStorage.setItem('query-dateRangeValue', JSON.stringify(dateRangeValue.map((date) => date ? date.toLocaleDateString() : null)))
      localStorage.setItem('query-dateValue', JSON.stringify(dateValue ? dateValue.toLocaleDateString() : null))
      localStorage.setItem('query-companysListValue', JSON.stringify(companysListValue))
      localStorage.setItem('query-projectNumbersListValue', JSON.stringify(projectNumbersListValue))
      localStorage.setItem('query-datatablesListValue', JSON.stringify(datatablesListValue))
      localStorage.setItem('query-treeViewSelectedIds', JSON.stringify(treeViewSelectedIds))
    }
  }, [isDateRange, dateRangeValue, dateValue, companysListValue, projectNumbersListValue, datatablesListValue, treeViewSelectedIds])
  
  
  
    
  return (
    <>
      <div style={{ padding: "10px 0px 0px 0px", width: "100%", display: "flex", alignItems: "flex-start", gap: "20px" }}>
        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>

          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", paddingBottom: "20px" }}>
            <h2 style={{ margin: "0px" }}>Lekérdezések</h2>
            <Button leftIcon={<Plus size={20} />} onClick={() => onCreateButtonClick()}>Kimutatás készítése</Button>
          </div>

          <div style={{ display: "flex", gap: "30px", flexDirection: "row", justifyContent: "flex-start" }}>

            <div style={{padding: "20px", width: "50%", display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "25px", backgroundColor: theme.colors.dark[7], borderRadius: "10px", border: `1px solid ${theme.colors.dark[3]}`, boxShadow: "rgb(37 38 43) 0px 0px 10px 0px"}}>

                <Text size={"xl"}>Mire szeretnél szűrni?</Text>

                <ScrollArea h={"380px"}>
                <div style={{ width: '100%', display: "flex", alignItems: "flex-end", gap: "20px", marginBottom: "25px" }}>
                {isDateRange ?
                  <DateRangePicker
                    locale="hu"
                    label="Időtartam"
                    placeholder="Dátum"
                    value={dateRangeValue}
                    onChange={setDateRangeValue}
                    style={{ width: '100%' }}
                    clearable />
                  :
                  <DatePicker
                    locale="hu"
                    label="Dátum"
                    placeholder="Dátum"
                    value={dateValue}
                    onChange={(date) => setDateValue(date)}
                    style={{ width: '100%' }} />}

                  <Checkbox
                    label="Idősáv"
                    checked={isDateRange}
                    onChange={() => setIsDateRange(!isDateRange)}
                    style={{ marginTop: "10px", width: "95px", marginBottom: "7px" }} />
                </div>
                <div style={{marginBottom: "25px"}}>
                <SearchMultipleCompany
                  value={companysListValue}
                  onChange={setCompanysListValue}
                  canSelectItself={true}
                  styles={{ minWidth: '100%' }} />
                </div>
                <MultiSelect
                  data={projectNumbers}
                  label="Munkaszám(ok)"
                  placeholder="Munkaszám"
                  searchable
                  nothingFound="Nothing found"
                  style={{ width: '100%', marginBottom: "25px" }}
                  value={projectNumbersListValue}
                  onChange={setProjectNumbersListValue} />
                <MultiSelect
                  data={datatables}
                  label="Adattáblák"
                  placeholder="Adattábla"
                  searchable
                  nothingFound="Nothing found"
                  style={{ width: '100%', marginBottom: "25px" }}
                  value={datatablesListValue}
                  onChange={setDatatablesListValue} />
              </ScrollArea>

            </div>

            <div style={{ padding: "20px", backgroundColor: theme.colors.dark[7], borderRadius: "10px", border: `1px solid ${theme.colors.dark[3]}`, boxShadow: `rgb(37 38 43) 0px 0px 10px 0px` }}>
              <Text size={"xl"}>Mit szeretnél látni?</Text>
              <BuiltyTreeView selectedIds={treeViewSelectedIds} setSelectedIds={setTreeViewSelectedIds}/>
            </div>

          </div>
      </div>


      </div>
    {/* <Modal
      opened={addModalIsOpened}
      onClose={() => setAddModalIsOpened(false)}
      title="Új lekérdezés készítése"
      closeOnClickOutside={false}
    >
        <div style={{ padding: "15px 0px", width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "20px" }}>

          <div style={{ width: '100%', display: "flex", alignItems: "flex-end", gap: "20px" }}>
            {isDateRange ?
              <DateRangePicker
                locale="hu"
                label="Időtartam"
                placeholder="Dátum"
                value={dateRangeValue}
                onChange={setDateRangeValue}
                style={{ width: '100%' }}
                clearable />
              :
              <DatePicker
                locale="hu"
                label="Dátum"
                placeholder="Dátum"
                value={dateValue}
                onChange={(date) => setDateValue(date)}
                style={{ width: '100%' }} />}
            <Checkbox
              label="Idősáv"
              checked={isDateRange}
              onChange={() => setIsDateRange(!isDateRange)}
              style={{ marginTop: "10px", width: "95px", marginBottom: "7px" }} />
          </div>
          <MultiSelect
        data={['React', 'Angular', 'Svelte', 'Vue', 'Riot', 'Next.js', 'Blitz.js']}
        label="Cég(ek)"
        placeholder="Cég"
        searchable
        nothingFound="Nothing found"
        style={{ width: '100%' }}
        value={companysListValue}
        onChange={setCompanysListValue}
    />
          <SearchMultipleCompany
            value={companysListValue}
            onChange={setCompanysListValue}
            canSelectItself={true}
            styles={{ minWidth: '100%' }} />
          <MultiSelect
            data={projectNumbers}
            label="Munkaszám(ok)"
            placeholder="Munkaszám"
            searchable
            nothingFound="Nothing found"
            style={{ width: '100%' }}
            value={projectNumbersListValue}
            onChange={setProjectNumbersListValue} />
          <MultiSelect
            data={datatables}
            label="Adattáblák"
            placeholder="Adattábla"
            searchable
            nothingFound="Nothing found"
            style={{ width: '100%' }}
            value={datatablesListValue}
            onChange={setDatatablesListValue} />
          <Button
            fullWidth
            style={{ marginBottom: "-10px", marginTop: "20px" }}
            leftIcon={<Search size={16} />}
            loading={isSearching}
            onClick={() => search()}
          >
            {isSearching ? "Keresés..." : "Keresés"}
          </Button>
        </div>
      </Modal> */}


    {/* <Modal
        fullScreen
        title={}
        opened={reportModalIsOpened}
        onClose={() => setReportModalIsOpened(false)}
    >

    
    </Modal> */}

    </>
  )
}







const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    minHeight: 650,
  },

  title: {
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));