import React from 'react'
import { Button, ButtonProps, Group } from '@mantine/core';
import googleicon from '../../../assets/google.png';

export function GoogleButton(props: any) {
    return <Button leftIcon={<GoogleIcon/>} variant="default" color="gray" {...props} />;
  }


function GoogleIcon() {
  return (
    <img src={googleicon} alt="" width={"14px"} height={"14px"}/>
  )
}
