import { ActionIcon, Button, Center, Input, Menu, Modal, NumberInput, Paper, RingProgress, Select, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications';
import { deleteDoc, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { AlertCircle, At, Check, Edit, Pencil, Road, Selector, Tools, Trash, Select as SelectIcon, SquareCheck, CircleCheck } from 'tabler-icons-react'
import { useFirestore } from '../../../../hooks/useFirebase';
import { separateBy3Digits, useNumberWithSpaces } from '../../../../hooks/utils'
import CreateEditModal from '../Modal';
import './RingProgress.css';
import AnimatedRingProgress from '../../../../components/Charts/AnimatedRingProgress/AnimatedRingProgress';

interface ContractProps {
    modeIsOwnContracts: boolean,
    showEditButton: boolean,
    showSelectButtonInsteadOfContentButton: boolean,
    onSelectButtonClick: Function,
    firestore_id: string
    project_number: string,
    buyer: string,
    contractor: string,
    contract_price: number
    after_work_price: number,
    forint_readiness: number,
    percentage_readiness: number,
    project_numbers: {value:string,label:string}[]
    comment: string
}

export default function Contract({ modeIsOwnContracts, showEditButton, showSelectButtonInsteadOfContentButton, onSelectButtonClick, firestore_id, project_number, buyer, contractor, contract_price, after_work_price, forint_readiness, percentage_readiness, project_numbers, comment }: ContractProps) {

  const [modalOpened, setModalOpened] = useState(false)
  const [deleteModalOpened, setDeleteModalOpened] = useState(false)

  const [projectNumber, setProjectNumber] = useState<string | null>(project_number)
  const [buyerName, setBuyerName] = useState(buyer)
  const [contractorName, setContractorName] = useState(contractor)
  const [commentText, setCommentText] = useState(comment)

  const navigate = useNavigate()
  const db = useFirestore()

  
  async function editContract(){
    try {
    
        const docRef = doc(db, 'contract', firestore_id);
        // Update the timestamp field with the value from the server
        const updateTimestamp = await updateDoc(docRef, {
            project_number: projectNumber,
            customer: buyerName,
            contractor: contractorName,
            comment: commentText,
            last_modified: serverTimestamp(),
        });

        setModalOpened(false)

        showNotification({
            id: 'noti',
            color: "green",
            title: `Szerződés sikeresen módosítva`,
            icon: <Check size={16} />,
            message: 'A szerződést sikeresen módosítottuk',
            autoClose: 5000,
        });
    } catch (error) {
        console.log(error)
      showNotification({
        id: 'noti',
        color: "red",
        title: `Hiba történt`,
        icon: <AlertCircle size={16} />,
        message: 'Nem sikerült módosítani a szerződésed, kérlek később próbáld újra!',
        autoClose: false,
    });
    }
  }

  async function deleteContract(){
    try {
        await deleteDoc(doc(db, "contract", firestore_id));
        
        showNotification({
            id: 'noti',
            color: "green",
            title: `Szerződés sikeresen törölve`,
            icon: <Check size={16} />,
            message: 'A szerződést sikeresen töröltük',
            autoClose: 5000,
        });
    } catch (error) {
        console.log(error)
      showNotification({
        id: 'noti',
        color: "red",
        title: `Hiba történt`,
        icon: <AlertCircle size={16} />,
        message: 'Nem sikerült törölni a szerződésed, kérlek később próbáld újra!',
        autoClose: false,
    });
    }
  }
  

  return (
    <>
    <Paper shadow="xs" p="xl" withBorder style={{backgroundColor: "#25262b", marginBottom: "20px"}}>
        <div style={{display: "flex", width: "100%", justifyContent: "space-between", gap: "6vw"}}>

            <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                <div>
                    <div style={{display: "flex", gap: "30px"}}>
                        <div>
                            <div style={{display: "flex", gap: "15px", alignItems: "center"}}>
                                <h3 style={{margin:'0px', marginBottom: "5px"}}>{project_number}</h3>
                                <Road size={38}></Road>
                            </div>
                            {modeIsOwnContracts ? (
                                <Text>Megrendelő: {buyer}</Text>
                            ) : (
                                <Text>Alvállalkozó: {contractor}</Text>
                            )}
                        </div>
                        
                    </div>
                </div>

                <div style={{display:"flex", alignItems: "center", gap:"5px", height: "100px"}}>
                    {/* <Text>Készültség:</Text> */}
                    <AnimatedRingProgress percentage_readiness={percentage_readiness} modeIsOwnContracts={modeIsOwnContracts}></AnimatedRingProgress>
                    {/* <div className="progress-bar" role="progressbar" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}></div> */}
                    {/* <div className="progress-container" ref={percentageRef}>
                        <div className="progress-circle">
                            <div className="progress"></div>
                            <span className="progress-text">0%</span>
                        </div>
                    </div> */}

                </div>
            </div>

            <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                <div style={{display:"flex", flexDirection: "column", justifyContent: "space-between"}}>
                    <div>
                        <Text>Szerződéses Ár: <strong>{separateBy3Digits(Math.round(contract_price))} Ft</strong></Text>
                        <Text>Pótmunka Ár: <strong>{separateBy3Digits(Math.round(after_work_price))} Ft</strong></Text>
                    </div>
                    <Text>Készültség: <strong>{separateBy3Digits(Math.round(forint_readiness))} Ft</strong></Text>
                </div>
                
                <div style={{display:"flex", flexDirection: "column", gap: "20px"}}>
                    {showSelectButtonInsteadOfContentButton ? (
                        <Button leftIcon={<CircleCheck/>} variant={"outline"} style={{height: "80px"}} onClick={() => onSelectButtonClick()}>
                            Szerződéses <br /> <br /> felmérés megadása
                        </Button>
                    ) : (
                        <Button color={modeIsOwnContracts ? "cyan" : "green"} leftIcon={<Tools size={16}/>} variant={'outline'} onClick={() => navigate(`/${modeIsOwnContracts ? "own-contracts" : "subcontractor-contracts"}/${firestore_id}`)}>Műszaki tartalom</Button>
                    )}
                    {showEditButton ? (
                        <Menu shadow="md" width={200} position={'bottom-end'}>
                            <Menu.Target>
                                {/* <ActionIcon variant="outline" size={'lg'}><Edit size={16} /></ActionIcon> */}
                                <Button
                                    leftIcon={<Edit size={16}/>}
                                    variant={'filled'}
                                    color={'gray'}
                                >Szerkesztés</Button>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Item icon={<Pencil size={14} color={'lime'}/>} onClick={() => setModalOpened(true)}>Szerkesztés</Menu.Item>
                                <Menu.Item icon={<Trash size={14} />} color="red" onClick={() => setDeleteModalOpened(true)}>Törlés</Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    ) : ""}
                </div>
            </div>

        </div>
        
    </Paper>


    <CreateEditModal
        modeIsOwnContrats={modeIsOwnContracts}
        modeIsCreate={false}
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        onButtonClick={editContract}
        projectNumber={projectNumber}
        setProjectNumber={setProjectNumber}
        project_numbers={project_numbers}
        buyerName={buyerName}
        setBuyerName={setBuyerName}
        contractorName={contractorName}
        setContractorName={setContractorName}
        comment={commentText}
        setComment={setCommentText}
    />


    <Modal
        withCloseButton={false}
        opened={deleteModalOpened}
        onClose={() => setDeleteModalOpened(false)}
        title={<Text color={"red"}>FIGYELEM!</Text>}
    >
      <h2>A szerződés eltávolításával a benne található munkalapok, és táblák is törlődnek!</h2>
      <h2>
        Biztosan szeretnéd törölni ezt a szerződést?
      </h2>
      <div style={{display: "flex", width: "100%", justifyContent: "space-between", gap: "15px"}}>
        <Button variant='light' color={'gray'} fullWidth onClick={() => setDeleteModalOpened(false)}>Mégsem</Button>
        <Button variant='filled' color={'red'} fullWidth onClick={() => { setDeleteModalOpened(false), deleteContract() }}>Törlés</Button>
      </div>
    </Modal>

    </>
  )
}
