import { ScrollArea, useMantineTheme, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'

import ContractProgressCard from '../ContractProgressCard';
import { useFirestore } from '../../../../hooks/useFirebase';
import { query, collection, where, getDocs, onSnapshot, Unsubscribe, deleteDoc, doc, setDoc } from 'firebase/firestore';
import { contractProgressType } from '../../../../interfaces/contractProgressTypes';
import { useCapitalizeFirstLetter } from '../../../../hooks/utils';
import BuiltyLineChart from '../../../../components/Charts/BuiltyLineChart';
import { showNotification } from '@mantine/notifications';
import { AlertCircle, Check } from 'tabler-icons-react';


interface ChartAndListProps {
  modeIsOwnContracts: boolean
    selectedProjectNumber: string | null;
    setSelectedProjectNumber: React.Dispatch<React.SetStateAction<string | null>>;
    selectedContract: string | null;
    setSelectedContract: React.Dispatch<React.SetStateAction<string | null>>;
    selectedContractDocId: string;
    setSelectedContractDocId: React.Dispatch<React.SetStateAction<string>>;
    modeIsDelete: boolean
}
let unsubscribe: Unsubscribe | undefined;

export default function ChartAndList({ modeIsOwnContracts, selectedProjectNumber, setSelectedProjectNumber, selectedContract, setSelectedContract, selectedContractDocId, setSelectedContractDocId, modeIsDelete }: ChartAndListProps) {

  const theme = useMantineTheme();

  const [contractProgressList, setContractProgressList] = useState<{id:string, data:contractProgressType}[]>([])


  async function getContractProgresses(){
    const db = useFirestore()

    const q = query(collection(db, "contract-progress"), where("project_number", "==", selectedProjectNumber), where("contract_id", "==", selectedContractDocId));

    // const querySnapshot = await getDocs(q);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      
      console.log(querySnapshot.size);
      console.log(selectedProjectNumber)
      console.log(selectedContractDocId)
  
      let docs: {id:string, data:contractProgressType}[] = []
      querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          docs.push({id: doc.id, data: doc.data() as contractProgressType})
      });
  
      docs.sort((a, b) => b.data.created_at.seconds - a.data.created_at.seconds);
      setContractProgressList(docs)

    })

    return unsubscribe
  }

  async function deleteDataFromFirestore(contractProgressDocId: string){

    let docToUpdateWith = null
    if(contractProgressDocId === contractProgressList[0].id){
      console.log("Updating contract")

      docToUpdateWith = contractProgressList[1]
      const deletedDoc = contractProgressList[0]
      console.log("docToUpdateWith", docToUpdateWith, "deletedDoc", deletedDoc)
    }

    const db = useFirestore()
    try {
      // delete contract progress
      await deleteDoc(doc(db, "contract-progress", contractProgressDocId));

      // update contract's content to the last contract progress's content
      if(docToUpdateWith){
        await setDoc(doc(db, "contract", docToUpdateWith.data.contract_id), {
          content: docToUpdateWith.data.contract_content,
          percentage_readiness: docToUpdateWith.data.percentage_readiness,
          forint_readiness: docToUpdateWith.data.forint_readiness,
        }, {merge: true});
      }

      
      showNotification({
        color: 'green',
        title: 'Siker!',
        message: `A kért felmérést sikeresen eltávolítottuk!`,
        icon: <Check size={16} />,
        autoClose: 3000,
      })
    } catch (error) {
      console.log(error)
      showNotification({
        color: 'red',
        title: 'Ajjaj, hiba történt!',
        message: 'A kért szerződést nem sikerült eltávolítani!',
        icon: <AlertCircle size={16} />,
        autoClose: false,
      })
    }
  }

  
  useEffect(() => {

    if(selectedProjectNumber !== null && selectedContract !== null){
        getContractProgresses().then((unsub) => {
          unsubscribe = unsub;
        })
    }

    return () => {
      if(unsubscribe){
        unsubscribe()
        console.log("unsubscribed from contract progress " + selectedContract + " listening");
      }
    }
  }, [selectedContractDocId])

  useEffect(() => {
    if(selectedProjectNumber == null || selectedContract == null){
      setContractProgressList([])
      setSelectedContractDocId("")
    }
  }, [selectedProjectNumber, selectedContract])
  

  return (
    <ScrollArea style={{height: "70vh"}}>
      <div>
        {selectedProjectNumber != null && selectedContract != null && contractProgressList.length !== 0 && modeIsDelete == false ? (
            <BuiltyLineChart
              maxHeight={200}
              width={"100%"}
              padding={"20px 0"}
              label={modeIsOwnContracts ? 'Ősszesített bevétel' : 'Összesített költség'}
              labels={createChartData(contractProgressList).labels}
              data={createChartData(contractProgressList).data}
              backgroundColor={modeIsOwnContracts ? theme.colors.cyan[0] : theme.colors.green[0]}
              borderColor={modeIsOwnContracts ? theme.colors.cyan[8] : theme.colors.green[8]}
              aspectRatio={1144/200}
            />
        ) : ""}
      </div>

      <div style={{display: "flex", flexWrap: "wrap", gap: "2vw", padding: "20px 0 20px 0", justifyContent: "stretch"}}>
        {contractProgressList.map((contractProgress) => (
            <ContractProgressCard
              modeIsOwnContracts={modeIsOwnContracts}
              contractProgressDocId={contractProgress.id}
              key={contractProgress.id}
              projectNumber={contractProgress.data.project_number}
              contractor={contractProgress.data.contractor}
              customer={contractProgress.data.customer}
              forint_readiness={contractProgress.data.forint_readiness}
              percentage_readiness={contractProgress.data.percentage_readiness}
              created_at={contractProgress.data.created_at}
              modeIsDelete={modeIsDelete}
              deleteDataFromFirestore={deleteDataFromFirestore}
            />
        ))}
        {selectedProjectNumber == null || selectedContract == null ? (
          <div style={{width: "100%", height: "50vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Text>Válassz munkaszámot és szerződést!</Text>
          </div>
        ) : ""}
        {selectedProjectNumber != null && selectedContract != null && contractProgressList.length === 0 ? (
          <div style={{width: "100%", height: "50vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Text>Nincs szerződéses haladás rögzítve ehhez a szerződéshez.</Text>
          </div>
        ) : ""}
      </div>
      </ScrollArea>
  )
}


function createChartData(contractProgressList:{id:string, data:contractProgressType}[]){

  const copyedContractProgressList = [...contractProgressList]

  copyedContractProgressList.sort((a, b) => a.data.created_at.seconds - b.data.created_at.seconds);

  const chartData = {
      labels: copyedContractProgressList.map(contractProgress => useCapitalizeFirstLetter(new Intl.DateTimeFormat('hu-HU', { month: "2-digit", day: "2-digit"}).format(contractProgress.data.created_at.seconds*1000)).replace(".", "")),
      data: copyedContractProgressList.map(contractProgress => contractProgress.data.forint_readiness),
  }

  return chartData
}