import { Accordion, ActionIcon, Button, createStyles, Input, Modal, Table, Tooltip, Text, TextInput, Select, Textarea, Checkbox, Loader } from '@mantine/core'
import { useIntersection } from '@mantine/hooks';
import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown, DeviceFloppy, Edit, Plus, Trash } from 'tabler-icons-react'
import { useStore } from '../../../../hooks/useStore';
import { useNumberWithSpaces } from '../../../../hooks/utils';
import { contractContentType } from '../../../../interfaces/contractContentType';
import TableRow from '../TableRow';
import GroupChangeModal from '../../GroupChangeModal/GroupChangeModal';


const useStyles = createStyles((theme) => ({
    wrapper: {
      paddingTop: theme.spacing.xl * 2,
      paddingBottom: theme.spacing.xl * 2,
      minHeight: 650,
    },
  
    title: {
      marginBottom: theme.spacing.xl * 1.5,
    },
  
    item: {
      borderRadius: theme.radius.md,
      marginBottom: theme.spacing.xl,
  
      border: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
}));


interface TableBlockProps {
    modeIsEdit: boolean,
    modeIsWatch: boolean,
    tableBlock: {
        id: string,
        is_after_work: boolean,
        tableName: string;
        tableData: {
            id: string
            name: string;
            quantity: number;
            unit: string;
            total: number;
            measuredQuantity: number;
            forint_readiness: number;
            percentage_readiness: number
        }[];
    },
    tableIndex: number,
    sheetIndex: number,
    modeIsOwnContracts: boolean,
    setIsLoading: Function
}

export default function TableBlock({ modeIsEdit, modeIsWatch, tableBlock, tableIndex, sheetIndex, modeIsOwnContracts, setIsLoading }: TableBlockProps) {

  const [showLoadingWhenOpen, setShowLoadingWhenOpen] = useState(true);

  const { classes } = useStyles();
  const contractContent: contractContentType = useStore((state:any) => state.contractContent)
  const setContractContent = useStore((state:any) => state.setContractContent)

//   const [changeTableNameModalOpened, setChangeTableNameModalOpened] = useState(false)
//   const [deleteTableModalOpened, setDeleteTableModalOpened] = useState(false)

//   const [changeTableNameValue, setChangeTableNameValue] = useState("")
//   const [changeTableNameValueIsInvalid, setChangeTableNameValueIsInvalid] = useState(false)
//   const [changeTableNameIsAfterWork, setChangeTableNameIsAfterWork] = useState(false)
  

  const [addButtonIsLoading, setAddButtonIsLoading] = useState(false)


  function changeTableNameOrIsAfterWork(changedTableNameValue: string, changedTableNameIsAfterWork: boolean){
        const contractContentCopy = contractContent
        contractContentCopy[sheetIndex].sheetData[tableIndex].tableName = changedTableNameValue
        contractContentCopy[sheetIndex].sheetData[tableIndex].is_after_work = changedTableNameIsAfterWork
        setContractContent([...contractContentCopy])
        console.log(contractContentCopy)
  }

  function removeTable(){
    const contractContentCopy = contractContent
    contractContentCopy[sheetIndex].sheetData.splice(tableIndex, 1);
    setContractContent([...contractContentCopy])
    // setDeleteTableModalOpened(false)
  }

  function addNewRow(){
    // setAddButtonIsLoading(true)
    const contractContentCopy = contractContent
    contractContentCopy[sheetIndex].sheetData[tableIndex].tableData.push({
        id: Date.now().toString(),
        name: "",
        quantity: 0,
        unit: "",
        total: 0,
        measuredQuantity: 0,
        forint_readiness: 0,
        percentage_readiness: 0
    })
    setContractContent([...contractContentCopy])
    // setTimeout(() => { setAddButtonIsLoading(false) }, 500);
  }

  function onClick(){
    setTimeout(() => {
        setShowLoadingWhenOpen((value) => !value)
    }, 300);
  }

  return (
    <>
    <Accordion chevronPosition="left" chevron={<ChevronDown size={30} />} variant="separated" defaultChecked={false} key={tableIndex}>
        <Accordion.Item className={classes.item} value={tableBlock.id}>
            <Accordion.Control onClick={onClick}>
                <div style={{display: "flex", justifyContent: "space-between"}}>

                    <h3 style={{margin: "5px"}}>{tableBlock.tableName} {tableBlock.is_after_work == true ? "- Pótmunka" : ""}</h3>

                    {modeIsEdit ? (
                    <div style={{display: "flex", alignItems: "center", gap: "15px"}}>
                        <Text style={{marginRight: "5px"}}>Összesen: {useNumberWithSpaces(Math.round(tableBlock.tableData.reduce((a, b) => a + b.total, 0)))} Ft</Text>
                        
                        <TopBarEditButton tableBlock={tableBlock} changeTableNameOrIsAfterWork={changeTableNameOrIsAfterWork}></TopBarEditButton>

                        <TopBarDeleteButton removeTable={removeTable}></TopBarDeleteButton>

                        <TopBarNewRowButton addNewRow={addNewRow}></TopBarNewRowButton>
                    </div>
                    ) : (
                        !modeIsWatch ? <GroupChangeModal modeIsOwnContracts={modeIsOwnContracts} setIsLoading={setIsLoading} tableIndexToChange={{ tableIndex: tableIndex, sheetIndex: sheetIndex, tableNameToChange: tableBlock.tableName}} showOnlyIcon></GroupChangeModal> : null
                    )}

                </div>
            </Accordion.Control>
            
            <Accordion.Panel>

            {showLoadingWhenOpen ? (
                <Text>Betöltés...</Text>
            ) : (  
                <Table >
                    <thead>
                        <tr>
                        <th></th>
                        <th>Megnevezés</th>
                        <th>Mennyiség</th>
                        <th>Mértékegység</th>
                        <th>Összesen</th>
                        <th>Felmért mennyiség</th>
                        <th>Forintos Készültség</th>
                        <th>Százalékos készültség</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableBlock.tableData.map((row, index) => (
                            <TableRow key={row.id} modeIsEdit={modeIsEdit} modeIsWatch={modeIsWatch} rowIndex={index} row={row} sheetIndex={sheetIndex} tableIndex={tableIndex} />
                        ))}
                    </tbody>
                </Table>
            )}

            {modeIsEdit ? (
                    <div style={{display: "flex", alignItems: "center", gap: "15px", width: "100%", justifyContent: "flex-end", margin: "10px 0px 5px 0px"}}>

                    <TopBarEditButton tableBlock={tableBlock} changeTableNameOrIsAfterWork={changeTableNameOrIsAfterWork}></TopBarEditButton>

                    <TopBarDeleteButton removeTable={removeTable}></TopBarDeleteButton>

                    <TopBarNewRowButton addNewRow={addNewRow}></TopBarNewRowButton>

                    </div>
                    ) : ""}

            </Accordion.Panel>
        </Accordion.Item>
    </Accordion>

    </>
  )
}



function TopBarEditButton({ tableBlock, changeTableNameOrIsAfterWork }: { tableBlock: any, changeTableNameOrIsAfterWork: (changeTableNameValue: string, changeTableNameIsAfterWork: boolean) => void }){

    const [changeTableNameModalOpened, setChangeTableNameModalOpened] = useState(false)

    const [changeTableNameValue, setChangeTableNameValue] = useState("")
    const [changeTableNameValueIsInvalid, setChangeTableNameValueIsInvalid] = useState(false)
    const [changeTableNameIsAfterWork, setChangeTableNameIsAfterWork] = useState(false)

    useEffect(() => {
        if(changeTableNameValue.length != 0){
            setChangeTableNameValueIsInvalid(false)
        }
      }, [changeTableNameValue])

    return (
        <>
        <Tooltip label="Tábla nevének szerkesztése">
            <ActionIcon
                variant="outline"
                size={"lg"}
                color={"green"}
                onClick={(event) => {
                    event.stopPropagation(),
                    setChangeTableNameValue(tableBlock.tableName),
                    setChangeTableNameIsAfterWork(tableBlock.is_after_work || false)
                    setChangeTableNameModalOpened(true)
                }}
            >
                <Edit size={16} />
            </ActionIcon>
        </Tooltip>
        
        <Modal
            centered
            title={"Tábla nevének módosítása"}
            opened={changeTableNameModalOpened}
            onClose={() => setChangeTableNameModalOpened(false)}
        >
            <div>
                <TextInput
                    value={changeTableNameValue}
                    onChange={(event) => setChangeTableNameValue(event.currentTarget.value)}
                    error={changeTableNameValueIsInvalid ? "A tábla neve nem lehet üres" : null}
                />
                <div style={{marginTop: "25px", width: "100%", display: "flex", justifyContent: "center"}}>
                <Checkbox
                    label="Pótmunka"
                    color="green"
                    checked={changeTableNameIsAfterWork}
                    onChange={(event) => setChangeTableNameIsAfterWork(event.currentTarget.checked)}
                />
                </div>
                <Button
                    leftIcon={<DeviceFloppy/>}
                    style={{marginTop: "25px"}}
                    fullWidth
                    onClick={() => { changeTableNameOrIsAfterWork(changeTableNameValue, changeTableNameIsAfterWork), setChangeTableNameModalOpened(false) }}
                >
                    Mentés
                </Button>
            </div>
        </Modal>
        </>
    )
}

function TopBarDeleteButton({ removeTable }: { removeTable: () => void }){

    const [deleteTableModalOpened, setDeleteTableModalOpened] = useState(false)

    return (
        <>
        <Tooltip label="Tábla törlése">
            <ActionIcon
                variant="outline"
                size={"lg"}
                color={"red"}
                onClick={(event) => {
                    event.stopPropagation()
                    setDeleteTableModalOpened(true)
                }}
            >
                <Trash size={16} />
            </ActionIcon>
        </Tooltip>
        
        <Modal
        withCloseButton={false}
        opened={deleteTableModalOpened}
        onClose={() => setDeleteTableModalOpened(false)}
        >
            <h2>A tábla tartalmazhat adatokat, a tábla eltávolításával a benne levő adatok is törlődnek!</h2>
            <h2>
                Biztosan szeretnéd törölni ezt a táblát?
            </h2>
            <div style={{display: "flex", width: "100%", justifyContent: "space-between", gap: "15px"}}>
                <Button variant='light' color={'gray'} fullWidth onClick={() => setDeleteTableModalOpened(false) }>Mégsem</Button>
                <Button variant='filled' color={'red'} fullWidth onClick={() => { removeTable(), setDeleteTableModalOpened(false) }}>Törlés</Button>
            </div>
        </Modal>
        </>
    )
}

function TopBarNewRowButton({ addNewRow }: { addNewRow: () => void }){
    return (
        <Tooltip label="Ezzel a gombbal tudsz új sort hozzáadni ehhez a táblához">
            <Button
                variant="outline"
                leftIcon={<Plus/>}
                color={"gray"}
                // loading={addButtonIsLoading}
                style={{marginRight: "30px"}}
                onClick={(event) => {
                    event.stopPropagation()
                    addNewRow()
                }}
            >
                Új sor
            </Button>
        </Tooltip>
    )
}
