import Header from './components/Header';
import { Button } from '@mantine/core'
import React, { useLayoutEffect, useState } from 'react'
import { Plus } from 'tabler-icons-react'
import Dashboard from '../../components/dashboard'
import Cards from './components/Cards';
import { useStore } from '../../hooks/useStore';
import { DailyPostType } from '../../interfaces/dailyPostType';
import { useSessionStorage } from '@mantine/hooks';
import { useParams } from 'react-router-dom';

function DailyPosts() {
  
  const [projectNumbers, setProjectNumbers] = useState([])
  const [selectedProjectNumber, setSelectedProjectNumber] = useSessionStorage<string | null>({ key: 'dailyPost-selectedProjectNumber', defaultValue: null })
  
  const [modeIsDelete, setModeIsDelete] = useState(false)
  // const [withAddedContractProgress, setWithAddedContractProgress] = useState(false)
  
  const [posts, setPosts] = useState<DailyPostType[]>([])
  
  return (
    <>
      <Header setProjectNumbers={setProjectNumbers} projectNumbers={projectNumbers} selectedProjectNumber={selectedProjectNumber} setSelectedProjectNumber={setSelectedProjectNumber} mode={"dailyPost"} setModeIsDelete={setModeIsDelete} posts={posts}/>
      <Cards posts={posts} setPosts={setPosts} selectedProjectNumber={selectedProjectNumber} mode={"dailyPost"} modeIsDelete={modeIsDelete}/>
    </>
  )
}

export default DailyPosts