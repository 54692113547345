import { ActionIcon, Button, Divider, Input, Loader, Modal, NumberInput, Paper, Popover, Progress, ScrollArea, Select, Switch, Table, Text, Tooltip, useMantineTheme } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { ChartBar, ChartInfographic, ChartLine, ChartPie, DeviceFloppy, DotsVertical, Download, Edit, Plus, Printer } from 'tabler-icons-react'
import { yellow_button } from '../../App'
import { useNumberWithSpaces } from '../../hooks/utils'


interface StatisticsProgressProps {
    projectNumbersList: string[],
    selectedProjectNumber: string | null,
    setSelectedProjectNumber: Function,
    modeIsResult: boolean,
    leftTable: { rows: { name: string, value: number }[], footer: { name: string, value: number }}
    rightTable: { rows: { name: string, value: number }[], footer: { name: string, value: number }}
    resultCost?: number,
    barPercentage: number,
    history: { actualCostPercentage: number, date: string, subcontractorContractsProgressCosts: number, subcontractorContractsCosts: number}[]
    overheadPercentage: number,
    setoverheadPercentage: Function,
    overheadForint: number,
    setoverheadForint: Function,
    otherCosts: number,
    setotherCosts: Function,
    resultForint: number,
    isLoading: boolean,
}

export default function StatisticsProgress({ projectNumbersList, selectedProjectNumber, setSelectedProjectNumber, modeIsResult, leftTable, rightTable, resultCost, barPercentage, history, overheadPercentage, setoverheadPercentage, overheadForint, setoverheadForint, otherCosts, setotherCosts, resultForint, isLoading }: StatisticsProgressProps) {

  const [editRezsiModalIsOpened, setEditRezsiModalIsOpened] = useState(false)
  const [editEgyebModalIsOpened, setEditEgyebModalIsOpened] = useState(false)

//   const [rezsiPercentageValue, setRezsiPercentageValue] = useState(rightTable.rows[0].value)
//   const [rezsiForintValue, setRezsiForintValue] = useState(leftTable.footer.value / 100 * rightTable.rows[0].value)
//   const [egyebValue, setEgyebValue] = useState(rightTable.rows[1].value)

  const theme = useMantineTheme()

  const months = ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember', 'október', 'november', 'december'];
  const today = new Date();
  const monthName = months[today.getMonth()];
  const dayOfMonth = today.getDate();

  const todayDate = `${monthName} ${dayOfMonth}.`;


  return (
    <div style={{display: "flex", justifyContent: "space-between", gap:"25px", paddingTop: "15px"}}>


        <div style={{display:"flex", flexDirection: "column", justifyContent: "flex-start", width: "73%"}}>

            <div style={{display: "flex", gap: "20px", margin: `10px 0px ${modeIsResult ? 40 : 45}px 0px`}}>
                <Select
                    label=""
                    placeholder="Válassz munkaszámot!"
                    style={{flex: 1}}
                    clearable
                    searchable
                    nothingFound="Nincs találat"
                    maxDropdownHeight={280}
                    data={projectNumbersList}
                    value={selectedProjectNumber}
                    onChange={(value) => setSelectedProjectNumber(value)}
                />
                <Button leftIcon={<Printer size={16}/>} variant="default" disabled>Nyomtatás</Button>
                <Button leftIcon={<Download size={16}/>} disabled>Letöltés</Button>
            </div>
            

            {isLoading ? (
                <div style={{width: "100%", height: "50vh", display:"flex", alignItems: "center", justifyContent: "center"}}>
                    <Loader size={'lg'}/>
                </div>
            ) : (
                <div>

                <div style={{display: "flex", gap:"25px"}}>
                    <Table border={0} verticalSpacing="lg" fontSize="md" highlightOnHover style={{width: "50%"}}>
                        <thead>
                            <tr>
                            <th>Megnevezés</th>
                            <th>Ár</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leftTable.rows.map((row, index) => (
                                <tr key={index}>
                                    <td style={{maxWidth: "170px"}}>{row.name}</td>
                                    <td>{useNumberWithSpaces(row.value)} Ft</td>
                                </tr>
                            ))}
                            <tr style={{borderTop: "2px solid #C1C2C5"}}>
                                <td><strong>{leftTable.footer.name}</strong></td>
                                <td><strong>{useNumberWithSpaces(leftTable.footer.value)} Ft</strong></td>
                            </tr>
                        </tbody>
                    </Table>

                    <Table border={0} verticalSpacing="lg" fontSize="md" highlightOnHover style={{width: "50%"}}>
                        <thead>
                            <tr>
                            <th>Megnevezés</th>
                            <th>Ár</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rightTable.rows.map((row, index) => (
                                <tr key={index}>
                                    <td style={{maxWidth: "170px"}}>{row.name} {modeIsResult && index == 0 ? ` (${overheadPercentage} %)` : ""}</td>
                                    <td>{useNumberWithSpaces(modeIsResult ? ( index == 0 ? overheadForint : otherCosts) : row.value)} Ft</td>
                                    {modeIsResult ? (
                                        <Tooltip label="Szerkesztés">
                                            <ActionIcon variant="subtle" size="sm" style={{marginTop: "23px"}} onClick={() => { index == 0 ? setEditRezsiModalIsOpened(true) : setEditEgyebModalIsOpened(true)}}>
                                                <Edit size={16} />
                                            </ActionIcon>
                                        </Tooltip>
                                    ) : ""}
                                </tr>
                            ))}
                            <tr style={{borderTop: "2px solid #C1C2C5"}}>
                                <td><strong>{rightTable.footer.name}</strong></td>
                                <td><strong>{useNumberWithSpaces(rightTable.footer.value)} Ft</strong></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

                {modeIsResult ? (
                    <>
                    <h4 style={{textAlign: "center"}}>Eredmény forintban: {useNumberWithSpaces(resultForint)} Ft</h4>
                    <h4 style={{textAlign: "center"}}>Eredmény százalékban: {barPercentage} %</h4>
                    </>
                ) : ""}


                <br />
                <div style={{margin: `${modeIsResult ? -65 : 20}px 0px 0px 0px`, display: "flex", justifyContent: "space-between"}}>
                    <h3 style={{ display :"inline", color: "#2f9e44"}}>{barPercentage ? barPercentage : 0} %</h3>
                    {!modeIsResult ? (
                        <>
                        <h3 style={{color: "rgb(255 255 255 / 60%)"}}>{todayDate}</h3>
                        <h3
                        style={{ display :"inline", color: modeIsResult ? theme.colors.gray[4] : yellow_button}}
                        >
                            {barPercentage ? (100 - barPercentage < 0 ? 0 : parseFloat((100 - barPercentage).toFixed(2))) : 100} %
                        </h3>
                        </>
                    ) : ""}
                </div>
                <Progress
                    size="lg"
                    sections={[
                        { value: barPercentage ? barPercentage : 0, color: 'green' },
                        { value: barPercentage ? 100 - barPercentage : 100, color: modeIsResult ? theme.colors.gray[4] : yellow_button },
                    ]}
                />


            </div>
            )}
            
            

        </div>


        <div style={{backgroundColor: "transparent", width: "23%"}}>

                <Paper shadow="xs" p="md" style={{backgroundColor: "#25262b"}}>
                    <div style={{display: "flex", justifyContent: "space-between", padding: "0px 10px"}}>
                        <Text weight={'bold'} size={'lg'}>Előzmények</Text>
                        <div style={{display:"flex", gap: "10px"}}>
                            <ActionIcon variant="outline">
                                <ChartPie size="1.125rem"/>
                            </ActionIcon>

                            {/* <Popover width={200} position="bottom" withArrow shadow="md">
                                <Popover.Target>
                                    <ActionIcon variant="outline">
                                        <DotsVertical size="1.125rem"/>
                                    </ActionIcon>
                                </Popover.Target>
                                <Popover.Dropdown sx={(theme) => ({ background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white })}>
                                    <div style={{display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                                        <Text>Pontos adatok</Text>
                                        <Switch
                                            label=""
                                            color="green"
                                        />
                                    </div>
                                </Popover.Dropdown>
                            </Popover> */}
                        </div>
                    </div>
                    <ScrollArea style={{height: "65vh", marginTop: "10px"}} offsetScrollbars>
                        
                        {history.length == 0 ? (
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "60vh", paddingLeft: "10px"}}>
                                {isLoading ? (
                                    <Loader size={"md"}/>
                                ) : (
                                    <Text>Nincs előzmény</Text>
                                )}

                            </div>
                        ) :
                        history.map((item, index) => (
                            <div style={{paddingLeft: "10px", paddingBottom: "15px"}} key={index}>
                                <div style={{ display: "flex", justifyContent: "space-between"}}>
                                    <h3 style={{ display :"inline", color: "#2f9e44"}}>{item.actualCostPercentage} %</h3>
                                    <h3 style={{color: "rgb(255 255 255 / 60%)"}}>{item.date}</h3>
                                    <h3 style={{ display :"inline", color: modeIsResult ? theme.colors.gray[4] : yellow_button}}>{100 - item.actualCostPercentage} %</h3>
                                </div>
                                <Progress
                                    size="lg"
                                    sections={[
                                        { value: item.actualCostPercentage, color: 'green' },
                                        { value: 100 - item.actualCostPercentage, color: modeIsResult ? theme.colors.gray[4] : yellow_button },
                                    ]}
                                />
                            </div>
                        ))}
                        
                        
                        
                    </ScrollArea>
                </Paper>
            </div>

            <Modal opened={editRezsiModalIsOpened} onClose={() => setEditRezsiModalIsOpened(false)} title="Rezsi megadása" centered>
                <div style={{display: "flex", gap: "20px"}}>
                    <NumberInput
                        label='Rezsi százalék'
                        style={{width: "40%"}}
                        // rightSection={"%"}
                        precision={2}
                        value={overheadPercentage}
                        onChange={(e) => { setoverheadPercentage(Number(e || 0)),
                            // console.log(Number(e || 0), Number(leftTable.footer.value) / 100 * Number(e || 0)),
                            setoverheadForint(Number(Math.abs(Number(leftTable.footer.value) / 100 * Number(e || 0)).toFixed(0)))
                        }}
                    >
                    </NumberInput>
                    <NumberInput
                        label='Rezsi forint'
                        style={{width: "60%"}}
                        // rightSection={"Ft"}
                        value={overheadForint}
                        onChange={(e) => { setoverheadForint(Number(e || 0)),
                            // console.log(Number(e || 0), Number(leftTable.footer.value), rezsiPercentageValue, Number(leftTable.footer.value) / rezsiPercentageValue),
                            setoverheadPercentage(Number(Math.abs(Number(e || 0) / (Number(leftTable.footer.value) / 100)).toFixed(2)))
                        }}
                        parser={(value:any) => value.replace(/\Ft\s?|( *)/g, '')}
                        formatter={(value:any) =>
                                !Number.isNaN(parseFloat(value))
                                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                : ''}
                    >
                    </NumberInput>
                </div>
                <Button fullWidth leftIcon={<DeviceFloppy size={16}/>} style={{marginTop: "20px"}} onClick={() => setEditRezsiModalIsOpened(false)}>Mentés</Button>
            </Modal>

            <Modal opened={editEgyebModalIsOpened} onClose={() => setEditEgyebModalIsOpened(false)} title="Egyéb költségek megadása" centered>
                <div>
                    <NumberInput
                        // rightSection={"Ft"}
                        label="Egyéb költségek forintban"
                        value={otherCosts}
                        onChange={(e) => setotherCosts(Number(e || 0))}
                        parser={(value:any) => value.replace(/\Ft\s?|( *)/g, '')}
                        formatter={(value:any) =>
                                !Number.isNaN(parseFloat(value))
                                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                : ''}
                    >
                    </NumberInput>
                </div>
                <Button fullWidth leftIcon={<DeviceFloppy size={16}/>} style={{marginTop: "20px"}} onClick={() => setEditEgyebModalIsOpened(false)}>Mentés</Button>
            </Modal>

    </div>
  )
}
