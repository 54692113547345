import { useSessionStorage } from '@mantine/hooks'
import React, { useState } from 'react'
import Dashboard from '../../components/dashboard'
import { DailyPostType } from '../../interfaces/dailyPostType'
import Cards from '../DailyPosts/components/Cards'
import Header from '../DailyPosts/components/Header'

function PlannedCosts() {

  const [projectNumbers, setProjectNumbers] = useState([])
  const [selectedProjectNumber, setSelectedProjectNumber] = useSessionStorage<string | null>({ key: 'plannedCosts-selectedProjectNumber', defaultValue: null })

  const [modeIsDelete, setModeIsDelete] = useState(false)

  const [posts, setPosts] = useState<DailyPostType[]>([])
  
  return (
    <>
      <Header setProjectNumbers={setProjectNumbers} projectNumbers={projectNumbers} setSelectedProjectNumber={setSelectedProjectNumber} mode={'plannedCost'} setModeIsDelete={setModeIsDelete} selectedProjectNumber={selectedProjectNumber} posts={posts} />
      <Cards selectedProjectNumber={selectedProjectNumber} mode={'plannedCost'} modeIsDelete={modeIsDelete} posts={posts} setPosts={setPosts}/>
    </>
  )
}

export default PlannedCosts