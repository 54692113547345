export function useNumberWithSpaces(x:number | string) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function useCapitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


export async function wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export function separateBy3Digits(num: number): string {
  const numString = num.toString();
  let result = '';
  
  for (let i = 0; i < numString.length; i++) {
    result = result + numString[i];
    if ((numString.length - i - 1) % 3 === 0 && i !== numString.length - 1) {
      result = result + ' ';
    }
  }

  return result;
}



export const weekday = ["Vasárnap","Hétfő","Kedd","Szerda","Csütörtök","Péntek","Szombat"];
export const month = [
  "Január",
  "Február",
  "Március",
  "Április",
  "Május",
  "Június",
  "Július",
  "Augusztus",
  "Szeptember",
  "Október",
  "November",
  "December"
];