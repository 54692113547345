import { Loader, Select, Autocomplete } from '@mantine/core'
import { useDebouncedState, useDebouncedValue } from '@mantine/hooks'
import { httpsCallable } from 'firebase/functions'
import React, { useEffect, useState } from 'react'
import { useFunctions, useUserCompany } from '../../hooks/useFirebase'


interface SearchCompanyProps {
  value: string,
  onChange: (value: string | null) => void,
  canSelectItself: boolean,
  className?: string,
  placeholder?: string,
  styles?: any
}
export default function SearchCompany({ value, onChange, canSelectItself, className, placeholder, styles }: SearchCompanyProps) {

  const [companyList, setCompanyList] = useState(value == "" || value == null ? [] : [value])
  const [searched, setSearched] = useState(false)
  const [isSearching, setIsSearching] = useState(false)

  // const [value, setValue] = useState('')
  // const [debouncedValue] = useDebouncedValue(value, 1000);
  const [searchValue, setSearchValue] = useDebouncedState('', 1000);

  const [firstRun, setFirstRun] = useState(true)
  const [userCompany, setUserCompany] = useState<string | null>(null)

  function searchForCompany() {
    setIsSearching(true)
    const searchCompanyName = useFunctions("searchCompanyName")
    
    searchCompanyName({ search: searchValue }).then(async (res) => {
        console.log(res)
      const data = res.data as { success: boolean, results: string[] }
      if(data.success){

          if(canSelectItself){
            setCompanyList(data.results)
          } else {

            let userCompanyToFilter = "";

            if(userCompany){
              userCompanyToFilter = userCompany
            } else {
              userCompanyToFilter = await useUserCompany()
              setUserCompany(userCompanyToFilter)
            }

            await useUserCompany()
            const filtered = data.results.filter((company) => company !== userCompanyToFilter)
            setCompanyList(filtered)
          }

          console.log(data.results.length)
          setSearched(true)
          setIsSearching(false)

      } else {
        console.log("Hiba történt")
      }
    })

  }

  useEffect(() => {
    if(!firstRun){
      if(searchValue.length >= 3){
          searchForCompany()
          console.log("runned")
      }
      if(searchValue == ""){
          setCompanyList([])
          setSearched(false)
      }
    } else {
      setTimeout(() => {
        setFirstRun(false)
      }, 500);
    }
  }, [searchValue])

  // useEffect(() => {
  //   console.log(companyList)
  // }, [companyList])
  
  

  return (
    <Select
      className={className}
      label=""
      placeholder={placeholder ? placeholder : "Keress céget"}
      searchable
      nothingFound={isSearching ? <Loader></Loader> : (searched ? 'Nincs találat' : "Keress")}
      data={companyList}
      onSearchChange={(result) => setSearchValue(result)}
      // onChange={(result) => setSearchValue(result)}
      filter={(value, item) => true}
      maxDropdownHeight={150}
      onChange={onChange}
      value={value}
      styles={styles}
  />
  )
}
