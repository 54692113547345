import { Center, RingProgress, Text } from '@mantine/core'
import React, { useEffect, useRef } from 'react'



export default function AnimatedRingProgress({ percentage_readiness, modeIsOwnContracts }: {percentage_readiness: number, modeIsOwnContracts: boolean}) {

  const fullCircle = 320.4424506661589
  const endPercentage = percentage_readiness// ? percentage_readiness : 0//Math.round(isNaN(forint_readiness/contract_price*100) ? 0 : forint_readiness/contract_price*100)

  const percentageRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
  const percentage = useRef(0)

  useEffect(() => {
    const interval = setInterval(() => {
        if (percentage.current >= endPercentage) {
            clearInterval(interval);
        } else {
            percentage.current = percentage.current + 1
            const percentageElement = percentageRef.current as HTMLElement;

            const percent = fullCircle / 100 * percentage.current

            const outerCircle = percentageElement.children[0].children[0] as HTMLElement;
            const innerCircle = percentageElement.children[0].children[1] as HTMLElement;
            outerCircle.style.strokeDasharray = `${fullCircle - percent} ${percent}`;
            innerCircle.style.strokeDasharray = `${percent} ${fullCircle - percent}`;
        }
    }, 15);

    return () => clearInterval(interval);
  }, []);

  return (
    <RingProgress
        size={140}
        roundCaps
        thickness={12}
        ref={percentageRef}
        sections={[{ value: percentage.current, color: modeIsOwnContracts ? "cyan" : "green" }]}
        label={
        <Center>
            <strong>
                <Text size={'xl'}>{Math.round(endPercentage)} %</Text>
            </strong>
        </Center>
        }
    />
  )
}
