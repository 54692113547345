import { TextInput } from '@mantine/core';
import { ChangeEventHandler, HTMLInputTypeAttribute } from 'react';

const data = [
  { value: 'eur', label: '🇪🇺 EUR' },
  { value: 'usd', label: '🇺🇸 USD' },
  { value: 'cad', label: '🇨🇦 CAD' },
  { value: 'gbp', label: '🇬🇧 GBP' },
  { value: 'aud', label: '🇦🇺 AUD' },
];

interface inputType {
    label: string,
    type: HTMLInputTypeAttribute,
    placeholder: string,
    description?: string,
    value: string,
    onChange: ChangeEventHandler<HTMLInputElement>
}

export function Input({ label, type, placeholder, description, value, onChange}: inputType) {

  return (
    <TextInput
      type={type}
      placeholder={placeholder}
      label={label}
      description={description}
      value={value}
      onChange={onChange}
    />
  );
}