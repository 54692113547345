import React from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ChartOptions,
    LineController,
    ChartDataset
    } from 'chart.js';
import { Chart } from 'react-chartjs-2'
import { useMantineTheme } from '@mantine/core';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
    LineController
);


interface BuiltyCardProps {
    maxHeight: number | string,
    width?: number | string,
    maxWidth?: number | string,
    padding: string,
    label: string,
    labels: string[],
    data: number[],
    backgroundColor?: any,
    borderColor?: any,
    aspectRatio?: number,
    showLabel?: boolean,
    datasets?: ChartDataset<"line">[],
    hightlightZeroLine?: boolean
}

export default function BuiltyLineChart({ maxHeight, width, maxWidth, padding, label, labels, data, backgroundColor, borderColor, aspectRatio, showLabel, datasets, hightlightZeroLine }: BuiltyCardProps) {

  const theme = useMantineTheme();

  if(data.length === 0 && datasets === undefined) {
    return (
      <div style={{height: maxHeight, width: width, display: "flex", alignItems: "center", justifyContent: "center"}}>Nincs találat</div>
    )
  } else {
    return (
      <Chart
              style={{maxHeight: maxHeight, width: width, maxWidth: maxWidth, padding: padding}}
              type={'line'}
              options={{
                aspectRatio: aspectRatio ? aspectRatio : undefined,
                plugins: {
                  legend: {
                    display: false
                  },
                  datalabels: {
                    display: false
                  },
                  tooltip: {
                    titleFont: {
                      size: 16
                    },
                    bodyFont: {
                      size: 16
                    },
                    callbacks: {
                      label: function(context) {
                        let label = context.dataset.label || '';
  
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits: 0 }).format(context.parsed.y);
                        }
                        return label;
                      }    
                    }
                  }
                },
                scales: {
                  y: {
                    grid: {
                      color(ctx, options) {
                          return hightlightZeroLine ? (ctx.tick.value === 0 ? "#fff" : "rgba(0, 0, 0, 0.1)") : "rgba(0, 0, 0, 0.1)";
                      },
                    },
                    beginAtZero: true,
                    ticks: {
                      font: {
                        size: 16
                      }
                    }
                  },
                  x: {
                    ticks: {
                      font: {
                        size: showLabel === false ? 0 : 16
                      },
                    },
                  },
                },
              }}
              data={{
                labels: labels,
                datasets: datasets ? datasets : [{
                    label: label,
                    data: data,
                    backgroundColor: backgroundColor ? backgroundColor : theme.colors.green[0],
                    borderColor: borderColor ? borderColor : theme.colors.green[8],
                    pointRadius: 5,
                    tension: 0.4,
                    pointHitRadius: 40,
                }]
              }}
            />
    )
  }
}
