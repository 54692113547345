import { ActionIcon, Button, Checkbox, Group, Loader, Modal, Paper, ScrollArea, SegmentedControl, Select, Stepper, Table, Text, TextInput } from '@mantine/core'
import { Dropzone, FileWithPath, MS_EXCEL_MIME_TYPE } from '@mantine/dropzone'
import { showNotification } from '@mantine/notifications'
import React, { useEffect, useRef, useState } from 'react'
import { ArrowRight, CloudUpload, DeviceFloppy, Edit, Plus, Trash, Upload, X } from 'tabler-icons-react'
import { useStore } from '../../../hooks/useStore'
import { contractContentType } from '../../../interfaces/contractContentType'
import AddEditSheetNamesModal from '../AddEditSheetNamesModal'
import SheetNamesRow from '../SheetNamesRow'
import UploadContentFromExcel from '../UploadContentFromExcel'


interface UploadContentProps {
    modeIsOwnContracts: boolean,
    contractContentIsNull: boolean,
    showAsModal: boolean
}

export default function UploadContent({ modeIsOwnContracts, contractContentIsNull, showAsModal }: UploadContentProps) {

    const [addModalOpened, setAddModalOpened] = useState(false)

    const content = (
      <div style={{width: "100%", height: "400px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
        <UploadContentFromExcel setAddModalOpened={setAddModalOpened}></UploadContentFromExcel>

        <div style={{"display": "flex", "alignItems": "center", "gap": "15px"}}>
        <hr className="solid"></hr>
        <h5>Vagy</h5>
        <hr className="solid"></hr>
        </div>

        {/* <Button leftIcon={<Upload/>} onClick={() => setUploadManuallyModalOpened(true)}>Feltöltés manuálisan</Button> */}
        <AddEditSheetNamesModal IsEditMode={contractContentIsNull ? false : true} showActionIcon={false} />
      </div>
    )

    // if(contractContentIsNull){
    //   return <div></div>
    // }

    if(showAsModal){
      return content
    }

    return (
      <>

      <Button variant='outline' leftIcon={<Upload size={20} />} onClick={() => setAddModalOpened(true)} color={modeIsOwnContracts ? "cyan" : "green"}>
        Feltöltés / Hozzáadás
      </Button>

      
      <Modal
        opened={addModalOpened}
        onClose={() => setAddModalOpened(false)}
        title={"Válassz feltöltési módot!"}
        size={"lg"}
        >
          {content}
        </Modal>

    </>
    )
  }