import './index.css'
// Import the functions you need from the SDKs you need
import Routing from './Routing'
import { MantineProvider, Button, useMantineTheme } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { useStore } from './hooks/useStore';
import { useEffect, useState } from 'react';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { useHotkeys } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
// import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './error/ErrorPage';
import * as Sentry from "@sentry/react";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


function App() {
  
  const theme = useMantineTheme();

  // useHotkeys([
  //   ['ctrl+Ö', () => window.location.pathname = "/daily-posts"],
  //   ['ctrl+K', () => console.log('Trigger search')],
  //   ['alt+mod+shift+X', () => console.log('Rick roll')],
  // ]);

  // const auth = getAuth();
  // connectAuthEmulator(auth, "http://localhost:9099");

  // const db = getFirestore();
  // connectFirestoreEmulator(db, 'localhost', 8080);
  // useEffect(() => {
  //   fetch('https://vassmartin5.npkn.net/builty-functions/kis_beka')
  // .then((response) => response.json())
  // .then((data) => console.log(data));
  // }, [])
  

  return (
    <div className='app'>
      <Sentry.ErrorBoundary fallback={<ErrorPage/>}>
        <MantineProvider theme={{
          colorScheme: 'dark',
          colors: {
          brand: [theme.colors.green[0],theme.colors.green[1],theme.colors.green[2],theme.colors.green[3],theme.colors.green[4],theme.colors.green[5],theme.colors.green[6],theme.colors.green[7],theme.colors.green[8],theme.colors.green[9]],},
          primaryColor: 'brand', }} withGlobalStyles withNormalizeCSS>
          <NotificationsProvider>
            <Routing />
          </NotificationsProvider>
        </MantineProvider>
      </Sentry.ErrorBoundary>
    </div>
  )
}

export default App




export const yellow_button = "rgb(127 125 0)"
export const yellow_light_button_bg = "rgb(127 125 0 / 20%)"
export const yellow_light_button_text = "rgb(206 203 0)"
export const yellow_light_button_hover = "rgb(127 125 0 / 30%)"
