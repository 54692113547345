import { Card, Image, Text, Badge, Button, Group, useMantineTheme, ActionIcon, Modal, Chip } from '@mantine/core';
import { deleteDoc, doc, DocumentData, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useCapitalizeFirstLetter, useNumberWithSpaces, weekday } from '../../../../../hooks/utils';

import { yellow_button, yellow_light_button_bg, yellow_light_button_hover, yellow_light_button_text } from "../../../../../App"
import { AlertCircle, Bookmark, Check, DotsVertical, InfoCircle, Road, SunWind, Tags, Trash } from 'tabler-icons-react';
import { useState } from 'react';
import { useFirestore } from '../../../../../hooks/useFirebase';
import { showNotification } from '@mantine/notifications';

interface BuiltyCardProps {
  data: DocumentData
  docid: string,
  mode: string,
  modeIsDelete: boolean,
  // withAddedContractProgress: boolean
}

export default function BuiltyCard({ data, docid, mode, modeIsDelete }: BuiltyCardProps) {

  const [deleteModalIsOpened, setDeleteModalIsOpened] = useState(false)
  // console.log(mode)

  const theme = useMantineTheme()
  const navigate = useNavigate()

  async function deleteDataFromFirestore(){
    const db = useFirestore()
    try {
      await deleteDoc(doc(db, `${mode === "dailyPost" ? "daily" : "plan"}`, docid));
      
      showNotification({
        color: 'green',
        title: 'Siker!',
        message: `A kért ${mode === "dailyPost" ? "összesítőt" : "tervezettet"} sikeresen eltávolítottuk!`,
        icon: <Check size={16} />,
        autoClose: 3000,
      })
    } catch (error) {
      console.log(error)
      showNotification({
        color: 'red',
        title: 'Ajjaj, hiba történt!',
        message: `A kért ${mode === "dailyPost" ? "összesítőt" : "tervezettet"} nem sikerült eltávolítani!`,
        icon: <AlertCircle size={16} />,
        autoClose: false,
      })
    }
  }

  return (
    <>
    <Card shadow="sm" p="md" radius="md" withBorder style={{ backgroundColor: "#25262b", width: `${window.innerWidth < 1800 ? "17.8%" : "14.5%"}`}}>
      <Card.Section>
        <div>
          
          {/* <Image
            src="https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80"
            height={100}
            alt="Norway"
          /> */}
        </div>
      </Card.Section>

      <Group mt="xs" mb="xs">
        <Badge
        variant="dot"
        mb={-5}
        mt={2}
        styles={() => ({
          root: {
            width: "100%",
            ":before": {
              backgroundColor: mode === "dailyPost" ? theme.colors.green[8] : theme.colors.yellow[4]
            }
          }
        })}
        >
          {data.project_number ? data.project_number : "Nincs munkaszám"}
          {/* M5761_Mogyoródi_utva_felújítása_és_aszfaltozása */}
          {/* style={{"marginLeft": "0px", "position": "absolute", "top": "0px", "left": "0px", "zIndex": 100}} */}
        </Badge>
          <Text weight={500} size="md">
            {/* Szerda,<br></br>Szeptember 21 */}
            {/* {JSON.stringify(new Date(data.date.seconds*1000))} */}
            {weekday[new Date(data.date?.seconds*1000).getDay()]}<br></br>
            {useCapitalizeFirstLetter(new Intl.DateTimeFormat('hu-HU', { month: "long", day: "2-digit"}).format(data.date.seconds ? data.date.seconds*1000 : Date.now())).replace(".", "")}
          </Text>
        {/* <div style={{"display": "flex", "alignItems": "center", "justifyContent": "space-between"}}> */}
        {/* </div> */}
      </Group>


      {/* <Text size="sm" color="">
        Módosítva: 2020.05.19
      </Text> */}

      {mode !== "dailyPost" ? (
        <>
        <Text size="sm" color="dimmed">
          Előkalkuláció:
        </Text>
        <Text size="sm" color="">
            {useNumberWithSpaces(data.final_price)} Ft
        </Text>
        </>
      ) : ""}

      <div style={{display: "flex", flexWrap: "wrap", alignItems: "flex-end", gap: "5px", marginTop: "10px"}}>
          {/* <Road></Road> */}
          {/* <Chip checked={false} size="xs" children={<Text>jcb-4cx</Text>}></Chip>
          <Chip checked={false} size="xs" children={<Text>Vianova 87</Text>}></Chip>
          <Chip checked={false} size="xs" children={<Text>caterppilar 4zx</Text>}></Chip>
          <Chip checked={false} size="xs" children={<Text>tóth trans</Text>}></Chip> */}
          {/* <SunWind></SunWind> */}
          <ActionIcon><Bookmark size={20}></Bookmark></ActionIcon>
          <ActionIcon><Tags size={20}></Tags></ActionIcon>
          <ActionIcon><Trash size={20}></Trash></ActionIcon>
          <ActionIcon><InfoCircle size={20}></InfoCircle></ActionIcon>
      </div>


      <div style={{display: "flex", alignItems: "flex-end", gap: "15px"}}>
        {(data.project_number == "" || data.project_number == null) ? (
          <Button
          variant="light"
          fullWidth
          mt="md"
          radius="md"
          onClick={() => navigate(mode === "dailyPost" ? `/daily-posts/watch/${docid}` : `/planned-costs/watch/${docid}`)}
          color="red"
          >
            Folytatás...
          </Button>
        ) : (
          <Button
          variant="light"
          fullWidth
          mt="md"
          radius="md"
          onClick={() => navigate(mode === "dailyPost" ? `/daily-posts/watch/${docid}` : `/planned-costs/watch/${docid}`)}
          styles={() => ({
            root: {
              color: mode === "dailyPost" ? "rgb(178, 242, 187)" : yellow_light_button_text,
              backgroundColor: mode === "dailyPost" ? "rgba(47, 158, 68, 0.2)" : yellow_light_button_bg,
              "&:hover": {
                backgroundColor: mode === "dailyPost" ? "rgba(55, 178, 77, 0.25)" : yellow_light_button_hover
              }
            },
          })}
          >
            Megnézem
          </Button>
        )}
        {modeIsDelete ? (
          <ActionIcon size={36} variant="light" color={'red'} onClick={() => setDeleteModalIsOpened(true)}>
            <Trash size={16} />
          </ActionIcon>
        ) : ""}
      </div>
    </Card>

    <Modal opened={deleteModalIsOpened} onClose={() => setDeleteModalIsOpened(false)} title="Biztosan törlöd?">
        <div style={{display: "flex", gap: "20px"}}>
          <Button variant='light' color={"gray"} fullWidth onClick={() => setDeleteModalIsOpened(false)}>Mégsem</Button>
          <Button variant='light' color={"red"} fullWidth onClick={() => {setDeleteModalIsOpened(false); deleteDataFromFirestore()}}>Törlés</Button>
        </div>
    </Modal>
    </>    
  );
}