import { Button, Modal, Textarea, TextInput } from '@mantine/core'
import { showNotification, updateNotification } from '@mantine/notifications'
import { addDoc, arrayUnion, collection, doc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { AlertCircle, Check, Plus } from 'tabler-icons-react'
import { useAuth, useFirestore, useUserCompany } from '../../../../hooks/useFirebase'

interface HeaderProps {
  setIsEditing: Function,
  isEditing: boolean,
  changesHaveBeenSaved: boolean,
  setChangesHaveBeenSaved: Function,
  rowChangesMadeInTable: object[],
  setRowChangesMadeInTable: Function
}



function Header({ setIsEditing, isEditing, changesHaveBeenSaved, setChangesHaveBeenSaved, rowChangesMadeInTable, setRowChangesMadeInTable }: HeaderProps) {

  const [opened, setOpened] = useState(false)
  const [projectNumber, setProjectNumber] = useState("")
  const [comment, setComment] = useState("")

  const isSafeToExit = useRef(false)
  const [showUnsavedWarningModal, setShowUnsavedWarningModal] = useState(false)


  // useEffect(() => {
  //   return () => {
  //     console.log("component unmounted", isSafeToExit.current)
  //     if(isSafeToExit.current == false){
  //       setShowUnsavedWarningModal(true)
  //       const wantsToSave = window.confirm("Szeretnéd menteni a változtatásokat")
  //       console.log(wantsToSave)
  //       if(wantsToSave){
  //         saveChangesToDatabase()
  //         alert("A Változtatások sikeresen mentve lettek")
  //       }
  //     }
  //   }
  // }, [])

  // useEffect(() => {
  //   if(changesHaveBeenSaved == true){
  //     isSafeToExit.current = true
  //   } else {
  //     isSafeToExit.current = false
  //   }
  //   // console.log("isSafeToExit", isSafeToExit.current)
  // }, [changesHaveBeenSaved])



  async function addProjectNumber(){
    try {
      setOpened(false)
      showNotification({
        id: 'load-data',
        loading: true,
        title: `Módosítások mentése, adattáblák létrehozása`,
        message: 'Az adatok mentése a fiókodba folyamatban van',
        autoClose: false,
        disallowClose: true,
      });
      const db = useFirestore()
      const user = useAuth()
      const company = await useUserCompany()

      // Add a new document with a generated id.
      const docRef = await addDoc(collection(db, "project"), {
        project_number: projectNumber,
        overhead: 0,
        other_costs: 0,
        comment: comment,
        created_at: serverTimestamp(),
        last_modified: serverTimestamp(),
        company: company,
        user_id: user.uid,
        // tervezett_költség: 0,
        // valós_költség: 0,
        // valós_bevétel: 0,
        // eredmény_forintban: 0,
        // eredmény_százalékban: 0,
        // daily: [],
        // plan: []
      });

      // adding project number to user's project numbers
      await updateDoc(doc(db, "user", user.uid), {
        project_numbers: arrayUnion(projectNumber)
      });

      console.log("Document written with ID: ", docRef.id);
      setProjectNumber("")
      setComment("")
      setTimeout(() => {
        updateNotification({
          id: 'load-data',
          title: `Munkaszám létrehozva`,
          icon: <Check size={16} />,
          message: 'A munkasszámot sikeresen létrehoztuk és a fiókodhoz rendeltük!',
          autoClose: 5000,
        });
      }, 2000);
    } catch (error){
      console.log("Error adding document: ", error);
      updateNotification({
        id: 'load-data',
        color: 'red',
        title: 'Hiba történt!',
        message: 'A munkaszám létrehozása nem sikerült',
        icon: <AlertCircle size={16} />,
        autoClose: false,
      });
    }
  }

  function saveChangesToDatabase(){
    // console.log(sortedData)
    // console.log(rowsDataGotFromFirebase)
    console.log(rowChangesMadeInTable)
    // rowChangesMadeInTable.map(() => {
    // })
    const db = useFirestore()
    const documentRef = collection(db, "project");

    // setSortedData([])
    // setrowsDataGotFromFirebase([])
    showNotification({
      id: 'load-data',
      loading: true,
      title: `Módosítások mentése`,
      message: 'Az adatok mentése a fiókodba folyamatban van',
      autoClose: false,
      disallowClose: true,
    });

    try {
      // updating data in cloud firestore
      rowChangesMadeInTable.map(async (change:any) => {
        console.log(change.id)
        console.log(change.changes)
        await setDoc(doc(documentRef, change.id), {
          comment: change.changes.comment,
          project_number: change.changes.project_number,
          last_modified: serverTimestamp(),
        }, {merge: true});   
      })
      setChangesHaveBeenSaved(true)
      setIsEditing(false)
      setRowChangesMadeInTable([])

      setTimeout(() => {
        updateNotification({
          id: 'load-data',
          title: 'Siker!',
          message: 'Az adatok sikeresen módosítva lettek az adatbázisodban',
          icon: <Check size={16} />,
          autoClose: 5000,
        });
      }, 2000);
    } catch (error) {
      console.log(error)
        updateNotification({
          id: 'load-data',
          color: 'red',
          title: 'Hiba történt!',
          message: 'Az adatok nem lettek módosítva az adatbázisodban',
          icon: <AlertCircle size={16} />,
          autoClose: false,
        });
    }
  }

  return (
    <>
      <div style={{"display": "flex", "alignItems": "center", "justifyContent": "space-between", "margin": "-5px 0px 20px 0px"}}>
          <h2 style={{"margin": "0px"}}>Munkaszámaid</h2>
          <div style={{"display": "flex", "gap": "20px"}}>
            {changesHaveBeenSaved ? "" : <Button variant='outline' color={"green"} onClick={() => saveChangesToDatabase()}>Szerkesztettek mentése</Button>}
            <Button variant='default' color={"gray"} onClick={() => setIsEditing(!isEditing)}>{isEditing ? "Normál mód" : "Szerkesztés mód"}</Button>
            <Button leftIcon={<Plus size={20}/>} onClick={() => setOpened(!opened)}>
                Új munkaszám létrehozása
            </Button>
          </div>

      </div>
      <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Munkaszám hozzáadása"
      centered
      >
        <div style={{"display": "flex", "flexDirection": "column", "gap": "15px"}}>
        <TextInput label="Munka száma / Neve" description={<p style={{"marginTop": "0px", "color": "red"}}>Ezt később már nem tudod módosítani</p>} required value={projectNumber} onChange={(event) => setProjectNumber(event.target.value)}/>
        <Textarea
          placeholder=""
          label="Megjegyzés"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          autosize
          minRows={2}
          maxRows={10}
        />
        <Button style={{"marginTop": "10px"}} onClick={() => addProjectNumber()}>Mentés</Button>
        </div>
      </Modal>
      {/* <Modal
        opened={showUnsavedWarningModal}
        onClose={() => setShowUnsavedWarningModal(false)}
        title="Szeretnéd menteni a változtatásokat?"
        centered
      >
        <div>
          <div style={{"display": "flex", "gap": "20px"}}>
            <Button variant='light' color={"gray"} onClick={() => setShowUnsavedWarningModal(false)}>Elvetés</Button>
            <Button variant='light' onClick={() => {
              saveChangesToDatabase()
              setShowUnsavedWarningModal(false)
            }}>Mentés</Button>
          </div>
        </div>
      </Modal> */}
    </>
  )
}

export default Header