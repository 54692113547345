import { Button, Group, Input, Loader, Modal, Space, Text, NumberInput, Slider, Flex } from '@mantine/core'
import { addDoc, collection, doc, getDoc, onSnapshot, serverTimestamp, Unsubscribe, updateDoc } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AlertCircle, Calculator, Check, Edit, Notes, Plus, Upload } from 'tabler-icons-react'
import { useAuth, useFirestore, useUserCompany } from '../../hooks/useFirebase'
import { useStore } from '../../hooks/useStore'
import { contractContentType } from '../../interfaces/contractContentType'
import { contractType } from '../../pages/OwnContracts/components/List/List'
import AddEditSheetNamesModal from './AddEditSheetNamesModal'
import ExcelLikeWatcher from './ExcelLikeWatcher'
import GroupChangeModal from './GroupChangeModal'
import UploadContent from './UploadContent'
import { DatePicker } from '@mantine/dates'


interface ContractContentProps {
    modeIsEdit: boolean,
    modeIsWatch: boolean,
    modeIsOwnContracts: boolean,
    // contractContentFromDailyPostsNewDay?: contractContentType
    // contractTypeFromDaiyPostsNewDay?: "own" | "sub"
    // contractOtherCompanyFromDailyPostsNewDay?: string
    // setContractSelectorModalIsOpened: Function,
    // selectedContractHistoryDocId?: string,
    // selectedContractDocId?: string
}

let initiall_contract_content: contractContentType = []
export default function ContractContent({ modeIsEdit, modeIsWatch, modeIsOwnContracts }: ContractContentProps) {

//   const [uploadManuallyModalOpened, setUploadManuallyModalOpened] = useState(false)

  const setContractContent = useStore((state:any) => state.setContractContent)
  const contractContent: contractContentType = useStore((state:any) => state.contractContent)

  const [contract, setContract] = useState<contractType | null>()
  const [isLoading, setIsLoading] = useState(true)

//   const [isSaving, setIsSaving] = useState(false)
  const isSaving = useStore((state:any) => state.isSaving)
  const setIsSaving = useStore((state:any) => state.setIsSaving)

  const [isErrorOnSave, setIsErrorOnSave] = useState(false)
  const [firstRun, setFirstRun] = useState(true)

  const [contractContentIsNull, setContractContentIsNull] = useState(true)

//   const [contractContentProgressDocId, setContractContentProgressDocId] = useState<string | null>(null)
  const contractContentProgressDocId = useRef<string | null>(null)

  const [contractProgressCreatedAt, setContractProgressCreatedAt] = useState(new Date())

//   const formDailyIncomeObject = useStore((state:any) => state.formDailyIncomeObject)
//   const setFormDailyIncomeObject = useStore((state:any) => state.setFormDailyIncomeObject)
//   const formDailySubcontractorCostObject = useStore((state:any) => state.formDailySubcontractorCostObject)
//   const setFormDailySubcontractorCostObject = useStore((state:any) => state.setFormDailySubcontractorCostObject)
//   const setFormDailyIncome = useStore((state:any) => state.setFormDailyIncome)

  const { docId } = useParams()
  const { contractDocId } = useParams()
  const { contractProgressDocId } = useParams()

  async function fetchContractFromUrlId(){
    console.log(docId)

    const db = useFirestore()
    let docRef;
    if(!modeIsWatch){
        docRef = doc(db, "contract", docId || contractDocId ||"");
    } else {
        docRef = doc(db, "contract-progress", contractProgressDocId ||"");
    }

    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        const contract = docSnap.data()
        // if(contract.content == null || contract.content == undefined){
        //     contract["content"] = []
        // }
        setContract({ id: docSnap.id, ...(docSnap.data()) } as contractType);
        const content = modeIsWatch ? contract.contract_content : contract.content
        setContractContent(content)

        //update screen
        setContractContentIsNull(content.length == 0)

    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setContract(null)
    }

  }

  async function saveContractContent(){

    try {
        console.log(contract)
        const db = useFirestore()
        const docRef = doc(db, 'contract', contract?.id || "");
        // Update the timestamp field with the value from the server
        const [forint_readiness, percentage_readiness] = calculateGlobalReadiness(contractContent)
        console.log(forint_readiness, percentage_readiness)

        const [contractPrice, afterWorkPrice] = calculateContractPrice(contractContent)
        console.log(contractPrice)

        const updateTimestamp = await updateDoc(docRef, {
            content: contractContent,
            contract_price: contractPrice,
            after_work: afterWorkPrice,
            forint_readiness: forint_readiness,
            percentage_readiness: percentage_readiness
        });
        setIsErrorOnSave(false)
    } catch (error) {
        console.log(error)
        setIsErrorOnSave(true)
    }
  }

//   async function saveContractHistoryContent() {
//     try {
//         // console.log(contract)
//         const db = useFirestore()
//         const docRef = doc(db, 'contract-history', selectedContractHistoryDocId || "");
//         // Update the timestamp field with the value from the server
//         const [forint_readiness, percentage_readiness] = calculateGlobalReadiness(contractContent)
//         console.log(forint_readiness, percentage_readiness)
//         const updateTimestamp = await updateDoc(docRef, {
//             contract_content: contractContent,
//             forint_readiness: forint_readiness,
//             percentage_readiness: percentage_readiness
//         });
//         console.log("successfully saved to contract history")


//     } catch (error) {
//         console.log(error)
//         setIsErrorOnSave(true)
//     }
//   }

//   function setTheFormsDailyIncomeAndSubcontractorCost(){
//     if(contractTypeFromDaiyPostsNewDay == "own"){
//         const [changed_form_forint_readiness, changed_percentage_readiness] = calculateGlobalReadiness(contractContent)

//         const copy = formDailyIncomeObject
//         copy[`${selectedContractHistoryDocId?.split("-")[1]}`]["changed"] = changed_form_forint_readiness

//         setFormDailyIncomeObject(copy)
//         console.log(copy)
//     } else if(contractTypeFromDaiyPostsNewDay == "sub"){
//         const [changed_form_forint_readiness, changed_percentage_readiness] = calculateGlobalReadiness(contractContent)

//         const copy = formDailySubcontractorCostObject
//         copy[`${selectedContractHistoryDocId?.split("-")[1]}`]["changed"] = changed_form_forint_readiness

//         setFormDailySubcontractorCostObject(copy)
//         console.log(copy)
//     }
//   }

  async function saveContractContentProgress(){
    const db = useFirestore()

    const [forint_readiness, percentage_readiness] = calculateGlobalReadiness(contractContent)
    
    if(contractContentProgressDocId.current){
        // update the contract progress document
        await updateDoc(doc(db, "contract-progress", contractContentProgressDocId.current), {
            contract_content: contractContent,
            last_modified: serverTimestamp(),
            forint_readiness: forint_readiness,
            percentage_readiness: percentage_readiness,
        });

    } else {
        // create a new contract progress document

        const user = useAuth()
        const company = await useUserCompany()

        // Add a new document with a generated id.
        const docRef = await addDoc(collection(db, "contract-progress"), {
            company: company,
            contract_content: contractContent,
            contract_id: contract?.id,
            contractor: contract?.contractor,
            customer: contract?.customer,
            project_number: contract?.project_number,
            // created_at: serverTimestamp(),
            created_at: contractProgressCreatedAt,
            last_modified: serverTimestamp(),
            forint_readiness: forint_readiness,
            percentage_readiness: percentage_readiness,
            user_id: user.uid
        });
        console.log("Document written with ID: ", docRef.id);
        // setContractContentProgressDocId(docRef.id)
        contractContentProgressDocId.current = docRef.id
    }
  }


  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;

    // if(!modeIsWatch){
        fetchContractFromUrlId()
    // } else {
    //     // setContractContent(contractContentFromDailyPostsNewDay)
    //     // setContractContentIsNull(contractContentFromDailyPostsNewDay?.length == 0)
    // }

    setTimeout(() => {
        setIsLoading(false)
    }, 1000);
    

    setTimeout(() => {
          // it is prohibiting the save on component mount when it is unnecesary
        setFirstRun(false)
    }, 1000);

  }, [])

  useEffect(() => {
    console.log(contractContent)
    if(!firstRun){
        setIsSaving(true)
        setTimeout(() => {
            setIsSaving(false)

            if(modeIsEdit){
                //update screen
                setContractContentIsNull(contractContent.length == 0)
                // save content
                saveContractContent()

            // when adding new contract progress
            } else if(!modeIsWatch && !modeIsEdit){
                console.log("mode is not edit")
                // save contract progress
                saveContractContentProgress()
                // save contract updated with recorded quantitys as new contract content 
                saveContractContent()
                // saveContractHistoryContent()
                // setTheFormsDailyIncomeAndSubcontractorCost()
                // saveContractContent()
            }
        }, 2000);

    }
    // if(firstRun){
    //     initiall_contract_content = contractContentFromDailyPostsNewDay || []
    //     const [initiall_form_forint_readiness, initiall_percentage_readiness] = calculateGlobalReadiness(initiall_contract_content)
    // }
}, [contractContent])


    // useEffect(() => {
    //     if(!modeIsEdit){
    //         setContractContent(contractContentFromDailyPostsNewDay)
    //         setContractContentIsNull(contractContentFromDailyPostsNewDay?.length == 0)
    //     }
    // }, [contractContentFromDailyPostsNewDay])
    
  

  return (
    <>
    <div style={{ padding: "30px" }}>

        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "30px" }}>

            <div style={{display: "flex", alignItems: "center", gap: "25px"}}>
                <Button
                    variant='light'
                    loading={isSaving}
                    onClick={() => {history.back()} }
                    color={modeIsOwnContracts ? "cyan" : "green"}
                >
                    {isSaving ? "Változtatások mentése" : "Vissza"}
                </Button>
                {isSaving ? "" : (
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                        {isErrorOnSave ? (
                            <>
                            <AlertCircle color='red'/>
                            <h4 style={{margin: "0px"}}>Hiba történt! Nincs mentve</h4>
                            </>
                        ) : (
                            <>
                            <Check color='green'/>
                            <h4 style={{margin: "0px"}}>Mentve</h4>
                            </>
                        )}
                    </div>
                )}
            </div>

            {modeIsOwnContracts ? (
                <h1 style={{ fontWeight: "bold" }}>{(contract?.customer || "").length <= 20 ? contract?.customer : `${contract?.customer.slice(0, 20)}...`} saját szerződés {modeIsEdit ? "szerkesztése" : "felmérése"}</h1>
            ) : (
                <h1 style={{ fontWeight: "bold" }}>{(contract?.contractor || "").length <= 20 ? contract?.contractor : `${contract?.contractor.slice(0, 20)}...`} saját szerződés {modeIsEdit ? "szerkesztése" : "felmérése"}</h1>
            )}

            <div style={{"display": "flex", "gap":"20px"}}>
                {modeIsEdit ? (
                    <UploadContent modeIsOwnContracts={modeIsOwnContracts} contractContentIsNull={contractContentIsNull} showAsModal={false}/>
                ): (
                    !modeIsWatch ? (
                        <Flex gap={"20px"}>
                            <DatePicker placeholder="Pick date" value={contractProgressCreatedAt} onChange={(value) => setContractProgressCreatedAt(value ?? new Date())}/>
                            <GroupChangeModal modeIsOwnContracts={modeIsOwnContracts} setIsLoading={setIsLoading}></GroupChangeModal>
                        </Flex>
                    ) : <div style={{width: "197px"}}></div>
                )}
            </div>

        </div>
        
        <div>
            {/* {modeIsEdit ? "" : (
                <div style={{display: "flex", alignItems: "center", gap: "35px", marginBottom: "25px"}}>
                    <h3 style={{ margin: '0px', marginBottom: "0px" }}>{contractOtherCompanyFromDailyPostsNewDay} {contractTypeFromDaiyPostsNewDay == "own" ? "megrendelő" : "alvállalkozó"} szerződése</h3>
                    <Button
                        leftIcon={<Notes/>}
                        variant="light"
                        onClick={() => setContractSelectorModalIsOpened(true)}
                    >
                            Másik szerződés kiválasztása
                    </Button>
                </div>
            )} */}
            {contractContentIsNull ? (
                    <div style={{marginTop: "13vh"}}>
                        {modeIsEdit ? (
                            <UploadContent modeIsOwnContracts={modeIsOwnContracts} contractContentIsNull={contractContentIsNull} showAsModal />
                        ) : (
                            <h3 style={{paddingTop: "200px", textAlign: "center"}}>A szerződés üres, nincs műszaki tartalma.</h3>
                        )}
                    </div>
                ) : (
                    isLoading ? null : (
                        <ExcelLikeWatcher modeIsEdit={modeIsEdit} modeIsWatch={modeIsWatch} contractContentIsNull={contractContentIsNull} modeIsOwnContracts={modeIsOwnContracts} setIsLoading={setIsLoading}/>
                    )
            )}
        </div>

        { isLoading ? <div className='loader-backdrop dashboard'><Loader size={'xl'} color={modeIsOwnContracts ? "cyan" : "green"}/></div> : "" }
    </div>


    {/* <AddEditSheetNamesModal
        IsEditMode={contractContentIsNull ? false : true}
        uploadManuallyModalOpened={uploadManuallyModalOpened}
        setUploadManuallyModalOpened={setUploadManuallyModalOpened}
    /> */}



    </>
  )
}




export function calculateGlobalReadiness(contractContent: contractContentType){
    let allPercentageReadiness = 0
    let globalForintReadiness = 0
    let numberOfRows = 0

    contractContent.forEach((sheets) => {
        sheets.sheetData.forEach((tableBlock) => {
            tableBlock.tableData.forEach((row) => {
                allPercentageReadiness += row.percentage_readiness
                globalForintReadiness += row.forint_readiness

                numberOfRows += 1
            })
        })
    })
    let globalPercentageReadiness = allPercentageReadiness / numberOfRows
    globalPercentageReadiness = isNaN(globalPercentageReadiness) ? 0 : globalPercentageReadiness

    globalForintReadiness = Math.round(globalForintReadiness)
    globalPercentageReadiness = Math.round(globalPercentageReadiness)

    return [globalForintReadiness, globalPercentageReadiness]
}


export function calculateContractPrice(contractContent: contractContentType){
    let contractPrice = 0
    let afterWorkPrice = 0
    contractContent.forEach((sheet) => {
        sheet.sheetData.forEach((tableBlock) => {
            tableBlock.tableData.forEach((row) => {

                if(tableBlock.is_after_work){
                    afterWorkPrice += row.total
                } else {
                    contractPrice += row.total
                }
            })

        })
    })

    contractPrice = Math.round(contractPrice)
    afterWorkPrice = Math.round(afterWorkPrice)
    return [contractPrice, afterWorkPrice]
}