import { useSessionStorage } from '@mantine/hooks';
import React, { useState } from 'react'
import ChartAndList from '../OwnContractProgress/components/ChartAndList'
import Header from '../OwnContractProgress/components/Header'

export default function SubcontractorContractProgress() {

  const [selectedProjectNumber, setSelectedProjectNumber] = useSessionStorage<string | null>({ key: "subcontractor-selectedProjectNumber", defaultValue: null });
  // const [selectedProjectNumber, setSelectedProjectNumber] = useState<string | null>(null)
  
  const [selectedContract, setSelectedContract] = useSessionStorage<string | null>({ key: "subcontractor-selectedContract", defaultValue: null });
  // const [selectedContract, setSelectedContract] = useState<string | null>(null)

  const [selectedContractDocId, setSelectedContractDocId] = useState("")

  const [modeIsDelete, setModeIsDelete] = useState(false)

  return (
    <>
      <Header modeIsOwnContracts={false} selectedProjectNumber={selectedProjectNumber} setSelectedProjectNumber={setSelectedProjectNumber} selectedContract={selectedContract} setSelectedContract={setSelectedContract} selectedContractDocId={selectedContractDocId} setSelectedContractDocId={setSelectedContractDocId} setModeIsDelete={setModeIsDelete} />
      <ChartAndList modeIsOwnContracts={false} selectedProjectNumber={selectedProjectNumber} selectedContract={selectedContract} setSelectedProjectNumber={setSelectedProjectNumber} setSelectedContract={setSelectedContract} selectedContractDocId={selectedContractDocId} setSelectedContractDocId={setSelectedContractDocId} modeIsDelete={modeIsDelete}/>
    </>
  )
}
