import { Button, Modal } from '@mantine/core'
import { showNotification, updateNotification } from '@mantine/notifications'
import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, query, serverTimestamp, where } from 'firebase/firestore'
import React, { useState } from 'react'
import { AlertCircle } from 'tabler-icons-react'
import { useFirestore } from '../../hooks/useFirebase'
import { Input } from './PhoneNumber'
import './Register.css'
import ReCAPTCHA from "react-google-recaptcha"
import { useAuthMyServer } from '../../hooks/useAuthMyServer'




const recaptchaRef:any = React.createRef();

function Register() {

    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")


    const [opened, setOpened] = useState(false)
    const [confirmOpened, setConfirmOpened] = useState(false)

    function formInputIsValid(){
        recaptchaRef.current.execute()
        console.log("recaptcha passed")
        let everythingIsOk = true
        const regName = /^[a-zA-Z]+ [a-zA-Z]/;
        if(!regName.test(name)){
            everythingIsOk = false
            showNotification({
                color: 'red',
                title: 'Ajjaj, név probléma',
                message: 'Kérlek add meg a teljes nevedet, szóközzel elválasztva!',
                icon: <AlertCircle size={16} />,
                autoClose: false,
              })
        }
        // \b \b represents a word to check, I am checking bt, kft, zrt here
        if(!(/[a-zA-Z0-9] \bbt\b/.test(company.toLocaleLowerCase()) || /[a-zA-Z0-9] \bkft\b/.test(company.toLocaleLowerCase()) || /[a-zA-Z0-9] \bzrt\b/.test(company.toLocaleLowerCase()))){
            everythingIsOk = false
            showNotification({
                color: 'red',
                title: 'Ajjaj, szervezet probléma',
                message: 'Kérlek tűntesd fel a cégnév mezőben a jogi formát! A jelenleg elfogadott formák: Bt, Kft, Zrt',
                icon: <AlertCircle size={16} />,
                autoClose: false,
              })
        }
        // \b \b represents a word to check, I am checking @gmail.com, @outlook.com, @outlook.hu, @hotmail.hu here
        if(!(/[a-zA-Z0-9]\b@gmail.com\b$/.test(email) || /[a-zA-Z0-9]\b"@outlook.com\b$/.test(email) || /[a-zA-Z0-9]\b@outlook.hu\b$/.test(email) || /[a-zA-Z0-9]\b@hotmail.com\b$/.test(email))){
            everythingIsOk = false
            showNotification({
                color: 'red',
                title: 'Ajjaj, email probléma',
                message: 'Hibás email címet adtál meg! A jelenleg elfogadott email szolgáltatók a következők: @gmail.com, @outlook.com, @outlook.hu, @hotmail.com. Ha az általad regisztrálni kívánt szolgáltató nincs a listában és azzal szeretnél regisztrálni, írj nekünk egy emailt!',
                icon: <AlertCircle size={16} />,
                autoClose: false,
              })
        }
        if(!(phoneNumber.includes("36") && phoneNumber.length == 11)){  
            everythingIsOk = false
            showNotification({
                color: 'red',
                title: 'Ajjaj, telefonszám probléma',
                message: 'Kérlek tűntesd fel az ország kódodat! Helyes formátum: +36*********. Jelenleg csak magyar számokat fogadunk el',
                icon: <AlertCircle size={16} />,
                autoClose: false,
              })
        }
        return everythingIsOk
    }
    async function registerOrganization(){
        if(formInputIsValid()){

            // checking if the user hasnt registered before
            const db = useFirestore()
            const q = query(collection(db, "code"), where("email", "==", email));
            const querySnapshot = await getDocs(q);
            // console.log(querySnapshot.docs)
            if(querySnapshot.docs.length == 0 || querySnapshot.docs[0].data().code == ""){
                setConfirmOpened(true)
                try {
                    // get the token from database to make request to my server
                    let token: string = await useAuthMyServer()

                    // adding email to the confirmed-email table
                    const docRef = await addDoc(collection(db, "confirmed-email"), { email: email, confirmed: false });
                    console.log("Document written with ID: ", docRef.id);

                    // send confirmation email out
                    fetch('https://builty-functions.onrender.com/send-confirmation-email', {
                        method: "POST",
                        headers: {"token": `${token}`, 'Content-Type': 'application/json'},
                        body: JSON.stringify({"email": email, "confirmation_link": `${window.location.origin}/confirm-organization-email/${docRef.id}`})
                    })

                    // listen for email confirmation
                    const unsub = onSnapshot(doc(db, "confirmed-email", docRef.id), async (doc:any) => {
                        console.log("Current data: ", doc.data());
                        if(doc.data().confirmed == true){
        
                            // fetch server for checkout session url
                            const response2 = await (await fetch('https://builty-functions.onrender.com/checkout', {
                                method: "POST",
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify({"email": email})
                            })).json()
        
                            // saving form values to database code table
                            if(querySnapshot.docs.length == 0){
                                const docRef = await addDoc(collection(db, "code"), {
                                    code: "",
                                    company: company,
                                    created_at: serverTimestamp(),
                                    customer_id: "",
                                    email: email,
                                    name: name,
                                    phone_number: phoneNumber,
                                    role: "admin"
                                });
                                console.log("Document written with ID: ", docRef.id);
                            }

                            // deleting confirmed-email document
                            localStorage.setItem("confirmEmailDocId", docRef.id)
                            
                            // call checkout on my server to request stripe to get session id
                            // console.log(response)
                            window.location.href = response2.url
                        }
                    });

                } catch (error){
                    console.error(error)
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: 'Fizetési probléma lépett fel',
                        message: 'A regisztrációs folyamat megszakadt, a fizetési szolgáltató nem érhető el',
                        icon: <AlertCircle size={16} />,
                        autoClose: false,
                    })
                }
            } else {
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: 'Már regisztrált email',
                    message: 'Úgy tűnik hogy az email címeddel már korábban regisztráltak szervezetet, ellenőrizd a postaládád. Ha prolémát észlelsz, keres minket emailen!',
                    icon: <AlertCircle size={16} />,
                    autoClose: false,
                })
            }
        } // else already handled by formInputIsValid()
    }

    return (
        <>
        <div className='register-page-content-container'>
            <div className='register-page-content'>
            <h1>Szervezet Regisztrálása</h1>
            <Input label={'Név'} type={'string'} placeholder={'Teljes neved'} value={name} onChange={(e:any) => setName(e.target.value)} />
            <Input label={'Cég'} type={'string'} placeholder={'Szervezet neve amit regisztrálni szeretnél'} value={company} onChange={(e:any) => setCompany(e.target.value)} />
            <Input label={'Email cím'} description="" type={'string'} placeholder={'Saját email címed'} value={email} onChange={(e:any) => setEmail(e.target.value)} />
            <Input label={'Telefonszám'} type={'number'} placeholder={'Mobilszámod amin el tudunk érni'} value={phoneNumber} onChange={(e:any) => setPhoneNumber(e.target.value)} />
            <ReCAPTCHA
                theme='dark'
                sitekey="6LfBY_MhAAAAAGhRWgkyonjJVdXszR0qCasvc9gw"
                size='invisible'
                ref={recaptchaRef}
                onChange={() => {}}
            />
            <Button variant='gradient' gradient={{ from: 'teal', to: 'green', deg: 105 }} className='register-organization-submit-button' onClick={() => {if(formInputIsValid()){setOpened(true)}}}>Szervezet Regisztrálása</Button>
            </div>
        </div>
        <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Figyelmeztetés!"
        centered
      >
        <h4>Név: {name}</h4>
        <h4>Szervezet neve: {company}</h4>
        <h4>Email: {email}</h4>
        <h4>Telefonszám: {phoneNumber}</h4>
        <br />
        <Button variant='outline' style={{"width": "100%"}} onClick={() => {setOpened(false), registerOrganization()}}>Rendben</Button>
      </Modal>
      <Modal
        opened={confirmOpened}
        closeOnClickOutside={false}
        withCloseButton={true}
        onClose={() => setConfirmOpened(false)}
        title="Email cím megerősítése"
        centered
      >
        <h4>A megerősítő emailt kiküldtük, kérlek igazold hogy az email a tiéd, majd térj vissza ide</h4>
      </Modal>
        </>
    )
}

export default Register