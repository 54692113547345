import { doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFirestore } from '../../hooks/useFirebase';

function ConfirmEmail() {

    const [docUpdated, setDocUpdated] = useState(true)
    const { docId } = useParams()
    
    useEffect(() => {
        updateDocumentInFirestore()
    }, [])

    async function updateDocumentInFirestore(){
        try {
            const db = useFirestore()
            console.log(docId)
            await updateDoc(doc(db, "confirmed-email", docId || ""), {
                confirmed: true
              });
        } catch (error){
            console.error(error)
            setDocUpdated(false)
        }
    }

    return (
        <div style={{"height": "100vh", "display": "flex", "alignItems": "center", "justifyContent": "center", "flexDirection": "column"}}>
            {docUpdated ? (
                <>
                <h1>Email cím visszaigazolva</h1>
                <h3>Kérlek térj vissza a regisztrációs lapra és töltsd ki a fizetési űrlapot</h3>
                </>
                ) : (
                <><h1>Hiba történt!</h1>
                <h3>Email cím visszaigazolása sikertelen volt! Kérlek írj nekünk egy emailt a probléma megoldásához!</h3></>
            )}
        </div>
    )
}

export default ConfirmEmail