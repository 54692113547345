import { Button, Loader, SegmentedControl } from '@mantine/core'
import { showNotification, updateNotification } from '@mantine/notifications'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { AlertCircle, Check } from 'tabler-icons-react'
import { useAuth, useFirestore, useUserCompany } from '../../../../hooks/useFirebase'
import { useStore } from '../../../../hooks/useStore'
import { wait } from '../../../../hooks/utils'
import { cacheRowType } from '../../../../interfaces/databaseTypes'

interface HeaderProps {
  setImportToggleValue: Function
  company: string,
  importToggleValue: string,
  setOpenedImport: Function
}

export default function Header({ setImportToggleValue, importToggleValue, company, setOpenedImport }: HeaderProps) {

  const [isLoading, setIsLoading] = useState(false)

  const selectionState = useStore((state:any) => state.selection)
  const setSelectionState = useStore((state:any) => state.setSelection)

  const allRowsForImporting = useStore((state:any) => state.allRowsForImporting)

  function getSelectionDataFromIds(){
    const filteredIds = allRowsForImporting.filter((row: { _firestore_id: string }) => selectionState.includes(row._firestore_id));
    return filteredIds
  }

  async function uploadSelectedToUsersDatabase(){
    const uploadable = getSelectionDataFromIds()
    console.log(uploadable)
    if(uploadable.length >= 20){
      setIsLoading(true)
    }

    showNotification({
      id: 'cloning',
      color: "green",
      title: `Adatok másolása az adatbázisodba`,
      loading: true,
      icon: <AlertCircle size={16} />,
      message: 'Az általad kijelölt sorokat átmásoljuk a te adatbázisodba, hogy később módosítani és használni tudd',
      autoClose: false,
    });

    
    try {
      
      const db = useFirestore()
      const company = await useUserCompany()
      const user = useAuth()

      setOpenedImport(false)

      uploadable.forEach(async (item:{_firestore_id: string, data: object, company: string, table_name: string}) => {
        // Add a new document with a generated id.
        const docRef = await addDoc(collection(db, "database"), {
          company: company,
          created_at: serverTimestamp(),
          data: item.data,
          project_number: "",
          table_name: item.table_name,
          user_id: user.uid
        });
        console.log("Document written with ID: ", docRef.id);

        //creating data in cache
        const rows = JSON.parse(sessionStorage.getItem(item.table_name) || "[]")
        if(rows.length != 0){
          rows.push({
            id: docRef.id,
            data: item.data,
            created_at: { seconds: new Date().getTime() / 1000 },
            last_modified: { seconds: new Date().getTime() / 1000 }
          } as cacheRowType)
          sessionStorage.setItem(item.table_name, JSON.stringify(rows))
        }
        
        await wait(100)
      });

      updateNotification({
        id: 'cloning',
        title: `Adatok másolva`,
        icon: <Check size={16} />,
        message: 'Az általad kért sorokat átmásoltuk az adatbázisodba, későbbi szerkesztésre',
        autoClose: 5000,
      });
      setIsLoading(false)
      
    } catch (error) {
      updateNotification({
        id: 'cloning',
        color: "red",
        title: `Az adatok nem lettek átmásolva`,
        icon: <AlertCircle size={16} />,
        message: 'Valamilyen hiba történt a másoláskor, kérlek később próbáld újra',
        autoClose: false,
      });
      setIsLoading(false)
    }
  }

  useEffect(() => {
    return () => {
      setSelectionState([])
    }
  }, [])
  

  return (
    <>
      <div style={{display: "flex", maxHeight: "40px", alignItems: "center", width: "85vw", justifyContent: "space-between", margin: "10px 0px"}}>
          <SegmentedControl
              value={importToggleValue}
              data={[
                  { label: company, value: company },
                  { label: 'Közösség', value: 'all' },
              ]}
              onChange={(value) => { setImportToggleValue(value); setSelectionState([]) }}
          />
          <h3 style={{margin: "0px"}}>Adatok importálása</h3>
          <Button onClick={() => uploadSelectedToUsersDatabase()}>Kijelöltek importálása</Button>
      </div>

      { isLoading ? <div className='loader-backdrop dashboard'><Loader size={'xl'}/></div> : "" }
    </>
  )
}