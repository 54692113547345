import { showNotification } from "@mantine/notifications";
import { collection, getDocs, query, where } from "firebase/firestore";
import { contractProgressType } from "../interfaces/contractProgressTypes";
import { DailyPostType } from "../interfaces/dailyPostType";
import { useFirestore, useFunctions, useUserCompany } from "./useFirebase";
import { IconX } from '@tabler/icons';
import { Edit, Filter, Magnet, Pencil, Plus, Printer, Search, Trash, X } from 'tabler-icons-react';
import * as Sentry from '@sentry/browser';


export async function useAddContractProgressToDaily(Dailys:DailyPostType[], userCompany: string, mode: "returnOnlyInputDailys" | "returnListWithAllDaysEvenWhenDailyPostHasNotBeenMade", dateFrom?: Date, dateTo?: Date): Promise<DailyPostType[] | undefined> {
    console.log("calculating ContractProgressToDailys")

    const addContractProgressToDaily = useFunctions("addContractProgressToDaily");

    const datefrom = dateFrom ? dateFrom.getTime() : dateFrom;
    const dateto = dateTo ? dateTo.getTime() : dateTo;
    // const datefrom = undefined;
    // const dateto = undefined;

    try {
        const resultFromaddContractProgressToDaily: any = await addContractProgressToDaily({
            Dailys,
            timezoneDifference: new Date().getTimezoneOffset() / 60 / -1,
            userCompany,
            mode,
            datefrom,
            dateto
        });

        console.log("dailys", resultFromaddContractProgressToDaily)
        console.log("dailys.data.dailyobject", resultFromaddContractProgressToDaily.data.dailyobject)
    
        return resultFromaddContractProgressToDaily.data.dailys as DailyPostType[];

    } catch (error) {

        console.log("error", error);
        Sentry.captureException(error);

        showNotification({
            autoClose: false,
            title: "Hiba történt!",
            message: 'Sajnos nem sikerült lekérdezni minden adatot, már dolgozunk a hiba kijavításán, kérlek próbáld újra később!',
            color: 'red',
            // icon: <IconX />,
        });

        return undefined;
    }

    // const db = useFirestore();
    // // Sort the array based on the 'date' property
    // Dailys.sort((a, b) => {
    //     const dateA = a.date.seconds;
    //     const dateB = b.date.seconds;
        
    //     return dateB - dateA; // descending
    // });

    // const datesObject: {[key: string]: DailyPostType} = {};

    // Dailys.forEach((daily) => {
    //     // const seconds = daily.date.seconds ? daily.date.seconds : daily.date._seconds;

    //     const year = new Date(daily.date.seconds*1000).getFullYear();
    //     const month = new Date(daily.date.seconds*1000).getMonth() + 1;
    //     const day = new Date(daily.date.seconds*1000).getDate();
    //     const dateString = `${year}-${month}-${day}`;

    //     // console.log(dateString);

    //     datesObject[dateString] = daily;
    // })

    // // remove duplicates from datesObject
    // const uniquedatesObject: {[key: string]: DailyPostType} = {};
    // Object.keys(datesObject).forEach((key) => {
    //     if (!uniquedatesObject.hasOwnProperty(key)) {
    //         uniquedatesObject[key] = datesObject[key];
    //     }
    // });
    // console.log("uniquedatesObject", uniquedatesObject);
    // // console.log(Dailys)



    // // get associated contract progresses
    // let contractProgressArray: contractProgressType[] = [];
    // // const userCompany = await useUserCompany();
    // // console.log(Object.keys(uniquedatesObject).length)

    //  // Create a JavaScript Date object from the dateString
    // //  const today = new Date();
    // //  let dateFrom = new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000));
    // //  let dateTo = today;


    // //  if(fetchDateTo){
    // //     dateFrom = new Date(checkOnlyThisDate);
    // //     dateTo = new Date(checkOnlyThisDate);
    // //     // Set the time to 23:59:59
    // //     dateFrom.setHours(0);
    // //     dateFrom.setMinutes(0);
    // //     dateFrom.setSeconds(0);

    // //     dateTo.setHours(23);
    // //     dateTo.setMinutes(59);
    // //     dateTo.setSeconds(59);

    // //     console.log(dateFrom, dateTo)
    // //  }
 
    //  // console.log(datesObject);
    // let possibleProjectNumbers = Dailys.map((daily) => daily.project_number);
    // // filter out duplicates
    // possibleProjectNumbers = [...new Set(possibleProjectNumbers)];
    // console.log("possibleProjectNumbers", possibleProjectNumbers);
    //  // console.log(`where("created_at", ">=", ${new Date(dateFrom)}), where("created_at", "<=", ${new Date(dateTo)}, where("customer", "==", ${userCompany}), where("project_number", "in", ${possibleProjectNumbers}))`)

    //  let q
    // if(dateFrom && dateTo){
    //     q = query(collection(db, "contract-progress"), where("created_at", ">=", dateFrom), where("created_at", "<=", dateTo), where(`customer`, "==", userCompany), where("project_number", "in", possibleProjectNumbers));
    //  } else if(dateFrom){
    //     q = query(collection(db, "contract-progress"), where("created_at", ">=", dateFrom), where(`customer`, "==", userCompany), where("project_number", "in", possibleProjectNumbers));
    //  } else if(dateTo){
    //     q = query(collection(db, "contract-progress"), where("created_at", "<=", dateTo), where(`customer`, "==", userCompany), where("project_number", "in", possibleProjectNumbers));
    //  } else {
    //     // this means we are fetching all days
    //     q = query(collection(db, "contract-progress"), where(`customer`, "==", userCompany), where("project_number", "in", possibleProjectNumbers));
    //  }
    //  const querySnapshot = await getDocs(q);

    //  // console.log("querySnapshot.size", querySnapshot.size);
     
    //  const innerArray: contractProgressType[] = [];
    //  querySnapshot.forEach((doc) => {
    //      // doc.data() is never undefined for query doc snapshots
    //      innerArray.push(doc.data() as contractProgressType);
    //     //  console.log(doc.id, " => ", doc.data());
    //  });
    //  contractProgressArray = innerArray;
    //  // console.log("query run")

    // // // this promise is needed, so it makes the code synchronous
    // // const p: Promise<contractProgressType[]> = new Promise((resolve, reject) => {

    // //     Object.keys(uniquedatesObject).map(async (key) => {
    
    // //         const year = Number(key.split("-")[0]);
    // //         const month = Number(key.split("-")[1]);
    // //         const day = Number(key.split("-")[2]);
    // //         // console.log(year, month, day);
            
    // //         // Create a JavaScript Date object from the dateString
    // //         let dateFrom = new Date(year, month-1, day);
    // //         let dateTo = new Date(year, month-1, day);
        
    // //         // Set the time to 23:59:59
    // //         dateTo.setHours(dateTo.getHours() + 23);
    // //         dateTo.setMinutes(dateTo.getMinutes() + 59);
    // //         dateTo.setSeconds(dateTo.getSeconds() + 59);
        
    // //         // console.log(datesObject);
    // //         const possibleProjectNumbers = Dailys.map((daily) => daily.project_number);
    // //         // console.log(`where("created_at", ">=", ${new Date(dateFrom)}), where("created_at", "<=", ${new Date(dateTo)}, where("customer", "==", ${userCompany}), where("project_number", "in", ${possibleProjectNumbers}))`)
    
    // //         const q = query(collection(db, "contract-progress"), where("created_at", ">=", dateFrom), where("created_at", "<=", dateTo), where(`customer`, "==", userCompany), where("project_number", "in", possibleProjectNumbers));
    // //         const querySnapshot = await getDocs(q);
    
    // //         // console.log("querySnapshot.size", querySnapshot.size);
            
    // //         const innerArray: contractProgressType[] = [];
    // //         querySnapshot.forEach((doc) => {
    // //             // doc.data() is never undefined for query doc snapshots
    // //             innerArray.push(doc.data() as contractProgressType);
    // //             console.log(doc.id, " => ", doc.data());
    // //         });
    // //         // console.log("query run")
            
    // //         resolve(innerArray);
    // //     })
    // // })
    
    // // contractProgressArray = await p;
    // console.log("contractProgressArray", contractProgressArray);
    // console.log("loaded this many contract progresses", contractProgressArray.length);

    // // function isSameDay(date1: Date, date2: Date) {
    // //     console.log(date1, date2);
    // //     return date1.getFullYear() === date2.getFullYear() &&
    // //            date1.getMonth() === date2.getMonth() &&
    // //            date1.getDate() === date2.getDate();
    // //   }
      
    // // contractProgressArray.forEach((contractProgress) => {
    // //     const thing = Dailys.find((daily) => daily.project_number === contractProgress.project_number && isSameDay(new Date(daily.date.seconds*1000), new Date(contractProgress.created_at.seconds*1000)));
    // //     console.log(thing)
    // // })
    // contractProgressArray.sort((a, b) => {
    //     return b.created_at.seconds - a.created_at.seconds
    // })
    // // console.log(JSON.stringify(contractProgressArray));

    // const contractProgressCalculatedPerDayObject: {[key: string]: { contractProgresses: contractProgressType[], countedChange: number }} = {};
    // // const contractProgressCalculatedPerDayObject: {[key: string]: { contractProgresses: { [contractor: string]: contractProgressType[] }, countedChange: number }} = {};
    // contractProgressArray.forEach((contractProgress) => {
    //     const year = new Date(contractProgress.created_at.seconds*1000).getFullYear();
    //     const month = new Date(contractProgress.created_at.seconds*1000).getMonth() + 1;
    //     const day = new Date(contractProgress.created_at.seconds*1000).getDate();
    //     const dateString = `${year}-${month}-${day}`;

    //     if(!contractProgressCalculatedPerDayObject.hasOwnProperty(`${contractProgress.project_number}-----${dateString}-----${contractProgress.contractor}`)) {
    //         // const contractor = contractProgress.contractor;
    //         // contractProgressCalculatedPerDayObject[`${contractProgress.project_number}-----${dateString}`].contractProgresses = {contractor: [contractProgress]};
    //         contractProgressCalculatedPerDayObject[`${contractProgress.project_number}-----${dateString}-----${contractProgress.contractor}`] = {contractProgresses: [contractProgress], countedChange: 0};
    //     } else {
    //         contractProgressCalculatedPerDayObject[`${contractProgress.project_number}-----${dateString}-----${contractProgress.contractor}`].contractProgresses.push(contractProgress);
    //     }
    // })

    // // Grouping by the "contractor" property
    // // const groupedProjects = contractProgressCalculatedPerDayObject.reduce((acc, project) => {
    // //     const contractor = project.contractor;
    // //     if (!acc[contractor]) {
    // //     acc[contractor] = [];
    // //     }
    // //     acc[contractor].push(project);
    // //     return acc;
    // // }, {});

    // console.log("contractProgressCalculatedPerDayObject before calc.", contractProgressCalculatedPerDayObject);
    
    // console.log(contractProgressArray)
    // // calculate difference between contract progresses in a day
    // Object.entries(contractProgressCalculatedPerDayObject).forEach(([key, value], index) => {
    //     // contractProgressCalculatedPerDayObject[key].countedChange = value.contractProgresses[0].forint_readiness - value.contractProgresses[value.contractProgresses.length-1].forint_readiness
    //     let contractProgressButOnlyWithTheCurrentContractor = contractProgressArray.filter((contractProgress) => contractProgress.contractor === key.split("-----")[2] && contractProgress.project_number === key.split("-----")[0])
    //     // contractProgressButOnlyWithTheCurrentContractor.sort((a, b) => {
    //     //     return a.created_at.seconds - b.created_at.seconds
    //     // })
    //     console.log("contractProgressButOnlyWithTheCurrentContractor", contractProgressButOnlyWithTheCurrentContractor)
    //     // console.log("index", index)
    //     // finc last contract progress on that day
    //     let progressIndex = contractProgressButOnlyWithTheCurrentContractor.findIndex((contractProgress) => contractProgress.project_number === key.split("-----")[0] && contractProgress.created_at.seconds*1000 === value.contractProgresses[0].created_at.seconds*1000)
        
    //     console.log("progressIndex", progressIndex)
    //     console.log(contractProgressButOnlyWithTheCurrentContractor.length, contractProgressButOnlyWithTheCurrentContractor)
    //     // console.log(contractProgressButOnlyWithTheCurrentContractor[progressIndex].forint_readiness)
    //     // console.log(contractProgressButOnlyWithTheCurrentContractor[progressIndex+1].forint_readiness)
        
    //     if(contractProgressCalculatedPerDayObject[key].contractProgresses.length == 1){
    //         if(contractProgressButOnlyWithTheCurrentContractor.length === 1){
    //             contractProgressCalculatedPerDayObject[key].countedChange = contractProgressButOnlyWithTheCurrentContractor[progressIndex].forint_readiness
    //         } else {
    //             if(progressIndex + 1 < contractProgressButOnlyWithTheCurrentContractor.length){
    //                 contractProgressCalculatedPerDayObject[key].countedChange = contractProgressButOnlyWithTheCurrentContractor[progressIndex].forint_readiness - contractProgressButOnlyWithTheCurrentContractor[progressIndex + 1].forint_readiness
    //             } else {
    //                 contractProgressCalculatedPerDayObject[key].countedChange = value.contractProgresses[0].forint_readiness // this is the last added contract progress
    //             }
    //         }
    //     } else { // if more than one contract progress has been recorded on that day
    //         const howManyProgressInThatDay = value.contractProgresses.length
    //         console.log("howManyProgressInThatDay", howManyProgressInThatDay)
    //         // console.log("contractProgressButOnlyWithTheCurrentContractor", contractProgressButOnlyWithTheCurrentContractor)
    //         // console.log("key", key, "value", value)
    //         // console.log(contractProgressButOnlyWithTheCurrentContractor[progressIndex])
    //         // console.log(contractProgressButOnlyWithTheCurrentContractor[progressIndex+howManyProgressInThatDay])
    //         // last contract progress
    //         if(value.contractProgresses.length === contractProgressButOnlyWithTheCurrentContractor.length){
    //             // getting the first and the last contract progress and calculating the difference
    //             contractProgressCalculatedPerDayObject[key].countedChange = value.contractProgresses[0].forint_readiness
    //         } else {
    //             // const progressIndexIfThereIsMoreThanOne = contractProgressButOnlyWithTheCurrentContractor.findIndex((contractProgress) => value.contractProgresses[0].created_at.seconds*1000 === contractProgress.created_at.seconds*1000)
    //             // console.log("progressIndexIfThereIsMoreThanOne+howManyProgressInThatDay", progressIndexIfThereIsMoreThanOne+howManyProgressInThatDay)
    //             console.log("progressIndex + howManyProgressInThatDay", progressIndex + howManyProgressInThatDay)
    //             // console.log("key", key)
    //             if(progressIndex + howManyProgressInThatDay < contractProgressButOnlyWithTheCurrentContractor.length){
    //                 contractProgressCalculatedPerDayObject[key].countedChange = contractProgressButOnlyWithTheCurrentContractor[progressIndex].forint_readiness - contractProgressButOnlyWithTheCurrentContractor[progressIndex + howManyProgressInThatDay].forint_readiness
    //             } else {
    //                 // console.log("lefutott jani 2")
    //                 contractProgressCalculatedPerDayObject[key].countedChange = value.contractProgresses[0].forint_readiness // this is the last added contract progress
    //             }
    //         }
    //     }
    // })
    // console.log("contractProgressCalculatedPerDayObject after calc.", contractProgressCalculatedPerDayObject);

    // // adding changes to Dailys
    // Object.entries(contractProgressCalculatedPerDayObject).forEach(([key, value]) => {
    //     console.log("adding run")
    //     const projectNumber = key.split("-----")[0];
    //     const dateString = key.split("-----")[1];
    //     const index = Dailys.findIndex((daily) => {
    //         // const seconds = daily.date.seconds ? daily.date.seconds : daily.date._seconds; // remove the _seconds property, and format the Dailys server side to look like they came from Firestore instead of Meilisearch
    //         const dailyDate = new Date(daily.date.seconds*1000);
    //         const progressDate = new Date(dateString);
    //         console.log(dailyDate, dateString);
    //         // console.log(daily.project_number === projectNumber, progressDate.getFullYear() === dailyDate.getFullYear(), progressDate.getMonth() === dailyDate.getMonth(), progressDate.getDate() === dailyDate.getDate()); 
    //         // console.log("-------")

    //         return daily.project_number === projectNumber && progressDate.getFullYear() === dailyDate.getFullYear() && progressDate.getMonth() === dailyDate.getMonth() && progressDate.getDate() === dailyDate.getDate();
        
    //     });
        
    //     // console.log(index)
    //     if(index !== -1){ // if daily exists
    //         Dailys[index].final_price += value.countedChange

    //         const companysInProgressesWithPrice = value.contractProgresses.map((contractProgress) => { return { contractor: contractProgress.contractor, price: value.countedChange } })
    //         console.log("companysInProgressesWithPrice when daily exists", companysInProgressesWithPrice)

    //         const inside_added_progresses = Dailys[index].companies_inside_added_progresses

    //         if(inside_added_progresses !== undefined){
    //             Dailys[index].companies_inside_added_progresses = [...inside_added_progresses, ...companysInProgressesWithPrice]
    //         } else {
    //             Dailys[index].companies_inside_added_progresses = companysInProgressesWithPrice
    //         }
    //         console.log("dateString, added to existstent", dateString);
    //     }

    //     if(mode === "returnListWithAllDaysEvenWhenDailyPostHasNotBeenMade" && index === -1){
    //         // const year = Number(key.split("-")[0]);
    //         // const month = Number(key.split("-")[1]);
    //         // const day = Number(key.split("-")[2]);
    //         // console.log(year, month, day);
            
    //         // Create a JavaScript Date object from the dateString
    //         // let date = new Date(year, month-1, day);
    //         let date = new Date(dateString);
    //         console.log("dateString, created new Daily", dateString);
    //         // console.log("date", date);

    //         const companysInProgressesWithPrice = value.contractProgresses.map((contractProgress) => { return { contractor: contractProgress.contractor, price: value.countedChange } })
    //         console.log("companysInProgressesWithPrice when daily does not exists", companysInProgressesWithPrice)

    //         Dailys.push({
    //             companies_inside_added_progresses: companysInProgressesWithPrice,
    //             project_number: projectNumber,
    //             date: { seconds: date.getTime() / 1000, nanoseconds: 0 },
    //             final_price: value.countedChange,
    //             id: "",
    //             comment: "",
    //             company: "",
    //             companies_inside: [],
    //             tables_inside: [],
    //             created_at: {
    //                 seconds: 0,
    //                 nanoseconds: 0
    //             },
    //             data: "",
    //             last_modified: {
    //                 seconds: 0,
    //                 nanoseconds: 0
    //             },
    //             scheme: "",
    //             user_id: ""
    //         })
    //     }
    // })

    // // remove duplicates from companies_inside_added_progresses
    // Dailys.forEach((daily, index) => {
    //     if(daily.companies_inside_added_progresses){
    //         const uniqueArray = daily.companies_inside_added_progresses.filter((obj, index, arr) => {
    //             return index === arr.findIndex((innerObj) => innerObj.contractor === obj.contractor);
    //             });

    //         Dailys[index].companies_inside_added_progresses = uniqueArray
    //     }
    // })

    // console.log("Dailys", Dailys);


    // return Dailys;
    
    // // let contractProgressIncreasePriceBy = 0
    // // if(contractProgressArray.length === 1){
    // //     contractProgressIncreasePriceBy = contractProgressArray[0].forint_readiness
    // // } else {
    // //     contractProgressIncreasePriceBy = contractProgressArray[0].forint_readiness - contractProgressArray[1].forint_readiness
    // // }

    // // Dailys.forEach((daily) => {
    // //     const thing = contractProgressArray.find((contractProgress) => contractProgress.project_number === daily.project_number);
    // //     console.log(thing)
    // // })







    // // // creating the contract Progress Cache
    // // const contractProgressCache: {[key: dateString]: counter} = {}

    // // // get db
    // // const db = useFirestore();

    // // // count final_prices of days
    // // Dailys.forEach(async (daily) => {

    // //     // Convert Unix timestamp to milliseconds
    // //     const timestampInMilliseconds = daily.date.seconds * 1000;

    // //     // Create a JavaScript Date object from the timestamp in milliseconds
    // //     let dateFrom = new Date(timestampInMilliseconds);
    // //     let dateTo = new Date();

    // //     // Set the time to 23:59:59
    // //     dateTo.setHours(dateTo.getHours() + 23);
    // //     dateTo.setMinutes(dateTo.getMinutes() + 59);
    // //     dateTo.setSeconds(dateTo.getSeconds() + 59);

    // //     const dateString = `${dateFrom.getFullYear()}-${dateFrom.getMonth() + 1}-${dateFrom.getDate()}`;

    // //     if(datesObject[dateString]){
    // //         datesObject[dateString] += daily.final_price;
    // //     } else {
    // //         const q = query(collection(db, "contract-progress"), where("created_at", ">=", dateFrom), where("created_at", "<=", dateTo));
    // //         const querySnapshot = await getDocs(q);
    // //         querySnapshot.forEach((doc) => {
    // //         // doc.data() is never undefined for query doc snapshots
    // //         console.log(doc.id, " => ", doc.data());
    // //         });

    // //     }
    // // })

    // // // Use the 'startTimestamp' and 'endTimestamp' in your Firestore query to retrieve documents created on the same day
    // // // Replace 'collectionName' with the actual name of your collection
    // // firebase.firestore()
    // // .collection('collectionName')
    // // .where('createdAt', '>=', startTimestamp)
    // // .where('createdAt', '<=', endTimestamp)
    // // .get()
    // // .then((querySnapshot) => {
    // //     querySnapshot.forEach((doc) => {
    // //     // Handle each document here
    // //     console.log(doc.id, ' => ', doc.data());
    // //     });
    // // })
    // // .catch((error) => {
    // //     console.log('Error getting documents: ', error);
    // // });

}