import { useToggle, upperFirst } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Divider,
  Checkbox,
  Anchor,
  Stack,
  Modal,
  Input,
} from '@mantine/core';
import { GoogleButton } from './SocialButtons/GoogleButton';
import { getAuth, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth';
import { showNotification, updateNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { useFirestore } from '../../hooks/useFirebase';
import { MouseEventHandler, Ref, useEffect, useRef, useState } from 'react';
import { AlertCircle, Check } from 'tabler-icons-react';
import ReCAPTCHA from 'react-google-recaptcha';

interface AuthenticationFormProps {
  loginwithgoogle: Function,
  // setisLoading: Function,
  // setOpened: Function,
  // codeValue: string,
  email: string,
  setEmail: Function,
  emailError: boolean,
  recaptchaRef: any,
  reCaptchaPassed: boolean,
  aszf: boolean,
  setAszf: Function,
  checkForm: MouseEventHandler<HTMLButtonElement>
}
export function AuthenticationForm({loginwithgoogle, email, setEmail, emailError, recaptchaRef, reCaptchaPassed, aszf, setAszf, checkForm}: AuthenticationFormProps) {

  // let navigate = useNavigate();
  // const db = useFirestore()

  // const [type, toggle] = useToggle(['login', 'register']);
  
  const type = 'login'
  // const form = useForm({
  //   initialValues: {
  //     email: '',
  //     name: '',
  //     // password: '',
  //     terms: true,
  //   },

  //   validate: {
  //     email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Helytelen email'),
  //     terms: (val:boolean) => (val == true ? null : "Kérlek fogadd el az ászf-et!")
  //     // password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
  //   },
  // });

  
  return (
    <>
    <Paper radius="md" p="xl" withBorder style={{"width": "350px"}}>
      {/* <div style={{"display": "flex", "justifyContent": "center"}}> */}
        <Text size="xl" weight={500}>
          Üdvözöl a Builty, belépés
          {/* , {type == "login" ? "Bejelentkezek" : "Regisztrálok"} */}
        </Text>
      {/* </div> */}

      <Group grow mb="md" mt="md">
        <GoogleButton radius="xl" onClick={loginwithgoogle}>Google</GoogleButton>
      </Group>

      <Divider label=" vagy folytatás emailel" labelPosition="center" my="lg" />

      {/* <form onSubmit={form.onSubmit(console.log)}> */}
      <form onSubmit={(e) => {e.preventDefault()}}>
        <Stack>
          {/* {type === 'register' && (
            <TextInput
              label="Name"
              placeholder="Your name"
              value={form.values.name}
              onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
            />
          )} */}

          <TextInput
            name="Email"
            autoComplete="off"
            required
            label="Email"
            placeholder="hello@builty.hu"
            // value={form.values.email}
            value={email}
            onChange={(event:any) => setEmail(event.target.value)}
            // onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
            error={emailError && 'Helytelen email cím'}
          />
          <ReCAPTCHA
            theme='dark'
            ref={recaptchaRef}
            sitekey="6LfBY_MhAAAAAGhRWgkyonjJVdXszR0qCasvc9gw"
            size='invisible'
            onChange={() => { reCaptchaPassed = true }} // it gets called when user successfully passes the recaptcha
            onErrored={() => { reCaptchaPassed = false }}
            onExpired={() => { reCaptchaPassed = false }}
          />

          {/* <PasswordInput
            required
            label="Password"
            placeholder="Your password"
            value={form.values.password}
            onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
            error={form.errors.password && 'Password should include at least 6 characters'}
          /> */}

          {/* {type === 'register' && ( */}
            <Checkbox
              label="Elfogadom az ÁSZF-et"
              // checked={form.values.terms}
              checked={aszf}
              onChange={(event:any) => setAszf(!aszf)}
              // onChange={(event) => form.setFieldValue('terms', event.currentTarget.checked)}
            />
          {/* )} */}
        </Stack>

        <Group position="apart" mt="xl">
          {/* <Anchor
            component="button"
            type="button"
            color="dimmed"
            onClick={() => toggle()}
            size="xs"
          >
            {type === 'register'
              ? 'Already have an account? Login'
              : "Don't have an account? Register"}
          </Anchor> */}
          <Button style={{"width": "100%"}} variant='gradient' gradient={{ from: 'teal', to: 'green', deg: 105 }} type="submit" onClick={checkForm}>Folytatás</Button>
        </Group>
      </form>
    </Paper>
  </>
  );
}