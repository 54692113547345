import { Button, Modal } from '@mantine/core'
import React, { useState } from 'react'
import { Report } from 'tabler-icons-react'
import { incomeObjectType } from '../../QueryGraphs';
import { DailyPostType } from '../../../../interfaces/dailyPostType';
import { Table } from '@mantine/core';
import { useNumberWithSpaces } from '../../../../hooks/utils';



interface ShowPureResultProps {
    costDocuments: DailyPostType[],
    incomeObject: incomeObjectType
}


export default function ShowPureResult({ costDocuments, incomeObject }: ShowPureResultProps) {

  const [opened, setOpened] = useState(false);

  console.log({costDocuments, incomeObject})

  return (
    <>
    <Button leftIcon={<Report size={16} />}  variant={'light'} onClick={() => setOpened(true)}>Keresés részletei</Button>

    <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Részletes"
        size="90vw"
      >
        {costDocuments.map((document) => 
            <div key={document.id}>
            <h2>{new Date(document.date.seconds * 1000).toLocaleDateString()}</h2>
            
                {JSON.parse(document.data).map((docData: any) => {
                    const table = docData.data
                    const tablename = docData.tableName

                    // it is for empty tables that has been removed because of a filter parameter
                    if(table.length === 0) return <div key={tablename}></div>
                    
                    return (
                        <div key={tablename} style={{ marginBottom: "60px" }}>
                        <h3 style={{ margin: "5px" }}>{tablename}</h3>

                        <Table>
                            <thead>
                                <tr>
                                    {table[0].map((cell: any) => <th key={cell.column}>{cell.column}</th>)}
                                </tr>
                            </thead>

                        {table.map((row: any, rowIndex: number) => 
                            <tbody key={rowIndex}>
                                <tr>
                                    {/* <td>{JSON.stringify(row)}</td> */}
                                    {row.map((cell: any, cellIndex: number) => 
                                        <td key={cellIndex}>{(cell.type === "number" || cell.type === "text" || cell.type === "price") ? useNumberWithSpaces(cell.value) : cell.value}</td>
                                    )}
                                </tr>
                            </tbody>
                        )}
                        </Table>
                        </div>
                    )
                }
                )}

            </div>
        )}
    </Modal>
    </>
  )
}
