import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { useAuth, useFirestore } from "./useFirebase";

export async function useTableCache(tableParam:string | undefined){

    if(tableParam == undefined){
        return []
    }

    const db = useFirestore()
    const user = useAuth()

    if(sessionStorage.getItem(tableParam!) == null){
        console.warn("rows have been fetched")
        const q = query(collection(db, "database"), where("user_id", "==", user.uid), where("table_name", "==", tableParam));
        let rows: any = []
        const querySnapshot = await getDocs(q);

        // rows = []
        querySnapshot.docs.forEach((doc) => {
          // console.log(doc.id)
          const rowObject = {"id": doc.id, "data": doc.data().data, "created_at": doc.data().created_at, "last_modified": doc.data().last_modified}
          // console.log("row", rowObject)
          // let rowArrayObject = []
          // rowArray.map((objectcell: string, index: number) => {
          //   console.log(objectcell)
          //   // const columName = columns[index]
          //   // rowArrayObject[index] = {columName: objectcell}
          // })
          rows.push(rowObject)
          // console.log(new Date(rowObject.created_at.seconds*1000) - new Date(rowObject.created_at.seconds*1000+5))
        })
        console.log(rows)
        // this sorts the rows by created time
        rows.sort((a:any,b:any)=> (a.created_at.seconds*1000 + a.created_at.nanoseconds/1000000000) - (b.created_at.seconds*1000 + b.created_at.nanoseconds/1000000000));
        // rows = rows.sort(function(a:any,b: any){
        //   // Turn your strings into dates, and then subtract them
        //   // to get a value that is either negative, positive, or zero.
        //   return new Date(b.created_at.seconds*1000) - new Date(a.created_at.seconds*1000);
        // });
        // setSortedData([...rows])
        // setrowsDataGotFromFirebase([...rows])
        sessionStorage.setItem(tableParam!, JSON.stringify(rows))
        console.warn("rows in sessionStorage updated")
  
        return rows
  
      } else {
        console.log("rows fetched from sessionStorage")
        // alert("sessionstorage")
        const rows: any = JSON.parse(sessionStorage.getItem(tableParam!) || "[]")
        console.log(rows)
        // setSortedData(rows)
        // setrowsDataGotFromFirebase(rows)

        return rows
      }

}