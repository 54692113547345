import { NumberInput, Popover, Select, Tooltip } from '@mantine/core'
import { useDisclosure, useFocusTrap } from '@mantine/hooks';
import { cleanNotifications, showNotification } from '@mantine/notifications';
import React, { Children, forwardRef, memo, ReactNode, useCallback, useState } from 'react'
import { Calendar, Clock, InfoCircle, InfoSquare, Rotate } from 'tabler-icons-react';
import { yellow_button } from '../../../../App';
import { useStore } from '../../../../hooks/useStore';
import { useCapitalizeFirstLetter, useNumberWithSpaces } from '../../../../hooks/utils';
import { FormSchemeType, FormStateType } from '../../../../interfaces/formTypes';

import "./FormItemCell.css"

interface FormItemCellProps {
    cell: {
        value: string | number | null;
        column: string;
        initial_load: boolean;
        title: string;
        type: string;
        width_category: string;
    },
    onchange: Function,
    rowindex: number,
    rowData: { id: string; created_at: object; data: any; }[],
    table: string,
    row: {
        value: string | number | null;
        column: string;
        initial_load: boolean;
        title: string;
        type: string;
        width_category: string;
    }[],
    cellValue: string | number | null
}

const FormItemCell = memo(({ cell, onchange, rowindex, table, rowData, row, cellValue }: FormItemCellProps) => {

    const label = rowindex == 0 ? cell.title : ""
    // const formState: FormStateType = useStore((state:any) => state.formState)
    const formScheme: FormSchemeType = useStore((state:any) => state.formScheme)
    
    const formMode = useStore((state:any) => state.formMode)

    const formModeIsDaily = formMode.toLowerCase().includes('dailyPost'.toLowerCase())

    // const IconWrapper = ({ children }:any) => {
    //     const [opened, { close, open }] = useDisclosure(false);
    //     return (
    //         <Popover width={200} position="bottom" withArrow shadow="md" opened={opened}>
    //         <Popover.Target>
    //             <div onMouseEnter={open} onMouseLeave={close}>
    //                 { children }
    //             </div>
    //         </Popover.Target>
    //         <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
    //             <p>This popover is shown when user hovers the target element</p>
    //         </Popover.Dropdown>
    //         </Popover>
    //     )
    // };

    // function setIcon(){

    //     let óradíj_napidíj: string | undefined
    //     let icon
    //     let format
    //     // console.log(rowData, rowindex)
    //     try {
    //         format = rowData[0].data["Óradíj/Napidíj"] // it is 0 because the filter should return only one row
    //         óradíj_napidíj = format
    //     } catch {
    //         óradíj_napidíj = undefined
    //     }
    //     if(óradíj_napidíj && rowData.length == 1){
    //         if(óradíj_napidíj.toLowerCase() == "óradíj"){
    //             icon = <Tooltip label={format} position="bottom" color="green" withArrow>
    //                         <div>
    //                             <Clock className='price-mode-icon'/>
    //                         </div>
    //                     </Tooltip>
    //         } else if(óradíj_napidíj.toLowerCase() == "napidíj"){
    //             icon = <Tooltip label={format} position="bottom" color="green" withArrow>
    //                         <div>
    //                             <Calendar className='price-mode-icon'/>
    //                         </div>
    //                     </Tooltip>
    //         } else {
    //             icon = <Tooltip label={format} position="bottom" color="green" withArrow>
    //                         <div>
    //                             <InfoSquare className='price-mode-icon'/>
    //                         </div>
    //                     </Tooltip>
    //         }
    //     }
    //     return { icon: icon, format: format}
    // }

    // function ToolTipWrapper({ children }: any){
    //     const format = setIcon()
    //     // console.log(format)
    //     if(format.icon){
    //         return (
    //             <Tooltip
    //                 label={<div style={{"color": "white", "display": "flex", "alignItems": "center", "gap": "5px"}}>{useCapitalizeFirstLetter(format.format)}</div>}
    //                 position='bottom'
    //                 transition="fade"
    //                 transitionDuration={500}
    //                 color="green"
    //                 withArrow>
    //                 { children }
    //             </Tooltip>
    //         )
    //     } else {
    //         return children
    //     }
    // }
    // if(cell.type === "price"){
    //     if(cell.value == 0){
    //         label = 
    //     } else {
    //         try {
    //             const óradíj_napidíj = rowData[rowindex].data["Óradíj/Napidíj"] // checking if óradíj/napidíj is available on this table
    //             label = óradíj_napidíj
    //         } catch {
    //             console.log(rowData)
    //             label = rowindex == 0 ? cell.title : ""
    //         }
    //     }
    // } else {
    //     label = rowindex == 0 ? cell.title : ""
    // }

  const formatInitialData = useCallback((column_name:string) => {
    // console.log(column_name)
    //console.log(initialRowData)
    const filteredArray = rowData.map((column) => {
        // console.log("column.data", column.data, column_name, column.data[column_name])
        return column.data[column_name] || ""
    })
    // console.log(filteredArray)

    // filtering duplicates so it won't have duplicated items in the selects
    const filteredDuplicates = [...new Set(filteredArray)];
    // console.log('%cFormItemCell.tsx line:118 filteredDuplicates', 'color: #007acc;', filteredDuplicates);

    // console.log(formScheme, formState)
    
    // if(formScheme.length != 0 && formState.length != 0){
        const tableIndexInFormScheme = formScheme.findIndex(x => x.title === table)
        console.log(tableIndexInFormScheme)
        // const tableIndexInFormState = formState.findIndex(x => x.tableName === table)
        // console.log(tableIndexInFormState)
        //--------------------------checking if all cells have been filled out in a row or not, if row is fully filled I return the saved input value
        const filterWith = formScheme[tableIndexInFormScheme].data.filter_with
        // const row = formState[tableIndexInFormState].data[rowindex]
        // console.log(filterWith)
        // console.log(row)
        let all_filtered_input_is_containing_value = true
        let values_to_display_in_the_row: { column: string; value: string; }[] = []
        filterWith.map((tableToFilterWith) => {
            const cellIndexInFormState = row.findIndex((x: { column: string; }) => x.column === tableToFilterWith)
            const cellValue = row[cellIndexInFormState].value
            if(cellValue == ""){
                all_filtered_input_is_containing_value = false
            } else {
                console.log(cellValue)
                if(typeof cellValue == "string"){
                    values_to_display_in_the_row.push({ column: tableToFilterWith, value: cellValue.toString() })
                }
            }
        })
    
        let value_to_display_index = values_to_display_in_the_row.findIndex((x) => x.column === column_name)
        if(all_filtered_input_is_containing_value && value_to_display_index !== -1){
            console.log(values_to_display_in_the_row)
            
            console.log(values_to_display_in_the_row, value_to_display_index)
            let value_to_display = values_to_display_in_the_row[value_to_display_index].value
    
            return [value_to_display]
        } else {
            return filteredDuplicates
        }
    // }
    // return filteredDuplicates
  }, [rowData])


//   console.log("cell column", cell.column, formatInitialData(cell.column))


  return (
    <div style={{"display": "flex", "gap": "20px", "marginBottom": "30px"}}>
                    {cell.type == "select" ? (
                        <Select
                            size='md'
                            value={cellValue as string}
                            onChange={(changedText:string) => onchange(changedText, cell.column, rowindex, table)}
                            className='upload-form-input'
                            label={label}
                            style={{"width": `${Number(cell.width_category) * 10}px`}}
                            placeholder="Válassz"
                            clearable
                            searchable
                            nothingFound="Nincs találat"
                            maxDropdownHeight={280}
                            data={cell.initial_load == true ? formatInitialData(cell.column) : []}
                            styles={(theme) => ({
                                item: {
                                  // applies styles to selected item
                                  '&[data-selected]': {
                                    '&, &:hover': {
                                      backgroundColor:
                                        formModeIsDaily ? theme.colors.green[8] : yellow_button,
                                    },
                                  },
                        
                                  // applies styles to hovered item (with mouse or keyboard)
                                  '&[data-hovered]': {},
                                },
                                input: {
                                  ":focus-within": {
                                    borderColor: formModeIsDaily ? theme.colors.green[8] : yellow_button,
                                  }
                                }
                              })}
                        />
                    ) : ""}
                    {cell.type == "number" ? (
                        <NumberInput
                            datatype='number'
                            size='md'
                            value={cellValue as number}
                            onChange={(changedText:number) => onchange(changedText, cell.column, rowindex, table)}
                            className='upload-form-input'
                            defaultValue={0}
                            placeholder=""
                            style={{"width": `${Number(cell.width_category) * 10}px`}}
                            label={label}
                            parser={(value:any) => value.replace(/\Ft\s?|( *)/g, '')}
                            formatter={(value:any) =>
                                !Number.isNaN(parseFloat(value))
                                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                : ''}
                            styles={(theme) => ({
                                item: {
                                  // applies styles to selected item
                                  '&[data-selected]': {
                                    '&, &:hover': {
                                      backgroundColor:
                                        formModeIsDaily ? theme.colors.green[8] : yellow_button,
                                    },
                                  },
                        
                                  // applies styles to hovered item (with mouse or keyboard)
                                  '&[data-hovered]': {},
                                },
                                input: {
                                  ":focus-within": {
                                    borderColor: formModeIsDaily ? theme.colors.green[8] : yellow_button,
                                  }
                                }
                              })}
                        />
                    ) : ""}
                    {cell.type == "price" ? (
                        <>
                        {/* <ToolTipWrapper> */}
                            <NumberInput
                                size='md'
                                value={cellValue as number}
                                onChange={(changedText) => onchange(changedText, cell.column, rowindex, table)}
                                className='upload-form-input'
                                rightSection={"Ft"}
                                // icon={<div onMouseOver={() => console.log("hello bello")}>{setIcon().icon}</div>}
                                label={label}
                                style={{"width": `${Number(cell.width_category) * 10}px`}}
                                // defaultValue={1000}
                                parser={(value:any) => value.replace(/\Ft\s?|( *)/g, '')}
                                formatter={(value:any) =>
                                    !Number.isNaN(parseFloat(value))
                                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                    : ''}
                                styles={(theme) => ({
                                    item: {
                                        // applies styles to selected item
                                        '&[data-selected]': {
                                        '&, &:hover': {
                                            backgroundColor:
                                            formModeIsDaily ? theme.colors.green[8] : yellow_button,
                                        },
                                        },
                            
                                        // applies styles to hovered item (with mouse or keyboard)
                                        '&[data-hovered]': {},
                                    },
                                    input: {
                                        ":focus-within": {
                                        borderColor: formModeIsDaily ? theme.colors.green[8] : yellow_button,
                                        }
                                    }
                                    })}
                            />
                        {/* </ToolTipWrapper> */}
                        </>
                    ) : ""}
                    {cell.type == "text" ? (
                        <div style={{"display": "flex", "alignItems": "center"}}>
                            <h2 style={{"margin": "0"}} className={rowindex == 0 ? "firstrow" : ""}>{useNumberWithSpaces(cellValue || 0)} Ft</h2>
                        </div>
                    ) : ""}
                </div>
  )
})
// , (prevProps, nextProps) => {
//     console.log(prevProps.table)
//     // return false
//     if(prevProps.table == localStorage.getItem("table_changed_in_form")){
//         // localStorage.removeItem("table_changed_in_form")
//         return false // rerenders
//     } else {
//         return true
//     }
// })

export default FormItemCell