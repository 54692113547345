import { Loader, Modal, Select, Text } from '@mantine/core'
import { DateRangePicker, DateRangePickerValue } from '@mantine/dates'
import React, { useEffect, useState } from 'react'
import BuiltyLineChart from '../../../../../components/Charts/BuiltyLineChart';
import { useAddContractProgressToDaily } from '../../../../../hooks/useAddContractProgressToDaily';
import { useUserCompany } from '../../../../../hooks/useFirebase';
import { DailyPostType } from '../../../../../interfaces/dailyPostType'


interface FullDailyCostsModalProps {
    fullDailyCostsModalIsOpened: boolean,
    setFullDailyCostsModalIsOpened: Function,
    projectNumbers: string[],
    selectedProjectNumber: string | null,
    setSelectedProjectNumber: Function,
    posts: DailyPostType[],
}
export default function FullDailyCostsModal({ fullDailyCostsModalIsOpened, setFullDailyCostsModalIsOpened, projectNumbers, selectedProjectNumber, setSelectedProjectNumber, posts }: FullDailyCostsModalProps) {
  
    // const [datepickerValue, setDatepickerValue] = useState<DateRangePickerValue>([
    //     new Date(2021, 11, 1),
    //     new Date(2021, 11, 5),
    // ]);

    const [chartData, setChartData] = useState<{ labels: string[], data: number[], project_number: string | null }>()
    const [isLoading, setIsLoading] = useState(true)


    function calcCostChart(fetchedData: DailyPostType[]){
        console.log(fetchedData)
    
        const formatDate = (post: DailyPostType) => { return `${new Date(post.date.seconds*1000).getMonth()+1}-${new Date(post.date.seconds*1000).getDate()}`}
        let labels = fetchedData.map((post) => formatDate(post))
        labels = labels.reverse()
        console.log(labels)
    
        let data: any = {}
        // setting object values to zero
        labels.forEach((label) => {
          data[label] = 0
        })
        labels = [...new Set(labels)];
    
        fetchedData.forEach(post => {
          const date = formatDate(post)
          data[date] = data[date] + post.final_price
        });
    
        data = Object.values(data)
        console.log(data)
    
    
        setChartData({ labels: labels, data: data, project_number: selectedProjectNumber })
      }


    useEffect(() => {
      if(fullDailyCostsModalIsOpened){
        if(posts.length === 0){
            setChartData(undefined)
        } else {
            if(selectedProjectNumber !== chartData?.project_number){
                const f = async () => {
                    setIsLoading(true)
                    const list = await useAddContractProgressToDaily([...posts], await useUserCompany(), "returnListWithAllDaysEvenWhenDailyPostHasNotBeenMade")
                    console.log("List", list)
                    if(list){
                      list.sort((a, b) => new Date(b.date.seconds*1000).getTime() - new Date(a.date.seconds*1000).getTime())
                      calcCostChart(list)
                      setTimeout(() => {
                        setIsLoading(false)
                      }, 200);
                    }
      
                }
                f()
            }
        }
      }
    }, [posts, fullDailyCostsModalIsOpened])
    
  
    return (
    <Modal
        opened={fullDailyCostsModalIsOpened}
        onClose={() => setFullDailyCostsModalIsOpened(false)}
        size="90%"
        title={<div style={{display: "flex", alignItems: "center", gap: "15px"}}>
        {/* <DateRangePicker
            label=""
            placeholder="Válassz időtartamot!"
            style={{"width": "300px"}}
            value={datepickerValue}
            onChange={setDatepickerValue}
        /> */}
        <Text>Teljes napi költség kimutatás</Text>
        <Select
        label=""
        style={{"width": "250px", fontWeight: "normal"}}
        placeholder="Összes"
        searchable
        clearable
        nothingFound="Nincs találat"
        maxDropdownHeight={280}
        data={projectNumbers}
        value={selectedProjectNumber}
        onChange={(text) => setSelectedProjectNumber(text)}
        />
        <Text>munkaszám alatt</Text>
        </div>}
    >
      {isLoading ? (
        <div style={{height: "70vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}><Loader size="lg"></Loader></div>
      ) : <BuiltyLineChart maxHeight={'70vh'} width={'70vw'} padding={'0px'} label={''} labels={chartData?.labels ?? []} data={chartData?.data ?? []}></BuiltyLineChart>
      }
    </Modal>
  )
}
