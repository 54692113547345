import React from 'react'
import SearchCompany from '../../components/SearchCompany/SearchCompany'

function AI() {
  return (
    <SearchCompany value={''} onChange={function (value: string | null): void {
      throw new Error('Function not implemented.')
    } } canSelectItself={true}/>
  )
}

export default AI