import { Loader } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import React, { useEffect, useState, useRef } from 'react'
import StatisticsProgress from '../../../../components/StatisticsProgress/StatisticsProgress'
import { useFirestore, useUserCompany } from '../../../../hooks/useFirebase'
import { ContractType } from '../../../../interfaces/contractType'
import { DailyPostType } from '../../../../interfaces/dailyPostType'


interface CostResultProps {
    projectNumbersList: string[],
    selectedProjectNumber: string | null,
    setSelectedProjectNumber: Function,
    dailyPosts: DailyPostType[],
    plannedCosts: DailyPostType[],
    ownContracts: ContractType[],
    subcontractorContracts: ContractType[],
    isLoading: boolean,
    setIsLoading: Function
}

export default function CostResult({ projectNumbersList, selectedProjectNumber, setSelectedProjectNumber, dailyPosts, plannedCosts, ownContracts, subcontractorContracts, isLoading, setIsLoading }: CostResultProps) {
  
  const [actualIncome, setActualIncome] = useState(0)
  const [actualCost, setActualCost] = useState(0)
  const [partialResult, setPartialResult] = useState(0)

  const [overheadPercentageValue, setOverheadPercentageValue] = useState(0)
  const [overheadForintValue, setOverheadForintValue] = useState(0)
  const [otherCostsValue, setOtherCostsValue] = useState(0)
  const [allOtherCosts, setAllOtherCosts] = useState(0)

  const [resultForint, setResultForint] = useState(0)
  const [resultPercentage, setResultPercentage] = useState(0)
  const [debouncedResultPercentage] = useDebouncedValue(resultPercentage, 3000);


  const [projectDocId, setProjectDocId] = useState<string | null>(null)


  const notificationIsShown = useRef(false)


  async function calculate(mode: "initiall" | "recalculate"){

    let overheadPercentage = overheadPercentageValue
    let otherCosts = otherCostsValue

    if(mode == "initiall"){
      [overheadPercentage, otherCosts] = await fetchOverheadAndOtherCosts()
    }

    let actualIncomeTotal = 0
    ownContracts.forEach((contract) => {
      actualIncomeTotal += contract.forint_readiness
    })
    actualIncomeTotal = Math.round(actualIncomeTotal)


    let actualCostTotal = 0
    dailyPosts.forEach((post) => {
      actualCostTotal += post.final_price
    })
    subcontractorContracts.forEach((contract) => {
      actualCostTotal += contract.forint_readiness
    })
    actualCostTotal = Math.round(actualCostTotal)


    const partialResultTotal = actualIncomeTotal - actualCostTotal

    const overheadForint = mode == 'initiall' ? calculateOverheadForintFromOverheadPercentage(overheadPercentage, partialResultTotal) : overheadForintValue
    const allOtherCostsTotal = otherCosts + overheadForint

    const resultForintTotal = actualIncomeTotal - actualCostTotal - allOtherCostsTotal

    const fixedActualIncomeTotal = actualIncomeTotal == 0 ? 1 : actualIncomeTotal
    const resultPercentageTotal = Number((resultForintTotal / fixedActualIncomeTotal * 100).toFixed(2))

    setActualIncome(actualIncomeTotal)
    setActualCost(actualCostTotal)
    setPartialResult(partialResultTotal)
    setOverheadPercentageValue(overheadPercentage)
    setOverheadForintValue(overheadForint)
    setOtherCostsValue(otherCosts)
    setAllOtherCosts(allOtherCostsTotal)
    setResultForint(resultForintTotal)
    setResultPercentage(resultPercentageTotal)

  }

  function calculateOverheadForintFromOverheadPercentage(overheadPercentage: number, partialResultTotal: number): number{
    return Number(Math.abs(Number(partialResultTotal) / 100 * Number(overheadPercentage)).toFixed(0))
  }


  async function fetchOverheadAndOtherCosts(){
    const db = useFirestore()
    const company = await useUserCompany()

    console.log(selectedProjectNumber, company)
    const q = query(collection(db, "project"), where("company", "==", company), where("project_number", "==", selectedProjectNumber));

    const querySnapshot = await getDocs(q);
    console.log(querySnapshot.size)
    const project: { id: string, other_costs: number | undefined, overhead_percentage: number | undefined }[] = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      project.push({ id: doc.id, ...doc.data() as any })
    });
    
    let overheadPercentage = 0
    let otherCosts = 0
    
    if(project.length != 0){
      setProjectDocId(project[0].id)

      overheadPercentage = Number(project[0].overhead_percentage || 0)
      // const overheadForint = i cannot calculate it without partial results
      otherCosts = Number(project[0].other_costs || 0)
    }


    return [overheadPercentage, otherCosts]
  }

  async function updateOverheadAndOtherCostsInFirestore(){
    const db = useFirestore()
    if(projectDocId){
      await updateDoc(doc(db, "project", projectDocId), {
        overhead_percentage: overheadPercentageValue,
        overhead_costs: overheadForintValue,
        other_costs: otherCostsValue,
      });
      console.log("overhead Updated in firestore")
    } else {
      if(!notificationIsShown.current){
        showNotification({
          title: 'Nem sikerült menteni!',
          message: 'A rendszer nem találja a projektet a fiókodban. Az eredmény kalkuláció működni fog, de legközelebb is meg kell majd adnod a rezsidíjat, és az egyéb költségeket.',
          color: 'red',
          autoClose: false
        })
        notificationIsShown.current = true
      }
    }
  }


  useEffect(() => {
    if(selectedProjectNumber){
      calculate('initiall')
      setTimeout(() => {
        setIsLoading(false)
        notificationIsShown.current = false
      }, 1000);
    } else {
      setActualIncome(0)
      setActualCost(0)
      setPartialResult(0)
      setOverheadPercentageValue(0)
      setOverheadForintValue(0)
      setOtherCostsValue(0)
      setAllOtherCosts(0)
      setResultForint(0)
      setResultPercentage(0)
    }
  }, [selectedProjectNumber, dailyPosts, plannedCosts, ownContracts, subcontractorContracts])


  useEffect(() => {
    if(selectedProjectNumber){
      calculate('recalculate')
      updateOverheadAndOtherCostsInFirestore()
    }
  }, [overheadPercentageValue, overheadForintValue, otherCostsValue])
  

  return (
    <StatisticsProgress
        projectNumbersList={projectNumbersList}
        selectedProjectNumber={selectedProjectNumber}
        setSelectedProjectNumber={setSelectedProjectNumber}
        modeIsResult={true}
        leftTable={{
          rows: [
            {
              name: 'Projekt aktuális bevétele:',
              value: actualIncome
            },
            {
              name: 'Projekt aktuális költsége:',
              value: actualCost
            },
          ],
          footer: {
              name: 'Részeredmény:',
              value: partialResult
          }
        }}
        rightTable={{
          rows: [
            {
              name: 'Rezsi:',
              value: 0
            },
            {
              name: 'Egyéb költségek:',
              value: 0
            }
          ],
          footer: {
              name: 'Összes egyéb költség:',
              value: allOtherCosts
          }
        }}
        barPercentage={resultPercentage}
        overheadPercentage={overheadPercentageValue}
        setoverheadPercentage={setOverheadPercentageValue}
        overheadForint={overheadForintValue}
        setoverheadForint={setOverheadForintValue}
        otherCosts={otherCostsValue}
        setotherCosts={setOtherCostsValue}
        resultForint={resultForint}
        history={[]}
        isLoading={isLoading}
        />
  )
}




function debounce<F extends (...args: any[]) => any>(func: F, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
