import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as Sentry from "@sentry/react";
import { useNavigate } from 'react-router-dom';


if(!location.href.includes("localhost")){
  
  Sentry.init({
    dsn: "https://7e4907aef1a44f96a65edf362e4a2a39@o4504956202844160.ingest.sentry.io/4504956210053120",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // window.onerror = () => {
  //   location.pathname = "/error"
  // }
  // window.addEventListener('error', () => {
  //   // Redirect the user to the error screen
  //   console.log("error történt");
  //   location.pathname = "/error"
  // });

}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
