// import { FirebaseError } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

export const ProtectedRoute = () => {
    // const auth = getAuth();
  
    if (localStorage.getItem("user") == null) {
      return <Navigate to="/login" replace />;
    }
    
    // const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);


    // useEffect(() => {
    //   console.log("currentUser", auth.currentUser);
    //   const unsubscribe = onAuthStateChanged(auth, (user) => {
    //     if (user) {
    //       setUserIsLoggedIn(true)
    //       console.log("user", user);
    //     }
    //     else {
    //       setUserIsLoggedIn(false)
    //       console.log("user false", user);
    //     }
    //   });
    //   return () => {
    //     unsubscribe();
    //   };
    // }, []);
    
    // if(userIsLoggedIn == false){
    //   return <Navigate to="/login" replace />;
    // } else {
    // }
    
    return <Outlet />;


  };