import { Autocomplete, Button, Group, Menu, UnstyledButton, Text, createStyles, Modal, Card, SimpleGrid, TextInput, Divider, Table, SegmentedControl, ScrollArea, Select, NumberInput } from '@mantine/core'
import { cleanNotifications, cleanNotificationsQueue, showNotification, updateNotification } from '@mantine/notifications';
import { Cell, Check, ChevronDown, AlertCircle } from 'tabler-icons-react';
import React, { MouseEventHandler, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Dashboard from '../../components/dashboard'

import './UploadToDatabase.css'
import { useAuth, useFirestore, useUserCompany } from '../../hooks/useFirebase';
import { doc, FieldValue, getDoc, serverTimestamp } from 'firebase/firestore';
import { collection, addDoc } from "firebase/firestore"; 
import { TableSort } from './TableSort';
import TableBlock from './TableBlock';
import Header from './components/Header';
import ModalContent from './components/ModalContent';
import { cacheRowType, databaseRowType } from '../../interfaces/databaseTypes';
import { wait } from '../../hooks/utils';
import { ColumnType } from '../Datatables/components/Table/Table';
import SearchCompany from '../../components/SearchCompany/SearchCompany';

function UploadToDatabase() {

  const tableName = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false)

  const [selectedTable, setSelectedTable] = useState("")
  const [uploadType, setUploadType] = useState("")
  const [openedChoose, setOpenedChoose] = useState(false)
  const [openedTable, setOpenedTable] = useState(false)
  const [openedExcel, setOpenedExcel] = useState(false)
  const [openedExcelPreview, setOpenedExcelPreview] = useState(false)
  const [openedImport, setOpenedImport] = useState(false)
  
  const [dataTableNames, setdataTableNames] = useState<any[]>([])
  const [dataTableColumnNames, setdataTableColumnNames] = useState<ColumnType[]>([])

  const [uploadFormState, setUploadFormState] = useState<{value:string, type: string}[]>([
    // {"oszlop1": "turorudi", "oszlop2": "macilaci", "oszlop3": "ebéd", "oszlop4": "", "oszlop5": "", "oszlop6": "turorudi", "oszlop7": ""},
    // {"oszlop1": "turorudi", "oszlop2": "macilaci", "oszlop3": "ebéd", "oszlop4": "", "oszlop5": "", "oszlop6": "turorudi", "oszlop7": ""},
    // {"oszlop1": "turorudi", "oszlop2": "macilaci", "oszlop3": "ebéd", "oszlop4": "", "oszlop5": "", "oszlop6": "turorudi", "oszlop7": ""},
    // {"oszlop1": "turorudi", "oszlop2": "macilaci", "oszlop3": "ebéd", "oszlop4": "", "oszlop5": "", "oszlop6": "turorudi", "oszlop7": ""}
  ])
  const [uploadExcelInputValue, setUploadExcelInputValue] = useState("")
  const [arrayExcelInputValue, setArrayExcelInputValue] = useState([])

  const [importToggleValue, setImportToggleValue] = useState("")
  const [company, setCompany] = useState("")

  async function getUserCompany(){
    const company = await useUserCompany()
    setCompany(company)
    setImportToggleValue(company)
  }

  useEffect(() => {
    getUserCompany()
  }, [])




  function setFormValue(inputText:string, rowindex:number, cellindex:number, key:string){
    // console.log(inputText)
    // console.log(rowindex)
    // console.log(cellindex)
    // console.log(key)

    const innerState = uploadFormState
    const row: any = innerState[rowindex]
    row[key] = inputText
    // console.log(row[key])
    // console.log(innerState)
    setUploadFormState([...innerState])
  }

  async function loadDatatables(){
    const db = useFirestore()
    const user = useAuth()
    console.log(user)

    const docRef = doc(db, "user", `${user.uid}`);
    const docSnap = await getDoc(docRef);
    const tables = docSnap.data()?.database

    let icon
    tables.map(async (tableName:string, index:number) => {

      // cache icons in sessionstorage
      const iconsInSessionStorage = sessionStorage.getItem(`${tableName}-icon`)

      if(iconsInSessionStorage){
        // console.log("icon got from cache")
        icon = iconsInSessionStorage
      } else {
        
        // console.log("icon got from firestore")
        const docRef = doc(db, "table", tableName);
        const docSnap = await getDoc(docRef);
        icon = docSnap.data()?.icon

        // saving to cache
        sessionStorage.setItem(`${tableName}-icon`, icon)
      }


      tables[index] = { title: tableName, icon: icon }
    })

    setdataTableNames(tables)
    // setisLoading(false)
  }

  async function loadColumnNames(selectedTable:string) {
    console.log('%cUploadToDatabase.tsx line:91 selectedtable', 'color: #007acc;', selectedTable);
    const db = useFirestore()
    const user = useAuth()

    // console.log(selectedTable)
    const docRef = doc(db, "table", selectedTable);
    const docSnap = await getDoc(docRef);
    const columns: ColumnType[] = docSnap.data()?.columns
    console.log(columns)
    console.log(selectedTable)
    // const table = snapshot.filter((doc:any) => doc.table == selectedtable)
    // console.log(table)
    // const columns = table[0]['columns']
    setdataTableColumnNames(columns)

    addRowToUploadFormState(columns)
  }

  function addRowToUploadFormState(columns:ColumnType[]){
    const columnNames = columns
    console.log(columnNames)
    const newRow:any = {} 
    columnNames.map((column, index:number) => {
      // console.log(column)
      newRow[column.name] = ""
    })
    // console.log(newRow)
    // const newUploadFormState: any = uploadFormState
    // newUploadFormState.push(newRow)
    setUploadFormState([...uploadFormState, newRow])
  }

  function removeLastRowFromUploadFormState(){
    uploadFormState.pop()
    setUploadFormState([...uploadFormState])
  }

  async function uploadToDatabase(){
    const db = useFirestore()
    const user = useAuth()
    console.log(uploadFormState)

    const docRef = doc(db, "user", user.uid);
    const docSnap = await getDoc(docRef);

    const company = docSnap.data()?.company
    const project_number = ""
    const table_name = selectedTable
    const user_id = user.uid

    setOpenedTable(false)
    setUploadFormState([])

    showNotification({
      id: 'load-data',
      loading: true,
      title: `Adatok mentése a ${selectedTable} táblába`,
      message: 'Az adatok mentése a fiókodba folyamatban van',
      autoClose: false,
      disallowClose: true,
    });

    try {
      uploadFormState.map(async (row: object) => {

          //creating data in database
          const docRef = await addDoc(collection(db, "database"), {
            company: company,
            data: row,
            project_number: project_number,
            table_name: table_name,
            user_id: user_id,
            created_at: serverTimestamp(),
            last_modified: serverTimestamp()
          } as databaseRowType);
          console.log("Document written with ID: ", docRef.id);

          //creating data in cache
          const rows = JSON.parse(sessionStorage.getItem(table_name!) || "[]")
          if(rows.length != 0){
            rows.push({
              id: docRef.id,
              data: row,
              created_at: { seconds: new Date().getTime() / 1000 },
              last_modified: { seconds: new Date().getTime() / 1000 }
            } as cacheRowType)
            sessionStorage.setItem(table_name!, JSON.stringify(rows))
          }

          await wait(100)

      })

      setTimeout(() => {
        updateNotification({
          id: 'load-data',
          title: 'Siker!',
          message: 'Az adatok sikeresen mentve lettek az adatbázisodba',
          icon: <Check size={16} />,
          autoClose: 5000,
        });
      }, 2000);
    } catch (error) {
      console.log(error)
      setTimeout(() => {
        updateNotification({
          id: 'load-data',
          color: 'red',
          title: 'Hiba történt!',
          message: 'Az adatok nem lettek mentve az adatbázisodba',
          icon: <AlertCircle size={16} />,
          autoClose: false,
        });
      }, 2000);
    }
  }

  async function validateAndUploadExcelTextArea(){
    cleanNotifications()
    showNotification({
      id: 'load-data',
      loading: true,
      title: `Ellenőrzés`,
      message: 'A feltölteni kívánt adatok ellenőrzése folyamatban van',
      autoClose: false,
      disallowClose: true,
    });
    const text = uploadExcelInputValue
    let splitted_data: any = text.split("\n")
    for (let index = 0; index < splitted_data.length; index++) {
      const splitted_row: string[] = splitted_data[index].split("\t")
        splitted_data[index] = splitted_row
    }
    // ez teszi lehetővé hogy a fölösleges entereket ingnoráljuk
    splitted_data = splitted_data.filter((row:string[][]) => row.length != 1)
    console.table(JSON.stringify(splitted_data))

    const inappropriate: number[] = []
    splitted_data.map((row:string[], index:number) => {
      if(row.length != dataTableColumnNames.length){
        console.error(`row number ${index+1} is not appropriate`)
        inappropriate.push(index+1)
      } else {
        console.log(`row number ${index+1} is ok`)
      }
    })

    if(inappropriate.length == 0 && splitted_data.length != 0){
      setTimeout(() => {
        updateNotification({
          id: 'load-data',
          title: 'Helyes! Feltöltés...',
          message: 'Az adatok megfelelnek a struktúrának, a feltöltésük az adatbázisba folyamatban van',
          icon: <Check size={16} />,
          autoClose: false,
          loading: true
        });
        setOpenedExcel(false)
        setUploadExcelInputValue("")
      }, 2000);
    } else {
      setTimeout(() => {
        updateNotification({
          id: 'load-data',
          color: 'red',
          title: 'Ajjaj! Hibás adatok',
          message: `Az adatok nem felelnek meg a struktúrának (lásd: előnézet), a probléma a következő sorokkal van: ${inappropriate}`,
          icon: <AlertCircle size={16} />,
          autoClose: false,
        });
      }, 2000);
      return false
    }

    console.warn("converting arrays to objects...")
    const convertedData: object[] = []
    splitted_data.map((row:[], rowindex:number) => {
      const rowObject: any = {}
      dataTableColumnNames.map((tablename, tableindex:number) => {
        rowObject[tablename.name] = row[tableindex]
      })
      convertedData.push(rowObject)
    })
    console.log(convertedData)

    const db = useFirestore()
    const user = useAuth()

    const docRef = doc(db, "user", user.uid);
    const docSnap = await getDoc(docRef);

    const company = docSnap.data()?.company
    const project_number = ""
    const table_name = selectedTable
    const user_id = user.uid

    try {
      convertedData.map(async (row: object) => {
          
          //creating data in firestore
          const docRef = await addDoc(collection(db, "database"), {
            company: company,
            data: row,
            project_number: project_number,
            table_name: table_name,
            user_id: user_id,
            created_at: serverTimestamp()
          });
          console.log("Document written with ID: ", docRef.id);

          //creating data in cache
          const rows = JSON.parse(sessionStorage.getItem(table_name!) || "[]")
          if(rows.length != 0){
            rows.push({
              id: docRef.id,
              data: row,
              created_at: { seconds: new Date().getTime() / 1000 },
              last_modified: { seconds: new Date().getTime() / 1000 }
            } as cacheRowType)
            sessionStorage.setItem(table_name!, JSON.stringify(rows))
          }

          await wait(100)
      })

      setTimeout(() => {
        updateNotification({
          id: 'load-data',
          title: 'Siker!',
          message: 'Az adatok sikeresen mentve lettek az adatbázisodba',
          icon: <Check size={16} />,
          autoClose: 5000,
        });
      }, 5000);
    } catch (error) {
      console.log(error)
      setTimeout(() => {
        updateNotification({
          id: 'load-data',
          color: 'red',
          title: 'Hiba történt!',
          message: 'Az adatok nem lettek mentve az adatbázisodba',
          icon: <AlertCircle size={16} />,
          autoClose: false,
        });
      }, 5000);
    }

  }

  function convertExcelInputValueToArrays(){
    const text = uploadExcelInputValue
    let splitted_data: any = text.split("\n")
    for (let index = 0; index < splitted_data.length; index++) {
      const splitted_row: string[] = splitted_data[index].split("\t")
        splitted_data[index] = splitted_row
    }
    // ez teszi lehetővé hogy a fölösleges entereket ingnoráljuk
    splitted_data = splitted_data.filter((row:string[][]) => row.length != 1)
    // console.table(JSON.stringify(splitted_data))

    setArrayExcelInputValue(splitted_data)
  }

  async function handleOnTablePress(onPressValue:string){
      setIsLoading(true)
      setSelectedTable(onPressValue) // this is needed for further things, it has no connection with loadColumnNames
      console.log(onPressValue)
      await loadColumnNames(onPressValue)
      setOpenedChoose(false)
      uploadType == "manual" ? setOpenedTable(true) : setOpenedExcel(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 200);
  }
  

  // const inputs = uploadFormState.map((row:any) => {
  //     console.log(row)
  //     Object.values(row).map((cell: any) => {
  //       console.log(cell)
  //       return (
  //         <TextInput
  //             label="Floating label"
  //             placeholder="OMG, it also has a placeholder"
  //             required
  //             // classNames={classes}
  //             value={cell}
  //             // onChange={(event) => setValue(event.currentTarget.value)}
  //             // onFocus={() => setFocused(true)}
  //             // onBlur={() => setFocused(false)}
  //             mt="md"
  //             autoComplete="nope"
  //           />
  //       )
  //     })
  //   })

  const headers = dataTableColumnNames.map((column: ColumnType, index:number) => 
    <div className={`upload-input-${dataTableColumnNames.length}`} key={column.name}>
      <p style={{"display": "flex", "justifyContent": "center"}}>{column.name}</p>
    </div>
  )

  const inputs = uploadFormState.map((row, rowindex) => 
    <div key={rowindex} className='upload-row' style={{marginTop: "25px"}}>
      {
        Object.entries(row).map(([key, cellValue], cellindex) => {

          if(dataTableColumnNames[cellindex].type == "company"){
            return <SearchCompany
              className={`upload-input-${Object.values(row).length}`}
              value={cellValue}
              onChange={(value) => setFormValue(value || "", rowindex, cellindex, key)}
              canSelectItself={true}
              key={key}
            />
          }
          if(dataTableColumnNames[cellindex].type == "select"){
            return <Select
              className={`upload-input-${Object.values(row).length}`}
              label=""
              placeholder="Válassz egységet!"
              data={dataTableColumnNames[cellindex].select_from || []}
              maxDropdownHeight={150}
              value={cellValue}
              onChange={(value) => setFormValue(value || "", rowindex, cellindex, key)}
              key={key}
            />
          }
          if(dataTableColumnNames[cellindex].type == "number"){
            return <NumberInput
              className={`upload-input-${Object.values(row).length}`}
              value={Number(cellValue)}
              onChange={(value) => setFormValue(String(value || 0), rowindex, cellindex, key)}
              parser={(value:any) => value.replace(/\Ft\s?|( *)/g, '')}
              formatter={(value:any) =>
                  !Number.isNaN(parseFloat(value))
                  ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  : ''}
              key={key}
              />
          }
          if(dataTableColumnNames[cellindex].type == "text"){
            return <TextInput
              key={cellindex}
              className={`upload-input-${Object.values(row).length}`}
              // label="Floating label"
              // placeholder="OMG, it also has a placeholder"
              // required
              // classNames={classes}
              value={cellValue as string}
              onChange={(event) => setFormValue(event.currentTarget.value, rowindex, cellindex, key)}
              // onFocus={() => setFocused(true)}
              // onBlur={() => setFocused(false)}
              // mt="md"
              autoComplete="nope"
            />
          }

        }
        )
      }
    </div>
  )


  return (
    <>
        <div style={{"display": "flex", "alignItems": "center", "flexDirection": "column"}}>
            <br />
            <br />
            <br />
            <br />
        <Button variant='light' onClick={() => {loadDatatables(), setOpenedChoose(true); setUploadType("manual")}}>Manuális feltöltés</Button>
        <div style={{"display": "flex", "alignItems": "center", "gap": "15px"}}>
          <hr className="solid"></hr>
          <h5>Vagy</h5>
          <hr className="solid"></hr>
        </div>
        <Button variant='outline' onClick={() => {loadDatatables(), setOpenedChoose(true); setUploadType("excel")}}>Feltöltés excelből</Button>
        <div style={{"display": "flex", "alignItems": "center", "gap": "15px"}}>
          <hr className="solid"></hr>
          <h5>Vagy</h5>
          <hr className="solid"></hr>
        </div>
        <Button variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }} onClick={() => {setOpenedImport(true)}}>Importálás (ajánlott)</Button>
        </div>

      <Modal
        opened={openedChoose}
        onClose={() => setOpenedChoose(false)}
        title="Válassz adattáblát ahová szeretnél feltölteni!"
        centered
        size={"95%"}
      >
        <ActionsGrid tables={dataTableNames} onclick={(event:any) => handleOnTablePress(event.target.innerText)}/>
      </Modal>

      <Modal
        opened={openedTable}
        onClose={() => setOpenedTable(false)}
        title={`Feltöltés a ${selectedTable} táblába`}
        centered
        size={"95%"}
        closeOnClickOutside={false}
        withCloseButton={false}
      >
        <div className={`header-container`}>
          {headers}
        </div>
        {inputs}
        <div className='upload-button-container'>
          <Button variant='subtle' color={'gray'} onClick={() => {setOpenedTable(false); setOpenedChoose(true); setUploadFormState([])}}>Vissza</Button>
          <Button variant='subtle' color={'red'} onClick={() => removeLastRowFromUploadFormState()}>Utolsó sor törlése</Button>
          <Button variant='subtle' onClick={() => addRowToUploadFormState(dataTableColumnNames)}>Sor hozzáadása</Button>
          <Button variant='light' onClick={() => uploadToDatabase()}>Feltöltés az adatbázisba</Button>
        </div>
      </Modal>

      <Modal
        opened={openedExcel}
        onClose={() => setOpenedExcel(false)}
        title={`Feltöltés Excelből, a ${selectedTable} táblába`}
        centered
        size={"95%"}
      >
        <div className='upload-excel-container'>
          <textarea className='upload-textarea' value={uploadExcelInputValue} onInput={(e:any) => setUploadExcelInputValue(e.target.value)} placeholder="Illeszd be ide az excelből kimásolt sorokat, amiket fel szeretnél tölteni">
          </textarea>
          <div style={{"display": "flex", "gap": "20px"}}>
            <Button variant='subtle' color={"gray"} className='' onClick={() => {setUploadFormState([]), setOpenedExcel(false), setOpenedChoose(true)}}>Vissza</Button>
            <Button variant='subtle' color={"gray"} className='upload-excel-button' onClick={() => {convertExcelInputValueToArrays(), setOpenedExcel(false), setOpenedExcelPreview(true)}}>Előnézet</Button>
            <Button variant='light' className='upload-excel-button' onClick={() => validateAndUploadExcelTextArea()}>Feltöltés</Button>
          </div>
        </div>
      </Modal>

      <Modal
        opened={openedExcelPreview}
        onClose={() => setOpenedExcelPreview(false)}
        title="Előnézet"
        centered
        size={"95%"}
      >
        <div style={{"display": "flex", "flexDirection": "column", "gap": "20px", "alignItems": "center"}}>
          <Table>
            <thead>
              <tr>
                {
                  dataTableColumnNames.map((tablename, tableindex:number) => (
                    <th key={tableindex}>{tablename.name}</th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                arrayExcelInputValue.map((row:[], rowindex:number) => (
                  <tr key={rowindex}>
                    {row.map((cell:string, cellnumber:number) => (
                      <td key={cellnumber} className={dataTableColumnNames.length != row.length ? "inappropriate-line" : ""}>{cell}</td>
                    ))}
                  </tr>))
              }
            </tbody>
          </Table>
          <Button variant='outline' className='upload-excel-button' color={"gray"} onClick={() => {setOpenedExcelPreview(false), setOpenedExcel(true)}}>Vissza</Button>
        </div>
      </Modal>

      <Modal
        opened={openedImport}
        onClose={() => setOpenedImport(false)}
        title={ <Header setImportToggleValue={setImportToggleValue} importToggleValue={importToggleValue} company={company} setOpenedImport={setOpenedImport}/> }
        centered
        size={"95%"}
      >
        <ModalContent importToggleValue={importToggleValue} />
      </Modal>
      { isLoading ? <div className='loader-backdrop dashboard'><div className="loader"></div></div> : "" }
    </>
  )
}

export default UploadToDatabase














interface ActionsGridProps {
  tables: any[],
  onclick: MouseEventHandler<HTMLButtonElement>
}


export function ActionsGrid({ tables, onclick }: ActionsGridProps) {
  const { classes, theme } = useStyles();
  console.log(tables)

  const items = tables.map((item:any, index:number) => (
      <UnstyledButton key={index} className={classes.item} style={{"height": "155px", "width": "190px"}} onClick={onclick}>
        <img src={item.icon} width={"22%"} height={"28%"} className="you-cannot-click-on-this-item"/>
        <Text size="sm" mt={7}>
          {item.title}
        </Text>
      </UnstyledButton>
  ));

  return (
    <Card withBorder radius="md" className={classes.card}>
      <Group position="apart">
        {/* <Text className={classes.title}>Adattáblák</Text> */}
        {/* <Anchor size="xs" color="dimmed" sx={{ lineHeight: 1 }}>
          + 21 other services
        </Anchor> */}
      </Group>
      <SimpleGrid cols={6} mt="md">
        {items}
      </SimpleGrid>
    </Card>
  );
}

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: theme.radius.md,
    height: 90,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease, transform 100ms ease',

    '&:hover': {
      boxShadow: `${theme.shadows.md} !important`,
      transform: 'scale(1.05)',
    },
  },
}));