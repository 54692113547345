import { ActionIcon, Button, Input, Loader, Modal, NumberInput, Select, Textarea, Tooltip, Text } from '@mantine/core'
import { IconPlayerTrackPrevFilled } from '@tabler/icons-react';
import React, { useState, useRef } from 'react'
import { ArrowNarrowLeft, InfoCircle, PlayerTrackPrev, Trash } from 'tabler-icons-react'
import { useStore } from '../../../../hooks/useStore';
import { useNumberWithSpaces } from '../../../../hooks/utils';
import { contractContentType } from '../../../../interfaces/contractContentType';


let timeoutId: string | number | NodeJS.Timeout | undefined;

interface TableRowProps {
    modeIsEdit: boolean,
    modeIsWatch: boolean,
    rowIndex: number,
    row: {
        name: string,
        quantity: number,
        unit: string,
        total: number,
        measuredQuantity: number,
        forint_readiness: number,
        percentage_readiness: number
    },
    sheetIndex: number,
    tableIndex: number
}

export default function TableRow({ modeIsEdit, modeIsWatch, rowIndex, row, sheetIndex, tableIndex }: TableRowProps) {

    type columnType = "name" | "quantity" | "unit" | "total" | "measuredQuantity"
    const [deleteRowModalOpened, setDeleteRowModalOpened] = useState(false)

    const contractContent: contractContentType = useStore((state:any) => state.contractContent)
    const setContractContent = useStore((state:any) => state.setContractContent)


    const [isCounting, setIsCounting] = useState(false)


    const cachedMeasuredQuantity = useRef(row.measuredQuantity)


    function removeRow() {
        const contractContentCopy = contractContent;
        console.log(rowIndex);
        contractContentCopy[sheetIndex].sheetData[tableIndex].tableData.splice(rowIndex, 1);
        console.log(contractContentCopy)
        setContractContent([...contractContentCopy]);
        setDeleteRowModalOpened(false);
      }
      

    function updateContractContentState(text: string | number, column: columnType){
        if(column == "quantity" || column == "total" || column == "measuredQuantity"){
            setIsCounting(true)
        }
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            
        const contractContentCopy = contractContent

        // if you want to test just comment out the two lines below
        // const szam = Math.round(Math.random()*10)
        // contractContent[sheetIndex].sheetData[tableIndex].tableData[rowIndex].measuredQuantity = szam
        const szam = contractContent[sheetIndex].sheetData[tableIndex].tableData[rowIndex].measuredQuantity
        


        // console.log(column)
        if(column == "name"){
            contractContentCopy[sheetIndex].sheetData[tableIndex].tableData[rowIndex].name = text.toString()
            // console.log("name run")
        }
        if(column == "quantity"){
            contractContentCopy[sheetIndex].sheetData[tableIndex].tableData[rowIndex].quantity = Number(text)
    
            const total = contractContent[sheetIndex].sheetData[tableIndex].tableData[rowIndex].total
            const quantity = Number(text)
            const measuredQuantity = szam
            const percentage_readiness = !isFinite(measuredQuantity / quantity * 100) ? 0 : Number((measuredQuantity / quantity * 100).toFixed(2))
            const forint_readiness = !isFinite(total / quantity) ? 0 : total / quantity * measuredQuantity
            console.log(percentage_readiness)

            contractContentCopy[sheetIndex].sheetData[tableIndex].tableData[rowIndex].percentage_readiness = percentage_readiness
            contractContentCopy[sheetIndex].sheetData[tableIndex].tableData[rowIndex].forint_readiness = Math.round(forint_readiness)
    
            // console.log("quantity run")
        }
        if(column == "unit"){
            contractContentCopy[sheetIndex].sheetData[tableIndex].tableData[rowIndex].unit = text.toString()
            // console.log("unit run")
        }
        if(column == "total"){
            contractContentCopy[sheetIndex].sheetData[tableIndex].tableData[rowIndex].total = Number(text)
    
            const total = Number(text)
            const quantity = contractContent[sheetIndex].sheetData[tableIndex].tableData[rowIndex].quantity
            const measuredQuantity = szam
            const percentage_readiness = !isFinite(measuredQuantity / quantity * 100) ? 0 : Number((measuredQuantity / quantity * 100).toFixed(2))
            const forint_readiness = !isFinite(total / quantity) ? 0 : total / quantity * measuredQuantity
    
            contractContentCopy[sheetIndex].sheetData[tableIndex].tableData[rowIndex].percentage_readiness = percentage_readiness
            contractContentCopy[sheetIndex].sheetData[tableIndex].tableData[rowIndex].forint_readiness = Math.round(forint_readiness)
            // console.log("total run")
        }
        if(column == "measuredQuantity"){
            contractContentCopy[sheetIndex].sheetData[tableIndex].tableData[rowIndex].measuredQuantity = Number(text)
    
            const total = contractContentCopy[sheetIndex].sheetData[tableIndex].tableData[rowIndex].total
            const quantity = contractContent[sheetIndex].sheetData[tableIndex].tableData[rowIndex].quantity
            const measuredQuantity = Number(text)
            const percentage_readiness = !isFinite(measuredQuantity / quantity * 100) ? 0 : Number((measuredQuantity / quantity * 100).toFixed(2))
            const forint_readiness = !isFinite(total / quantity) ? 0 : total / quantity * measuredQuantity
    
            contractContentCopy[sheetIndex].sheetData[tableIndex].tableData[rowIndex].percentage_readiness = percentage_readiness
            contractContentCopy[sheetIndex].sheetData[tableIndex].tableData[rowIndex].forint_readiness = Math.round(forint_readiness)
        }
        console.log(contractContentCopy)
        setContractContent([...contractContentCopy]);
        setTimeout(() => { setIsCounting(false)}, 0);

        }, 500);
    }

    function calcTextareaHeight(name: string): number {
        console.log("name.length", name.length)
        if(name.length > 70 && name.length < 140){
            return 2
        }
        if(name.length > 140 && name.length < 200){
            return 3
        }
        if(name.length > 200 && name.length < 270){
            return 4
        }
        if(name.length > 270 && name.length < 340){
            return 5
        }
        if(name.length > 340){
            return 6
        }
        return 1
    }

  return (
    <>
    <tr key={rowIndex}>

        <td>
            {modeIsEdit ? (
            <Tooltip label="Sor törlése">
                <ActionIcon
                    variant="default"
                    color={"red"}
                    onClick={(event) => setDeleteRowModalOpened(true)}
                >
                    <Trash size={16} />
                </ActionIcon>
            </Tooltip>
            ) : ""}
        </td>

        <td style={{ width: "31vw"}}>
            {modeIsEdit ? (
                <Textarea
                    autosize
                    minRows={calcTextareaHeight(row.name)}
                    maxRows={6}
                    defaultValue={row.name}
                    onChange={(event) => updateContractContentState(event.currentTarget.value, "name")}
                />
            ) : (
                <Text>{row.name}</Text>
                )}
        </td>

        <td style={{ width: "11vw"}}>
            {modeIsEdit ? (
                <NumberInput
                defaultValue={row.quantity}
                step={1}
                precision={row.quantity === parseInt(`${row.quantity}`) ? 0 : 2}
                decimalSeparator=","
                onChange={(value) => updateContractContentState(value ? value : 0, "quantity")}
                parser={(value:any) => value.replace(/\Ft\s?|( *)/g, '')}
                formatter={(value:any) =>
                        !Number.isNaN(parseFloat(value))
                        ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                        : ''}
                        />
            ) : (
                <Text>{useNumberWithSpaces(row.quantity)}</Text>
                )}
        </td>
        <td style={{ width: "11vw"}}>
            {modeIsEdit ? (
                <Select
                    placeholder="Mértékegység"
                    searchable
                    nothingFound="Nincs ilyen mértékegység"
                    defaultValue={row.unit}
                    onChange={(value) => updateContractContentState(value ? value : "", "unit")}
                    data={['m', 'm2', 'm3', 't (tonna)', 'q (mázsa)', 'kg', 'db', 'óra', 'nap', 'forduló']}
                    />
            ) : (
                <Text>{row.unit}</Text>
            )}
        </td>
        <td style={{ width: "11vw"}}>
            {modeIsEdit ? (
                <NumberInput
                    defaultValue={row.total}
                    onChange={(value) => updateContractContentState(value ? value : 0, "total")}
                    rightSection={"Ft"}
                    parser={(value:any) => value.replace(/\Ft\s?|( *)/g, '')}
                    formatter={(value:any) =>
                        !Number.isNaN(parseFloat(value))
                        ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                        : ''}
                />
            ) : (
                <Text>{useNumberWithSpaces(row.total)}</Text>
            )}
            </td>
        <td>
            {modeIsEdit || modeIsWatch ? useNumberWithSpaces(row.measuredQuantity) : (
                <Tooltip label={`Előző állapot: ${useNumberWithSpaces(cachedMeasuredQuantity.current)}`}>
                    <NumberInput
                        defaultValue={row.measuredQuantity}
                        step={1}
                        precision={row.measuredQuantity === parseInt(`${row.measuredQuantity}`) ? 0 : 2}
                        decimalSeparator=","
                        onChange={(value) => updateContractContentState(value ? value : 0, "measuredQuantity")}
                        parser={(value:any) => value.replace(/\Ft\s?|( *)/g, '')}
                        formatter={(value:any) =>
                            !Number.isNaN(parseFloat(value))
                            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                            : ''}
                    />
                </Tooltip>
             )}
        </td>
        <td>{isCounting ? <Loader size={'sm'}/> : `${useNumberWithSpaces(row.forint_readiness)} Ft`}</td>
        <td>{isCounting ? <Loader size={'sm'}/> : `${row.percentage_readiness} %`}</td>
    </tr>

    <Modal
        withCloseButton={false}
        opened={deleteRowModalOpened}
        onClose={() => setDeleteRowModalOpened(false)}
    >
      <h2>
        Biztosan szeretnéd törölni ezt a sort?
      </h2>
      <div style={{display: "flex", width: "100%", justifyContent: "space-between", gap: "15px"}}>
        <Button variant='light' color={'gray'} fullWidth onClick={() => setDeleteRowModalOpened(false) }>Mégsem</Button>
        <Button variant='filled' color={'red'} fullWidth onClick={() => removeRow() }>Törlés</Button>
      </div>
    </Modal>
    </>
  )
}
