import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createStyles, Box, Text, Group, ScrollArea, Drawer, Button, useMantineTheme } from '@mantine/core';
import { IconListSearch } from '@tabler/icons-react';
import { useSessionStorage, useWindowScroll } from '@mantine/hooks';
import { Notes } from 'tabler-icons-react';
import "./TableOfContentsDrawer.css"
import { refs } from '../../QueryGraphs';

const LINK_HEIGHT = 40;
const INDICATOR_SIZE = 10;
const INDICATOR_OFFSET = (LINK_HEIGHT - INDICATOR_SIZE) / 2;

const useStyles = createStyles((theme) => ({
  link: {
    ...theme.fn.focusStyles(),
    display: 'block',
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    lineHeight: 1,//LINK_HEIGHT,
    fontSize: theme.fontSizes.sm,
    height: LINK_HEIGHT,
    borderTopRightRadius: theme.radius.sm,
    borderBottomRightRadius: theme.radius.sm,
    borderLeft: `2px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      cursor: 'pointer',
    },
  },

  linkActive: {
    fontWeight: 500,
    color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 3 : 7],
  },

  links: {
    position: 'relative',
  },

  indicator: {
    transition: 'transform 150ms ease',
    border: `2px solid ${
      theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 3 : 7]
    }`,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    height: INDICATOR_SIZE,
    width: INDICATOR_SIZE,
    borderRadius: INDICATOR_SIZE,
    position: 'absolute',
    left: "-3px"//`${INDICATOR_SIZE / 2 + 1}px`,
  },
}));


let isScrolling: string | number | NodeJS.Timeout | undefined;



interface TableOfContentsFloatingProps {
  list: { label: string; ref: string; order: number }[];
  // scrollTo: (activeIndex: number) => void;
  // active: number;
  // setActive: (index: number) => void;
  queryMainViewport: React.RefObject<HTMLDivElement>;
}

export default function TableOfContentsDrawer({ list, queryMainViewport }: TableOfContentsFloatingProps) {

  const [tableOfContentsDrawerIsOpened, setTableOfContentsDrawerIsOpened] = useState(false)

  const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });
  const viewport = useRef<HTMLDivElement>(null);

  const topBarRef = useRef<HTMLDivElement>(null);
  
  const { classes, cx } = useStyles();
  const theme = useMantineTheme()

  const [topBarFirsttext, setTopBarFirsttext] = useState("")
  const [topBarSecondtext, setTopBarSecondtext] = useState("")
  const [topBarThirdtext, setTopBarThirdtext] = useState("")

  const [active, setActive] = useState(0);


  function generateTopBarText(){
    let firsttext = ""
    let secondtext = ""
    let thirdtext = ""

    if(active === 0){
      firsttext = list[0].label
    }

    if(list[active].order === 1){
      firsttext = list[active].label
    }

    if(list[active].order === 2){
      secondtext = list[active].label

      let index = active
      while(index >= 0){
       if(list[index].order === 1){
         firsttext = list[index].label
         break
       }
       index--
      }
    }

    if(list[active].order === 3){
      thirdtext = list[active].label

      let index = active
      while(index >= 0){
       if(list[index].order === 2){
         secondtext = list[index].label
         break
       }
       index--
      }

      index = active
      while(index >= 0){
       if(list[index].order === 1){
         firsttext = list[index].label
         break
       }
       index--
      }
    }


    return [firsttext, secondtext, thirdtext]
  }

  useEffect(() => {
    setTimeout(() => {
      if(tableOfContentsDrawerIsOpened){
        const activeElement: any = document.getElementsByClassName("tableOfContentsDrawer-active")[0]
        viewport.current?.scrollTo({ top: activeElement.offsetTop, behavior: 'auto' });
      }
    }, 10);
  }, [tableOfContentsDrawerIsOpened])


  useEffect(() => {
    const [firsttext, secondtext, thirdtext] = generateTopBarText()
    setTopBarFirsttext(firsttext)
    setTopBarSecondtext(secondtext)
    setTopBarThirdtext(thirdtext)
  }, [active])
  

  // handling topBar show and hide state
  useEffect(() => {
    queryMainViewport.current?.addEventListener('scroll', () => {
      const scrolled = queryMainViewport.current?.scrollTop ?? 0
      const screenSize = queryMainViewport.current?.offsetHeight ?? 0

      if(scrolled > (screenSize / 2)){
        topBarRef.current?.classList.remove("topBar-hide")
        topBarRef.current?.classList.add("topBar-show")
        // console.log("topBar-show", topBarRef.current, scrolled, screenSize)
      } else {
        topBarRef.current?.classList.remove("topBar-show")
        topBarRef.current?.classList.add("topBar-hide")
        // console.log("topBar-hide", topBarRef.current, scrolled, screenSize)
      }

      // update header text
      onScroll();
    });

    return () => {
      queryMainViewport.current?.removeEventListener('scroll', () => {});
    }
  }, [])



  function scrollToMainDiv(activeIndex: number){
    console.log("scrolled", refs[activeIndex].current)
    setActive(activeIndex)
    queryMainViewport.current?.scrollTo({ top: (refs[activeIndex].current?.offsetTop ?? 0) - 50, behavior: 'smooth' });
  }

  function onScroll(){
    // Clear the timeout if it has already been set
    clearTimeout(isScrolling);

    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(function() {
      // User has stopped scrolling, run your code here
      console.log('User has stopped scrolling');
      // Call your function or execute the desired code

      // let refIndex = refs.reverse().findIndex(ref => (ref.current?.offsetTop ?? 0) < (queryMainViewport.current?.scrollTop ?? 0))
      let refIndex = 0;
      // console.log("refIndex", refIndex, refs[refIndex])

      let elementOffsetTop = refs[refIndex].current?.offsetTop ?? 0
      let elementHeight = refs[refIndex].current?.offsetHeight ?? 0

      let scrollOffsetTop = queryMainViewport.current?.scrollTop ?? 0

      while (refIndex < refs.length && (elementOffsetTop + elementHeight / 2) < (scrollOffsetTop + (window.innerHeight / 2))) {
        refIndex++

        try {
          elementOffsetTop = refs[refIndex].current?.offsetTop ?? 0
          elementHeight = refs[refIndex].current?.offsetHeight ?? 0
          scrollOffsetTop = queryMainViewport.current?.scrollTop ?? 0
        } catch { setActive(refs.length-1) }
      }
      console.log("refIndex", refIndex)

      setActive(refIndex -1 === -1 ? 0 : refIndex - 1)
      // setActive(refIndex)

    }, 200); // Adjust the timeout duration as needed
  }
  
   

  const items = list.map((item, index) => (
    <Box<'div'>
      component="div"
      // href={item.link}
      onClick={(event) => {
        event.preventDefault();
        setActive(index);

        scrollToMainDiv(index)
        console.log(index)
      }}
      key={index}
      className={[cx(classes.link, { [classes.linkActive]: active === index }), active === index ? "tableOfContentsDrawer-active" : ""].join(" ")}
      sx={(theme) => ({ paddingLeft: `${item.order * theme.spacing.lg}px`, paddingTop: "12.5px", fontWeight: item.order === 1 ? "bold" : "normal" })}
    >
      {item.label}
    </Box>
  ));

  return (
    <>
    <div ref={topBarRef} className="topBar" style={{backgroundColor: theme.colors.dark[7], borderBottom: `1px solid #fff8`}}>
      <Text weight={"bold"} size={"lg"}>{topBarFirsttext}</Text>
      <Text>{topBarSecondtext}</Text>
      <Text>{topBarThirdtext}</Text>
    </div>

    <Button leftIcon={<Notes size={16} />}  variant={'filled'} color={'green'} onClick={() => {setTableOfContentsDrawerIsOpened(true)}} style={{position: "fixed", bottom: 30, right: 30, zIndex: 20}}>Tartalomjegyzék</Button>

    <Drawer
      opened={tableOfContentsDrawerIsOpened}
      onClose={() => setTableOfContentsDrawerIsOpened(false)}
      title={<div style={{display: "flex", alignItems: "center", gap: "10px"}}>
        <IconListSearch size={20} stroke={1.5} />
        <h4 style={{fontWeight: "normal"}}>Tartalomjegyzék</h4>
      </div>}
      padding="xl"
      size="330px"
      position="right"
      lockScroll={false}
    >
      <ScrollArea h={"85vh"} offsetScrollbars w={"100%"} viewportRef={viewport} onScrollPositionChange={onScrollPositionChange}>
        <div style={{paddingLeft: "5px"}}>
          {/* <Group mb="md">
            <IconListSearch size="1.1rem" stroke={1.5} />
            <Text>Table of contents</Text>
          </Group> */}
          <div className={classes.links}>
            <div
              className={classes.indicator}
              style={{ transform: `translateY(${active * LINK_HEIGHT + INDICATOR_OFFSET}px)`}}
              />
            {items}
          </div>
        </div>
      </ScrollArea>
    </Drawer>
    </>
  );
}



// const links: { label: string; link: string; order: number }[] = [
//   {
//     "label": "Költségek",
//     "link": "#usage",
//     "order": 1
//   },
//   {
//     "label": "Cég szerinti bontásban",
//     "link": "#position",
//     "order": 2
//   },
//   {
//     "label": "Napi",
//     "link": "#overlays",
//     "order": 3
//   },
//   {
//     "label": "Heti",
//     "link": "#focus",
//     "order": 3
//   },
//   {
//     "label": "Havi",
//     "link": "#1",
//     "order": 3
//   },
//   {
//     "label": "Évi",
//     "link": "#2",
//     "order": 3
//   },
//   {
//     "label": "Cég szerinti bontásban",
//     "link": "#position",
//     "order": 2
//   },
//   {
//     "label": "Napi",
//     "link": "#overlays",
//     "order": 3
//   },
//   {
//     "label": "Heti",
//     "link": "#focus",
//     "order": 3
//   },
//   {
//     "label": "Havi",
//     "link": "#1",
//     "order": 3
//   },
//   {
//     "label": "Évi",
//     "link": "#2",
//     "order": 3
//   },
//   {
//     "label": "Cég szerinti bontásban",
//     "link": "#position",
//     "order": 2
//   },
//   {
//     "label": "Napi",
//     "link": "#overlays",
//     "order": 3
//   },
//   {
//     "label": "Heti",
//     "link": "#focus",
//     "order": 3
//   },
//   {
//     "label": "Havi",
//     "link": "#1",
//     "order": 3
//   },
//   {
//     "label": "Évi",
//     "link": "#2",
//     "order": 3
//   },
//   {
//     "label": "Bevételek",
//     "link": "#usage",
//     "order": 1
//   },
//   {
//     "label": "Cég szerinti bontásban",
//     "link": "#position",
//     "order": 2
//   },
//   {
//     "label": "Napi",
//     "link": "#overlays",
//     "order": 3
//   },
//   {
//     "label": "Heti",
//     "link": "#focus",
//     "order": 3
//   },
//   {
//     "label": "Havi",
//     "link": "#1",
//     "order": 3
//   },
//   {
//     "label": "Évi",
//     "link": "#2",
//     "order": 3
//   },
//   {
//     "label": "Cég szerinti bontásban",
//     "link": "#position",
//     "order": 2
//   },
//   {
//     "label": "Napi",
//     "link": "#overlays",
//     "order": 3
//   },
//   {
//     "label": "Heti",
//     "link": "#focus",
//     "order": 3
//   },
//   {
//     "label": "Havi",
//     "link": "#1",
//     "order": 3
//   },
//   {
//     "label": "Évi",
//     "link": "#2",
//     "order": 3
//   },
//   {
//     "label": "Cég szerinti bontásban",
//     "link": "#position",
//     "order": 2
//   },
//   {
//     "label": "Napi",
//     "link": "#overlays",
//     "order": 3
//   },
//   {
//     "label": "Heti",
//     "link": "#focus",
//     "order": 3
//   },
//   {
//     "label": "Havi",
//     "link": "#1",
//     "order": 3
//   },
//   {
//     "label": "Évi",
//     "link": "#2",
//     "order": 3
//   },
// ]