import { ActionIcon, Button, Input, Loader, NumberInput, ScrollArea, Select, Text, Tooltip } from '@mantine/core'
import { SetValues } from '@mantine/form/lib/types';
import { useDebouncedValue, useFocusReturn, useFocusTrap } from '@mantine/hooks';
import { collection, FieldValue, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import React, { MouseEventHandler, useEffect, useState } from 'react'
import { Cell, Minus, Plus, ReportMoney, Rotate } from 'tabler-icons-react'
import { yellow_light_button_bg, yellow_light_button_hover, yellow_light_button_text } from '../../../../App';
import { useTableCache } from '../../../../hooks/useDatabaseCache';

import { useStore } from '../../../../hooks/useStore';
import { FormSchemeType, FormStateType } from '../../../../interfaces/formTypes';

import "./FormBlock.css"
import FormItemCell from '../FormItemCell';
import FormItemRow from '../FormItemRow';
import { useInView } from 'react-intersection-observer';




interface FormItemProps {
    index: number,
    removeTable?: MouseEventHandler<HTMLDivElement>,
    title: string,
    //formScheme: {form: ({ column: string; title: string; initial_load: boolean; width_category: string; type: string; } | { column: string; title: string; width_category: string; type: string; initial_load?: boolean; })[], filter_with: string[], count_with: string[], columns: string[], table: string, icon: string};
}
function FormItem({ title, removeTable, index }: FormItemProps){


//   const [numberOfRows, setNumberOfRows] = useState(1)
  const formState: FormStateType = useStore((state:any) => state.formState)
  const setFormState = useStore((state:any) => state.setFormState)

  const setFormFinalPrice: Function = useStore((state:any) => state.setFormFinalPrice)

  const formMode = useStore((state:any) => state.formMode)
  const formModeIsDaily = formMode.toLowerCase().includes('dailyPost'.toLowerCase())
  //console.log(formState[index].data)
  const numberOfRows = formState[index].data.length


//   //console.log([...Array(numberOfRows)])

  function addPlusOneRow(){
    const innerstate = formState
    // //console.log('%cFormItem.tsx line:31 innerstate[index].data.slice(-1)', 'color: #007acc;', innerstate[index].data.slice(-1));
    const lastRow = JSON.parse(JSON.stringify(innerstate[index].data.at(-1))) // this is a cheat, there was a problem that when i used this line without the JSONs, the object's reference stayed the same when beeing copied the JSON's create a new reference to the array and to the objects

    // it adds the last row, containing the calculated price, to the state
    Object.entries(lastRow).forEach(function([key, value], index) {
        const valuecopy:any = value
        if(valuecopy.type === 'text'){
            lastRow[key] = { column: "counted_price", initial_load: false, title: "Kiszámolt ár", type: "text", value: 0, width_category: "2"};
        }
    });
    
    // nulling out the copied cells
    lastRow.forEach((cell: { value: string | number; }, index: number) => {
        if(typeof cell.value == "string"){
            lastRow[index].value = null
        } else if(typeof cell.value == "number"){
            lastRow[index].value = 0
        }
    })

    // //console.log(lastRow == innerstate[index].data[0])
    innerstate[index].data.push(lastRow)
    console.log(innerstate)
    setFormState([...innerstate])
  }

  function removeLastOneRow(){
    const innerstate = formState
    //console.log(innerstate)
    innerstate[index].data.pop();
    // //console.log(lastRowDeleted)

    setFormState([...innerstate])
    setFormFinalPrice(calculateFinalPrice(innerstate, formMode))
  }

  return (
    <div id={title} style={{"padding": "0 30px", "backgroundColor": "#25262b", "border": "1px solid #2C2E33", "borderRadius": "10px", "marginBottom": "50px"}}>
        <div style={{"display": "flex", "justifyContent": "space-between", "alignItems": "center"}}>
            <div style={{"width": "35%", "display": "flex", "alignItems": "center"}}>
                <h2 style={{"marginRight": "50px"}}>{title}</h2>
                <div style={{"display": "flex", "gap": "20px"}}>
                    <Button
                    variant='light'
                    leftIcon={<Plus size={20}/>}
                    onClick={() => addPlusOneRow()}
                    styles={() => ({
                        root: {
                          color: formModeIsDaily ? "rgb(178, 242, 187)" : yellow_light_button_text,
                          backgroundColor: formModeIsDaily ? "rgba(47, 158, 68, 0.2)" : yellow_light_button_bg,
                          "&:hover": {
                            backgroundColor: formModeIsDaily ? "rgba(55, 178, 77, 0.25)" : yellow_light_button_hover
                          }
                        },
                      })}
                    >
                        Új sor
                    </Button>
                    {numberOfRows > 1 ? <Button variant='light' color={"red"} leftIcon={<Minus size={20}/>} onClick={() => { removeLastOneRow() }}>Utolsó sor</Button> : ""}
                </div>
            </div>
            {index === formState.length-1 ? (
                <div className='close-button-icon-container' onClick={removeTable}>
                    <Plus size={20} style={{"rotate": "45deg"}}></Plus>
                </div>
            ) : ""}
        </div>

        <div>
            {formState[index].data.map((rowArray, rowindex: number) => {
                //console.log('%cFormItem.tsx line:30 index', 'color: #007acc;', rowindex);
                return (
                    <div key={rowindex} style={{display: "flex", gap: "15px", alignItems: "flex-start"}}>
                        <FormItemRow title={title} rowArray={rowArray} rowindex={rowindex}/>
                    </div>
                )
            })}
        </div>

    </div>
  )
}
// , (prevProps, nextProps) => {
//     // return false
//     if(prevProps.title.valueOf() === localStorage.getItem("table_changed_in_form")?.valueOf()){
//         //console.log(`${prevProps.title}-${localStorage.getItem("table_changed_in_form")}`)
//         // localStorage.removeItem("table_changed_in_form")
//         return true // rerenders
//     } else {
//         return false
//     }
// })
export default FormItem








export function calculateFinalPrice(innerState: FormStateType, formMode: string): number{

    let finalPrice = 0
    innerState.map((tableBlock) => {
        tableBlock.data.map((row) => {
            const cellIndex = row.findIndex(x => x.column === "counted_price")
            finalPrice += Number(row[cellIndex].value)
        })
    })
    console.log(finalPrice)
    // setFormFinalPrice(finalPrice)
    localStorage.setItem(`${formMode == "dailyPost" ? "daily" : "planned"}-form-final-price`, JSON.stringify(finalPrice))

    return finalPrice
}




