import { query, collection, where, getDocs, DocumentData, doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useAuth, useFirestore } from '../../hooks/useFirebase';
import Dashboard from '../../components/dashboard'
import ActionsGrid from './components/ActionsGrid'

function Datatables() {

  const [tables, settables] = useState<Array<DocumentData>>([])
  const [isLoading, setisLoading] = useState(true)

  async function getDataTables(){
    const db = useFirestore()
    const user = useAuth()
    // console.log(user)

    const docRef = doc(db, "user", `${user.uid}`);
    const docSnap = await getDoc(docRef);
    const tables = docSnap.data()?.database
    // console.log(docSnap.data()?.database)

    let icon
    tables.map(async (tableName:string, index:number) => {

      // cache icons in sessionstorage
      const iconsInSessionStorage = sessionStorage.getItem(`${tableName}-icon`)

      if(iconsInSessionStorage){
        // console.log("icon got from cache")
        icon = iconsInSessionStorage
      } else {
        
        // console.log("icon got from firestore")
        const docRef = doc(db, "table", tableName);
        const docSnap = await getDoc(docRef);
        icon = docSnap.data()?.icon

        // saving to cache
        sessionStorage.setItem(`${tableName}-icon`, icon)
      }


      tables[index] = { title: tableName, icon: icon }
    })
    // console.log(tables)
    settables(tables)

    setTimeout(() => {
      setisLoading(false)
    }, 200);
    // const q = query(collection(db, "user"), where("user_id", "==", auth.currentUser?.uid));
    // const querySnapshot = await getDocs(q);
    // querySnapshot.forEach((doc) => {
    //   settables([...tables, {title: doc.data()}])
    // });
  }

  useEffect(() => {
    getDataTables()
  }, [])
  

  return (
    <>
      <div style={{"overflowY": "scroll", "maxHeight": "calc(100vh - 60px - 30px)", "marginTop": "auto", "marginBottom": "auto"}}>
        <ActionsGrid tables={tables}/>
      </div>
      { isLoading ? <div className='loader-backdrop dashboard'><div className="loader"></div></div> : "" }
    </>
  )
}

export default Datatables