import { Button } from '@mantine/core'
import Lottie from 'lottie-react'
import fail from "../assets/lottie/failed.json";
import React from 'react'
import { useNavigate } from 'react-router-dom';

function PaymentFail() {
    const navigate = useNavigate()
  return (
    <div style={{"width": "100vw", "height": "100vh", "display": "flex", "flexDirection": "column","alignItems": "center", "justifyContent": "center"}}>
        <Lottie animationData={fail} loop={false} style={{"width": "200px"}}/>
        <h1>Sikertelen fizetés</h1>
        <h3>Ajjaj, valami hiba történt, vagy visszaléptél a fizetési oldalról</h3>
        <Button variant='light' color={"red"} style={{"background": "rgb(226 91 91 / 20%)"}} onClick={() => navigate("/")}>Meggondoltam magam</Button>
        <Button variant='light' style={{"marginTop": "18px"}} onClick={() => navigate("/register-organization")}>Újrapróbálkozom</Button>
    </div>
  )
}

export default PaymentFail