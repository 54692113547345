import { ScrollArea } from '@mantine/core'
import { collection, DocumentData, getDocs, onSnapshot, orderBy, query, Unsubscribe, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { AlertTriangle } from 'tabler-icons-react'
import { useAuth, useFirestore, useUserCompany } from '../../../../hooks/useFirebase'
import Contract from '../Contract'


interface ListProps {
  projectNumbers: {value:string, label:string}[],
  modeIsOwnContracts: boolean
}

export type contractType = { id:string, project_number: string, customer: string, contractor: string, contract_price: number, after_work: number, forint_readiness: number, percentage_readiness: number, created_at: number, comment: string, content: [] }


export default function List({ projectNumbers, modeIsOwnContracts }: ListProps) {

  const [contracts, setContracts] = useState<contractType[]>([])
  

  async function fetchContracts(){

    const db = useFirestore()
    const user = useAuth()
    const company = await useUserCompany()

    const q = query(collection(db, "contract"), where("user_id", "==", user.uid), where(`${modeIsOwnContracts ? "contractor" : "customer"}`, "==", company));//, orderBy("created_at", "desc"));
    const pff = getDocs(q)
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let items: any[] = [];
      querySnapshot.forEach(doc => {
        items.push({ ...doc.data(), id: doc.id });
      });
      items.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));

      setContracts(items)
      // console.log(querySnapshot)
      // console.log("Contracts: ", items);
    });

    return unsubscribe

  }
  


  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;

    fetchContracts().then((unsub) => {
      unsubscribe = unsub;
    });
  
    return () => {
      if (unsubscribe) {
        unsubscribe();
        console.log("unsubscribed from contracts listening");
      }
    };
  }, [])
  

  return (
    <div>
      {contracts.length == 0 ? (
        <div style={{ width : "100%" , height: "75vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
            <AlertTriangle size={56}/>
            <h3>Nincs {modeIsOwnContracts ? "saját" : "alvállalkozói"} szerződésed rögzítve.</h3>
          </div>
        </div>
      ) : (
        <ScrollArea style={{"height": "77.5vh"}}>
          {contracts.map((contract) => 
              <Contract
                key={contract.id}
                modeIsOwnContracts={modeIsOwnContracts}
                firestore_id={contract.id}
                project_number={contract.project_number}
                buyer={contract.customer}
                contractor={contract.contractor}
                contract_price={contract.contract_price}
                after_work_price={contract.after_work}
                forint_readiness={contract.forint_readiness}
                percentage_readiness={contract.percentage_readiness}
                project_numbers={projectNumbers}
                comment={contract.comment}
                showEditButton={true}
                showSelectButtonInsteadOfContentButton={false}
                onSelectButtonClick={() => {}}
              />
          )}
        </ScrollArea>
      )}
    </div>
  )
}
