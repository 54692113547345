import { Modal } from '@mantine/core'
import React, { useState } from 'react'
import Dashboard from '../../components/dashboard'
import Header from './Components/Header/Header'
import Table from './Components/Table'

function ProjectNumbers() {

  const [isEditing, setIsEditing] = useState(false)

  const [changesHaveBeenSaved, setChangesHaveBeenSaved] = useState(true)
  const [rowChangesMadeInTable, setRowChangesMadeInTable] = useState<object[]>([])

  

  return (
    <>
        <Header setIsEditing={setIsEditing} isEditing={isEditing} changesHaveBeenSaved={changesHaveBeenSaved} setChangesHaveBeenSaved={setChangesHaveBeenSaved} rowChangesMadeInTable={rowChangesMadeInTable} setRowChangesMadeInTable={setRowChangesMadeInTable}></Header>
        <Table isEditing={isEditing} setChangesHaveBeenSaved={setChangesHaveBeenSaved} rowChangesMadeInTable={rowChangesMadeInTable} setRowChangesMadeInTable={setRowChangesMadeInTable}></Table>
    </>
  )
}

export default ProjectNumbers