import { collection, getDocs, query, where } from "firebase/firestore";
import { ContractType } from "../interfaces/contractType";
import { DailyPostType } from "../interfaces/dailyPostType";
import { useFirestore, useUserCompany } from "./useFirebase";

export async function useAddSubcontractorContractsToPlan(Plans: DailyPostType[]){
    console.log("calculating subcontractorContractsToPlan")
    const db = useFirestore();

    Plans.sort((a, b) => {
        const dateA = a.date.seconds;
        const dateB = b.date.seconds;
        return dateB - dateA; // descending
    })

    // get projects numbers
    let project_numbers_array = Plans.map((post) => post.project_number)
    // filter out empty project numbers
    project_numbers_array = project_numbers_array.filter((project_number) => project_number !== "" && project_number !== null && project_number !== undefined)
    // filter out duplicates
    project_numbers_array = [...new Set(project_numbers_array)]
    console.log(project_numbers_array)

    const subcontractor_contracts_arary: ContractType[] = []
    const userCompany = await useUserCompany();

    const q = query(collection(db, "contract"), where("customer", "==", userCompany), where("project_number", "in", project_numbers_array));
    const querySnapshot = await getDocs(q);
    console.log("querySnapshot.size", querySnapshot.size)

    querySnapshot.docs.forEach((doc) => {
        subcontractor_contracts_arary.push(doc.data() as ContractType);
    })
    console.log("subcontractor_contracts_arary", subcontractor_contracts_arary)


    const subcontractor_contracts_object: { [key: string]: number } = {}
    subcontractor_contracts_arary.forEach((contract) => {

        if(subcontractor_contracts_object.hasOwnProperty(contract.project_number)){
            subcontractor_contracts_object[contract.project_number] += contract.contract_price + contract.after_work
        } else {
            subcontractor_contracts_object[contract.project_number] = contract.contract_price + contract.after_work
        }

    })
    console.log(subcontractor_contracts_object)

    Object.entries(subcontractor_contracts_object).forEach(([key, value]) => {
        Plans.forEach((post) => {
            if(post.project_number === key){
                post.final_price += value
            }
        })
    })
    console.log("Plans", Plans)

    return Plans

}