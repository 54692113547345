import { ActionIcon, Button, Modal, Paper, TextInput, Text } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react'
import { Trash } from 'tabler-icons-react';
import { useStore } from '../../../hooks/useStore';
import { contractContentType } from '../../../interfaces/contractContentType';
import { useNumberWithSpaces } from '../../../hooks/utils';

let timeoutId: string | number | NodeJS.Timeout | undefined;

interface SheetNamesRowProps {
    itemIndex: number,
    contractContent: contractContentType,
    removeButtonClick: Function,
    isEditMode: boolean
}

export default function SheetNamesRow({ itemIndex, contractContent, removeButtonClick, isEditMode }: SheetNamesRowProps) {

//   const inputRef = useRef<HTMLInputElement>(null);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const setContractContent = useStore((state:any) => state.setContractContent)

  const itemName = contractContent[itemIndex].sheetName

  const [sheetPrice, setSheetPrice] = useState(0)
  
  function handleContractContentChange(text: string){
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {

      const contractContentCopy = contractContent
      contractContentCopy[itemIndex].sheetName = text
      setContractContent([...contractContentCopy])

    }, 2000);
  }

  // count sheet price
  useEffect(() => {
    const f = () => {

      let count = 0
      contractContent[itemIndex].sheetData.forEach((item) => {
        item.tableData.forEach((tableItem) => {
          count += tableItem.total
        })
      })
      setSheetPrice(Math.round(count))
    }
    f()
  }, [])
  

  return (
    <>
    <Paper shadow="xs" p="md"  radius="lg" style={{backgroundColor: "#25262b", height: "70px", marginBottom: "15px"}}>
        <div style={{display: "flex", gap: "15px", alignItems: "center"}}>
            <TextInput
                variant='filled'
                placeholder="Munkalap neve"
                defaultValue={itemName}
                onChange={(event) => handleContractContentChange(event.currentTarget.value)}
                // ref={inputRef}
                style={{ width: "25vw"}}
            />
            {/* <ActionIcon variant="outline" onClick={() => setIsEditMode(!isEditMode)}><Edit size={16} /></ActionIcon> */}
            <ActionIcon variant="outline" color={"red"}><Trash size={16} onClick={() => setDeleteModalOpened(true)}/></ActionIcon>

            <Text>{useNumberWithSpaces(sheetPrice)} Ft</Text>
        </div>
    </Paper>
    
    <Modal
        withCloseButton={false}
        opened={deleteModalOpened}
        onClose={() => setDeleteModalOpened(false)}
        title={isEditMode ? <Text color={"red"}>FIGYELEM!</Text> : ""}
    >
      {isEditMode ? (<><h2>A munkalap tartalmazhat táblákat, a munkalap eltávolításával ezek a táblák is törlődnek!</h2><br /></>) : ""}
      <h2>
        Biztosan szeretnéd törölni ezt a munkalapot?
      </h2>
      <div style={{display: "flex", width: "100%", justifyContent: "space-between", gap: "15px"}}>
        <Button variant='light' color={'gray'} fullWidth onClick={() => setDeleteModalOpened(false)}>Mégsem</Button>
        <Button variant='filled' color={'red'} fullWidth onClick={() => { removeButtonClick(itemIndex), setDeleteModalOpened(false) }}>Törlés</Button>
      </div>
    </Modal>
    </>
  )
}
